import { LoadbankDto } from "../../../interfaces/foundry/typicals/LoadbankDto";
import ResponseDto from "../../../interfaces/ResponseDto";
import { TrackException } from "../../../logging/LoggingManager";
import useGeneralDatacenterObjectsStore from "../../../state/DatacenterState/generalDatacenterObjectsState";
import { foundryApiClient } from "../../../modules/sharedLogic/services/AxiosClient";
import { AxiosError } from "axios";

export async function GetLoadbank(id: number): Promise<LoadbankDto> {
  return (await GetLoadbanks()).find((x) => x.id === id) as LoadbankDto;
}

export async function GetLoadbanks(): Promise<LoadbankDto[]> {
  const store = useGeneralDatacenterObjectsStore.getState();
  let loadbanks = store.loadbanks;
  if (loadbanks.length === 0) {
    loadbanks = await GetLoadbankDtos();
    store.setLoadbanks(loadbanks);
  }
  return loadbanks;
}

async function GetLoadbankDtos(): Promise<LoadbankDto[]> {
  try {
    const coolings = (
      (await foundryApiClient.get<ResponseDto>("/api/general/loadbanks")).data
        .results as LoadbankDto[]
    ).map((item: LoadbankDto) => {
      item = {
        ...item,
        type: item.display_name,
      };
      return item;
    });
    return coolings;
  } catch (ex) {
    TrackException(ex as AxiosError);
    throw ex;
  }
}
