import { Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { UnitType } from "../../../sharedLogic/types/UnitType";
import "./UnitMix.scss";

interface ProjectUnitMixProps {
  projectUnits: UnitType[];
}

const ProjectUnitMix: React.FC<ProjectUnitMixProps> = ({ projectUnits }) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={1} alignItems="center" className="unit-mix">
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={1}>
            <div className="column-title">{t("st")} </div>
          </Grid>
          <Grid item xs={2.5}>
            <div className="column-title">{`${t("StackedHousing.Actual").toLowerCase()} %`}</div>
          </Grid>
          <Grid item xs={2.1}>
            <div className="column-title">{`${t("StackedHousing.Input").toLowerCase()} %`}</div>
          </Grid>
          <Grid item xs={1.25}>
            <div className="column-title">{"𝛥 %"}</div>
          </Grid>
          <Grid item xs>
            <div className="column-title">{t("StackedHousing.ModuleType")}</div>
          </Grid>
        </Grid>
      </Grid>
      {projectUnits.map((unit, index) => (
        <Grid item xs={12} key={index}>
          <Grid container>
            {unit.count !== undefined && (
              <Grid item xs={0.4}>
                <span className="unit-mix__count">
                  {Math.round(unit.count)}
                </span>
              </Grid>
            )}

            <Grid item xs={2.75}>
              <span data-testid={`ratio-test-id-${index}`} className="unit-mix__percentage_project">
                {unit.ratio === undefined || unit.ratio === null
                  ? ""
                  : `${Math.round(unit.ratio)}`}
              </span>
            </Grid>

            <Grid item xs={2}>
              <span data-testid={`input-ratio-test-id-${index}`} className="unit-mix__percentage_project">
                {unit.inputRatio === undefined || unit.inputRatio === null
                  ? ""
                  : `${Math.round(unit.inputRatio)}`}
              </span>
            </Grid>

            <Grid item xs={1.25}>
              <span
                data-testid={`delta-ratio-test-id-${index}`}
                className={`unit-mix__percentage_project${
                  (unit.ratio ?? 0) - (unit.inputRatio ?? 0) < 0 ? "-red" : ""
                }`}
              >
                {unit.ratio === undefined || unit.inputRatio === undefined
                  ? ""
                  : `${Math.round(unit.ratio - unit.inputRatio)}`}
              </span>
            </Grid>

            <Grid item xs={5} sx={{ marginLeft: "0.75rem" }}>
              <div className="unit-mix__name-container">
                <div
                  className="unit-mix__color-indicator"
                  style={{ backgroundColor: unit.color }}
                />
                <Typography
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "1",
                    WebkitBoxOrient: "vertical",
                  }}
                  variant="body2"
                  className="unit-mix__item-name"
                >
                  {t(unit.name)}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default ProjectUnitMix;
