import ColorPalette from "../../../interfaces/ColorPalette";
import { ExportedLayout } from "../../HSA/types/api";
import {
  BuildingLocation,
  ParkingDto,
  ProjectAddressFeatureDto,
} from "../types/api";
import DrawedPlot, { GetEmptyDrawedPlot } from "../types/DrawedPlot";
import { TypeObject } from "../types/enums";

export interface BaseConfiguratorState {
  drawedPlot: DrawedPlot;
  currentPageType: TypeObject | null;
  projectAddressFeature: ProjectAddressFeatureDto | null;
  startDrawingPlot: boolean;
  editDrawingPlot: boolean;
  deleteDrawingPlot: boolean;
  navigateDrawingPlot: boolean;
  isInputPaneOpen: boolean;
  currentTabPageIndex: number;
  exportTabDisabled: boolean;
  calculateDistance: boolean;
  distanceBetweenPlotAndRoad: number;
  isSaveAvailable: boolean;
  exportedLayouts: ExportedLayout[];
  drawControlMode: string;
  colorPalette: ColorPalette | undefined;
  selectedExportedBuilingsIds: { code: string; count: number }[];
  buildingLocations: BuildingLocation[];
  parking: ParkingDto | undefined;
}

export interface BaseConfiguratorActions {
  setDrawedPlot: (drawedPlot: DrawedPlot) => void;
  setProjectAddressFeature: (
    projectAddressFeature: ProjectAddressFeatureDto
  ) => void;
  setStartDrawingPlot: (newValue: boolean) => void;
  setEditDrawingPlot: (boolValue: boolean) => void;
  setDeleteDrawingPlot: (boolValue: boolean) => void;
  setNavigateDrawingPlot: (newValue: boolean) => void;
  setIsInputPaneOpen: (boolValue: boolean) => void;
  setCurrentTabPageIndex: (newValue: number) => void;
  setExportTabDisabled: (boolValue: boolean) => void;
  setDistanceBetweenPlotAndRoad: (values: number) => void;
  setCalculateDistance: (values: boolean) => void;
  setCurrentPageType: (value: TypeObject | null) => void;
  setIsSaveAvailable: (value: boolean) => void;
  setExportedLayouts: (value: ExportedLayout[]) => void;
  setDrawControlMode: (value: string) => void;
  setColorPalette: (colorPalette: ColorPalette) => void;
  setBuildingLocations: (buildingCoordinates: BuildingLocation[]) => void;
  setParking: (parking: ParkingDto) => void;
  setSelectedExportedBuildingsIds: (
    values: { code: string; count: number }[]
  ) => void;
}

export function getBaseInitialState(): BaseConfiguratorState {
  return {
    drawedPlot: GetEmptyDrawedPlot(),
    currentPageType: null,
    projectAddressFeature: null,
    startDrawingPlot: false,
    editDrawingPlot: false,
    deleteDrawingPlot: false,
    navigateDrawingPlot: false,
    isInputPaneOpen: true,
    currentTabPageIndex: 0,
    exportTabDisabled: false,
    distanceBetweenPlotAndRoad: 0,
    calculateDistance: false,
    isSaveAvailable: false,
    exportedLayouts: [],
    drawControlMode: "",
    colorPalette: undefined,
    selectedExportedBuilingsIds: [],
    buildingLocations: [],
    parking: undefined,
  };
}

export function getBaseActions(
  set: (state: Partial<BaseConfiguratorState>) => void
): BaseConfiguratorActions {
  return {
    setDrawedPlot: (drawedPlot: DrawedPlot) => set({ drawedPlot: drawedPlot }),
    setProjectAddressFeature: (feature: ProjectAddressFeatureDto) =>
      set({ projectAddressFeature: feature }),
    setStartDrawingPlot: (newValue: boolean) =>
      set({ startDrawingPlot: newValue }),
    setEditDrawingPlot: (newValue: boolean) =>
      set({ editDrawingPlot: newValue }),
    setDeleteDrawingPlot: (newValue: boolean) =>
      set({ deleteDrawingPlot: newValue }),
    setNavigateDrawingPlot: (newValue: boolean) =>
      set({ navigateDrawingPlot: newValue }),
    setIsInputPaneOpen: (newValue: boolean) =>
      set({ isInputPaneOpen: newValue }),
    setCurrentTabPageIndex: (newValue: number) =>
      set({ currentTabPageIndex: newValue }),
    setExportTabDisabled: (newValue: boolean) =>
      set({ exportTabDisabled: newValue }),
    setDistanceBetweenPlotAndRoad: (newValue: number) =>
      set({ distanceBetweenPlotAndRoad: newValue }),
    setCalculateDistance: (newValue: boolean) =>
      set({ calculateDistance: newValue }),
    setCurrentPageType: (value: TypeObject | null) =>
      set({ currentPageType: value }),
    setIsSaveAvailable: (newValue: boolean) =>
      set({ isSaveAvailable: newValue }),
    setExportedLayouts: (value: ExportedLayout[]) =>
      set({ exportedLayouts: value }),
    setDrawControlMode: (value: string) => set({ drawControlMode: value }),
    setColorPalette: (colorPalette: ColorPalette) =>
      set({ colorPalette: colorPalette }),
    setSelectedExportedBuildingsIds: (
      values: { code: string; count: number }[]
    ) => set({ selectedExportedBuilingsIds: values }),
    setBuildingLocations: (locations: BuildingLocation[]) =>
      set({ buildingLocations: locations }),
    setParking: (parking: ParkingDto) => set({ parking: parking }),
  };
}
