import * as turf from "@turf/turf";
import { Element } from "../../../../../../HSA/types/api";
import { convertMilimetersToMeters } from "../../../../../utils/format";
import { MetersToLatitude, MetersToLongitude } from "../../DrawControlUtils";

export function ApplyBuildingTransformations(
  features: GeoJSON.Feature[],
  gpsCoordinates: number[][]
): GeoJSON.Feature[] {
  const coordinates = (features[0].geometry as GeoJSON.Polygon).coordinates;
  const rotationAngle = turf.bearing(coordinates[0][0], coordinates[0][1]);
  const buildingFeatureCollection: GeoJSON.FeatureCollection = {
    type: "FeatureCollection",
    features: [...features],
  };
  const buildingRotationAngle = turf.bearing(
    gpsCoordinates[0],
    gpsCoordinates[1]
  );
  const rotationAngleDelta = buildingRotationAngle - rotationAngle;
  const targetPosition = gpsCoordinates[0];
  const groundFloorFeatures = features.filter(
    (feature) =>
      feature.properties?.floorIndex === 0 && !feature.properties?.isSeparator
  );
  if (groundFloorFeatures.length === 0) {
    return features;
  }
  const firstGroundFeature = groundFloorFeatures[0];
  const currentPosition = (firstGroundFeature.geometry as GeoJSON.Polygon)
    .coordinates[0][0];
  const bearing = turf.rhumbBearing(
    turf.point(currentPosition),
    turf.point(targetPosition)
  );
  const distance = turf.rhumbDistance(
    turf.point(currentPosition),
    turf.point(targetPosition)
  );
  const translatedCollection = turf.transformTranslate(
    buildingFeatureCollection as turf.AllGeoJSON,
    distance,
    bearing
  ) as GeoJSON.FeatureCollection;
  const rotatedCollection = turf.transformRotate(
    translatedCollection as turf.AllGeoJSON,
    rotationAngleDelta,
    {
      pivot: targetPosition as turf.helpers.Coord,
    }
  ) as GeoJSON.FeatureCollection;
  return rotatedCollection.features;
}

export function ProcessFloorElements(
  elements: Element[],
  buildingName: string,
  buildingCode: string,
  floorIndex: number,
  currentBuildingBasePoint: number[]
): GeoJSON.Feature[] {
  const features: GeoJSON.Feature[] = [];
  const elementsByRow = elements.reduce((acc, element) => {
    const yKey = element.spatialLocation.y.toFixed(2);
    if (!acc[yKey]) acc[yKey] = [];
    acc[yKey].push(element);
    return acc;
  }, {} as Record<string, Element[]>);

  Object.values(elementsByRow).forEach((rowElements) => {
    const sortedRowElements = rowElements.sort(
      (a, b) => a.spatialLocation.x - b.spatialLocation.x
    );

    for (let i = 0; i < sortedRowElements.length; i++) {
      const element = sortedRowElements[i];
      const adjustedXMeters = convertMilimetersToMeters(
        element.spatialLocation.x
      );
      features.push(
        ...createElementFeature(
          element,
          buildingName,
          buildingCode,
          floorIndex,
          adjustedXMeters,
          currentBuildingBasePoint
        )
      );
    }
  });

  return features;
}

function createElementFeature(
  element: Element,
  buildingName: string,
  buildingCode: string,
  floorIndex: number,
  adjustedXMeters: number,
  currentBuildingBasePoint: number[]
): GeoJSON.Feature[] {
  const features: GeoJSON.Feature[] = [];
  const { spatialLocation, dimensions } = element;
  const yMeters = convertMilimetersToMeters(spatialLocation.y);
  const widthMeters = dimensions?.xSizeInMm
    ? convertMilimetersToMeters(dimensions.xSizeInMm)
    : 0;
  const lengthMeters = dimensions?.ySizeInMm
    ? convertMilimetersToMeters(dimensions.ySizeInMm)
    : 0;
  const baseHeight = convertMilimetersToMeters(spatialLocation.z);
  const height =
    baseHeight +
    (dimensions?.zSizeInMm
      ? convertMilimetersToMeters(dimensions.zSizeInMm)
      : 0);
  const minX =
    currentBuildingBasePoint[0] +
    MetersToLongitude(adjustedXMeters, currentBuildingBasePoint[1]);
  const maxX =
    currentBuildingBasePoint[0] +
    MetersToLongitude(
      adjustedXMeters + widthMeters,
      currentBuildingBasePoint[1]
    );
  const minY = currentBuildingBasePoint[1] + MetersToLatitude(yMeters);
  const maxY =
    currentBuildingBasePoint[1] + MetersToLatitude(yMeters + lengthMeters);

  features.push({
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [minX, minY],
          [maxX, minY],
          [maxX, maxY],
          [minX, maxY],
          [minX, minY],
        ],
      ],
    },
    properties: {
      buildingName: buildingName,
      buildingCode: buildingCode,
      spaceId: element.spaceId,
      floorIndex,
      baseHeight,
      height,
      color: element.color,
      widthMeters,
      lengthMeters,
    },
  });

  const corners = [
    [minX, minY],
    [maxX, minY],
    [maxX, maxY],
    [minX, maxY],
  ];

  corners.forEach((corner) => {
    features.push(
      createContourPoint(
        corner,
        buildingName,
        buildingCode,
        floorIndex,
        baseHeight,
        height
      )
    );
  });

  return features;
}

function createContourPoint(
  point: number[],
  buildingName: string,
  buildingCode: string,
  floorIndex: number,
  baseHeight: number,
  height: number
): GeoJSON.Feature {
  const size = 0.00000025;
  return {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [point[0] - size, point[1] - size],
          [point[0] + size, point[1] - size],
          [point[0] + size, point[1] + size],
          [point[0] - size, point[1] + size],
          [point[0] - size, point[1] - size],
        ],
      ],
    },
    properties: {
      buildingName,
      buildingCode,
      spaceId: `contour-${point[0]}-${point[1]}`,
      floorIndex,
      baseHeight,
      height: height,
      color: "#808080",
      widthMeters: size,
      lengthMeters: size,
      isContourPoint: true,
    },
  };
}