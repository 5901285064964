import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en/translation.json";
import nl from "./nl/translation.json";
import useLayoutStore from "../modules/sharedLogic/state/layoutState";

const defaultLng = "nl-NL";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    nl: {
      translation: nl,
    },
  },
  lng: useLayoutStore?.getState()?.selectedLang ?? defaultLng,
  fallbackLng: defaultLng,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
