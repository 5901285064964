import * as turf from "@turf/turf";
import proj4 from "proj4";
import wellknown from "wellknown";
import { BuildingLocation } from "../../../types/api";
import DrawedPlot from "../../../types/DrawedPlot";
import { GenerateGuid } from "../../../utils/HelperFunctions";

const polygonColor = "#000";
const polygonSelectedColor = "#FEB800";
const polygonPlotWithOffsetColor = "#FFFFFF";
const offsetDistance = -1.6;
const metersPerDegree = 111320;

proj4.defs(
  "EPSG:28992",
  "+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +units=m +towgs84=565.2369,50.0087,465.658,-0.406857330322398,0.350732676542563,-1.8703473836068,4.0812 +no_defs"
);

export function CalculateOffsetGeometry(
  existingGeometry: turf.Position[],
  offsetDistance: number
): turf.Polygon {
  const existingPolygon = turf.polygon([existingGeometry]);
  const bufferedPolygon = turf.buffer(existingPolygon, offsetDistance, {
    units: "meters",
  });
  return bufferedPolygon?.geometry;
}

export function CalculateRdCoordinates(coordinates: number[][]): number[][] {
  const rdCoordinates: number[][] = [];
  coordinates.forEach((point) => {
    const rdPoint = proj4("EPSG:4326", "EPSG:28992", [point[0], point[1]]);
    rdCoordinates.push(rdPoint);
  });

  return rdCoordinates;
}

export function GetPolygonOfString(
  polygon2d: string,
  plotOrigin: number[] | undefined = undefined
) {
  const divideBy = 100000000;
  const geojsonGeometry = wellknown.parse(polygon2d);
  const adjustedCoordinates: number[][] = [];
  (geojsonGeometry as GeoJSON.Polygon).coordinates[0].forEach((position) => {
    if (plotOrigin && plotOrigin.length === 2) {
      const x = Number(position[0]) / divideBy + plotOrigin[0];
      const y = Number(position[1]) / divideBy + plotOrigin[1];
      adjustedCoordinates.push([x, y]);
    } else {
      adjustedCoordinates.push([Number(position[0]), Number(position[1])]);
    }
  });
  return turf.polygon([adjustedCoordinates]);
}

export function ConvertLongLatToRd(coordinates: number[][]): number[][] {
  const rdCoordinates: number[][] = [];
  coordinates.forEach((point) => {
    const rdPoint = proj4("EPSG:4326", "EPSG:28992", [point[0], point[1]]);
    rdCoordinates.push(rdPoint);
  });

  return rdCoordinates;
}

export function ConvertRdToLongLat(coordinates: number[][]): number[][] {
  const longLatCoordinates: number[][] = [];
  coordinates.forEach((point) => {
    const longLatPoint = proj4("EPSG:28992", "EPSG:4326", [point[0], point[1]]);
    longLatCoordinates.push(longLatPoint);
  });
  return longLatCoordinates;
}

export function GetDrawedPlot(
  drawedPlot: DrawedPlot,
  plot: string
): DrawedPlot {
  const tempDrawedPlot = drawedPlot;
  if (typeof plot === "string" && plot && plot !== "") {
    const geojsonGeometry = wellknown.parse(plot);
    tempDrawedPlot.polygon = {
      id: GenerateGuid(),
      type: "Feature",
      geometry: geojsonGeometry,
      properties: {},
    } as GeoJSON.Feature<GeoJSON.Geometry>;
  }

  return tempDrawedPlot;
}

export function CreateDrawedPlot(
  polygon: GeoJSON.Feature<GeoJSON.Geometry>,
  drawedPlot?: DrawedPlot | undefined
) {
  let unqiueCoordinates: number[][] = [];
  if (polygon.geometry && polygon.geometry.type === "Polygon") {
    unqiueCoordinates = polygon.geometry.coordinates[0];
  }

  const turfPositions: turf.Position[] = [];
  unqiueCoordinates.forEach((point) => {
    turfPositions.push([point[0], point[1]] as turf.Position);
  });

  const offsetGeometry = CalculateOffsetGeometry(turfPositions, offsetDistance);
  const positions: number[][] = [];
  offsetGeometry.coordinates[0].forEach((position) => {
    positions.push([Number(position[0]), Number(position[1])]);
  });

  const newDrawedPlot = {
    id: polygon.id,
    polygon: polygon,
    lotSurface: Math.round((turf.area(polygon) * 100) / 100),
    lotCoordinates: unqiueCoordinates,
    lotRdCoordinates: ConvertLongLatToRd(unqiueCoordinates),
    buildableSurface:
      drawedPlot?.buildableSurface ||
      Math.round((turf.area(offsetGeometry) * 100) / 100),
    plotPercentage: drawedPlot?.plotPercentage || 85,
    buildableRdCoordinates: ConvertLongLatToRd(positions),
    buildableCoordinates: positions,
  } as DrawedPlot;

  return newDrawedPlot;
}

export function GetBuildableLot(
  plotPolygon: GeoJSON.Feature<GeoJSON.Geometry>
): turf.helpers.Feature<turf.helpers.Polygon, turf.helpers.Properties> {
  let plotCoordinates: number[][] = [];
  if (plotPolygon.geometry && plotPolygon.geometry.type === "Polygon") {
    plotCoordinates = plotPolygon.geometry.coordinates[0];
  }
  const turfPositions: turf.Position[] = [];
  plotCoordinates.forEach((point) => {
    turfPositions.push([point[0], point[1]] as turf.Position);
  });
  const existingPolygon = turf.polygon([plotCoordinates]);
  const bufferedPolygon = turf.buffer(existingPolygon, offsetDistance, {
    units: "meters",
  });

  return bufferedPolygon;
}

export function ExtractBuildingCoordinates(
  buildings: GeoJSON.FeatureCollection
): BuildingLocation[] {
  const buildingLocations = [] as BuildingLocation[];
  buildings.features?.forEach((feature) => {
    if (
      !feature.properties?.buildingName ||
      feature.geometry.type !== "Polygon"
    ) {
      return;
    }

    const buildingName = feature.properties.buildingName;
    if (!buildingLocations.find((x) => x.name === buildingName)) {
      const buildingLocation = {
        name: buildingName,
        code: feature.properties.buildingCode,
        gps: (feature.geometry as GeoJSON.Polygon).coordinates[0],
        rd: ConvertLongLatToRd(
          (feature.geometry as GeoJSON.Polygon).coordinates[0]
        ),
      } as BuildingLocation;
      buildingLocations.push(buildingLocation);
    }
  });

  return buildingLocations;
}

export function GetPlotOrigin(plotPolygon: GeoJSON.Feature<GeoJSON.Geometry>) {
  const buildableLot = GetBuildableLot(plotPolygon);
  const plotCoordinates = buildableLot.geometry.coordinates[0];
  return [Number(plotCoordinates[0][0]), Number(plotCoordinates[0][1])];
}

export function MetersToLongitude(meters: number, latitude: number): number {
  const longitudeScale = metersPerDegree * Math.cos((latitude * Math.PI) / 180);
  return meters / longitudeScale;
}

export function MetersToLatitude(meters: number): number {
  return meters / metersPerDegree;
}

export function GetDrawControlStyles() {
  return [
    {
      id: "gl-draw-line",
      slot: "middle",
      type: "line",
      filter: ["all", ["==", "$type", "LineString"], ["==", "active", "true"]],
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      paint: {
        "line-color": polygonSelectedColor,
        "line-dasharray": [0.2, 2],
      },
    },
    {
      id: "gl-draw-polygon-fill",
      slot: "middle",
      type: "fill",
      filter: ["all", ["==", "$type", "Polygon"], ["==", "active", "true"]],
      paint: {
        "fill-color": polygonSelectedColor,
        "fill-outline-color": polygonSelectedColor,
        "fill-opacity": 0.1,
      },
    },
    {
      id: "gl-draw-polygon-midpoint",
      slot: "middle",
      type: "circle",
      filter: ["all", ["==", "$type", "Point"], ["==", "meta", "midpoint"]],
      paint: {
        "circle-radius": 3,
        "circle-color": polygonSelectedColor,
      },
    },
    {
      id: "gl-draw-polygon-stroke-active",
      slot: "middle",
      type: "line",
      filter: ["all", ["==", "$type", "Polygon"], ["==", "active", "true"]],
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      paint: {
        "line-color": polygonSelectedColor,
        "line-dasharray": [0.2, 2],
      },
    },
    {
      id: "gl-draw-polygon-and-line-vertex-halo-active",
      slot: "middle",
      type: "circle",
      filter: [
        "all",
        ["==", "meta", "vertex"],
        ["==", "$type", "Point"],
        ["!=", "type_component", "datacenter"],
      ],
      paint: {
        "circle-radius": 5,
        "circle-color": "#FFF",
      },
    },
    {
      id: "gl-draw-polygon-and-line-vertex-active",
      slot: "middle",
      type: "circle",
      filter: ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"]],
      paint: {
        "circle-radius": 5,
        "circle-color": polygonSelectedColor,
      },
    },
    {
      id: "gl-draw-polygon-fill-inactive",
      slot: "middle",
      type: "fill",
      filter: ["all", ["==", "$type", "Polygon"], ["==", "active", "false"]],
      paint: {
        "fill-color": [
          "case",
          ["==", ["get", "user_class_id"], 0],
          polygonColor,
          ["==", ["get", "user_class_id"], 1],
          polygonPlotWithOffsetColor,
          ["==", ["get", "user_class_id"], 2],
          "#0000ff",
          "#ff0000",
        ],
        "fill-outline-color": [
          "case",
          ["==", ["get", "user_class_id"], 0],
          polygonColor,
          ["==", ["get", "user_class_id"], 1],
          polygonPlotWithOffsetColor,
          ["==", ["get", "user_class_id"], 2],
          "#0000ff",
          "#ff0000",
        ],
        "fill-opacity": 0.1,
      },
    },
    {
      id: "gl-draw-polygon-fill-inactive.hot",
      slot: "middle",
      type: "fill",
      filter: ["all", ["==", "$type", "Polygon"], ["==", "active", "false"]],
      paint: {
        "fill-color": "#000000",
        "fill-outline-color": "#000000",
        "fill-opacity": 0.1,
      },
    },
    {
      id: "gl-draw-polygon-stroke-inactive",
      type: "line",
      slot: "middle",
      filter: [
        "all",
        ["==", "$type", "Polygon"],
        ["==", "active", "false"],
        ["==", "user_kind", "plot"],
      ],
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      paint: {
        "line-color": [
          "case",
          ["==", ["get", "user_class_id"], 0],
          polygonColor,
          ["==", ["get", "user_class_id"], 1],
          polygonPlotWithOffsetColor,
          ["==", ["get", "user_class_id"], 2],
          "#0000ff",
          "#ff0000",
        ],
      },
    },
    {
      id: "gl-draw-polygon-stroke-plot-inactive",
      slot: "middle",
      type: "line",
      filter: [
        "all",
        ["==", "$type", "Polygon"],
        ["==", "active", "false"],
        ["==", "user_kind", "buildablePlot"],
      ],
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      paint: {
        "line-color": [
          "case",
          ["==", ["get", "user_class_id"], 0],
          polygonColor,
          ["==", ["get", "user_class_id"], 1],
          polygonPlotWithOffsetColor,
          ["==", ["get", "user_class_id"], 2],
          "#0000ff",
          "#ff0000",
        ],
      },
    },
  ];
}
