import { Grid, Link, Typography } from "@mui/material";
import { ModuleDetailedView } from "../../../HSA/components/ModuleDetailedView/ModuleDetailedView";
import { ModuleDetailedDialogHeader } from "../../../HSA/components/ModuleDetailedView/ModuleDetailedViewHeader";
import { SpaceCodeCounts } from "../../../HSA/types/api";
import { useDialog } from "../Dialog/CustomDialog";
import NumberField from "./NumberField";

interface ComponentsListProps {
  components: SpaceCodeCounts[];
}

export default function ComponentsList({ components }: ComponentsListProps) {
  const { openDialog, closeDialog } = useDialog();

  const handleLinkClick = (code: string) => {
    openDialog({
      title: code,
      content: <ModuleDetailedView currentModuleCode={code} />,
      width: "75%",
      height: "85%",
      dialogCustomHeader: (
        <ModuleDetailedDialogHeader
          onSave={() => {}}
          onClose={() => closeDialog()}
        />
      ),
    });
  };

  return (
    <div>
      {components.map((component, index) => (
        <Grid
          marginTop={-1}
          key={index}
          container
          columnSpacing={1}
          className="sidebar-section-property"
        >
          <Grid item xs={6}>
            <Typography component="div">
              <Link
                onClick={() => handleLinkClick(component.spaceCode)}
                component="button"
                sx={{
                  cursor: "pointer",
                  color: "black",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  marginTop: "5px",
                }}
              >
                {component.codeBusiness}
              </Link>
            </Typography>
          </Grid>
          <Grid item className="center-center" xs={"auto"}>
            <div>{":"}</div>
          </Grid>
          <Grid item xs className="center-center">
            <NumberField value={component.count} disabled={true} unit={"st"} />
          </Grid>
        </Grid>
      ))}
    </div>
  );
}
