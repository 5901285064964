import React from "react";
import { useTranslation } from "react-i18next";
import { TriangleIcon } from "../../../../../../icons/TriangleIcon";
import "./Legend.scss";
import { LegendItem } from "../../../../types/LegendaItem";

interface LegendProps {
  items: LegendItem[];
  objectScope?: string;
}

const LegendItemComponent: React.FC<LegendItem> = ({
  color,
  label,
  opacity,
  lineStyle,
}) => (
  <div className="legend-item">
    {opacity ? (
      lineStyle === "triangle" ? (
        <div style={{ marginLeft: "2px", marginRight: "8px" }}>
          <TriangleIcon size={14} />
        </div>
      ) : (
        <div
          className="legend-color"
          style={{
            backgroundColor: color,
            opacity: opacity,
          }}
        >
          {lineStyle === "cross" && <div className="legend-cross">X</div>}
        </div>
      )
    ) : lineStyle === "solid" ? (
      <div
        className="legend-line"
        style={{ border: `1px solid ${color}` }}
      ></div>
    ) : (
      <div>
        <div
          data-testid={"legend-line"}
          className="legend-line"
          style={{ border: `1px dashed ${color}` }}
        ></div>
      </div>
    )}
    <div data-testid={"legend-label"} className="legend-label">
      {label}
    </div>
  </div>
);

export default function Legend({ items, objectScope }: LegendProps) {
  const { t } = useTranslation();

  return (
    <div className="legend">
      {items.map((item, index) =>
        objectScope !== "HYPERSCALE" && item.label === "mdb" ? null : (
          <LegendItemComponent
            key={index}
            color={item.color}
            label={t(item.label)}
            opacity={item.opacity}
            lineStyle={item.lineStyle}
          />
        )
      )}
    </div>
  );
}
