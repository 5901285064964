import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { Fab, Popover, Typography } from "@mui/material";
import { AxiosError } from "axios";
import { LatLngExpression, Polygon } from "leaflet";
import mapboxgl, { LngLat, LngLatBounds } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ViewState } from "react-map-gl";
import { StoreApi, UseBoundStore } from "zustand";
import { MapboxAccessToken } from "../../../../config";
import { EllipseIcon } from "../../../../icons/EllipseIcon";
import useLayoutStore from "../../state/layoutState";
import { TrackException } from "../../logging/LoggingManager";
import DrawedMarker from "../../types/DrawedMarker";
import { MapDrawFeatureType, MapType } from "../../types/enums";
import DatacenterDrawControlView from "./Components/DrawControls/DatacenterDrawControl/DatacenterDrawControlView";
import FeatureDrawControlView from "./Components/DrawControls/FeatureDrawControl/FeatureDrawControlView";
import MarkerDrawControlView from "./Components/DrawControls/MarkerDrawControl/MarkerDrawControlView";
import PlotDrawControlView from "./Components/DrawControls/PlotDrawControl/PlotDrawControlView";
import Legend from "./Components/Legenda/Legend";
import { CalculateDistanceAOrNRoad } from "./Components/PdokDistanceCalculator";
import "./Maps.scss";

interface MapsProps {
  mapType: MapType;
  currentConfiguratorStore: UseBoundStore<StoreApi<any>>;
  mapDrawFeatureType?: MapDrawFeatureType;
  legendaItems?: { color: string; label: string; opacity: number }[];
  showFloatingActionButton?: boolean;
  drawedMarker?: DrawedMarker;
  setDrawedMarker?: (drawedMarker: DrawedMarker) => void;
  setProjectAddressFeatures?: (address: string) => void;
}

export default function Maps({
  mapType,
  mapDrawFeatureType,
  legendaItems,
  currentConfiguratorStore,
  showFloatingActionButton = false,
  drawedMarker,
  setDrawedMarker,
  setProjectAddressFeatures,
}: MapsProps) {
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const [viewport] = useState<ViewState>({
    bearing: 0,
    padding: { top: 0, bottom: 0, left: 0, right: 0 },
    pitch: 0,
    longitude: 5.52,
    latitude: 52.2,
    zoom: 6.4,
  });
  const { t } = useTranslation();
  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { selectedLang } = useLayoutStore();

  const {
    drawedPlot,
    projectAddressFeature,
    navigateDrawingPlot,
    setCalculateDistance,
    setNavigateDrawingPlot,
    calculateDistance,
    setDistanceBetweenPlotAndRoad,
  } = currentConfiguratorStore();

  useEffect(() => {
    if (
      projectAddressFeature &&
      projectAddressFeature.geometry.coordinates &&
      projectAddressFeature.geometry.coordinates.length > 0 &&
      map
    ) {
      let zoom = 1;
      switch (projectAddressFeature?.properties.feature_type) {
        case "country":
          zoom = 5;
          break;
        case "district":
        case "region":
          zoom = 8;
          break;
        case "neighborhood":
        case "postcode":
        case "locality":
        case "place":
          zoom = 13.5;
          break;
        case "poi":
        case "address":
          zoom = 19;
          break;
      }

      const lngLat: mapboxgl.LngLatLike = {
        lng: projectAddressFeature.geometry.coordinates[0],
        lat: projectAddressFeature.geometry.coordinates[1],
      };
      map.flyTo({ center: lngLat, zoom: zoom, speed: 2 });
    }
  }, [projectAddressFeature]);

  useEffect(() => {
    if (navigateDrawingPlot) {
      const coordinates = drawedPlot?.lotCoordinates;
      if (coordinates) {
        NavigateToFeature(coordinates);
      }
    }
  }, [navigateDrawingPlot]);

  useEffect(() => {
    if (
      calculateDistance &&
      drawedPlot.buildableCoordinates.length > 0 &&
      map
    ) {
      CalculateDistanceAOrNRoad(map, drawedPlot, setDistanceBetweenPlotAndRoad);
      setCalculateDistance(false);
    }
  }, [calculateDistance]);

  useEffect(() => {
    mapboxgl.accessToken = MapboxAccessToken;
    const map = new mapboxgl.Map({
      container: mapContainerRef.current || "",
      style: "mapbox://styles/mapbox/standard",
      center: [viewport.longitude, viewport.latitude],
      zoom: viewport.zoom,
      antialias: true,
      preserveDrawingBuffer: true,
    });

    map.addControl(
      new mapboxgl.ScaleControl({ unit: "metric" }),
      "bottom-right"
    );
    map.addControl(
      new mapboxgl.NavigationControl({
        showCompass: true,
        showZoom: true,
        visualizePitch: true,
      }),
      "bottom-right"
    );

    map.on("load", () => {
      let localeName = `name_${selectedLang}`;

      for (const layerId in map.style._mergedLayers) {
        const layer = map.style._mergedLayers[layerId];
        if (layer.type === "symbol") {
          let textField = layer.getLayoutProperty("text-field");
          if (!textField) continue;
          let expression = JSON.stringify(textField);
          let expressionNew = expression.replace(
            /"name_.+?"/g,
            `"${localeName}"`
          );
          if (expressionNew !== expression) {
            layer.setLayoutProperty("text-field", JSON.parse(expressionNew));
            map.style._updateLayer(layer);
          }
        }
      }
      map._update(true);
    });

    setMap(map);
  }, []);

  function NavigateToFeature(coordinates: number[][]) {
    try {
      if (map && coordinates.length > 0) {
        const fit = new Polygon(coordinates as LatLngExpression[]).getBounds();
        const southWest = new LngLat(
          fit.getSouthWest().lat,
          fit.getSouthWest().lng
        );
        const northEast = new LngLat(
          fit.getNorthEast().lat,
          fit.getNorthEast().lng
        );

        map.fitBounds(new LngLatBounds(southWest, northEast), {
          padding: { top: 75, bottom: 75, left: 75, right: 75 },
        });
      }
    } catch (ex) {
      TrackException(ex as AxiosError);
    } finally {
      if (setNavigateDrawingPlot) {
        setNavigateDrawingPlot(false);
      }
    }
  }

  return (
    <div className="map-view">
      <div ref={mapContainerRef} id="map" style={{ height: "100%" }} />
      {legendaItems && <Legend items={legendaItems} />}

      {mapType === MapType.CreatePlot && (
        <PlotDrawControlView
          map={map}
          currentStore={currentConfiguratorStore}
        />
      )}

      {mapType === MapType.GenerateFeature && (
        <FeatureDrawControlView
          map={map}
          currentStore={currentConfiguratorStore}
          mapDrawFeatureType={mapDrawFeatureType!}
        />
      )}

      {mapType === MapType.GenerateDatacenter && (
        <DatacenterDrawControlView
          map={map}
          currentStore={currentConfiguratorStore}
        />
      )}

      {mapType === MapType.CreateMarker && (
        <MarkerDrawControlView
          map={map}
          currentStore={currentConfiguratorStore}
          drawedMarker={drawedMarker!}
          setProjectAddress={setProjectAddressFeatures!}
          setDrawedMarker={setDrawedMarker!}
        />
      )}

      {showFloatingActionButton && (
        <div className="floating-action-button-layout">
          <Fab
            id="fab"
            className="floating-action-button"
            aria-label="add"
            disabled={!drawedPlot || drawedPlot.lotCoordinates.length === 0}
            onClick={handleClick}
          >
            <EllipseIcon size={25} />
          </Fab>

          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
          >
            <div className="popover-content">
              <Typography
                sx={{ p: 2 }}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleClose();
                  setCalculateDistance(true);
                }}
              >
                {t("Measure distance between drawed plot to A/N road")}
              </Typography>
            </div>
          </Popover>
        </div>
      )}
    </div>
  );
}
