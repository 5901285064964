import { create } from "zustand";
import { LowVoltageRoomDto } from "../../interfaces/foundry/typicals/LowVoltageRoomDto";
import WhitespaceDto from "../../interfaces/foundry/WhitespaceDto";
import { DatacenterDto } from "../../interfaces/foundry/DatacenterDto";
import { ScenarioDto } from "../../modules/Analysers/types/api";

export type DTOWhithChart = ScenarioDto;
export type TypicalDTO = DatacenterDto | LowVoltageRoomDto | WhitespaceDto;
export type DTO = TypicalDTO | DTOWhithChart;

export interface ExploreState<T extends DTO> {
  objects: T[] | null;
  loadedObjects: T[];
  selectedIds: number[];
  brushedObjects: T[];
  filteredObjects: T[];
  filteredWithCoolingTypes: T[];
  hoveredObject: T | null;
  isObjectsLoaded: boolean;
  objectSortProperty: string;
  objectSortAscending: boolean;
  isReset: boolean;
  dimensions: string[];
  chartLabels: string[];
  clickableAxes: string[];
  selectedAxes: string[];
  filterControlOptionsDictionary: { [key: string]: string };
  filterControlOptionsDictionaryReversed: { [key: string]: string };
  objectsTotalCount: number;
  currentObject: T | null;
  isOpenedDetailedView: boolean;
  selectedObject: DatacenterDto | null;
  filtersOnAxes: DTO[];
}

export interface ExploreActions<T extends DTO> {
  setObjects: (objects: T[] | null) => void;
  setObjectSortProperty: (values: string) => void;
  setObjectSortAscending: (value: boolean) => void;
  setBrushedObjects: (objects: T[]) => void;
  setLoadedObjects: (objects: T[]) => void;
  setSelectedIds: (objects: number[]) => void;
  setFilteredObjects: (objects: T[]) => void;
  setFilteredObjectsWithCoolingTypes: (object: T[]) => void;
  setFilterControlOptionsDictionary: (object: {
    [key: string]: string;
  }) => void;
  setFilterControlOptionsDictionaryReversed: (object: {
    [key: string]: string;
  }) => void;
  setIsObjectsLoaded: (newValue: boolean) => void;
  setHoveredObject: (newValue: T | null) => void;
  setIsReset: (boolValue: boolean) => void;
  setClickableAxes: (newValue: string[]) => void;
  setSelectedAxes: (newValue: string[]) => void;
  setObjectsTotalCount: (objectsTotalCount: number) => void;
  setCurrentObject: (newValue: T | null) => void;
  setIsOpenedDetailedView: (newValue: boolean) => void;
  resetState: () => void;
  setSelectedObject: (newValue: DatacenterDto | null) => void;
  setFilterOnAxes: (newValue: DTO[]) => void;
}

export function getBaseInitialState<T extends DTO>(): ExploreState<T> {
  return {
    objects: null,
    loadedObjects: [],
    selectedIds: [],
    brushedObjects: [],
    filteredObjects: [],
    filteredWithCoolingTypes: [],
    hoveredObject: null,
    isObjectsLoaded: false,
    objectSortProperty: "Best fit rate",
    objectSortAscending: true,
    isReset: false,
    dimensions: [],
    chartLabels: [],
    clickableAxes: [],
    selectedAxes: [],
    filterControlOptionsDictionary: {},
    filterControlOptionsDictionaryReversed: {},
    objectsTotalCount: 0,
    currentObject: null,
    isOpenedDetailedView: false,
    selectedObject: null,
    filtersOnAxes: [],
  };
}

export function createExploreStore<T extends DTO>() {
  return create<ExploreState<T> & ExploreActions<T>>((set, get) => ({
    ...getBaseInitialState<T>(),
    setSelectedAxes: (newValue) => set({ selectedAxes: newValue }),
    setObjects: (newObjects) =>
      set({
        objects: newObjects,
        brushedObjects: newObjects === null ? [] : get().brushedObjects,
        loadedObjects: newObjects === null ? [] : get().loadedObjects,
        filteredObjects: newObjects === null ? [] : get().filteredObjects,
        filteredWithCoolingTypes:
          newObjects === null ? [] : get().filteredWithCoolingTypes,
        isObjectsLoaded: newObjects === null ? false : get().isObjectsLoaded,
      }),
    setObjectsTotalCount: (newValue) => set({ objectsTotalCount: newValue }),
    setObjectSortProperty: (newValue) => set({ objectSortProperty: newValue }),
    setObjectSortAscending: (newValue) =>
      set({ objectSortAscending: newValue }),
    setBrushedObjects: (newValue) => set({ brushedObjects: newValue }),
    setLoadedObjects: (newObjects) => set({ loadedObjects: newObjects }),
    setSelectedIds: (newValue) => set({ selectedIds: newValue }),
    setFilteredObjects: (newValue) => set({ filteredObjects: newValue }),
    setFilteredObjectsWithCoolingTypes: (newValue) =>
      set({ filteredWithCoolingTypes: newValue }),
    setFilterControlOptionsDictionary: (newValue) =>
      set({ filterControlOptionsDictionary: newValue }),
    setFilterControlOptionsDictionaryReversed: (newValue) =>
      set({ filterControlOptionsDictionaryReversed: newValue }),
    setIsObjectsLoaded: (newValue) => set({ isObjectsLoaded: newValue }),
    setHoveredObject: (newValue) => set({ hoveredObject: newValue }),
    setIsReset: (newValue) =>
      set({
        isReset: newValue,
        filtersOnAxes: [],
      }),
    setClickableAxes: (newValue) => set({ clickableAxes: newValue }),
    setCurrentObject: (newValue) => set({ currentObject: newValue }),
    setIsOpenedDetailedView: (newValue) =>
      set({ isOpenedDetailedView: newValue }),
    resetState: () => {
      set({
        ...getBaseInitialState<T>(),
      });
    },
    setSelectedObject: (newValue) => set({ selectedObject: newValue }),
    setFilterOnAxes: (newValue) => set({ filtersOnAxes: newValue }),
  }));
}
