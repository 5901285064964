import { TransformatorDto } from "../../../interfaces/foundry/typicals/TransformatorDto";
import ResponseDto from "../../../interfaces/ResponseDto";
import { TypeObject } from "../../../interfaces/enums/TypeObjectEnum";
import { TrackException } from "../../../logging/LoggingManager";
import { SupplierDto } from "../../../interfaces/foundry/typicals/SupplierDto";
import { foundryApiClient } from "../../../modules/sharedLogic/services/AxiosClient";
import { GetSuppliers } from "./SupplierService";
import useGeneralDatacenterObjectsStore from "../../../state/DatacenterState/generalDatacenterObjectsState";
import { AxiosError } from "axios";

export async function GetTransformator(id: number): Promise<TransformatorDto> {
  return (await GetTransformators()).find(
    (x) => x.id === id
  ) as TransformatorDto;
}

export async function GetTransformators(
  update?: boolean
): Promise<TransformatorDto[]> {
  const store = useGeneralDatacenterObjectsStore.getState();
  let transformators = store.transformators;
  if (transformators.length === 0 || update) {
    transformators = await GetTransformatorDtos(await GetSuppliers());
    store.setTransformators(transformators);
  }
  return transformators;
}

async function GetTransformatorDtos(
  suppliers?: SupplierDto[]
): Promise<TransformatorDto[]> {
  try {
    const transformatorsData = (
      await foundryApiClient.get<ResponseDto>("/api/general/transformators")
    ).data;
    const transformators = (
      transformatorsData.results as TransformatorDto[]
    ).map((item: TransformatorDto) => {
      const currentSupplier: SupplierDto | undefined = suppliers?.find(
        (sup) => sup.id === item.supplier
      );
      item = {
        ...item,
        power_in_kva: item.power_in_kva,
        efficiency: item.efficiency * 100,
        typeObject: TypeObject.Transformator,
        supplier_name: currentSupplier?.name ? currentSupplier?.name : "",
      };
      return item;
    });
    return transformators;
  } catch (ex) {
    TrackException(ex as AxiosError);
    throw ex;
  }
}

export async function SaveTransformator(transformator: TransformatorDto) {
  try {
    transformator = {
      ...transformator,
      efficiency: transformator.efficiency / 100,
    };
    const response = await foundryApiClient.post<TransformatorDto>(
      "/api/general/transformators/",
      transformator
    );

    await GetTransformators(true);

    return response.data;
  } catch (ex) {
    TrackException(ex as AxiosError);
    throw ex;
  }
}

export async function UpdateTransformator(transformator: TransformatorDto) {
  try {
    transformator = {
      ...transformator,
      efficiency: transformator.efficiency / 100,
    };
    const response = await foundryApiClient.patch<TransformatorDto>(
      `/api/general/transformators/${transformator.id}/`,
      transformator
    );

    await GetTransformators(true);

    return response.data;
  } catch (ex) {
    TrackException(ex as AxiosError);
    throw ex;
  }
}

export async function DeleteTransformator(transformator: TransformatorDto) {
  try {
    const response = await foundryApiClient.delete<TransformatorDto>(
      "/api/general/transformators/" + transformator.id
    );

    await GetTransformators(true);

    return response.data;
  } catch (ex) {
    TrackException(ex as AxiosError);
    throw ex;
  }
}

export async function UpdateTrafoBuildingTypes(
  trafoId: number,
  buildingTypesIds: number[]
): Promise<TransformatorDto> {
  try {
    const response = await foundryApiClient.patch<TransformatorDto>(
      `/api/general/transformators/${trafoId}/`,
      { typical_types: buildingTypesIds }
    );

    await GetTransformators(true);

    return {
      ...response.data,
      power_in_kva: response.data.power_in_kva,
      efficiency: response.data.efficiency * 100,
      typeObject: TypeObject.Transformator,
    };
  } catch (ex) {
    TrackException(ex as AxiosError);
    throw ex;
  }
}
