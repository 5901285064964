import { FormControlLabel } from "@mui/material";
import AndroidSwitch from "../AndroidSwitch/AndroidSwitch";
import CustomFormControl from "./CustomFormControl";

interface FormControlSwitchProps {
  value: boolean | null;
  labelText: string;
  toolTipText?: string;
  showInfoButton?: boolean;
  disabled?: boolean;
  setValue?: (value: boolean) => void;
}

export default function FormControlSwitch({
  value,
  labelText,
  toolTipText,
  showInfoButton,
  disabled = false,
  setValue,
}: FormControlSwitchProps) {
  return (
    <div style={{ marginTop: "-10px" }}>
      <CustomFormControl
        labelText={labelText}
        showInfoButton={showInfoButton}
        toolTipText={toolTipText}
      >
        <FormControlLabel
          label=""
          control={
            <AndroidSwitch
              sx={{ m: 1 }}
              disabled={disabled}
              checked={value ?? false}
              onChange={() => setValue!(!value)}
            />
          }
        />
      </CustomFormControl>
    </div>
  );
}
