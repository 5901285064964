import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
} from "@mui/material";
import { CloseIcon } from "../../../icons/CloseIcon";
import CustomInputLabel from "../../../components/InputLabel/CustomInputLabel";
import { useTranslation } from "react-i18next";
import { UpsDto } from "../../../interfaces/foundry/typicals/UPSDto";

export interface UpsDialogProps {
  isUpsViewOpen: boolean;
  setIsUpsViewOpen: (value: boolean) => void;
  currentUps: UpsDto;
  setCurrentUps: (value: UpsDto) => void;
  handleSubmit: () => void;
}

export default function UpsDialog({
  currentUps,
  handleSubmit,
  isUpsViewOpen,
  setCurrentUps,
  setIsUpsViewOpen,
}: UpsDialogProps) {
    
  const { t } = useTranslation();

  return (
    <Dialog
      maxWidth="xl"
      open={isUpsViewOpen}
      onClose={() => setIsUpsViewOpen(false)}
      PaperProps={{
        style: {
          borderRadius: 20,
          width: "25%",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          paddingRight: "15px",
          paddingBottom: "5px",
          fontWeight: 700,
        }}
      >
        {`${t("Create")}/${t("Edit")} ${t("Ups")}`}
        <IconButton sx={{ ml: "auto" }} onClick={() => setIsUpsViewOpen(false)}>
          <CloseIcon className="svg" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: "25px" }}>
        <FormControl fullWidth variant="standard">
          <CustomInputLabel
            showInfoButton={false}
            labelText={t("ComponentsPage.Dialog.SupplierComponentType")}
            tooltipText=""
            id=""
          />
          <TextField
            fullWidth
            value={currentUps.supplier_component_type}
            style={{ marginTop: "5px" }}
            variant="standard"
            onChange={(event) =>
              setCurrentUps({
                ...currentUps,
                supplier_component_type: event.target.value,
              })
            }
          />
        </FormControl>
        <FormControl fullWidth variant="standard" sx={{ marginTop: "15px" }}>
          <CustomInputLabel
            showInfoButton={false}
            labelText={`${t("Power")} (kW)`}
            tooltipText=""
            id=""
          />
          <TextField
            fullWidth
            value={currentUps.power_in_kva}
            style={{ marginTop: "5px" }}
            variant="standard"
            onChange={(event) =>
              setCurrentUps({
                ...currentUps,
                power_in_kva: Number(event.target.value),
              })
            }
          />
        </FormControl>
        <FormControl fullWidth variant="standard" sx={{ marginTop: "15px" }}>
          <CustomInputLabel
            showInfoButton={false}
            labelText="Heatload (w)"
            tooltipText=""
            id=""
          />
          <TextField
            fullWidth
            value={currentUps.heatload_in_w}
            style={{ marginTop: "5px" }}
            variant="standard"
            onChange={(event) =>
              setCurrentUps({
                ...currentUps,
                heatload_in_w: Number(event.target.value),
              })
            }
          />
        </FormControl>
        <FormControl fullWidth variant="standard" sx={{ marginTop: "15px" }}>
          <CustomInputLabel
            showInfoButton={false}
            labelText={`${t("Length")} (mm)`}
            tooltipText=""
            id=""
          />
          <TextField
            fullWidth
            value={currentUps.length}
            style={{ marginTop: "5px" }}
            variant="standard"
            onChange={(event) =>
              setCurrentUps({
                ...currentUps,
                length: Number(event.target.value),
              })
            }
          />
        </FormControl>
        <FormControl fullWidth variant="standard" sx={{ marginTop: "15px" }}>
          <CustomInputLabel
            showInfoButton={false}
            labelText={`${t("Width")} (mm)`}
            tooltipText=""
            id=""
          />
          <TextField
            fullWidth
            value={currentUps.width}
            style={{ marginTop: "5px" }}
            variant="standard"
            onChange={(event) =>
              setCurrentUps({
                ...currentUps,
                width: Number(event.target.value),
              })
            }
          />
        </FormControl>
        <FormControl fullWidth variant="standard" sx={{ marginTop: "15px" }}>
          <CustomInputLabel
            showInfoButton={false}
            labelText={`${t("Height")} (mm)`}
            tooltipText=""
            id=""
          />
          <TextField
            fullWidth
            value={currentUps.height}
            style={{ marginTop: "5px" }}
            variant="standard"
            onChange={(event) =>
              setCurrentUps({
                ...currentUps,
                height: Number(event.target.value),
              })
            }
          />
        </FormControl>
        <FormControl fullWidth variant="standard" sx={{ marginTop: "15px" }}>
          <CustomInputLabel
            showInfoButton={false}
            labelText={`${t("ComponentsPage.Dialog.CO2Impact")} (€)`}
            tooltipText=""
            id=""
          />
          <TextField
            fullWidth
            value={currentUps.co2_footprint_in_euros}
            style={{ marginTop: "5px" }}
            variant="standard"
            onChange={(event) =>
              setCurrentUps({
                ...currentUps,
                co2_footprint_in_euros: Number(event.target.value),
              })
            }
          />
        </FormControl>
      </DialogContent>
      <DialogActions style={{ paddingRight: "20px" }}>
        <Button onClick={handleSubmit}>{t("Save")}</Button>
      </DialogActions>
    </Dialog>
  );
}
