export enum SpaceType {
  Undefined = "Undefined",
  Appartment1K = "Appartment1K",
  Appartment2K = "Appartment2K",
  Appartment3K = "Appartment3K",
  Appartment4K = "Appartment4K",
  Appartment5K = "Appartment5K",
  Appartment6K = "Appartment6K",
  Appartment7K = "Appartment7K",
  CascoPlint = "CascoPlint",
  Core1L = "Core1L",
  Core2L = "CoreIL",
  Hallway = "Hallway",
  ContainerSpace = "ContainerSpace",
  Dilatation = "Dilatation",
  EmergencyStairs = "EmergencyStairs",
  EndGableBlind = "EndGableBlind",
  EndGableWindowFrame = "EndGableWindowFrame",
  Entrance = "Entrance",
  Foundation = "Foundation",
  Roof = "Roof",
  StabilityWall = "StabilityWall",
  HSAGallery = "HSAGallery",
  HSACorridor = "HSACorridor",
  HSAPorchway = "HSAPorchway",
  HSASingleFamilyHome = "HSASingleFamilyHome",
}

export enum ModuleType {
  Undefined = "Undefined",
  Residential = "Residential",
  CommonArea = "CommonArea",
  Component = "Component",
}

export enum AccesType {
  Undefined = "Undefined",
  HSAGallery = "HSAGallery",
  HSACorridor = "HSACorridor",
  HSAPorchway = "HSAPorchway",
  HSASingleFamilyHome = "HSASingleFamilyHome",
}

export enum CatalogType {
  SpaceResidential = "SpaceResidential",
  SpaceCommonArea = "SpaceCommonArea",
  SpaceComponent = "SpaceComponent",
}

export enum GenerationFlags {
  HSAGallery = "HSAGallery",
  HSACorridor = "HSACorridor",
  HSAPorchway = "HSAPorchway",
  SingleFamilyHouse = "HSASingleFamilyHome",
}

export enum BuildingLayers {
  GroundFloor = "GroundFloor",
  Floor = "Floor",
}

export enum OutdoorSpaceTypes {
  Balcony = "Balcony",
  Terrace = "Terrace",
  Garden = "Garden",
  PrivateGarden = "PrivateGarden",
}

export enum ResidentPosition {
  Between = "Between",
  End = "End",
}
