import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as turf from "@turf/turf";
import { TrackException } from "../../../../../logging/LoggingManager";
import { ProjectFoundryDto } from "../../../../../types/api";
import DrawedPlot, {
  GetEmptyDrawedPlot,
} from "../../../../../types/DrawedPlot";
import { BaseMapDrawControl } from "../BaseMapDrawControl";
import {
  CalculateOffsetGeometry,
  CalculateRdCoordinates,
  GetDrawControlStyles,
  GetDrawedPlot,
} from "../../DrawControlUtils";
import { AxiosError } from "axios";

type PlotDrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
  drawedPlot: DrawedPlot;
  currentProject: ProjectFoundryDto;
  setDrawedPlot: (drawedPlot: DrawedPlot) => void;
  setIsSaveAvailable: (isSaveAvailable: boolean) => void;
  setCurrentProject: (currentProject: ProjectFoundryDto) => void;
  setNavigateDrawingPlot: (navigateDrawingPlot: boolean) => void;
};

export class PlotDrawControl extends BaseMapDrawControl {
  private offsetDistance = -4.8;
  private drawControlOptions: PlotDrawControlProps;

  constructor(options: PlotDrawControlProps) {
    super(options);
    this.drawControlOptions = options;
  }

  public onAdd(map: mapboxgl.Map): HTMLElement {
    this.map = map;
    this.initializeDrawControl();
    this.setupEventListeners();
    return this.drawControl.onAdd(map);
  }

  protected initializeDrawControl(): void {
    this.drawControl = new MapboxDraw({
      ...this.options,
      controls: {},
      displayControlsDefault: false,
      userProperties: true,
      modes: {
        ...MapboxDraw.modes,
      },
      styles: GetDrawControlStyles(),
    });
  }

  protected setupEventListeners(): void {
    if (!this.map) return;
    this.map.on("load", () => this.handleMapLoad());
    this.map.on("draw.create", (evt) => this.handleCreate(evt));
    this.map.on("draw.update", (evt) => this.handleUpdate(evt));
    this.map.on("draw.delete", () => this.handleDelete());
  }

  protected removeEventListeners(): void {
    if (!this.map) return;
    this.map.off("load", () => this.handleMapLoad());
    this.map.off("draw.create", (evt) => this.handleCreate(evt));
    this.map.off("draw.update", (evt) => this.handleUpdate(evt));
    this.map.off("draw.delete", () => this.handleDelete());
  }

  private handleMapLoad() {
    const tempDrawedPlot = GetDrawedPlot(
      this.drawControlOptions.drawedPlot,
      this.drawControlOptions.currentProject?.plot
    );
    if (tempDrawedPlot === undefined || !tempDrawedPlot.polygon) return;

    this.drawControlOptions.setDrawedPlot(tempDrawedPlot as DrawedPlot);
    this.drawControlOptions.setNavigateDrawingPlot(true);
    this.drawControl.add(tempDrawedPlot.polygon);
    this.updatePolygon(tempDrawedPlot.polygon);
  }

  private handleCreate(evt: { features: GeoJSON.Feature[] }) {
    const createdFeature = evt.features[0];
    if (createdFeature) {
      this.updatePolygon(createdFeature);

      setTimeout(() => {
        this.drawControl.changeMode("simple_select", {
          featureIds: [],
        });
      }, 200);
    }
  }

  private handleUpdate(evt: { features: GeoJSON.Feature[] }) {
    try {
      const updatedFeature = evt.features[0];
      if (updatedFeature.properties?.kind === "plot") {
        this.updatePolygon(updatedFeature);
      }
    } catch (ex) {
      TrackException(ex as AxiosError);
    }
  }

  private handleDelete() {
    this.drawControl.deleteAll();
    this.drawControlOptions.setDrawedPlot(GetEmptyDrawedPlot());
  }

  private updatePolygon(polygon: GeoJSON.Feature<GeoJSON.Geometry>) {
    const { features } = this.drawControl.getAll();
    features
      .filter((x) => x.id !== polygon.id)
      .forEach((feature) => this.drawControl.delete(feature.id + ""));

    this.drawControl.setFeatureProperty(polygon.id + "", "kind", "plot");
    this.drawControl.setFeatureProperty(polygon.id + "", "class_id", 0);

    let unqiueCoordinates: number[][] = [];
    if (polygon.geometry && polygon.geometry.type === "Polygon") {
      unqiueCoordinates = polygon.geometry.coordinates[0];
    }

    const turfPositions: turf.Position[] = [];
    unqiueCoordinates.forEach((point) => {
      turfPositions.push([point[0], point[1]] as turf.Position);
    });

    const positions: number[][] = [];
    const offsetGeometry = CalculateOffsetGeometry(
      turfPositions,
      this.offsetDistance
    );
    if (!offsetGeometry) return;

    const newFeature: GeoJSON.Feature<GeoJSON.Geometry> = {
      type: "Feature",
      geometry: offsetGeometry,
      properties: {
        kind: "buildablePlot",
        class_id: 1,
      },
    };
    this.drawControl.add(newFeature);

    offsetGeometry.coordinates[0].forEach((position) => {
      positions.push([Number(position[0]), Number(position[1])]);
    });

    const drawedPlot = GetDrawedPlot(
      this.drawControlOptions.drawedPlot,
      this.drawControlOptions.currentProject?.plot
    );
    const newDrawedPlot = {
      id: polygon.id,
      polygon: polygon,
      plotPercentage: drawedPlot?.plotPercentage,
      lotSurface: Math.round((turf.area(polygon) * 100) / 100),
      lotCoordinates: unqiueCoordinates,
      lotRdCoordinates: CalculateRdCoordinates(unqiueCoordinates),
      buildableSurface: drawedPlot?.buildableSurface
        ? drawedPlot.buildableSurface
        : Math.round((turf.area(offsetGeometry) * 100) / 100),
      buildableRdCoordinates: CalculateRdCoordinates(positions),
      buildableCoordinates: positions,
    } as DrawedPlot;

    this.drawControlOptions.setDrawedPlot(newDrawedPlot);
    this.drawControlOptions.setIsSaveAvailable(true);
  }
}
