import { TypeObject } from "../../../../../interfaces/enums/TypeObjectEnum";
import { ExploreConfigStore } from "../../../../../interfaces/exploreConfig";
import { DatacenterDto } from "../../../../../interfaces/foundry/DatacenterDto";
import {
  DTO,
  TypicalDTO,
} from "../../../../../state/ExploreState/ExploreState";
import ExploreView from "../ExploreView/ExploreView";

interface BaseExploreViewProps<T extends DTO> {
  type: TypeObject;
  currentTypicalConfigStore: ExploreConfigStore<T>;
  datacenterConfigStore?: ExploreConfigStore<DatacenterDto>;
  notAllowedToUpdate?: boolean;
}

export default function BaseExploreView<T extends DTO>({
  type,
  currentTypicalConfigStore,
  datacenterConfigStore,
  notAllowedToUpdate = false,
}: BaseExploreViewProps<T>) {
  const {
    summaryInfo,
    sortByOptionsDictionary,
    dimensions,
    chartLabels,
    clickableAxes,
    filterControlOptionsDictionary,
    filterControlOptionsDictionaryReversed,
    handleCustomFilter,
    handleObjectFilters,
  } = currentTypicalConfigStore;

  const currentStore = currentTypicalConfigStore.currentStore();

  function orderObjects(
    objects: T[],
    sortProperty: string,
    isSortOrderAscending: boolean
  ) {
    const currentObjects = objects as TypicalDTO[];
    const length =
      currentStore?.loadedObjects?.length === 0
        ? 50
        : currentStore?.loadedObjects.length;
    if (sortProperty === "Best fit rate") {
      currentStore.setLoadedObjects(
        currentObjects
          .sort((a, b) =>
            isSortOrderAscending ? b.value - a.value : a.value - b.value
          )
          .slice(0, length) as T[]
      );
    } else if (sortProperty === "kw/m²") {
      currentStore.setLoadedObjects(
        currentObjects
          .sort((a, b) =>
            isSortOrderAscending ? b.kw_m2 - a.kw_m2 : a.kw_m2 - b.kw_m2
          )
          .slice(0, length) as T[]
      );
    } else if (sortProperty === "kw/m³") {
      currentStore.setLoadedObjects(
        currentObjects
          .sort((a, b) =>
            isSortOrderAscending ? b.kw_m3 - a.kw_m3 : a.kw_m3 - b.kw_m3
          )
          .slice(0, length) as T[]
      );
    } else if (sortProperty === "Cost value") {
      currentStore.setLoadedObjects(
        currentObjects
          .sort((a, b) =>
            isSortOrderAscending
              ? b.cost_value - a.cost_value
              : a.cost_value - b.cost_value
          )
          .slice(0, length) as T[]
      );
    } else if (sortProperty === "Construction time") {
      currentStore.setLoadedObjects(
        currentObjects
          .sort((a, b) =>
            isSortOrderAscending
              ? b.construction_time - a.construction_time
              : a.construction_time - b.construction_time
          )
          .slice(0, length) as T[]
      );
    } else if (sortProperty === "Whitespace cooling ratio") {
      currentStore.setLoadedObjects(
        currentObjects
          .sort((a, b) =>
            isSortOrderAscending
              ? b.cooling_area_effectivity - a.cooling_area_effectivity
              : a.cooling_area_effectivity - b.cooling_area_effectivity
          )
          .slice(0, length) as T[]
      );
    }
  }

  return (
    <ExploreView
      type={type}
      notAllowedToUpdate={notAllowedToUpdate}
      summaryInfo={summaryInfo}
      currentState={currentStore}
      dimensions={dimensions}
      chartLabels={chartLabels}
      clickableAxes={clickableAxes}
      sortByOptionsDictionary={sortByOptionsDictionary}
      orderObjects={orderObjects}
      handleCustomFilter={handleCustomFilter}
      handleObjectFilters={handleObjectFilters}
      filterControlOptionsDictionary={filterControlOptionsDictionary}
      filterControlOptionsDictionaryReversed={
        filterControlOptionsDictionaryReversed
      }
      datacenterConfigStore={datacenterConfigStore}
    />
  );
}
