import "./Scene.scss";
import React from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import SummaryInfoDto from "../../types/InfoField";
import { formatValue } from "../../utils/format";
import UnitMix from "../../../HSA/components/UnitMix/UnitMix";
import { SpaceDto } from "../../../HSA/types/api";

interface SummaryViewProps<T extends SpaceDto> {
  currentObject: T;
  summaryInfo: SummaryInfoDto[];
}

export default function SummaryView<T extends SpaceDto>({
  currentObject,
  summaryInfo,
}: SummaryViewProps<T>) {
  const { t } = useTranslation();

  const getNestedValue = (obj: T, path: string) => {
    return path.split(".").reduce((acc, part) => {
      if (acc && typeof acc === "object" && part in acc) {
        return (acc as Record<string, unknown>)[part];
      }
      return undefined;
    }, obj as unknown);
  };

  const renderValue = (value: unknown, unit: string) => {
    if (typeof value === "number" || !isNaN(Number(value))) {
      const numericValue = Number(value);
      return `${formatValue(numericValue, unit)} ${unit}`.trim();
    }
    if (typeof value === "string") {
      return t(value);
    }
    return "";
  };

  return (
    <>
      {summaryInfo.map((info) => {
        const value = getNestedValue(currentObject, info.property);
        return (
          <Grid container key={info.property as string}>
            <Grid item xs={4}>
              <div className="sub-titel truncated-div">{t(info.label)}:</div>
            </Grid>
            <Grid item xs={8}>
              <div className="sub-titel">
                {info.type === "unitMix" ? (
                  <UnitMix
                    units={currentObject.unitMix}
                    showSlider={false}
                    editPercentage={false}
                    isUsedforInfoCards={true}
                    setUnits={() => {}}
                  />
                ) : (
                  renderValue(value, info.unit ?? "")
                )}
              </div>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
}
