import { GetModule } from "../../../HSA/services/ModuleService";
import { GetSpaceBuilding } from "../../../HSA/services/BuildingService";
import { TypeObject } from "../../types/enums";
import { SpaceDto } from "../../../HSA/types/api";

export const typeObjectFetchMap: {
  [key in TypeObject]: (code: string) => Promise<SpaceDto>;
} = {
  [TypeObject.HSAResidential]: GetModule,
  [TypeObject.HSABuilding]: GetSpaceBuilding,
  [TypeObject.MapView]: async () => {
    throw new Error("Function not implemented.");
  },
  [TypeObject.ExportView]: async () => {
    throw new Error("Function not implemented.");
  },
  [TypeObject.HSACountingModel]: async () => {
    throw new Error("Function not implemented.");
  },
};

export async function getCurrentObjectDetails<T extends SpaceDto>(
  currentObject: T
) {
  const object = currentObject as { code: string; typeObject: TypeObject };
  const fetchFunction = typeObjectFetchMap[object.typeObject];
  if (fetchFunction) {
    let details = await fetchFunction(object.code);
    if (!details.code) {
      details = currentObject;
    }
    return details;
  } else {
    throw new Error("Unsupported typeObject");
  }
}
