import * as THREE from "three";
import { Element } from "../../../../HSA/types/api";
import { SpaceType } from "../../../../HSA/types/enums";

interface BoxComponentProps {
  element: Element;
  buildingCenter: { x: number; y: number; z: number };
}

const BoxComponent: React.FC<BoxComponentProps> = ({
  element,
  buildingCenter,
}) => {
  const { xSizeInMm, ySizeInMm, zSizeInMm } = element.dimensions;
  const { x, y, z } = (() => {
    let { x, y, z } = element.spatialLocation;

    if (
      element.spaceType === SpaceType.Core1L ||
      element.spaceType === SpaceType.Core2L
    ) {
      const offset = 1;
      x += offset;
      y += offset;
      z += offset;
    }

    return { x, y, z };
  })();

  const boxGeometry = new THREE.BoxGeometry(
    (xSizeInMm ?? 0) / 1000,
    Number(zSizeInMm ?? 0) / 1000,
    Number(ySizeInMm ?? 0) / 1000
  );

  const position = [
    -(x / 1000 + (xSizeInMm ?? 0) / 2000) + buildingCenter.x,
    Number(z) / 1000 + (Number(zSizeInMm) ?? 0) / 2000,
    Number(y) / 1000 + Number(ySizeInMm ?? 0) / 2000 - buildingCenter.y,
  ];

  return (
    <mesh
      position={new THREE.Vector3(...position)}
      geometry={boxGeometry}
      scale={[1, 1, 1]}
      rotation={new THREE.Euler(0, 0, 0)}
    >
      <meshBasicMaterial
        attach="material"
        color={element.color}
        opacity={1}
        transparent={false}
      />
      <lineSegments>
        <edgesGeometry args={[boxGeometry]} />
        <lineBasicMaterial color="black" />
      </lineSegments>
    </mesh>
  );
};

export default BoxComponent;
