import React from "react";
import Tooltip from "@mui/material/Tooltip";
import Info from "@mui/icons-material/Info";
import { useTranslation } from "react-i18next";
import "./CustomInputLabel.scss";

interface LabelWithInfoButtonProps {
  labelKey: string;
}

const LabelWithInfoButton: React.FC<LabelWithInfoButtonProps> = ({labelKey}) => {
  const { t, i18n } = useTranslation();
  const infotagKey = `${labelKey}-infotag`;
  const infoTagValue = i18n.exists(infotagKey) ? t(infotagKey) : null;

  return (
    <div style={{ display: "flex" }} className="label-with-infobutton">
      {labelKey && <div className="label">{t(labelKey)}</div>}
      {infoTagValue && (
        <Tooltip arrow title={infoTagValue} placement="top">
          <Info className="icon"
            sx={{
              marginLeft: "5px",              
              fontSize: "18px",
              fill: "silver",
            }}
          />
        </Tooltip>
      )}
    </div>
  );
};

export default LabelWithInfoButton;
