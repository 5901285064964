import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { AxiosError } from "axios";
import i18n from "i18next";
import { ShowError } from "../http/NotificationService";
import { ValidationError } from "../interfaces/error/ValidationError";
import { appInsights } from "../modules/sharedLogic/logging/AppInsightsService";

export function TrackException(error: AxiosError) {
  if (error && error.code !== "ERR_CANCELED") {
    notifyUser(error);
    appInsights?.trackException({ error, severityLevel: SeverityLevel.Error });
  } else if (!error) {
    appInsights?.trackException({ error, severityLevel: SeverityLevel.Error });
  }
}

export function TrackTrace(message: string) {
  appInsights?.trackTrace({
    message: message,
    severityLevel: SeverityLevel.Information,
  });
}

export function TrackEvent(event: string) {
  appInsights?.trackEvent({ name: event });
}

function notifyUser(error: AxiosError) {
  let message = error.message;
  if (
    error.response?.status === 404 &&
    message.includes("temporarily unavailable")
  ) {
    message = "Errors.ConnectionAPIFailed";
  }

  const validationErrors =
    error.response?.data &&
    typeof error.response.data === "object" &&
    !Array.isArray(error.response.data)
      ? parseValidationErrors(error.response.data as Record<string, unknown>)
      : [];
  const fullMessage =
    (validationErrors && validationErrors.length > 0
      ? validationErrors.map((x) => x.message).join("\n")
      : (error.response?.data as string)) ?? message;

  const { key, values } = getDynamicMessage(fullMessage);
  const translatedMessage = translateFullErrorMessage(key, values);
  ShowError(translatedMessage);
}

function getDynamicMessage(message: string): {
  key: string;
  values: Record<string, string>;
} {
  const patterns = [
    { regex: /(.*) does not exist/, key: "Errors.MustNotBeEmpty" },
    { regex: /(.*) must not be empty/, key: "Errors.MustNotBeEmpty" },    
    { regex: /Het verzoek is ongeldig. (.*)/, key: "Errors.RequestIsInvalid" },    
  ];

  for (const { regex, key } of patterns) {
    const match = message.match(regex);
    if (match) {
      const values: Record<string, string> = {};
      match.slice(1).forEach((group, index) => {
        if (group) {
          const translatedValue = i18n.t(group.replaceAll("'", ""));
          values[`item-${index}`] = translatedValue;
        }
      });

      return { key, values };
    }
  }

  return { key: message, values: {} };
}

function translateFullErrorMessage(
  key: string,
  values: Record<string, string>
): string {
  const translatedMessage = i18n.t(key, { ...values, defaultValue: key });
  return translatedMessage;
}

function parseValidationErrors(
  data: Record<string, unknown>
): ValidationError[] {
  const errors: ValidationError[] = [];

  if (typeof data === "object" && data !== null) {
    for (const key in data) {
      if (
        key === "title" &&
        typeof data[key] === "string" &&
        data[key].includes("validation errors occurred")
      ) {
        return [
          { field: "", message: "Errors.ValidationErrorsOccurred" },
        ] as ValidationError[];
      } else if (data.hasOwnProperty(key)) {
        const messages = data[key];
        if (Array.isArray(messages)) {
          messages.forEach((message) => {
            if (typeof message === "string") {
              errors.push({ field: key, message: message });
            }
          });
        } else if (typeof messages === "string") {
          errors.push({ field: key, message: messages });
        }
      }
    }
  } else if (typeof data === "string") {
    errors.push({ field: "", message: data });
  }

  return errors;
}
