import React, { useEffect, useMemo, useRef } from "react";
import { createRoot } from "react-dom/client";
import SceneCanvas from "../../sharedLogic/components/Scene/SceneCanvas/SceneCanvas";
import { TrackException } from "../../sharedLogic/logging/LoggingManager";
import { IsStringUndefinedOrNullOrEmpty } from "../../sharedLogic/utils/stringUtils";
import { GetSpaceBuilding } from "../services/BuildingService";
import { UploadThumbnail } from "../services/ModuleService";
import useBuildingThumbnailGenerationStore from "../state/buildingThumbnailGenerationState";
import { SpaceBuildingDto } from "../types/api";

interface BuildingsThumbnailGeneratorProps {
  buildings: SpaceBuildingDto[];
  loadedBuildings: SpaceBuildingDto[];
}

const BuildingsThumbnailGenerator: React.FC<
  BuildingsThumbnailGeneratorProps
> = ({ buildings, loadedBuildings }) => {
  const sceneCanvasRef = useRef<{
    downloadImage: () => Promise<string>;
    cleanupWebGLContext?: () => void;
    dispose?: () => void;
  } | null>(null);
  const maxRetries = 3;
  const retryDelay = 250;
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const [refreshProcess, setRefreshProcess] = React.useState(false);
  const { processing, buildingThumbnailGenerationQueue, setProcessing } =
    useBuildingThumbnailGenerationStore();

  const sleep = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const trimTransparentPixels = async (dataUrl: string): Promise<string> => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();

    try {
      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
        img.src = dataUrl;
      });

      canvas.width = img.width;
      canvas.height = img.height;
      ctx?.drawImage(img, 0, 0);

      const imageData = ctx?.getImageData(0, 0, canvas.width, canvas.height);
      if (!imageData) return "";

      let top = 0,
        bottom = canvas.height,
        left = 0,
        right = canvas.width;
      const { data, width, height } = imageData;

      topLoop: for (let y = 0; y < height; y++) {
        for (let x = 0; x < width; x++) {
          if (data[(y * width + x) * 4 + 3] > 0) {
            top = y;
            break topLoop;
          }
        }
      }

      bottomLoop: for (let y = height - 1; y >= 0; y--) {
        for (let x = 0; x < width; x++) {
          if (data[(y * width + x) * 4 + 3] > 0) {
            bottom = y + 1;
            break bottomLoop;
          }
        }
      }

      leftLoop: for (let x = 0; x < width; x++) {
        for (let y = 0; y < height; y++) {
          if (data[(y * width + x) * 4 + 3] > 0) {
            left = x;
            break leftLoop;
          }
        }
      }

      rightLoop: for (let x = width - 1; x >= 0; x--) {
        for (let y = 0; y < height; y++) {
          if (data[(y * width + x) * 4 + 3] > 0) {
            right = x + 1;
            break rightLoop;
          }
        }
      }

      const croppedCanvas = document.createElement("canvas");
      croppedCanvas.width = right - left;
      croppedCanvas.height = bottom - top;
      const croppedCtx = croppedCanvas.getContext("2d");

      croppedCtx?.drawImage(
        canvas,
        left,
        top,
        right - left,
        bottom - top,
        0,
        0,
        right - left,
        bottom - top
      );

      return croppedCanvas.toDataURL("image/png", 1.0);
    } catch (error) {
      return "";
    } finally {
      canvas.remove();
    }
  };

  const processBuildingThumbnail = async (
    building: SpaceBuildingDto
  ): Promise<boolean> => {
    if (!iframeRef.current) return false;

    let retryCount = 0;
    while (retryCount < maxRetries) {
      try {
        const updatedBuilding = await GetSpaceBuilding(building.code);
        const iframeDoc = iframeRef.current.contentDocument;
        if (!iframeDoc) return false;

        const container = iframeDoc.createElement("div");
        iframeDoc.body.appendChild(container);
        const root = createRoot(container);

        const result = await new Promise<boolean>((resolve) => {
          root.render(
            <SceneCanvas
              ref={(ref) => {
                sceneCanvasRef.current = ref;
              }}
              currentObject={updatedBuilding}
            />
          );

          setTimeout(async () => {
            try {
              if (sceneCanvasRef.current) {
                const dataUrl = await sceneCanvasRef.current.downloadImage();
                const croppedDataUrl = await trimTransparentPixels(dataUrl);

                if (!IsStringUndefinedOrNullOrEmpty(croppedDataUrl)) {
                  building.view2d_png = croppedDataUrl;
                  building.thumbnailUrl = croppedDataUrl;

                  const blob = dataUrlToBlob(croppedDataUrl);
                  const file = new File([blob], `${updatedBuilding.code}.png`, {
                    type: "image/png",
                  });

                  await UploadThumbnail(updatedBuilding.code, file);
                }

                if (sceneCanvasRef.current?.cleanupWebGLContext) {
                  sceneCanvasRef.current.cleanupWebGLContext();
                }
                if (sceneCanvasRef.current?.dispose) {
                  sceneCanvasRef.current.dispose();
                }
                root.unmount();
                container.remove();

                resolve(!IsStringUndefinedOrNullOrEmpty(croppedDataUrl));
              }
            } catch (error) {
              TrackException(error as Error);
              resolve(false);
            } finally {
              if (global.gc) global.gc();
            }
          }, retryDelay);
        });

        if (result) {
          return true;
        }

        retryCount++;
        if (retryCount < maxRetries) {
          await sleep(retryDelay);
        }
      } catch (error) {
        retryCount++;
        if (retryCount === maxRetries) return false;
        await sleep(retryDelay);
      }
    }
    return false;
  };

  const dataUrlToBlob = (dataUrl: string): Blob => {
    const arr = dataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)?.[1] || "image/png";
    const bstr = atob(arr[1]);
    const u8arr = new Uint8Array(bstr.length);

    for (let i = 0; i < bstr.length; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }

    return new Blob([u8arr], { type: mime });
  };

  const buildingsNeedingThumbnails = useMemo(() => {
    return loadedBuildings.filter(
      (building) =>
        IsStringUndefinedOrNullOrEmpty(building.thumbnailUrl) &&
        !buildingThumbnailGenerationQueue.isProcessing(building.code)
    );
  }, [loadedBuildings]);

  useEffect(() => {
    if (buildingsNeedingThumbnails.length > 0) {
      buildingsNeedingThumbnails
        .sort((a, b) => b.value - a.value)
        .forEach((building) => {
          buildingThumbnailGenerationQueue.enqueue(building.code);
        });

      setRefreshProcess(!refreshProcess);
    }
  }, [buildingsNeedingThumbnails]);

  useEffect(() => {
    const processBuildings = async () => {
      setProcessing(true);

      const iframe = document.createElement("iframe");
      iframe.style.position = "absolute";
      iframe.style.left = "-9999px";
      iframe.style.width = "800px";
      iframe.style.height = "600px";
      iframe.setAttribute("sandbox", "allow-same-origin allow-scripts");
      document.body.appendChild(iframe);
      iframeRef.current = iframe;

      try {
        while (buildingThumbnailGenerationQueue.size() > 0) {
          const nextBuildingCode = buildingThumbnailGenerationQueue.dequeue();
          if (nextBuildingCode) {
            const building = buildings.find((b) => b.code === nextBuildingCode);
            if (building) {
              const success = await processBuildingThumbnail(building);
              if (!success) {
                buildingThumbnailGenerationQueue.complete(nextBuildingCode);
                buildingThumbnailGenerationQueue.enqueue(nextBuildingCode);
              }
            }
          }
        }
      } catch (error) {
        TrackException(error as Error);
      } finally {
        if (iframeRef.current) {
          document.body.removeChild(iframeRef.current);
          iframeRef.current = null;
        }
        setProcessing(false);
      }
    };

    if (!processing && buildingThumbnailGenerationQueue.size() > 0) {
      processBuildings();
    }
  }, [refreshProcess]);

  useEffect(() => {
    const resizeObserverError = (event: Event & { message: string }) => {
      if (
        event.message ===
        "ResizeObserver loop completed with undelivered notifications."
      ) {
        event.stopImmediatePropagation();
      }
    };
    window.addEventListener("error", resizeObserverError);
    return () => {
      window.removeEventListener("error", resizeObserverError);
    };
  }, []);

  return null;
};

export default BuildingsThumbnailGenerator;
