import { create } from "zustand";
import { GenerationService } from "../services/GenerationService";
import { SaveModule } from "../services/ModuleService";
import {
  ModuleDto,
  SpaceResidentialDto,
  SupportedValueNumbersDto,
} from "../types/api";
import {
  BuildingLayers,
  AccesType,
  OutdoorSpaceTypes,
  ResidentPosition,
  SpaceType,
} from "../types/enums";
import {
  isSpaceCommonAreaDto,
  isSpaceComponentDto,
  isSpaceResidentialDto,
} from "../utils/utils";

export interface ValidationError {
  property: string;
  message: string;
}

type PDFFile = string | File | null;
let supportedValueNumbers: SupportedValueNumbersDto | null;

interface ModuleDetailsState {
  isEditing: boolean;
  moduleCode: string;
  newModuleCode: string;
  file: PDFFile;
  module: ModuleDto | null;
  modules: ModuleDto[];
  validationErrors: ValidationError[];
  generationProcessId: number;
  selectedAccessType: string;
}

interface ModuleDetailsActions {
  validateModule: () => boolean;
  setFile: (file: PDFFile) => void;
  saveModule: () => Promise<string>;
  clearValidationErrors: () => void;
  setModuleCode: (code: string) => void;
  setNewModuleCode: (code: string) => void;
  setIsEditing: (isEditing: boolean) => void;
  setModule: (module: ModuleDto) => void;
  setModules: (modules: ModuleDto[]) => void;
  handlePropertyChange: (
    path: string,
    newValue: string | boolean | number
  ) => void;
  setGenerationProcessId: (generationProcessId: number) => void;
  setSelectedAccessType: (accessType: string) => void;
}

type RequiredField = keyof ModuleDto | [keyof ModuleDto, string];

function getRequiredFields(module: ModuleDto): RequiredField[] {
  const baseFields: RequiredField[] = [
    "codeBusiness",
    "spaceType",
    "buildingLayer",
    "description",
    ["dimensions", "xSizeInMm"],
    ["dimensions", "ySizeInMm"],
    ["dimensions", "zSizeInMm"],
    "grossFloorAreaInM2",
    "accessType",
  ];

  if (isSpaceResidentialDto(module)) {
    return [
      ...baseFields,
      "residentPosition",
      "outsideSpaceType",
      "bayWidth1",
      "usableAreaInM2",
      "grossFloorAreaOutdoorInM2",
      "rentalPoints",
    ] as RequiredField[];
  } else if (isSpaceCommonAreaDto(module)) {
    return [...baseFields, "bayWidth1"] as RequiredField[];
  } else if (isSpaceComponentDto(module)) {
    return baseFields;
  }

  return baseFields;
}

function getYSizeInMm(module: ModuleDto): number | undefined {
  if (isSpaceResidentialDto(module))
    return getYSizeResidentialInMm(
      module.buildingLayer,
      module.residentPosition
    );
  else if (isSpaceCommonAreaDto(module))
    return getYSizeCommonAreaInMm(module.spaceType);
  else return undefined;
}

function getYSizeResidentialInMm(
  buildingLayer: string,
  residentPosition: string | undefined
): number | undefined {
  if (buildingLayer === BuildingLayers.GroundFloor)
    return supportedValueNumbers?.dataResidential?.groundFloorDepthInMm;
  else if (buildingLayer === BuildingLayers.Floor) {
    if (residentPosition === ResidentPosition.Between)
      return supportedValueNumbers?.dataResidential.floorBetweenDepthInMm;
    else if (residentPosition === ResidentPosition.End)
      return supportedValueNumbers?.dataResidential.floorEndDepthInMm;
  } else return undefined;
}

function getYSizeCommonAreaInMm(spaceType: SpaceType): number | undefined {
  if (spaceType === SpaceType.Hallway)
    return supportedValueNumbers?.dataCommonArea.floorHallwayDepthInMm;
  else if (spaceType === SpaceType.Entrance)
    return supportedValueNumbers?.dataCommonArea.entranceDepthInMm;
  else if (spaceType === SpaceType.Core1L || spaceType === SpaceType.Core2L)
    return supportedValueNumbers?.dataCommonArea.coreDepthInMm;
  else if (spaceType === undefined) return undefined;
  else return supportedValueNumbers?.dataCommonArea.otherDepthInMm;
}

function getZSizeInMm(buildingLayer: string): number | undefined {
  if (buildingLayer === BuildingLayers.GroundFloor)
    return supportedValueNumbers?.groundFloorHeightInMm;
  else if (buildingLayer === BuildingLayers.Floor)
    return supportedValueNumbers?.floorHeightInMm;
  else return undefined;
}

function isNestedField(
  field: RequiredField
): field is [keyof ModuleDto, string] {
  return Array.isArray(field);
}

function smartSet(
  variableToSet: number | undefined | "",
  valueToSet: number | undefined
) {
  if (valueToSet === undefined && variableToSet === undefined) return undefined;
  else if (valueToSet === undefined && variableToSet !== undefined)
    return ""; // clear any previously filled in value
  else return valueToSet;
}

export const useModuleDetailsStore = create<
  ModuleDetailsState & ModuleDetailsActions
>((set, get) => ({
  file: null,
  module: null,
  moduleCode: "",
  newModuleCode: "",
  isEditing: false,
  validationErrors: [],
  modules: [],
  generationProcessId: 0,
  selectedAccessType: "",
  setFile: (file) => set({ file }),
  setIsEditing: (isEditing) => set({ isEditing }),
  setModuleCode: (code) => set({ moduleCode: code }),
  setNewModuleCode: (code) => set({ newModuleCode: code }),
  setModule: (module) => set({ module: module, file: module?.fileUrl }),
  setModules: (modules) => set({ modules: modules }),
  setGenerationProcessId: (generationProcessId) => set({ generationProcessId }),
  setSelectedAccessType: (selectedAccessType) => set({ selectedAccessType }),
  clearValidationErrors: () => set({ validationErrors: [] }),
  handlePropertyChange: (
    property: string,
    newValue: string | boolean | number
  ) => {
    set((state) => {
      if (!state.module) return state;

      const path = property.split(".");
      const newTypical = { ...state.module };
      let current: any = newTypical;
      for (let i = 0; i < path.length - 1; i++) {
        if (current[path[i]] === undefined) {
          current[path[i]] = {};
        }
        current = current[path[i]];
      }
      current[path[path.length - 1]] = newValue;

      return { module: newTypical };
    });

    (async () => {
      if (property === "accessType") {
        const generationService = new GenerationService();
        if (newValue === AccesType.HSASingleFamilyHome) {
          set((state) => {
            if (!state.module) return state;
            const updatedModule = { ...(state.module as SpaceResidentialDto) };
            updatedModule.buildingLayer = BuildingLayers.GroundFloor;
            updatedModule.outsideSpaceType = OutdoorSpaceTypes.PrivateGarden;
            return { module: updatedModule };
          });
        }
        supportedValueNumbers =
          await generationService.getSupportedValueNumbers(newValue as string);

        set({ selectedAccessType: newValue as string });
      }
      if (
        property === "accessType" ||
        property === "buildingLayer" ||
        property === "residentPosition" ||
        property === "spaceType"
      ) {
        set((state) => {
          if (!state.module) return state;
          const updatedModule = { ...state.module };
          updatedModule.dimensions.ySizeInMm = smartSet(
            updatedModule.dimensions.ySizeInMm,
            getYSizeInMm(state.module)
          );
          updatedModule.dimensions.zSizeInMm = smartSet(
            updatedModule.dimensions.zSizeInMm,
            getZSizeInMm(state.module.buildingLayer)
          );
          return { module: updatedModule };
        });
      }
    })();
  },
  validateModule: () => {
    const { module: typical } = get();
    const errors: ValidationError[] = [];
    if (typical) {
      getRequiredFields(typical).forEach((field) => {
        let value: any;
        let path: string;

        if (isNestedField(field)) {
          const [parentKey, childKey] = field;
          value = typical[parentKey] && (typical[parentKey] as any)[childKey];
          path = `${parentKey}.${childKey}`;
        } else {
          value = typical[field];
          path = field;
        }

        if (
          value === undefined ||
          value === null ||
          value === "" ||
          (Array.isArray(value) && value.length === 0)
        ) {
          errors.push({
            property: path,
            message: `${path} is required`,
          });
        }
      });
    }
    set({ validationErrors: errors });
    return errors.length === 0;
  },
  saveModule: async (): Promise<string> => {
    const { module, file, validateModule } = get();

    if (module && validateModule()) {
      const hasCode = module.code !== undefined;
      const moduleCode = await SaveModule(
        module,
        typeof file === "string" ? undefined : file || undefined
      );

      set((state) => {
        const updatedModules = [...state.modules];
        const moduleIndex = updatedModules.findIndex(
          (t) => t.code === moduleCode
        );
        if (moduleIndex > -1) {
          const updatedModule = updatedModules[moduleIndex];
          updatedModules[moduleIndex] = {
            ...updatedModule,
            grossFloorAreaInM2: module.grossFloorAreaInM2,
          };
        } else {
          updatedModules.push({ ...module, code: moduleCode });
        }

        return {
          module: state.module ? { ...state.module, code: moduleCode } : null,
          isEditing: false,
          modules: updatedModules,
          newModuleCode: !hasCode ? moduleCode : "",
        };
      });

      return moduleCode;
    }
    return "Invalid";
  },
}));
