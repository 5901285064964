import {
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface FilterCheckboxesProps {
  items: string[];
  selectedValues: string[];
  onChange: (newSelectedValues: string[]) => void;
  unit?: string;
}

const FilterCheckboxes: React.FC<FilterCheckboxesProps> = ({
  items,
  selectedValues,
  onChange,
  unit = "",
}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const visibleItems = expanded ? items : items.slice(0, 6);

  const handleCheckboxChange = (value: string, checked: boolean) => {
    const newSelectedValues = checked
      ? [...selectedValues, value]
      : selectedValues.filter((v) => v !== value);
    onChange(newSelectedValues);
  };

  return (
    <div>
      {visibleItems
        .sort((a, b) => t(a).localeCompare(t(b)))
        .map((item) => (
          <div key={item}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    "& .MuiSvgIcon-root": { fontSize: 22 },
                    padding: "2px",
                  }}
                  checked={selectedValues.includes(item)}
                  onChange={(event) =>
                    handleCheckboxChange(item, event.target.checked)
                  }
                />
              }
              label={`${t(item)}${unit ? ` ${unit}` : ""}`}
              sx={{ display: "flex", alignItems: "center" }}
            />
          </div>
        ))}
      {items.length > 6 && (
        <Typography component="div">
          <Link
            component="button"
            onClick={() => setExpanded(!expanded)}
            sx={{
              cursor: "pointer",
              color: "black",
              fontWeight: "bold",
              textDecoration: "underline",
              marginTop: "5px",
            }}
          >
            {expanded ? t("Less") : t("More")}
          </Link>
        </Typography>
      )}
    </div>
  );
};

export default FilterCheckboxes;
