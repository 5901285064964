import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDialog } from "../../../sharedLogic/components/Dialog/CustomDialog";
import Scene from "../../../sharedLogic/components/Scene/Scene";
import SceneDetail from "../../../sharedLogic/components/Scene/SceneDetail/SceneDetail";
import useUserStore from "../../../sharedLogic/state/userState";
import InfoField from "../../../sharedLogic/types/InfoField";
import { SpaceBuildingDto } from "../../types/api";
import { BuildingDetailedInfo } from "../ConfiguratorPage/Configs/BuildingConfig";
import "./ExportedBuildingsControl.scss";

interface ExportedBuildingsControlProps {
  summaryInfo: InfoField[];
  currentBuildings: SpaceBuildingDto[];
  selectedExportedBuilingsIds: { code: string; count: number }[];
  setCurrentBuildings: (value: SpaceBuildingDto[]) => void;
  setSelectedExportedBuildingsIds: (
    values: { code: string; count: number }[]
  ) => void;
}

export default function ExportedBuildingsControl({
  summaryInfo,
  currentBuildings,
  selectedExportedBuilingsIds,
  setSelectedExportedBuildingsIds,
}: ExportedBuildingsControlProps) {
  const { t } = useTranslation();
  const { openDialog } = useDialog();
  const useStore = useUserStore();

  const onDoubleClick = (building: SpaceBuildingDto) => {
    openDialog({
      title: `${building.name} details`,
      content: (
        <SceneDetail
          currentObject={building}
          detailedInfo={BuildingDetailedInfo}
        />
      ),
      width: "75%",
      height: "85%",
    });
  };

  return (
    <div>
      <div className="exported-buildings-view">
        {[selectedExportedBuilingsIds].length ?? 0 > 0 ? (
          currentBuildings.map((building) => (
            <Grid item xl={2} key={building.code}>
              <Scene
                currentState={undefined}
                currentObject={building}
                summaryInfo={summaryInfo}
                isCandidate={true}
                isInOriginalList={false}
                isExportView={true}
                selectedExportedBuilingsIds={selectedExportedBuilingsIds}
                setSelectedExportedBuildingsIds={
                  setSelectedExportedBuildingsIds
                }
                customTitle={building.name}
                onDoubleClick={onDoubleClick}
                notAllowedToUpdate={
                  useStore.appAbility?.cannot(
                    "update",
                    "configurators.stackedhousing"
                  ) ?? true
                }
              />
            </Grid>
          ))
        ) : (
          <div className="empty-conf">{t("No building selected")}</div>
        )}
      </div>

      {([selectedExportedBuilingsIds].length ?? 0 > 0) && (
        <hr className="no-padding mt20" />
      )}
    </div>
  );
}
