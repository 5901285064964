import { AxiosError } from "axios";
import { TrackException } from "../../../logging/LoggingManager";
import { sanitizeFilename } from "../../../utils/HelperFunctions";
import { forgeApiClient } from "../../sharedLogic/services/AxiosClient";
import {
  ExploreActions,
  ExploreState,
} from "../../sharedLogic/state/ExploreState/ExploreState";
import useUserStore from "../../sharedLogic/state/userState";
import { DimensionsDto } from "../../sharedLogic/types/api";
import { TypeObject } from "../../sharedLogic/types/enums";
import {
  convertCentsToEuro,
  convertEuroToCents,
  convertMilimetersToMeters,
  convertSquareMetersToSquareMilimeters,
  convertSquareMilimetersToSquareMeters,
} from "../../sharedLogic/utils/format";
import {
  ModuleDto,
  SpaceCommonAreaDto,
  SpaceResidentialDto,
} from "../types/api";
import {
  isSpaceCommonAreaDto,
  isSpaceComponentDto,
  isSpaceResidentialDto,
} from "../utils/utils";

const modulesEndpoint = "/api/v1/modules";
export async function GetAllSpaceResidentials(
  currentState: ExploreState<SpaceResidentialDto> &
    ExploreActions<SpaceResidentialDto>,
  projectExternalId: string
): Promise<ModuleDto[]> {
  try {
    const response = await forgeApiClient.get<SpaceResidentialDto[]>(
      `/api/v1/projects/${projectExternalId}/modules`
    );
    const residentialResults = response.data.map(
      (module: SpaceResidentialDto, index: number) => {
        return {
          ...MapModule(module),
          id: index,
          co2: 0,
          investmentValue: 0,
          investmentValuePerUnitPrice: 0,
          typeObject: TypeObject.HSAResidential,
          renderType: "thumbnail" as "chart" | "thumbnail" | "image" | "other",
        } as SpaceResidentialDto;
      }
    );
    currentState?.setObjectsTotalCount(residentialResults.length);
    return residentialResults;
  } catch (e) {
    TrackException(e as AxiosError);
    console.error("Error in getModule:", e);
    throw e;
  }
}

export async function GetModule(moduleCode: string): Promise<ModuleDto> {
  try {
    const response = await forgeApiClient.get<ModuleDto>(
      `${modulesEndpoint}/${moduleCode}`
    );
    return MapModule(response.data);
  } catch (e) {
    TrackException(e as AxiosError);
    console.error("Error in getModule:", e);
    throw e;
  }
}

export async function SaveModule(
  module: ModuleDto,
  file?: File | undefined
): Promise<string> {
  try {
    if (module.code === undefined) {
      module.code = "";
    }
    if (module.owner === undefined) {
      module.owner = useUserStore.getState().emailAddress;
    }

    const moduleToSave = prepareModuleForSave(module);

    let endpoint = modulesEndpoint;
    if (isSpaceResidentialDto(moduleToSave)) {
      endpoint = `${endpoint}/residential`;
    } else if (isSpaceCommonAreaDto(moduleToSave)) {
      endpoint = `${endpoint}/common-area`;
    } else if (isSpaceComponentDto(moduleToSave)) {
      endpoint = `${endpoint}/component`;
    }

    const response =
      moduleToSave.code !== ""
        ? await forgeApiClient.put<string>(
            `${endpoint}/${moduleToSave.code}`,
            moduleToSave
          )
        : await forgeApiClient.post<string>(endpoint, moduleToSave);

    const moduleCode = response.data;
    if (moduleCode !== "" && file) {
      await UploadFile(moduleCode, file);
    }

    return moduleCode;
  } catch (e) {
    TrackException(e as AxiosError);
    console.error("Error in saveModule:", e);
    throw e;
  }
}

function prepareModuleForSave(module: ModuleDto): ModuleDto {
  const moduleToSave = JSON.parse(JSON.stringify(module));
  moduleToSave.grossFloorAreaInMm2 = convertSquareMetersToSquareMilimeters(
    moduleToSave.grossFloorAreaInM2
  );
  moduleToSave.grossFloorAreaOutdoorInMm2 =
    convertSquareMetersToSquareMilimeters(
      moduleToSave.grossFloorAreaOutdoorInM2
    );
  moduleToSave.usableAreaInMm2 = convertSquareMetersToSquareMilimeters(
    moduleToSave.usableAreaInM2
  );
  moduleToSave.costInEuroCents = convertEuroToCents(moduleToSave.costInEuro);
  return moduleToSave;
}

export async function UploadFile(
  typicalCode: string,
  file: string | File
): Promise<void> {
  const formData = new FormData();
  if (typeof file === "string") {
    const fileName = file.split("/").pop() || "document.pdf";
    const sanitizedFilename = sanitizeFilename(fileName);
    formData.append("file", file);
    formData.append("fileName", sanitizedFilename);
  } else if (file instanceof File) {
    const sanitizedFilename = sanitizeFilename(file.name);
    const sanitizedFile = new File([file], sanitizedFilename, {
      type: file.type,
    });
    formData.append("file", sanitizedFile);
  } else {
    return;
  }

  await forgeApiClient.put(`${modulesEndpoint}/${typicalCode}/file`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function UploadThumbnail(
  moduleCode: string,
  file: File
): Promise<void> {
  const formData = new FormData();
  formData.append("thumbnail", file);
  await forgeApiClient.put(
    `${modulesEndpoint}/${moduleCode}/thumbnail`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
}

export function MapModule(moduleDetailed: ModuleDto): ModuleDto {
  const baseModule = {
    ...moduleDetailed,
    grossFloorAreaInM2: convertSquareMilimetersToSquareMeters(
      moduleDetailed.grossFloorAreaInMm2
    ),
    costInEuro: convertCentsToEuro(moduleDetailed.costInEuroCents),
    dimensions: {
      xSizeInMm: moduleDetailed.dimensions.xSizeInMm,
      ySizeInMm: moduleDetailed.dimensions.ySizeInMm,
      zSizeInMm: moduleDetailed.dimensions.zSizeInMm,
      xSizeInM: convertMilimetersToMeters(moduleDetailed.dimensions.xSizeInMm),
      ySizeInM: convertMilimetersToMeters(moduleDetailed.dimensions.ySizeInMm),
      zSizeInM: convertMilimetersToMeters(moduleDetailed.dimensions.zSizeInMm),
    } as DimensionsDto,
  };

  if (isSpaceResidentialDto(moduleDetailed)) {
    return {
      ...baseModule,
      grossFloorAreaOutdoorInM2: convertSquareMilimetersToSquareMeters(
        moduleDetailed.grossFloorAreaOutdoorInMm2
      ),
      usableAreaInM2: convertSquareMilimetersToSquareMeters(
        moduleDetailed.usableAreaInMm2
      ),
      bayWidth1: moduleDetailed.bayWidth1,
      bayWidth2: moduleDetailed.bayWidth2,
      costVsGO:
        convertCentsToEuro(moduleDetailed.costInEuroCents) /
        convertSquareMilimetersToSquareMeters(moduleDetailed.usableAreaInMm2),
      costVsBVO:
        convertCentsToEuro(moduleDetailed.costInEuroCents) /
        convertSquareMilimetersToSquareMeters(
          moduleDetailed.grossFloorAreaInMm2
        ),
    };
  } else if (isSpaceCommonAreaDto(moduleDetailed)) {
    const commonArea = moduleDetailed as SpaceCommonAreaDto;
    return {
      ...baseModule,
      bayWidth1: commonArea.bayWidth1,
      bayWidth2: commonArea.bayWidth2,
    };
  }

  return baseModule;
}
