import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as turf from "@turf/turf";
import { Map } from "mapbox-gl";
import DrawedMarker, {
  GetEmptyDrawedMarker,
} from "../../../../../types/DrawedMarker";
import { BaseMapDrawControl } from "../BaseMapDrawControl";
import { CalculateRdCoordinates } from "../../DrawControlUtils";

type MarkerDrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
  drawedMarker: DrawedMarker;
  setNavigateDrawingMarker: (navigate: boolean) => void;
  setDrawedMarker: (drawedMarker: DrawedMarker) => void;
  calculateDistanceFeatureToRoad: (
    feature: turf.Feature<GeoJSON.Geometry>
  ) => void;
};

export class MarkerDrawControl extends BaseMapDrawControl {
  private markerOptions: MarkerDrawControlProps;

  constructor(options: MarkerDrawControlProps) {
    super(options);

    this.markerOptions = options;
  }

  public onAdd(map: Map): HTMLElement {
    this.map = map;
    this.initializeDrawControl();
    this.setupEventListeners();
    return this.drawControl.onAdd(map);
  }

  protected initializeDrawControl(): void {
    this.drawControl = new MapboxDraw({
      ...this.options,
      controls: {},
      displayControlsDefault: false,
      userProperties: true,
      modes: { ...MapboxDraw.modes },
      styles: [
        {
          id: "points",
          slot: "top",
          type: "symbol",
          filter: ["all", ["==", "$type", "Point"]],
          layout: {
            "icon-image": "marker",
            "icon-size": 0.75,
            "icon-allow-overlap": true,
          },
        },
      ],
    });
  }

  protected setupEventListeners(): void {
    if (!this.map) return;
    this.map.on("load", () => this.handleMapLoad());
    this.map.on("draw.create", (evt) => this.handleCreate(evt));
    this.map.on("draw.delete", () => this.handleDelete());
  }

  protected removeEventListeners(): void {
    if (!this.map) return;
    this.map.off("load", () => this.handleMapLoad());
    this.map.off("draw.create", (evt) => this.handleCreate(evt));
    this.map.off("draw.delete", () => this.handleDelete());
  }

  private handleMapLoad = async () => {
    this.map!.loadImage("marker.png", (error, image) => {
      if (error) throw error;
      if (image) {
        this.map!.addImage("marker", image);
      }
    });

    if (
      !this.markerOptions.drawedMarker ||
      !this.markerOptions.drawedMarker.marker
    )
      return;

    this.drawControl.add(this.markerOptions.drawedMarker.marker);
    this.markerOptions.calculateDistanceFeatureToRoad(
      this.markerOptions.drawedMarker.marker
    );
    this.markerOptions.setNavigateDrawingMarker(true);
  };

  private handleCreate = (evt: { features: GeoJSON.Feature[] }) => {
    if (!this.map) return;

    this.drawControl.getAll().features.forEach((feature) => {
      if (feature.id !== evt.features[0].id) {
        this.drawControl.delete(feature.id as string);
      }
    });

    const createdFeature = evt.features[0];
    if (createdFeature) {
      const coordinates =
        createdFeature.geometry.type === "Point"
          ? [createdFeature.geometry.coordinates]
          : [];

      const newDrawedMarker: DrawedMarker = {
        id: createdFeature.id as string,
        marker: createdFeature,
        lotCoordinates: coordinates,
        lotRdCoordinates: CalculateRdCoordinates(
          coordinates.map(([lng, lat]) => [lng, lat])
        ),
      };

      this.markerOptions.setDrawedMarker(newDrawedMarker);
      this.markerOptions.setNavigateDrawingMarker(true);
    }
  };

  private handleDelete = () => {
    this.markerOptions.setDrawedMarker(GetEmptyDrawedMarker());
  };
}
