import { TextField } from "@mui/material";
import CustomFormControl from "./CustomFormControl";

interface FormControlTextFieldProps {
  value: number | string | undefined;
  labelText: string;
  id?: string;
  disabled?: boolean;
  toolTipText?: string;
  showInfoButton?: boolean;
  placeholder?: string;
  onChange?: (value: string) => void;
}

export default function FormControlTextField({
  id,
  labelText,
  value,
  disabled,
  showInfoButton,
  toolTipText,
  placeholder,
  onChange,
}: FormControlTextFieldProps) {
  return (
    <CustomFormControl
      labelText={labelText}
      showInfoButton={showInfoButton}
      toolTipText={toolTipText}
    >
      <TextField
        id={id}
        fullWidth
        disabled={disabled ? disabled : false}
        style={{ marginTop: "10px" }}
        placeholder={placeholder}
        value={value}
        onChange={(event) => {
          if (onChange) {
            onChange(event.target.value);
          }
        }}
        variant="standard"
        sx={{
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#000000",
          },
          "& .MuiInputBase-root.Mui-disabled:before": {
            borderBottomStyle: "solid",
          },
        }}
      />
    </CustomFormControl>
  );
}
