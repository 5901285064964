import React from "react";
import InputLabel from "@mui/material/InputLabel";
import "./CustomInputLabelWithInfoButton.scss";
import LabelWithInfoButton from "./LabelWithInfoButton";

interface CustomInputLabelProps {
  labelKey : string,
  id: string;
}

const CustomInputLabelWithInfoButton: React.FC<CustomInputLabelProps> = ({
  labelKey,
  id,
}) => {
  return (
    <InputLabel id={id} shrink={true}>
      <LabelWithInfoButton labelKey={labelKey}/>         
    </InputLabel>
  );
};

/* This component should eventually replace `CustomInputLabel`. When that's done, we can drop the `WithInfoButton`-suffix */
export default CustomInputLabelWithInfoButton;
