import {
  Box,
  Checkbox,
  Chip,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GetDesiredPowerWattages } from "../../../../http/foundry/InputPaneService";
import { TypeObject } from "../../../../interfaces/enums/TypeObjectEnum";
import useDatacenterGeneratorStore from "../../../../state/DatacenterState/datacenterGeneratorState";
import useGeneralDatacenterObjectsStore from "../../../../state/DatacenterState/generalDatacenterObjectsState";
import useInputPaneStore from "../../../../state/DatacenterState/inputPaneState";
import CustomFormControl from "../../../CustomFormControl/CustomFormControl";
import SliderWithMarks from "../../../SliderWithMarks/SliderWithMarks";
import "./WhitespaceControl.scss";

export default function WhitespaceControl() {
  const { t } = useTranslation();
  const {
    buildingType,
    powerWhitespaces,
    powerRack,
    coolingPrinciple,
    setPowerWhitespaces,
    setPowerRack,
    setCoolingPrinciple,
    setBuildingType,
  } = useInputPaneStore();

  const {
    powerRackOptions,
    coolingPrincipleOptions,
    desiredPowerOptions,
    buildingTypeDtoOptions,
    setDesiredPowerOptions,
  } = useGeneralDatacenterObjectsStore();

  const { isSaveAvailable, currentPageType } = useDatacenterGeneratorStore();

  useEffect(() => {
    if (desiredPowerOptions.length === 0) {
      (async () => {
        setDesiredPowerOptions(await GetDesiredPowerWattages());
      })();
    }
  }, []);

  useEffect(() => {
    setPowerWhitespaces([0, Math.max(...desiredPowerOptions)]);
  }, [desiredPowerOptions]);

  const handleChangePowerRack = (
    event: SelectChangeEvent<typeof powerRack>
  ) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all")
      setPowerRack(
        powerRack.length === powerRackOptions.length ? [] : powerRackOptions
      );
    else
      setPowerRack(
        typeof value === "string" ? value.split(",").map(Number) : value
      );
  };

  const handleChangeCoolingPrincible = (
    event: SelectChangeEvent<typeof coolingPrinciple>
  ) => {
    const value = event.target.value;
    const valuesCooling = Object.values(coolingPrincipleOptions);

    if (value[value.length - 1] === "all")
      setCoolingPrinciple(
        coolingPrinciple.length === valuesCooling.length ? [] : valuesCooling
      );
    else
      setCoolingPrinciple(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangeBuildingType = (event: SelectChangeEvent<string[]>) => {
    const selectedIds = event.target.value;
    const valuesBuildingType = Object.values(buildingTypeDtoOptions);

    if (selectedIds.includes("all")) {
      setBuildingType(
        buildingType.length === valuesBuildingType.length
          ? []
          : valuesBuildingType
      );
    } else {
      const selectedBuildings = valuesBuildingType.filter((building) =>
        building
          ? selectedIds.includes(building.id.toString())
          : selectedIds.includes("-1")
      );
      setBuildingType(selectedBuildings);
    }
  };

  return (
    <div className="whitespace-control">
      <CustomFormControl
        labelText={t("WhitespacePage.PowerWhitespace")}
        showInfoButton={true}
        toolTipText={t("WhitespacePage.PowerWhitespaceTooltip")}
      >
        <div className="slider-with-marks">
          <SliderWithMarks
            min={0}
            max={desiredPowerOptions ? Math.max(...desiredPowerOptions) : 0}
            value={powerWhitespaces}
            setValue={(value: number[]) => setPowerWhitespaces(value)}
            steps={0.5}
            disabled={!isSaveAvailable}
          />
        </div>
      </CustomFormControl>

      <CustomFormControl
        labelText={t("WhitespacePage.PowerRack")}
        showInfoButton={true}
        toolTipText={t("WhitespacePage.PowerRackTooltip")}
      >
        <Select
          multiple
          value={powerRack}
          disabled={!isSaveAvailable}
          style={{ height: "auto" }}
          labelId="type-power-rack-label"
          id="type-power-rack-select"
          onChange={handleChangePowerRack}
          label={t("WhitespacePage.PowerRack")}
          renderValue={(selected) => (
            <Box
              component={"div"}
              sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
            >
              {selected
                .sort((n1, n2) => n1 - n2)
                .map((value) => (
                  <Chip key={value} label={value} />
                ))}
            </Box>
          )}
        >
          <MenuItem value="all">
            <ListItemIcon>
              <Checkbox
                checked={
                  powerRackOptions.length > 0 &&
                  powerRack.length === powerRackOptions.length
                }
                indeterminate={
                  powerRack.length > 0 &&
                  powerRack.length < powerRackOptions.length
                }
              />
            </ListItemIcon>
            <ListItemText primary={t("Select all")} />
          </MenuItem>
          {powerRackOptions
            .sort((n1, n2) => n1 - n2)
            .map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={powerRack.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
        </Select>
      </CustomFormControl>

      <CustomFormControl
        labelText={t("WhitespacePage.CoolingPrinciple")}
        showInfoButton={true}
        toolTipText={t("WhitespacePage.CoolingPrincipleTooltip")}
      >
        <Select
          multiple
          style={{ height: "auto" }}
          disabled={!isSaveAvailable}
          labelId="type-cooling-principle-label"
          id="type-cooling-principle-select"
          value={coolingPrinciple}
          onChange={handleChangeCoolingPrincible}
          label={t("WhitespacePage.CoolingPrinciple")}
          renderValue={(selected) => (
            <Box
              component={"div"}
              sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
            >
              {selected
                .sort((x, y) => (x > y ? 1 : x < y ? -1 : 0))
                .map((value) => (
                  <Chip key={value} label={t(value)} />
                ))}
            </Box>
          )}
        >
          <MenuItem value="all">
            <ListItemIcon>
              <Checkbox
                checked={
                  Object.values(coolingPrincipleOptions).length > 0 &&
                  coolingPrinciple.length ===
                    Object.values(coolingPrincipleOptions).length
                }
                indeterminate={
                  coolingPrinciple.length > 0 &&
                  coolingPrinciple.length <
                    Object.values(coolingPrincipleOptions).length
                }
              />
            </ListItemIcon>
            <ListItemText primary={t("Select all")} />
          </MenuItem>
          {Object.values(coolingPrincipleOptions)
            .sort((x, y) => (x > y ? 1 : x < y ? -1 : 0))
            .map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={coolingPrinciple.includes(name)} />
                <ListItemText primary={t(name)} />
              </MenuItem>
            ))}
        </Select>
      </CustomFormControl>

      {currentPageType === TypeObject.WhitespaceTooling && (
        <CustomFormControl
          labelText={t("Building type")}
          showInfoButton={true}
          toolTipText={t("Building type")}
        >
          <Select
            multiple
            disabled={!isSaveAvailable}
            style={{ height: "auto" }}
            labelId="type-building-label"
            id="type-building-select"
            value={buildingType.map((t) => (t ? t.id.toString() : "undefined"))}
            onChange={handleChangeBuildingType}
            label={t("Building type")}
            renderValue={(selectedIds: string[]) => (
              <Box
                component={"div"}
                sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
              >
                {selectedIds.map((id) => {
                  if (id === "undefined") {
                    return <Chip key="undefined" label={t("Undefined")} />;
                  }
                  const building = Object.values(buildingTypeDtoOptions).find(
                    (t) => t && t.id.toString() === id
                  );

                  return building ? (
                    <Chip key={id} label={t(building.name)} />
                  ) : null;
                })}
              </Box>
            )}
          >
            <MenuItem value="all">
              <ListItemIcon>
                <Checkbox
                  checked={
                    Object.values(buildingTypeDtoOptions).length > 0 &&
                    buildingType.length ===
                      Object.values(buildingTypeDtoOptions).length
                  }
                  indeterminate={
                    buildingType.length > 0 &&
                    buildingType.length <
                      Object.values(buildingTypeDtoOptions).length
                  }
                />
              </ListItemIcon>
              <ListItemText primary={t("Select all")} />
            </MenuItem>
            {Object.values(buildingTypeDtoOptions).map((building) => (
              <MenuItem key={building.id} value={building.id.toString()}>
                <Checkbox
                  checked={buildingType.some((t) => t && t.id === building.id)}
                />
                <ListItemText primary={t(building.name)} />
              </MenuItem>
            ))}
          </Select>
        </CustomFormControl>
      )}
    </div>
  );
}
