import { Map } from "mapbox-gl";
import { useEffect, useRef, useState } from "react";
import { StoreApi, UseBoundStore } from "zustand";
import {
  DatacenterGeneratorActions,
  DatacenterGeneratorState,
} from "../../../../../../../state/DatacenterState/datacenterGeneratorState";
import { TrackException } from "../../../../../logging/LoggingManager";
import { GetDrawedPlot } from "../../DrawControlUtils";
import { DatacenterDrawControl } from "./DatacenterDrawControl";
import { GetSelectedBuildings } from "./DatacenterDrawUtils";
import { AxiosError } from "axios";

interface DatacenterDrawControlViewProps {
  map: Map | null;
  currentStore: UseBoundStore<
    StoreApi<DatacenterGeneratorState & DatacenterGeneratorActions>
  >;
}

export default function DatacenterDrawControlView({
  map,
  currentStore,
}: DatacenterDrawControlViewProps) {
  const [stylesLoaded, setStylesLoaded] = useState<boolean>(false);
  const drawControlRef = useRef<DatacenterDrawControl>();
  const {
    drawedPlot,
    drawControlMode,
    currentLayouts,
    currentProject,
    generatedDatacentersIds,
    setDrawedPlot,
    setNavigateDrawingPlot,
    setIsSaveAvailable,
    setCurrentProject,
    setGeneratedDatacentersIds,
  } = currentStore();

  useEffect(() => {
    if (!map) return;

    drawControlRef.current = new DatacenterDrawControl({
      currentProject: currentProject,
      drawedPlot: drawedPlot,
      setDrawedPlot: setDrawedPlot,
      setCurrentProject: setCurrentProject,
      setIsSaveAvailable: setIsSaveAvailable,
      setNavigateDrawingPlot: setNavigateDrawingPlot,
      setStylesLoaded: setStylesLoaded,
    });

    map.addControl(drawControlRef.current);

    const selectedBuildings = GetSelectedBuildings(currentProject);
    if (selectedBuildings && selectedBuildings.datacentersDetails) {
      generatedDatacentersIds.forEach((datacenter) => {
        datacenter.count = 0;
      });

      Object.keys(selectedBuildings.datacentersDetails).forEach((key) => {
        const match = key.match(/^datacenter-(\d+)-/);
        if (match) {
          const datacenterId = parseInt(match[1]);
          const datacenter = generatedDatacentersIds.find(
            (x) => x.id === datacenterId
          );
          if (datacenter) {
            datacenter.count++;
          }
        }
      });
    } else {
      generatedDatacentersIds.forEach((datacenter) => {
        datacenter.count = 1;
      });
    }
    setGeneratedDatacentersIds([...generatedDatacentersIds]);
  }, [map]);

  useEffect(() => {
    if (
      drawControlMode &&
      drawControlMode !== "" &&
      map &&
      drawControlRef.current
    ) {
      drawControlRef.current.getDrawControl().changeMode(drawControlMode);
    }
  }, [drawControlMode]);

  useEffect(() => {
    if (map && drawedPlot && drawedPlot.polygon && drawControlRef.current) {
      drawControlRef.current.generateDatacenter(
        drawedPlot.polygon,
        currentLayouts,
        generatedDatacentersIds
      );
    }
  }, [generatedDatacentersIds]);

  useEffect(() => {
    if (!drawControlRef.current || !stylesLoaded) {
      return;
    }

    try {
      for (let i = 0; i < generatedDatacentersIds.length; i++) {
        if (
          currentLayouts[i] &&
          currentLayouts[i].data &&
          currentLayouts[i].data.children &&
          generatedDatacentersIds.length > 0
        ) {
          const tempDrawedPlot = GetDrawedPlot(
            drawedPlot,
            currentProject?.plot
          );
          if (!tempDrawedPlot || !tempDrawedPlot.polygon) return;
          setDrawedPlot(tempDrawedPlot);
          const layers = drawControlRef.current?.getDrawControl().getAll();
          if (layers) {
            layers.features?.forEach((feature) => {
              if (feature?.properties?.kind.startsWith("datacenter")) {
                drawControlRef
                  .current!.getDrawControl()
                  .delete(feature.id + "");
              }
            });
            drawControlRef.current!.generateDatacenter(
              tempDrawedPlot.polygon,
              currentLayouts,
              generatedDatacentersIds
            );
          }
        }
      }
    } catch (ex) {
      TrackException(ex as AxiosError);
    }
  }, [
    drawControlRef,
    currentLayouts,
    generatedDatacentersIds,
    stylesLoaded,
    drawedPlot,
  ]);

  return null;
}
