import { create } from "zustand";
import {
  BaseConfiguratorActions,
  BaseConfiguratorState,
  getBaseActions,
  getBaseInitialState,
} from "../../sharedLogic/state/baseConfiguratorState";
import { SpaceBuildingDto } from "../types/api";

export interface HsaConfiguratorState extends BaseConfiguratorState {
  filterControlSelectedAxesApartment: string[];
  filterControlOptionsDictionary: { [key: string]: string };
  filterControlOptionsDictionaryReversed: { [key: string]: string };
  currentApartmentBuildings: SpaceBuildingDto[];
  generatedApartmentBuildingsIds: { id: number; count: number }[];
}

export interface HsaConfiguratorActions extends BaseConfiguratorActions {
  setFilterControlSelectedAxesApartment: (value: string[]) => void;
  setFilterControlOptionsDictionary: (value: { [key: string]: string }) => void;
  setFilterControlOptionsDictionaryReversed: (value: {
    [key: string]: string;
  }) => void;
  setCurrentApartmentBuildings: (value: SpaceBuildingDto[]) => void;
  setGeneratedApartmentBuildingsIds: (
    newValue: { id: number; count: number }[]
  ) => void;

  resetApartmentBuildingGeneratorState: () => void;
}

function getInitialState(): HsaConfiguratorState {
  return {
    ...getBaseInitialState(),
    filterControlSelectedAxesApartment: ["structuralHeightInMm"],
    filterControlOptionsDictionary: {},
    filterControlOptionsDictionaryReversed: {},
    currentApartmentBuildings: [],
    generatedApartmentBuildingsIds: [], 
  };
}

export function createHsaConfiguratorStore() {
  return create<HsaConfiguratorState & HsaConfiguratorActions>()((set) => ({
    ...getInitialState(),
    ...getBaseActions(set),
    setFilterControlSelectedAxesApartment: (newValue) =>
      set({ filterControlSelectedAxesApartment: newValue }),
    setFilterControlOptionsDictionary: (newValue) =>
      set({ filterControlOptionsDictionary: newValue }),
    setFilterControlOptionsDictionaryReversed: (newValue) =>
      set({ filterControlOptionsDictionaryReversed: newValue }),
    setCurrentApartmentBuildings: (value) =>
      set({ currentApartmentBuildings: value }),
    setGeneratedApartmentBuildingsIds: (newValue) =>
      set({ generatedApartmentBuildingsIds: newValue }),
    resetApartmentBuildingGeneratorState: () => set(getInitialState()),
  }));
}
