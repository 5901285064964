import { AxiosError, AxiosResponse } from "axios";
import ResponseDto from "../../interfaces/ResponseDto";
import { TrackException } from "../../logging/LoggingManager";
import { foundryApiClient } from "../../modules/sharedLogic/services/AxiosClient";
import { ProjectFoundryDto } from "../../modules/sharedLogic/types/api";

export async function GetProjects(): Promise<ProjectFoundryDto[]> {
  try {
    const response = await foundryApiClient.get<ResponseDto>(
      "/api/projects/projects"
    );
    return response.data.results as ProjectFoundryDto[];
  } catch (ex) {
    TrackException(ex as AxiosError);
    throw ex;
  }
}

export async function SaveProject(project: ProjectFoundryDto) {
  try {
    let response = {} as AxiosResponse<ProjectFoundryDto>;
    if (project.id === 0) {
      response = await foundryApiClient.post<ProjectFoundryDto>(
        "/api/projects/projects/",
        project
      );
    } else {
      response = await foundryApiClient.patch<ProjectFoundryDto>(
        `/api/projects/projects/${project.id}/`,
        project
      );
    }
    return response.data;
  } catch (ex) {
    const data = (ex as AxiosError).response?.data;
    if (data && (data as { name: string })["name"]) {
      return (data as { name: string })["name"][0];
    }
    TrackException(ex as AxiosError);
    throw ex;
  }
}

export async function DeleteProject(project: ProjectFoundryDto) {
  try {
    const response = await foundryApiClient.delete<ProjectFoundryDto>(
      `/api/projects/projects/${project.id}`
    );
    return response.data;
  } catch (ex) {
    TrackException(ex as AxiosError);
    throw ex;
  }
}
