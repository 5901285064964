import { ColDef } from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Gantt, ViewMode } from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetActivitiesByPhaseId } from "../../../../http/aggregate/ActivitiesService";
import ActivityDto from "../../../../interfaces/aggregate/ActivityDto";
import { ProductionKeyFigureDto } from "../../../../interfaces/aggregate/ProductionKeyFigureDto";
import useNitrogenStore from "../../../../state/NitrogenState/nitrogenState";
import Divider from "../Divider/Divider";
import {
  adjustScrollBottom,
  adjustScrollWidth,
  applyProjectStyles,
  calculateDuration,
  calculateDurationBetweenDates,
  calculateEndFromStartAndDuration,
  calculateIntervalForTask,
  calculateStartFromEndAndDuration,
  calculateWorkingDaysDuration,
  ExtendTheEndDateToCoverTheWholeDay,
  isWorkingDay,
  projectStyleMap,
  snapEndToDay,
  snapStartToDay,
} from "./helperFunctions";
import PlanningColumnDefsComponent from "./PlanningColumnDefs";
import "./PlanningView.scss";
import { CustomTask } from "./public-types";

interface PlanningViewProps {
  withGantt: boolean;
}

const PlanningView: React.FC<PlanningViewProps> = ({
  withGantt,
}: PlanningViewProps) => {
  const {
    activities,
    mainPhases,
    workingDays,
    setActivities,
    setActivitiesPerPhase,
  } = useNitrogenStore();

  const { t } = useTranslation();

  const [tasks, setTasks] = React.useState<CustomTask[]>([]);
  const view = React.useMemo(() => ViewMode.Month, []);
  const [leftWidth, setLeftWidth] = useState(50);
  const containerRef = useRef<HTMLDivElement>(null);

  let columnWidth = 65;
  if (view === ViewMode.Year) {
    columnWidth = 350;
  } else if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }

  useEffect(() => {
    setTimeout(() => {
      adjustScrollBottom();
      adjustScrollWidth(leftWidth);
    }, 0);
  });

  useEffect(() => {
    tasks
      .map(
        (task) =>
          (task.interval = calculateIntervalForTask(task, tasks, workingDays))
      )
      .toString();
  });

  useEffect(() => {
    setActivities(tasks);
  }, [tasks]);

  useEffect(() => {
    setTasks((currentTasks) => {
      let updatedTasks = currentTasks.map((task) => {
        if (task.start && task.duration !== undefined) {
          task.end = calculateEndFromStartAndDuration(
            task.start,
            task.duration,
            workingDays
          );
        }
        return task;
      });

      updatedTasks = updatedTasks.map((task) => {
        if (task.dependencies && task.dependencies.length > 0) {
          const latestEndDate = task.dependencies
            .map((depId) => updatedTasks.find((t) => t.id === depId)?.end)
            .filter((endDate): endDate is Date => endDate !== undefined)
            .reduce(
              (latest, current) => (current > latest ? current : latest),
              new Date(new Date().setHours(0, 0, 0, 0))
            );

          //if the old interval is greater than the new interval, the task will be moved to the right
          if (task.interval) {
            const intervalDays = parseInt(task.interval);
            const desiredStart = calculateEndFromStartAndDuration(
              latestEndDate,
              intervalDays,
              workingDays
            );

            if (desiredStart < task.start) {
              task.start = new Date(desiredStart);
              task.end = calculateEndFromStartAndDuration(
                task.start,
                task.duration!,
                workingDays
              );
            }
          }
          //if the old interval is less than the new interval, the task will be moved to the left
          if (task.interval) {
            const intervalDays = parseInt(task.interval);
            const desiredStart = calculateEndFromStartAndDuration(
              latestEndDate,
              intervalDays,
              workingDays
            );

            if (desiredStart > task.start) {
              task.start = new Date(desiredStart);
              task.end = calculateEndFromStartAndDuration(
                task.start,
                task.duration!,
                workingDays
              );
            }
          }
        }
        return task;
      });

      return applyProjectStyles(updatedTasks);
    });
    //update projects dates
    tasks
      .filter((task) => task.type === "project")
      .forEach((project) => {
        updateProjectDates(project.id);
      });
  }, [workingDays]);

  useEffect(() => {
    (async () => {
      const activitiesMappedWithPhases: { [key: string]: ActivityDto[] } = {};
      mainPhases.forEach(async (phase) => {
        const activities = await GetActivitiesByPhaseId(Number(phase.id));
        activitiesMappedWithPhases[phase.id] = activities;
      });
      setActivitiesPerPhase(activitiesMappedWithPhases);
    })();
  }, [mainPhases]);

  useEffect(() => {
    if (activities.length === 0) {
      const tasks = [] as CustomTask[];
      if (mainPhases === undefined) return;
      mainPhases.forEach((phase, index) => {
        const displayOrder = (index + 1) * 2;
        tasks.push({
          id: phase.id.toString(),
          name: phase.name,
          start: new Date(new Date().setHours(0, 0, 0, 0)),
          end: new Date(new Date().setHours(0, 0, 0, 0)),
          duration: 0,
          progress: 100,
          type: "project",
          displayOrder: displayOrder,
        });

        const addButtonPlaceholder: CustomTask = {
          id: `button-${phase.id}`,
          project: phase.id,
          name: "",
          start: phase.end
            ? phase.end
            : new Date(new Date().setHours(0, 0, 0, 0)),
          end: phase.end
            ? phase.end
            : new Date(new Date().setHours(0, 0, 0, 0)),
          duration: 0,
          type: "task",
          displayOrder: displayOrder + 1,
          styles: {
            backgroundColor: "transparent",
            backgroundSelectedColor: "transparent",
            progressColor: "transparent",
            progressSelectedColor: "transparent",
          },
          progress: 0,
        };
        tasks.push(addButtonPlaceholder);
      });

      setTasks(applyProjectStyles(tasks));
    } else {
      setTasks(applyProjectStyles(activities));
    }
  }, []);

  const addTaskUnderProject = (phaseId: string) => {
    const project =
      tasks.find((task) => task.id === phaseId.toString()) ?? tasks[0];
    setTasks((currentTasks) => {
      const tasksWithoutAddButton = currentTasks.filter(
        (task) => !(task.id == `button-${project.id}`)
      );
      let maxDisplayOrder = 0;
      tasksWithoutAddButton.forEach((task) => {
        if (
          task.project == project.id &&
          (task.displayOrder || 0) > maxDisplayOrder
        ) {
          maxDisplayOrder = task.displayOrder || 0;
        }
      });
      const newTaskDisplayOrder = maxDisplayOrder;
      const projectTasks = tasksWithoutAddButton.filter(
        (task) => task.project == project.id
      );
      const lastTask = projectTasks[projectTasks.length - 1]
        ? projectTasks[projectTasks.length - 1]
        : project;
      const projectStyle =
        projectTasks.length > 0 ? projectTasks[0].styles : null;
      const currentPhases = mainPhases.find(
        (phase) => phase.id.toString() === phaseId.toString()
      );

      const start = snapStartToDay(new Date(lastTask.end?.getTime())); // add one day to the end date
      const end = snapEndToDay(
        new Date(lastTask.end?.getTime() + 23 * 59 * 59 * 999)
      );

      const duration = calculateDurationBetweenDates(start, end, workingDays);

      const newTask = {
        id: `task-${Math.random().toString(36).substr(2, 9)}`,
        project: project.id,
        mainPhase: currentPhases?.name,
        dependencies: [],
        relation: null,
        interval: "0",
        name: "",
        start: start,
        end: end,
        duration: duration,
        type: "task",
        progress: 100,
        displayOrder:
          newTaskDisplayOrder !== 0
            ? newTaskDisplayOrder
            : project.displayOrder! + 1,
        styles: projectStyle ? { ...projectStyle } : {},
        equipmentUsagePercentage: undefined,
      };

      let updatedTasks = [...tasksWithoutAddButton, newTask];
      const addButtonPlaceholder: CustomTask = {
        id: `button-${project.id}`,
        project: project.id,
        name: "",
        start: lastTask.end,
        end: lastTask.end,
        duration: 0,
        type: "task",
        displayOrder:
          newTaskDisplayOrder !== 0
            ? newTaskDisplayOrder
            : project.displayOrder! + 1,
        styles: {
          backgroundColor: "transparent",
          backgroundSelectedColor: "transparent",
          progressColor: "transparent",
          progressSelectedColor: "transparent",
        },
        progress: 0,
      };
      updatedTasks.push(addButtonPlaceholder);
      updatedTasks.sort(
        (a, b) => (a.displayOrder ?? 0) - (b.displayOrder ?? 0)
      );
      return applyProjectStyles(updatedTasks as CustomTask[]);
    });
    updateProjectDates(project.id);
  };

  const removeActivityFromPahse = (activityId: string, phaseId: string) => {
    setTasks((currentTasks) => {
      let updatedTasks = currentTasks.filter((task) => task.id !== activityId);
      updatedTasks = applyProjectStyles(updatedTasks as CustomTask[]);
      return updatedTasks;
    });
    updateProjectDates(phaseId);
  };

  const updateProjectDates = (projectId: string) => {
    const project = tasks.find((task) => task.id === projectId);
    setTasks((currentTasks) => {
      let updatedTasks = [...currentTasks];
      const projectTasks = updatedTasks.filter(
        (task) => task.project === projectId && task.type !== "project"
      );
      const newStart =
        projectTasks.length > 1
          ? new Date(
              Math.min(
                ...projectTasks.map((task) =>
                  !task.id.startsWith("button-")
                    ? task.start?.getTime()
                    : Infinity
                )
              )
            )
          : project?.start!;
      const newEnd =
        projectTasks.length > 1
          ? new Date(
              Math.max(
                ...projectTasks.map((task) =>
                  !task.id.startsWith("button-") ? task.end?.getTime() : 0
                )
              )
            )
          : project?.start!;
      updatedTasks = updatedTasks.map((task) => {
        if (task.id === projectId) {
          return {
            ...task,
            start: projectTasks.length > 0 ? newStart : task.start,
            end: projectTasks.length > 0 ? newEnd : task.end,
            duration: calculateDurationBetweenDates(
              newStart,
              newEnd,
              workingDays
            ),
          };
        }
        return task;
      });

      return applyProjectStyles(updatedTasks as CustomTask[]);
    });
  };

  function getStartEndDateForProject(tasks: CustomTask[], projectId: string) {
    const projectTasks = tasks.filter((t) => t.project === projectId);
    let start = projectTasks[0].start;
    let end = projectTasks[0].end;

    for (let i = 0; i < projectTasks.length; i++) {
      const task = projectTasks[i];
      if (start && task.start && start?.getTime() > task.start?.getTime()) {
        start = task.start;
      }
      if (end && task.end && end?.getTime() < task.end?.getTime()) {
        end = task.end;
      }
    }
    return [start, end];
  }

  const handleTaskChange = (task: CustomTask) => {
    setTasks((prevTasks) => {
      let newTasks = prevTasks.map((t) => {
        if (t.id === task.id) {
          const updatedTask = { ...t, ...task };
          return updatedTask;
        }
        return t;
      });

      if (task.project) {
        const [start, end] = getStartEndDateForProject(newTasks, task.project);
        const project = newTasks.find((t) => t.id === task.project);
        if (
          project &&
          (project.start?.getTime() !== start?.getTime() ||
            project.end?.getTime() !== end?.getTime())
        ) {
          const changedProject = { ...project, start, end };
          newTasks = newTasks.map((t) =>
            t.id === task.project ? changedProject : t
          );
        }
      }

      if (
        task.quantity !== undefined &&
        task.productionRate !== undefined &&
        task.numberOfTeams !== undefined
      ) {
        const duration = calculateDuration(
          task.quantity,
          task.productionRate * 8,
          task.numberOfTeams
        );
        const end = calculateEndFromStartAndDuration(
          task.start!,
          duration,
          workingDays
        );
        newTasks = newTasks.map((t) =>
          t.id === task.id ? { ...t, duration, end } : t
        );
      }
      return applyProjectStyles(newTasks as CustomTask[]);
    });
  };

  const handleTaskDelete = (task: CustomTask) => {
    const conf = window.confirm("Are you sure about " + task.name + " ?");
    if (conf) {
      setTasks(tasks.filter((t) => t.id !== task.id));
    }
    return conf;
  };

  const handleStartOrDurationChange = (
    taskId: string,
    field: "start" | "duration",
    newValue: string | number | Date
  ) => {
    setTasks((currentTasks) => {
      return applyProjectStyles(
        currentTasks.map((task) => {
          if (task.id === taskId) {
            if (field === "start") {
              task.start = new Date(newValue);
            } else if (field === "duration" && typeof newValue === "number") {
              task.duration = newValue;
            }
            // Ensure both start and duration are set before calculating the end date
            if (task.start && typeof task.duration === "number") {
              task.end = calculateEndFromStartAndDuration(
                task.start,
                task.duration,
                workingDays
              );
            }

            // Adjust intervals for dependent tasks
            const dependentTasks = currentTasks.filter((t) =>
              t.dependencies?.includes(taskId)
            );
            dependentTasks.forEach((depTask) => {
              depTask.interval = calculateIntervalForTask(
                depTask,
                currentTasks,
                workingDays
              );
            });
          }

          return task;
        })
      );
    });
  };

  const handleDateChange = (
    taskId: string,
    field: "start" | "end",
    newValue: string | number | Date
  ) => {
    setTasks((currentTasks) => {
      return applyProjectStyles(
        currentTasks.map((task) => {
          if (task.id === taskId) {
            const date = new Date(newValue);
            if (date.getFullYear() === 1970) {
              if (field === "start") {
                task.start = new Date(new Date().toDateString());
              } else {
                task.end = ExtendTheEndDateToCoverTheWholeDay(new Date());
              }
              return task;
            }

            if (field === "start") {
              task.start = new Date(newValue);
            } else if (field === "end") {
              task.end = ExtendTheEndDateToCoverTheWholeDay(new Date(newValue));
            }

            if (task.start && task.end) {
              if (
                field === "end" &&
                task.productionRate !== undefined &&
                task.quantity !== undefined &&
                task.numberOfTeams !== undefined
              ) {
                task.start = calculateStartFromEndAndDuration(
                  task.end!,
                  task.duration!,
                  workingDays
                );
              } else if (
                field === "start" &&
                task.productionRate !== undefined &&
                task.quantity !== undefined &&
                task.numberOfTeams !== undefined
              ) {
                task.end = calculateEndFromStartAndDuration(
                  task.start!,
                  task.duration!,
                  workingDays
                );
              } else {
                const duration = calculateDurationBetweenDates(
                  task.start,
                  task.end,
                  workingDays
                );
                task.duration = duration;
              }
            } else if (task.start && typeof task.duration === "number") {
              task.end = calculateEndFromStartAndDuration(
                task.start,
                task.duration,
                workingDays
              );
            } else if (task.end && typeof task.duration === "number") {
              // Calculate the start date from the end date and duration in working days
              let remainingDays = task.duration;
              let currentDate = new Date(task.end);

              while (remainingDays > 0) {
                currentDate.setDate(currentDate.getDate() - 1);
                if (isWorkingDay(currentDate, workingDays)) {
                  remainingDays -= 1;
                }
              }

              task.start = currentDate;
            }
          }
          return task;
        })
      );
    });
  };

  const handleAgGridCellEditingStopped = (params: {
    colDef: ColDef;
    data: CustomTask;
    value: CustomTask[keyof CustomTask];
  }) => {
    const { colDef, data, value } = params;
    const { field } = colDef;

    if (colDef.field === "dependencyList") {
      const selectedIds = (value instanceof Array ? value : [])
        .map(
          (name) =>
            tasks.find(
              (task) =>
                task.name === name &&
                task.type !== "project" &&
                !task.id.includes("button-")
            )?.id
        )
        .filter((id) => id !== undefined) as string[];

      handleTaskChange({ ...data, dependencies: selectedIds });
    } else if (field === "start" || field === "end") {
      if (value instanceof Date) {
        handleDateChange(data.id, field, new Date(value));
      }
    } else if (field === "duration") {
      handleStartOrDurationChange(
        data.id,
        "duration",
        parseFloat(value as string)
      );
    } else if (
      (field === "numberOfTeams" || field === "quantity") &&
      data.quantity &&
      data.productionRate &&
      data.numberOfTeams
    ) {
      const duration = calculateDuration(
        data.quantity,
        data.productionRate,
        data.numberOfTeams
      );

      handleTaskChange({
        ...data,
        duration: duration,
        [field as string]: value,
      });
      handleStartOrDurationChange(data.id, "duration", duration);
    } else if (field === "productionKeyFigure") {
      if (
        typeof value === "object" &&
        value !== null &&
        "productionPerHour" in value
      ) {
        handleProductionKeyFigureChange(
          data.id,
          value as ProductionKeyFigureDto
        );
      }
    } else {
      handleTaskChange({ ...data, [field as string]: value });
    }
    updateProjectDates(data.project ?? "");
  };

  const handleProductionKeyFigureChange = (
    taskId: string,
    productionKeyFigure: ProductionKeyFigureDto | undefined
  ) => {
    if (!productionKeyFigure) return;

    const productionRate = productionKeyFigure.productionPerHour * 8;
    const numberOfTeams = productionKeyFigure.workForces;
    const quantity = tasks.find((task) => task.id === taskId)?.quantity ?? 0;
    const duration = calculateDuration(quantity, productionRate, numberOfTeams);

    const updatedTasks = tasks.map((task) => {
      if (task.id === taskId) {
        return {
          ...task,
          productionKeyFigureId: productionKeyFigure.id,
          productionRate: productionRate,
          numberOfTeams: numberOfTeams,
          duration: quantity ? duration : task.duration,
          end: quantity
            ? calculateEndFromStartAndDuration(
                task.start!,
                duration,
                workingDays
              )
            : task.end,
        } as CustomTask;
      }
      return task;
    });

    setTasks(updatedTasks);
  };

  const handleGanttDateChange = (
    taskId: string,
    start: Date | string,
    end: Date | string
  ) => {
    setTasks((currentTasks) => {
      let updatedTasks = currentTasks.map((task) => {
        if (task.id === taskId) {
          const startDate = new Date(start);
          const endDate = new Date(end);
          const duration = calculateWorkingDaysDuration(
            startDate,
            endDate,
            workingDays
          );

          task.start = startDate;
          task.end = endDate;
          task.duration = duration;

          // Recalculate intervals for tasks depending on this task
          const dependentTasks = currentTasks.filter((t) =>
            t.dependencies?.includes(taskId)
          );
          dependentTasks.forEach((depTask) => {
            depTask.interval = calculateIntervalForTask(
              depTask,
              currentTasks,
              workingDays
            );
          });
        }
        return task;
      });

      const projectsToUpdate = new Set(
        updatedTasks.filter((task) => task.project).map((task) => task.project)
      );
      projectsToUpdate.forEach((projectId) => {
        const projectTasks = updatedTasks.filter(
          (task) => task.project === projectId
        );
        if (projectTasks.length > 0) {
          const newProjectStart = new Date(
            Math.min(
              ...projectTasks.map((task) =>
                !task.id.startsWith("button-")
                  ? task.start?.getTime()
                  : Infinity
              )
            )
          );
          const newProjectEnd = new Date(
            Math.max(
              ...projectTasks.map((task) =>
                !task.id.startsWith("button-") ? task.end?.getTime() : 0
              )
            )
          );
          const projectDuration = calculateWorkingDaysDuration(
            newProjectStart,
            newProjectEnd,
            workingDays
          );
          const project = updatedTasks.find((task) => task.id === projectId);
          if (project) {
            project.start = newProjectStart;
            project.end = newProjectEnd;
            project.duration = projectDuration;
          }
        }
      });

      return applyProjectStyles(updatedTasks);
    });
  };

  const columnDefs = PlanningColumnDefsComponent(
    tasks,
    addTaskUnderProject,
    removeActivityFromPahse,
    true,
    true
  );

  return (
    tasks.length > 0 && (
      <div className="planning-view" ref={containerRef}>
        <div
          className="left-pane ag-theme-quartz"
          style={{
            width: `${leftWidth}%`,
          }}
        >
          <AgGridReact
            rowData={tasks}
            columnDefs={columnDefs}
            onCellEditingStopped={handleAgGridCellEditingStopped}
            getRowStyle={(params) => {
              const baseStyle: React.CSSProperties = {
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: "300",
                color: "#0C0800",
                paddingLeft: "10px",
                borderBottom: ".5px solid #f5f5f5",
                backgroundColor: "white",
              };

              if (Object.keys(projectStyleMap).includes(params.data.name)) {
                baseStyle.backgroundColor =
                  projectStyleMap[params.data.name].backgroundColor;
              }

              return {
                ...baseStyle,
              };
            }}
            defaultColDef={{
              resizable: true,
              sortable: true,
            }}
          />
        </div>
        <Divider containerRef={containerRef} setLeftWidth={setLeftWidth} />
        <div
          className="right-pane nitrogen"
          style={{
            width: `${100 - leftWidth}%`,
            height: "fit-content",
            borderLeft: !withGantt ? ".5px solid #ccc" : "none",
          }}
        >
          <Gantt
            tasks={tasks.map((task) => ({
              ...task,
              end:
                new Date(task.end) > new Date(task.start)
                  ? snapEndToDay(new Date(task.end))
                  : new Date(task.end),
            }))}
            viewMode={view}
            locale={t("en")}
            onDateChange={(task) => {
              const newStart = snapStartToDay(task.start);
              const newEnd = snapEndToDay(task.end);
              handleGanttDateChange(task.id, newStart, newEnd);
            }}
            TooltipContent={() => null}
            onDelete={handleTaskDelete}
            fontSize="12px"
            fontFamily="Poppins, sans-serif"
            rowHeight={42}
            columnWidth={columnWidth}
            listCellWidth="0px"
            TaskListTable={() => null}
            TaskListHeader={() => null}
          />
        </div>
      </div>
    )
  );
};

export default PlanningView;
