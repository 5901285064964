import { BaseTypicalDto } from "./BaseTypicalDto";
import { TypeObject } from "../../enums/TypeObjectEnum";
import { BuildingTypeDto } from "../BuildingTypeDto";

export interface BatteryDto extends BaseTypicalDto {
  id: number;
  length: number;
  width: number;
  height: number;
  type: string;
  display_name: string;
  supplier_name: string;
  power_in_kw_5_min: number;
  supplier_component_type: string;
  typical_types: BuildingTypeDto[];
  typeObject: TypeObject;
  co2_footprint_in_euros: number;
}

export function GetEmptyBatteryDto(): BatteryDto {
  return {
    id: 0,
    length: 0,
    width: 0,
    height: 0,
    supplier: 1,
    power_in_kw_5_min: 0,
    type: "Li-ion",
    co2_footprint_in_euros: 0
  } as BatteryDto;
}
