import { ClickAnalyticsPlugin } from "@microsoft/applicationinsights-clickanalytics-js";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { createBrowserHistory } from "history";
import { AzureApplicationInsightsConnectionString } from "../../../config";

const browserHistory = createBrowserHistory();
const clickPluginInstance = new ClickAnalyticsPlugin();
const clickPluginConfig = {
  autoCapture: true,
};
const reactPlugin = new ReactPlugin();

let ai: ApplicationInsights | undefined = undefined;
if (AzureApplicationInsightsConnectionString) {
  ai = new ApplicationInsights({
    config: {
      connectionString: AzureApplicationInsightsConnectionString,
      enableAutoRouteTracking: true,
      autoTrackPageVisitTime: true,
      extensions: [reactPlugin, clickPluginInstance],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
        [clickPluginInstance.identifier]: clickPluginConfig,
      },
    },
  });
  ai.loadAppInsights();
}

export default (Component: any) =>
  ai ? withAITracking(reactPlugin, Component) : Component;
export const appInsights = ai?.appInsights;
