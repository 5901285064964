import i18next from "i18next";
import moment from "moment";
import { AggregatePageEnum } from "../../interfaces/aggregate/enums/AggregatePageEnum";
import { ExportEnum } from "../../interfaces/aggregate/enums/ExportEnum";
import { InputDataCatalogEnum } from "../../interfaces/aggregate/enums/InputDataCatalogEnum";
import { TrackException } from "../../logging/LoggingManager";
import { aggregateApiClient } from "../../modules/sharedLogic/services/AxiosClient";
import useDatabaseStore from "../../state/DatabaseState/databaseState";
import { AxiosError, AxiosResponse } from "axios";

interface FileType {
  extension: string;
  mimeType: string;
}

const fileTypes: { [key: string]: FileType } = {
  csv: { extension: "csv", mimeType: "text/csv" },
  excel: {
    extension: "xlsx",
    mimeType:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  },
};

export async function downloadFile(
  response: AxiosResponse,
  fileNamePrefix: string,
  fileType: "csv" | "excel"
) {
  const { extension, mimeType } = fileTypes[fileType];

  const blob = new Blob([response.data], { type: mimeType });
  const urlBlob = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = urlBlob;
  const dateString = moment(new Date()).format("DDMMYYYYHHmmss");
  const fileName = `${fileNamePrefix}_${dateString}.${extension}`;
  a.download = fileName.toLowerCase();
  a.click();

  setTimeout(() => {
    URL.revokeObjectURL(urlBlob);
  }, 100);
}

export async function ImportTableFromCSV(
  file: FormData,
  endpoint?: string,
  url?: string
) {
  try {
    url = url ?? `api/${endpoint}/csv`;
    return await aggregateApiClient.post(url, file, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
  } catch (ex) {
    TrackException(ex as AxiosError);
  }
}

export async function ExportTableToCSV(
  endpoint: string,
  option: InputDataCatalogEnum | AggregatePageEnum,
  typeExport: ExportEnum,
  categoryId?: string
) {
  try {
    let url: string;
    let fileNamePrefix: string;

    switch (typeExport) {
      case ExportEnum.KeyFigures:
        url = endpoint;
        const category = useDatabaseStore
          .getState()
          .categories.find((x) => x.id === Number(categoryId));
        fileNamePrefix = `${i18next.t(
          AggregatePageEnum[option as AggregatePageEnum]
        )}_${category?.name}`;
        break;
      case ExportEnum.Categories:
        url = `api/${endpoint}/csv?categoryId=${option}`;
        fileNamePrefix = `${i18next.t("Categories")}_${i18next.t(
          AggregatePageEnum[option as AggregatePageEnum]
        )}`;
        break;
      case ExportEnum.InputData:
        url = `api/${endpoint}/csv?categoryId=${option}`;
        fileNamePrefix = i18next.t(
          InputDataCatalogEnum[option as InputDataCatalogEnum]
        );
        break;
    }

    const response = await aggregateApiClient.get(`${url}&pageSize=5000`);
    await downloadFile(response, fileNamePrefix, "csv");

    return typeExport === ExportEnum.KeyFigures ||
      typeExport === ExportEnum.InputData
      ? response.data
      : [];
  } catch (ex) {
    TrackException(ex as AxiosError);
    return [];
  }
}
