import { convertMetersToMilimeters } from "../../../sharedLogic/utils/format";
import { SupportedValueNumbersDto } from "../../types/api";
import { AccesType } from "../../types/enums";

export function CalculateMinMaxnumberOfApartments(
  accessibilityType: string,
  dimensionsLength: [number, number],
  dimensionsHeight: [number, number],
  currentSupportedValues?: SupportedValueNumbersDto
): [number, number] {
  if (!currentSupportedValues) {
    return [1, 1];
  }

  const bayWidthEntranceInMm = currentSupportedValues.bayWidthEntranceInMm ?? 0;
  const bayWidthCoreInMm = currentSupportedValues.bayWidthCoreInMm ?? 0;
  const bayWidthContainerSpaceInMm =
    currentSupportedValues.bayWidthCoreInMm ?? 0;
  const minApartmentWidthInMm =
    currentSupportedValues.minApartmentWidthInMm ?? 0;
  const maxApartmentWidthInMm =
    currentSupportedValues.maxApartmentWidthInMm ?? 0;
  const floorHeightInMm = currentSupportedValues.floorHeightInMm ?? 0;
  const groundFloorHeightInMm =
    currentSupportedValues.groundFloorHeightInMm ?? 0;

  const minLengthInMm = convertMetersToMilimeters(dimensionsLength[0]);
  const maxLengthInMm = convertMetersToMilimeters(dimensionsLength[1]);
  const minHeightInMm = convertMetersToMilimeters(dimensionsHeight[0]);
  const maxHeightInMm = convertMetersToMilimeters(dimensionsHeight[1]);

  let minApartments = 1;
  let maxApartments = 1;
  if (
    accessibilityType === AccesType.HSAGallery ||
    accessibilityType === AccesType.HSACorridor
  ) {
    const rowsOfApartments =
      accessibilityType === AccesType.HSACorridor ? 2 : 1;
    const calculatedMinApartments = calculateNumberOfApartments(
      minLengthInMm,
      minHeightInMm,
      maxApartmentWidthInMm,
      bayWidthEntranceInMm,
      bayWidthCoreInMm,
      bayWidthContainerSpaceInMm,
      floorHeightInMm,
      groundFloorHeightInMm,
      rowsOfApartments
    );
    minApartments =
      calculatedMinApartments < currentSupportedValues.minApartments
        ? currentSupportedValues.minApartments
        : calculatedMinApartments;

    const calculatedMaxApartments = calculateNumberOfApartments(
      maxLengthInMm,
      maxHeightInMm,
      minApartmentWidthInMm,
      bayWidthEntranceInMm,
      bayWidthCoreInMm,
      bayWidthContainerSpaceInMm,
      floorHeightInMm,
      groundFloorHeightInMm,
      rowsOfApartments
    );

    maxApartments =
      currentSupportedValues.maxApartments < calculatedMaxApartments
        ? currentSupportedValues.maxApartments
        : calculatedMaxApartments;
  } else if (accessibilityType === AccesType.HSAPorchway) {
    minApartments =
      4 *
      getNumberOfFloors(minHeightInMm, groundFloorHeightInMm, floorHeightInMm);
    maxApartments =
      5 *
      getNumberOfFloors(maxHeightInMm, groundFloorHeightInMm, floorHeightInMm);
  } else if (accessibilityType === AccesType.HSASingleFamilyHome) {
    minApartments = Math.floor(
      convertMetersToMilimeters(dimensionsLength[0]) / maxApartmentWidthInMm
    );
    maxApartments = Math.floor(
      convertMetersToMilimeters(dimensionsLength[1]) / minApartmentWidthInMm
    );
  }

  return [minApartments, maxApartments];
}

const calculateNumberOfApartments = (
  lengtInMm: number,
  heightInMm: number,
  apartmentWidthInMm: number,
  bayWidthEntranceInMm: number,
  bayWidthCoreInMm: number,
  bayWidthContainerSpaceInMm: number,
  floorHeightInMm: number,
  groundFloorHeightInMm: number,
  rowsOfApartments: number = 1
) => {
  const widthForApartmentsInMm =
    lengtInMm - bayWidthEntranceInMm - bayWidthCoreInMm;
  const numberOfApartmentsGroundFloor = Math.floor(
    ((widthForApartmentsInMm - bayWidthContainerSpaceInMm) /
      apartmentWidthInMm) *
      rowsOfApartments
  );
  const numberOfApartmentsPerFloor = Math.floor(
    (widthForApartmentsInMm / apartmentWidthInMm) * rowsOfApartments +
      rowsOfApartments
  );
  const numberOfFloors = getNumberOfFloors(
    heightInMm,
    groundFloorHeightInMm,
    floorHeightInMm
  );

  return (
    numberOfApartmentsGroundFloor + numberOfApartmentsPerFloor * numberOfFloors
  );
};

const getNumberOfFloors = (
  buildingHeightInMm: number,
  groundFloorHeightInMm: number,
  floorHeightInMm: number
) => {
  return Math.floor(
    (buildingHeightInMm - groundFloorHeightInMm) / floorHeightInMm
  );
};
