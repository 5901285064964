import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
} from "@mui/material";
import { TransformatorDto } from "../../../interfaces/foundry/typicals/TransformatorDto";
import { CloseIcon } from "../../../icons/CloseIcon";
import CustomInputLabel from "../../../components/InputLabel/CustomInputLabel";
import { useTranslation } from "react-i18next";

interface TransformatorDialogProps {
  isTransformatorViewOpen: boolean;
  setIsTransformatorViewOpen: (value: boolean) => void;
  currentTransformator: TransformatorDto;
  setCurrentTransformator: (value: TransformatorDto) => void;
  handleSubmit: () => void;
}

export function TransformatorDialog({
  currentTransformator,
  handleSubmit,
  isTransformatorViewOpen,
  setCurrentTransformator,
  setIsTransformatorViewOpen,
}: TransformatorDialogProps) {

  const { t } = useTranslation();
  
  return (
    <Dialog
      maxWidth="xl"
      open={isTransformatorViewOpen}
      onClose={() => setIsTransformatorViewOpen(false)}
      PaperProps={{
        style: {
          borderRadius: 20,
          width: "25%",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          paddingRight: "15px",
          paddingBottom: "5px",
          fontWeight: 700,
        }}
      >
        {`${t("Create")}/${t("Edit")} ${t("transformator")}`}
        <IconButton
          sx={{ ml: "auto" }}
          onClick={() => setIsTransformatorViewOpen(false)}
        >
          <CloseIcon className="svg" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: "25px" }}>
      <FormControl fullWidth variant="standard">
          <CustomInputLabel
            showInfoButton={false}
            labelText={t("ComponentsPage.Dialog.SupplierComponentType")}
            tooltipText=""
            id=""
          />
          <TextField
            fullWidth
            value={currentTransformator.supplier_component_type}
            style={{ marginTop: "5px" }}
            variant="standard"
            onChange={(event) =>
              setCurrentTransformator({
                ...currentTransformator,
                supplier_component_type: event.target.value,
              })
            }
          />
        </FormControl>
        <FormControl fullWidth variant="standard" sx={{ marginTop: "15px" }}>
          <CustomInputLabel
            showInfoButton={false}
            labelText={`${t("Power")} (kW)`}
            tooltipText=""
            id=""
          />
          <TextField
            fullWidth
            style={{ marginTop: "5px" }}
            value={currentTransformator.power_in_kva}
            variant="standard"
            onChange={(event) =>
              setCurrentTransformator({
                ...currentTransformator,
                power_in_kva: Number(event.target.value),
              })
            }
          />
        </FormControl>
        <FormControl fullWidth variant="standard" sx={{ marginTop: "15px" }}>
          <CustomInputLabel
            showInfoButton={false}
            labelText="Efficiency (%)"
            tooltipText=""
            id=""
          />
          <TextField
            fullWidth
            style={{ marginTop: "5px" }}
            value={currentTransformator.efficiency}
            variant="standard"
            onChange={(event) =>
              setCurrentTransformator({
                ...currentTransformator,
                efficiency: Number(event.target.value),
              })
            }
          />
        </FormControl>
        <FormControl fullWidth variant="standard" sx={{ marginTop: "15px" }}>
          <CustomInputLabel
            showInfoButton={false}
            labelText={`${t("Length")} (mm)`}
            tooltipText=""
            id=""
          />
          <TextField
            fullWidth
            style={{ marginTop: "5px" }}
            value={currentTransformator.length}
            variant="standard"
            onChange={(event) =>
              setCurrentTransformator({
                ...currentTransformator,
                length: Number(event.target.value),
              })
            }
          />
        </FormControl>
        <FormControl fullWidth variant="standard" sx={{ marginTop: "15px" }}>
          <CustomInputLabel
            showInfoButton={false}
            labelText={`${t("Width")} (mm)`}
            tooltipText=""
            id=""
          />
          <TextField
            fullWidth
            style={{ marginTop: "5px" }}
            value={currentTransformator.width}
            variant="standard"
            onChange={(event) =>
              setCurrentTransformator({
                ...currentTransformator,
                width: Number(event.target.value),
              })
            }
          />
        </FormControl>
        <FormControl fullWidth variant="standard" sx={{ marginTop: "15px" }}>
          <CustomInputLabel
            showInfoButton={false}
            labelText={`${t("Height")} (mm)`}
            tooltipText=""
            id=""
          />
          <TextField
            fullWidth
            value={currentTransformator.height}
            style={{ marginTop: "5px" }}
            variant="standard"
            onChange={(event) =>
              setCurrentTransformator({
                ...currentTransformator,
                height: Number(event.target.value),
              })
            }
          />
        </FormControl>
        <FormControl fullWidth variant="standard" sx={{ marginTop: "15px" }}>
          <CustomInputLabel
            showInfoButton={false}
            labelText={`${t("ComponentsPage.Dialog.CO2Impact")} (€)`}
            tooltipText=""
            id=""
          />
          <TextField
            fullWidth
            value={currentTransformator.co2_footprint_in_euros}
            style={{ marginTop: "5px" }}
            variant="standard"
            onChange={(event) =>
              setCurrentTransformator({
                ...currentTransformator,
                co2_footprint_in_euros : Number(event.target.value),
              })
            }
          />
        </FormControl>
      </DialogContent>
      <DialogActions style={{ paddingRight: "20px" }}>
        <Button onClick={handleSubmit}>{t("Save")}</Button>
      </DialogActions>
    </Dialog>
  );
}
