import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import CancelButton from "../../../../components/CancelButton/CancelButton";
import CustomFormControl from "../../../../components/CustomFormControl/CustomFormControl";
import { UpdateLVRsBuildingTypes } from "../../../../http/foundry/LowVoltageRoomService";
import { UpdateWSsBuildingTypes } from "../../../../http/foundry/WhitespaceService";
import { UpdateRackBuildingTypes } from "../../../../http/foundry/typicals/RackService";
import { UpdateTrafoBuildingTypes } from "../../../../http/foundry/typicals/TransformatorService";
import { TypeObject } from "../../../../interfaces/enums/TypeObjectEnum";
import { BuildingTypeDto } from "../../../../interfaces/foundry/BuildingTypeDto";
import WhitespaceDto from "../../../../interfaces/foundry/WhitespaceDto";
import { LowVoltageRoomDto } from "../../../../interfaces/foundry/typicals/LowVoltageRoomDto";
import { TrackException } from "../../../../logging/LoggingManager";
import useDatacenterGeneratorStore from "../../../../state/DatacenterState/datacenterGeneratorState";
import useGeneralDatacenterObjectsStore from "../../../../state/DatacenterState/generalDatacenterObjectsState";
import {
  DTO,
  ExploreActions,
  ExploreState,
} from "../../../../state/ExploreState/ExploreState";
import { AxiosError } from "axios";

interface DcAddToSetDialogProps<T extends DTO> {
  currentState: ExploreState<T> & ExploreActions<T>;
  onClose: () => void;
  onSave: () => void;
  open: boolean;
}
export default function DcAddToSetDialog<T extends DTO>(
  props: DcAddToSetDialogProps<T>
) {
  const { currentState, onClose, onSave, open } = props;
  const [buildingType, setBuildingType] = useState<BuildingTypeDto[]>([]);
  const { currentPageType } = useDatacenterGeneratorStore();
  const { buildingTypeDtoOptions } = useGeneralDatacenterObjectsStore();

  const handleClose = () => {
    onClose();
    setBuildingType([]);
  };

  const saveBuildingTypes = async () => {
    try {
      const buildingTypeId = buildingType
        .filter((bt) => bt.id !== -1)
        .map((bt) => bt.id);

      const updatePromises = currentState.selectedIds.map((id: number) => {
        switch (currentPageType) {
          case TypeObject.LowVoltageRoomTooling:
            return UpdateLVRsBuildingTypes(id, buildingTypeId);
          case TypeObject.WhitespaceTooling:
            return UpdateWSsBuildingTypes(id, buildingTypeId);
          case TypeObject.Transformator:
            return UpdateTrafoBuildingTypes(id, buildingTypeId);
          case TypeObject.Rack:
            return UpdateRackBuildingTypes(id, buildingTypeId);
          default:
            return null;
        }
      });
      const results = await Promise.allSettled(updatePromises);
      const updatedLowVoltageRoom = results
        .filter(
          (result): result is PromiseFulfilledResult<LowVoltageRoomDto> =>
            result.status === "fulfilled"
        )
        .map((result) => result.value);

      const newItemsForLoadedObjects = currentState.loadedObjects.map(
        (item) => {
          const updatedItem = updatedLowVoltageRoom.find(
            (update) => update.id === item.id
          );

          if (updatedItem) {
            if (item.typeObject === TypeObject.LowVoltageRoomTooling)
              (item as LowVoltageRoomDto).building_types =
                updatedItem.building_types;
            else if (item.typeObject === TypeObject.WhitespaceTooling)
              (item as WhitespaceDto).building_types =
                updatedItem.building_types;
            else item = updatedItem as T;
          }
          return item;
        }
      );
      currentState.setLoadedObjects(newItemsForLoadedObjects);
      const newItemsForSelectedObjects = currentState.selectedIds.map((id) => {
        return id;
      });
      currentState.setSelectedIds(newItemsForSelectedObjects);
    } catch (ex) {
      TrackException(ex as AxiosError);
    }

    setBuildingType([]);
    onSave();
  };

  const handleChangeBuildingType = (event: SelectChangeEvent<string[]>) => {
    const selectedIds = event.target.value;
    const validBuildingTypeOptions = buildingTypeDtoOptions.filter(
      (bt): bt is BuildingTypeDto => bt !== undefined
    ) as BuildingTypeDto[];
    const valuesBuildingType = Object.values(validBuildingTypeOptions);

    if (selectedIds.includes("all")) {
      setBuildingType(
        buildingType.length === valuesBuildingType.length
          ? []
          : valuesBuildingType
      );
    } else {
      const selectedBuildings = valuesBuildingType.filter((building) =>
        selectedIds.includes(building?.id.toString())
      );
      setBuildingType(selectedBuildings);
    }
  };

  return (
    <Dialog
      maxWidth="xl"
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      PaperProps={{
        style: {
          borderRadius: 20,
          width: "28%",
          height: "25vh",
        },
      }}
    >
      <DialogTitle
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: 16,
          fontWeight: 900,
        }}
        sx={{ display: "flex", alignItems: "center" }}
      >
        {t("Add to set")}
        <Box component={"div"} sx={{ ml: "auto" }}>
          <CancelButton onClick={handleClose} />
        </Box>
      </DialogTitle>
      <DialogContent sx={{ padding: "0px 24px !important" }}>
        <CustomFormControl
          labelText={t("Building type")}
          showInfoButton={true}
          toolTipText={t("Building type")}
        >
          <Select
            multiple
            style={{ height: "auto" }}
            labelId="type-building-label"
            id="type-building-select"
            value={buildingType.map((t) => t.id.toString())}
            onChange={handleChangeBuildingType}
            label={t("Building type")}
            renderValue={(selectedIds: string[]) => (
              <Box
                component={"div"}
                sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
              >
                {selectedIds.map((id) => {
                  const building = Object.values(buildingTypeDtoOptions).find(
                    (t) => t && t.id.toString() === id
                  );
                  return building ? (
                    <Chip key={id} label={t(building.name)} />
                  ) : null;
                })}
              </Box>
            )}
          >
            <MenuItem value="all">
              <ListItemIcon>
                <Checkbox
                  checked={
                    Object.values(buildingTypeDtoOptions).length > 0 &&
                    buildingType.length ===
                      Object.values(buildingTypeDtoOptions).length
                  }
                  indeterminate={
                    buildingType.length > 0 &&
                    buildingType.length <
                      Object.values(buildingTypeDtoOptions).length
                  }
                />
              </ListItemIcon>
              <ListItemText primary={t("Select all")} />
            </MenuItem>
            {Object.values(buildingTypeDtoOptions).map(
              (building) =>
                building && (
                  <MenuItem key={building?.id} value={building?.id.toString()}>
                    <Checkbox
                      checked={buildingType.some(
                        (t) => t && t.id === building?.id
                      )}
                    />
                    <ListItemText primary={t(building.name)} />
                  </MenuItem>
                )
            )}
          </Select>
        </CustomFormControl>
      </DialogContent>
      <DialogActions>
        <Button
          className="button"
          variant="contained"
          color="secondary"
          onClick={() => {
            saveBuildingTypes();
          }}
        >
          {t("Save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
