import ResponseDto from "../../../interfaces/ResponseDto";
import RowDto from "../../../interfaces/foundry/typicals/RowDto";
import { TrackException } from "../../../logging/LoggingManager";
import useGeneralDatacenterObjectsStore from "../../../state/DatacenterState/generalDatacenterObjectsState";
import { foundryApiClient } from "../../../modules/sharedLogic/services/AxiosClient";
import { AxiosError } from "axios";

export async function GetRow(id: number): Promise<RowDto> {
  return (await GetRows()).find((x) => x.id === id) as RowDto;
}

export async function GetRows(): Promise<RowDto[]> {
  const store = useGeneralDatacenterObjectsStore.getState();
  let rows = store.rows;
  if (rows.length === 0) {
    rows = await GetRowDtos();
    store.setRows(rows);
  }
  return rows;
}

async function GetRowDtos(): Promise<RowDto[]> {
  try {
    const coolings = (
      (await foundryApiClient.get<ResponseDto>("/api/datacenter/rows")).data
        .results as RowDto[]
    ).map((item: RowDto) => {
      return item;
    });
    return coolings;
  } catch (ex) {
    TrackException(ex as AxiosError);
    throw ex;
  }
}
