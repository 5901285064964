import {
  GridColDef,
  GridColumnGroupingModel,
  GridRowModel,
} from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GetActivities } from "../../../http/aggregate/ActivitiesService";
import { GetCategories } from "../../../http/aggregate/CategoriesService";
import { GetInputDataByType } from "../../../http/aggregate/InputDataService";
import {
  DeleteProductionKeyFigure,
  GetEquipmentKeyFigures,
  GetProductionKeyFigures,
  SaveProductionKeyFigure,
  UpdateProductionKeyFigure,
} from "../../../http/aggregate/KeyFigureService";
import { BaseDatabaseOptionDto } from "../../../interfaces/aggregate/BaseDatabaseOptionDto";
import { EquipmentTypeDto } from "../../../interfaces/aggregate/EquipmentTypeDto";
import { ProductionKeyFigureDto } from "../../../interfaces/aggregate/ProductionKeyFigureDto";
import { AggregatePageEnum } from "../../../interfaces/aggregate/enums/AggregatePageEnum";
import { InputDataCatalogEnum } from "../../../interfaces/aggregate/enums/InputDataCatalogEnum";
import useUserStore from "../../../modules/sharedLogic/state/userState";
import useActivitiesStore from "../../../state/DatabaseState/activitiesState";
import useDatabaseStore from "../../../state/DatabaseState/databaseState";
import useEquipmentKeyFiguresStore from "../../../state/DatabaseState/equipmentKeyFiguresState";
import useObjectTypeStore from "../../../state/DatabaseState/objectTypesState";
import useProductionKeyFiguresStore from "../../../state/DatabaseState/productionKeyFiguresState";
import themeVariables from "../../../styles/theme-variables.module.scss";
import { safeNumber } from "../../../utils/HelperFunctions";
import { BaseDatabasePage, groupBy } from "../BaseDatabasePage";
import { AutoComplete } from "../components/AutoComplete/AutoComplete";

interface Item {
  description: string;
  brand: string;
  manufacturedYear: number;
  type: string;
}

export function ProductionKeyFiguresDatabaseView() {
  const { dataGridColumnWidth } = themeVariables;
  const { t } = useTranslation();
  const {
    dimensionUnits,
    productionUnits,
    materialTypes,
    productionKeyFigures,
    setDimensionUnits,
    setProductionUnits,
    setMaterialTypes,
    setProductionKeyFigures,
  } = useProductionKeyFiguresStore();

  const { allActivities, setAllActivities } = useActivitiesStore();
  const { equipmentKeyFigures, setEquipmentKeyFigures } =
    useEquipmentKeyFiguresStore();
  const { objectTypes, setObjectTypes } = useObjectTypeStore();

  const {
    owners,
    selectedNode,
    isEditMode,
    setCurrentPage,
    setCategories,
    setCurrentDatabaseOptions,
  } = useDatabaseStore();

  const [equipmentType, setEquipmentType] = React.useState<EquipmentTypeDto>();

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("Description"),
      editable: isEditMode,
      width: Number(dataGridColumnWidth),
      renderCell: (params) => {
        const productionKeyFigure = params.row as ProductionKeyFigureDto;
        if (productionKeyFigure.id === 0) {
          return "";
        }

        const productionKeyFiguresWithSameName = productionKeyFigures.filter(
          (x) => x.name === productionKeyFigure?.name && x.id !== 0
        );
        if (productionKeyFiguresWithSameName.length === 1) {
          return productionKeyFigure.name;
        }

        const index = Math.floor(productionKeyFiguresWithSameName.length / 2);
        if (
          productionKeyFiguresWithSameName[index] &&
          productionKeyFiguresWithSameName[index].id === productionKeyFigure.id
        ) {
          return productionKeyFigure.name;
        } else return "";
      },
    },
    {
      field: "dimensionAmount",
      headerName: t("Dimension"),
      editable: isEditMode,
      width: Number(dataGridColumnWidth),
    },
    {
      field: "dimensionUnit",
      headerName: t("Dimension unit"),
      editable: isEditMode,
      type: "singleSelect",
      width: Number(dataGridColumnWidth),
      getOptionLabel: (value) => (value as BaseDatabaseOptionDto).name,
      getOptionValue: (value) => (value as BaseDatabaseOptionDto).id,
      valueFormatter(value, row) {
        return row.dimensionUnit?.name;
      },
      valueOptions: dimensionUnits.sort((a, b) => a.name.localeCompare(b.name)),
      renderEditCell: (params) => {
        return (
          <AutoComplete
            params={params}
            renderOptions={dimensionUnits.sort((a, b) =>
              a.name.localeCompare(b.name)
            )}
          />
        );
      },
    },
    {
      field: "activity",
      headerName: t("Activity"),
      editable: isEditMode,
      type: "singleSelect",
      width: Number(dataGridColumnWidth),
      getOptionLabel: (value) => (value as BaseDatabaseOptionDto).name,
      getOptionValue: (value) => (value as BaseDatabaseOptionDto).id,
      valueFormatter(value, row) {
        return row.activity?.name;
      },
      valueOptions: allActivities.sort((a, b) => a.name.localeCompare(b.name)),
      renderEditCell: (params) => {
        return (
          <AutoComplete
            params={params}
            renderOptions={allActivities.sort((a, b) =>
              a.name.localeCompare(b.name)
            )}
          />
        );
      },
    },
    {
      field: "workForces",
      headerName: t("Workforce"),
      width: Number(dataGridColumnWidth),
      editable: isEditMode,
    },
    {
      field: "productionPerHour",
      headerName: t("Production per hour"),

      editable: isEditMode,
      width: Number(dataGridColumnWidth),
    },
    {
      field: "productionUnit",
      headerName: t("Production per day unit"),
      editable: isEditMode,
      width: Number(dataGridColumnWidth),
      type: "singleSelect",
      getOptionLabel: (value) => (value as BaseDatabaseOptionDto).name,
      getOptionValue: (value) => (value as BaseDatabaseOptionDto).id,
      valueFormatter(value, row) {
        return row?.productionUnit?.name;
      },
      valueOptions: productionUnits.sort((a, b) =>
        a.name.localeCompare(b.name)
      ),
      renderEditCell: (params) => {
        return (
          <AutoComplete
            params={params}
            renderOptions={productionUnits.sort((a, b) =>
              a.name.localeCompare(b.name)
            )}
          />
        );
      },
    },
    {
      field: "objectType",
      headerName: t("Object type"),
      editable: isEditMode,
      width: Number(dataGridColumnWidth),
      type: "singleSelect",
      getOptionLabel: (value) => (value as BaseDatabaseOptionDto).name,
      getOptionValue: (value) => (value as BaseDatabaseOptionDto).id,
      valueFormatter(value, row) {
        return row?.objectType?.name;
      },
      valueOptions: objectTypes.sort((a, b) => a.name.localeCompare(b.name)),
      renderEditCell: (params) => {
        return (
          <AutoComplete
            params={params}
            renderOptions={objectTypes.sort((a, b) =>
              a.name.localeCompare(b.name)
            )}
          />
        );
      },
    },
    {
      field: "equipmentType",
      headerName: t("Material type"),
      width: Number(dataGridColumnWidth),
      editable: isEditMode,
      type: "singleSelect",
      getOptionLabel: (value) => (value as BaseDatabaseOptionDto).name,
      getOptionValue: (value) => (value as BaseDatabaseOptionDto).id,
      valueFormatter(_value, row) {
        return row?.equipmentType?.name;
      },
      valueOptions: materialTypes.sort((a, b) => a.name.localeCompare(b.name)),
      renderEditCell: (params) => {
        return (
          <AutoComplete
            params={params}
            renderOptions={materialTypes.sort((a, b) =>
              a.name.localeCompare(b.name)
            )}
            onChange={(_event, newValue: EquipmentTypeDto | string | null) => {
              if (newValue !== null) {
                setEquipmentType(newValue as EquipmentTypeDto);
              }
            }}
          />
        );
      },
    },
    {
      field: "equipmentKeyFigure",
      headerName: `${t("Equipment")} (${t("optional")})`,
      width: 300,
      editable: isEditMode,
      type: "singleSelect",
      valueGetter(value, row) {
        if (!row.equipmentKeyFigure) {
          return "";
        }
        return `${row.equipmentKeyFigure.description} (${t(
          "Brand"
        ).toLowerCase()}: ${row.equipmentKeyFigure.brand?.name}, ${t(
          "ManufacturedYear"
        ).toLowerCase()}: ${row.equipmentKeyFigure.manufacturedYear}, ${t(
          "BrandType"
        ).toLowerCase()}: ${row.equipmentKeyFigure.brandType})`;
      },
      valueOptions: equipmentKeyFigures.map(
        (x) =>
          `${x.description} (${t("Brand").toLowerCase()}: ${x.brand.name}, ${t(
            "ManufacturedYear"
          ).toLowerCase()}: ${x.manufacturedYear}, ${t(
            "BrandType"
          ).toLowerCase()}: ${x.brandType})`
      ),
      renderEditCell: (params) => {
        const filteredEquipmentKeyFigures = equipmentType
          ? equipmentKeyFigures.filter(
              (x) => x.equipmentType.id === equipmentType.id
            )
          : equipmentKeyFigures;

        return (
          <AutoComplete
            params={params}
            renderOptions={filteredEquipmentKeyFigures
              .sort((a, b) => a.description.localeCompare(b.description))
              .map(
                (x) =>
                  `${x.description} (${t("Brand").toLowerCase()}: ${
                    x.brand.name
                  }, ${t("ManufacturedYear").toLowerCase()}: ${
                    x.manufacturedYear
                  }, ${t("BrandType").toLowerCase()}: ${x.brandType})`
              )}
          />
        );
      },
    },
  ];

  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: t("Element data"),
      children: [
        { field: "name" },
        { field: "dimensionAmount" },
        { field: "dimensionUnit" },
      ],
    },
    {
      groupId: t("Production data"),
      children: [
        { field: "activity" },
        { field: "workForces" },
        { field: "productionPerHour" },
        { field: "productionUnit" },
        { field: "equipment" },
        { field: "equipmentType" },
        { field: "objectType" },
      ],
    },
  ];

  async function processRowUpdate(
    gridRowModel: GridRowModel
  ): Promise<ProductionKeyFigureDto> {
    const productionKeyFigure = gridRowModel as ProductionKeyFigureDto;
    productionKeyFigure.categoryId =
      productionKeyFigure.category?.id || Number(selectedNode);
    productionKeyFigure.departmentId = productionKeyFigure.department?.id || 0;
    productionKeyFigure.equipmentTypeId =
      productionKeyFigure.equipmentType?.id || 0;
    productionKeyFigure.objectTypeId = productionKeyFigure.objectType?.id || 0;
    productionKeyFigure.activityId = productionKeyFigure.activity?.id || 0;
    productionKeyFigure.dimensionUnitId =
      productionKeyFigure.dimensionUnit?.id || 0;
    productionKeyFigure.productionUnitId =
      productionKeyFigure.productionUnit?.id || 0;
    productionKeyFigure.ownerId = productionKeyFigure.owner?.id || 0;
    productionKeyFigure.dimensionAmount = safeNumber(
      gridRowModel.dimensionAmount
    );
    productionKeyFigure.workForces = safeNumber(gridRowModel.workForces);
    productionKeyFigure.productionPerHour = safeNumber(
      gridRowModel.productionPerHour
    );

    if (
      gridRowModel.equipmentKeyFigure &&
      gridRowModel.equipmentKeyFigure !== ""
    ) {
      const parsedObject = parseString(gridRowModel.equipmentKeyFigure);
      if (parsedObject) {
        const equipmentKeyFigure = equipmentKeyFigures.find(
          (x) =>
            x.description === parsedObject.description &&
            x.manufacturedYear === parsedObject.manufacturedYear &&
            x.brandType === parsedObject.type &&
            x.brand.name.includes(parsedObject.brand)
        );

        productionKeyFigure.equipmentId = equipmentKeyFigure?.id ?? 0;
      }
    }

    if (productionKeyFigure.id === 0) {
      productionKeyFigure.id = await SaveProductionKeyFigure(
        productionKeyFigure
      );
      setProductionKeyFigures(
        productionKeyFigures.map((item: ProductionKeyFigureDto) =>
          item.id === 0 ? productionKeyFigure : item
        )
      );
    } else {
      const oldKeyFigure = productionKeyFigures.find(
        (x) => x.id === productionKeyFigure.id
      )!;
      productionKeyFigure.categoryId = oldKeyFigure.category.id;
      await UpdateProductionKeyFigure(productionKeyFigure);
      setProductionKeyFigures(
        productionKeyFigures.map((item: ProductionKeyFigureDto) =>
          item.id === productionKeyFigure.id ? productionKeyFigure : item
        )
      );
    }

    if (productionKeyFigure.id !== 0) {
      setTimeout(() => {
        LoadProductionKeyFigures();
      }, 500);
    }

    return productionKeyFigure;
  }

  useEffect(() => {
    setCurrentPage(AggregatePageEnum.ProductionKeyFigure);

    (async () => {
      setCategories(await GetCategories(AggregatePageEnum.ProductionKeyFigure));
      await LoadItems();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (selectedNode !== "") {
        await LoadProductionKeyFigures();
      }
    })();
  }, [selectedNode]);

  async function LoadItems() {
    const dimensionUnits = await GetInputDataByType(
      InputDataCatalogEnum.InputDataDimensionUnit
    );
    setCurrentDatabaseOptions(dimensionUnits);
    setDimensionUnits(dimensionUnits);
    setMaterialTypes(
      await GetInputDataByType(InputDataCatalogEnum.InputDataEquipmentType)
    );
    setObjectTypes(
      await GetInputDataByType(InputDataCatalogEnum.InputDataObjectType)
    );
    setProductionUnits(
      await GetInputDataByType(InputDataCatalogEnum.InputDataProductionUnit)
    );
    setEquipmentKeyFigures(await GetEquipmentKeyFigures());

    if (allActivities.length === 0) {
      setAllActivities(await GetActivities());
    }
  }

  async function LoadProductionKeyFigures() {
    setProductionKeyFigures(await GetProductionKeyFigures(selectedNode));
  }

  function parseString(input: string): Item | null {
    const regex =
      /^(.+?) \((?:fabrikant: (.+?), )?(?:bouwjaar: (\d{4}), )?(?:type: (.+?))?\)$/;
    const match = input.match(regex);

    if (!match) {
      return null;
    }

    const [, description, fabrikant, bouwjaar, type] = match;

    return {
      description: description.trim(),
      brand: fabrikant ? fabrikant.trim() : "",
      manufacturedYear: bouwjaar ? parseInt(bouwjaar, 10) : NaN,
      type: type ? type.trim() : "",
    };
  }

  return (
    <BaseDatabasePage
      columns={columns}
      data={groupBy(productionKeyFigures, (item) => item.name)}
      columnGroupingModel={columnGroupingModel}
      addNewItem={(groupKey: string) => {
        const productionKeyFigureDto = productionKeyFigures.find(
          (x) => x.name === groupKey
        );
        setProductionKeyFigures([
          ...productionKeyFigures,
          {
            id: 0,
            name: productionKeyFigureDto?.name,
            objectType: productionKeyFigureDto?.objectType,
            category: productionKeyFigureDto?.category,
            owner: owners.find(
              (x) => x.emailAddress === useUserStore.getState().emailAddress
            ),
          } as ProductionKeyFigureDto,
        ]);
      }}
      baseProcessRowUpdate={processRowUpdate}
      handleCancel={() => {
        setProductionKeyFigures(productionKeyFigures.filter((x) => x.id !== 0));
      }}
      handleDelete={(params) => {
        DeleteProductionKeyFigure(Number(params));
        setProductionKeyFigures(
          productionKeyFigures.filter((x) => x.id !== params)
        );
      }}
    />
  );
}
