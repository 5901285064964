import { Grid } from "@mui/material";
import { memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import UnitMix from "../../../HSA/components/UnitMix/UnitMix";
import { ValidationError } from "../../../HSA/state/moduleDetailsState";
import InfoField from "../../types/InfoField";
import BooleanField from "./BooleanField";
import NumberField from "./NumberField";
import "./PropertySectionView.scss";
import SelectField from "./SelectField";
import CustomTextField from "./TextField";
import ComponentsList from "./ComponentsList";
import { UnitType } from "../../types/UnitType";
import { SpaceCodeCounts } from "../../../HSA/types/api";
import LabelWithInfoButton from "../../../../components/InputLabel/LabelWithInfoButton";

interface PropertySectionViewProps<T> {
  sectionTitle: string;
  fields: InfoField[];
  data: T;
  isNew: boolean;
  editing: boolean;
  validationErrors: ValidationError[];
  onPropertyChange?: (
    path: string,
    newValue: string | boolean | number
  ) => void;
}

const PropertySectionView = memo(
  <T,>({
    sectionTitle,
    fields,
    data,
    isNew,
    editing,
    onPropertyChange,
    validationErrors,
  }: PropertySectionViewProps<T>) => {
    return (
      <>
        <div className="property-section-title">{sectionTitle}</div>
        <hr />
        <div className="property-section-properties">
          {fields.map((field, index) => (
            <PropertyField
              key={index}
              field={field}
              data={data}
              isNew={isNew}
              editing={editing}
              onPropertyChange={onPropertyChange}
              validationErrors={validationErrors}
            />
          ))}
        </div>
      </>
    );
  }
);

export default PropertySectionView;

interface PropertyFieldProps<T> {
  field: InfoField;
  data: T;
  isNew: boolean;
  editing: boolean;
  validationErrors: ValidationError[];
  onPropertyChange?: (
    path: string,
    newValue: string | boolean | number
  ) => void;
}

const PropertyField = memo(
  <T,>({
    field,
    data,
    isNew,
    editing,
    onPropertyChange,
    validationErrors,
  }: PropertyFieldProps<T>) => {
    const { t } = useTranslation();
    const value = useMemo(() => {
      if (field.defaultValue !== undefined) return field.defaultValue;
      let current: unknown = data;
      const path = field.property.split(".");
      for (const key of path) {
        if (
          current === null ||
          current === undefined ||
          typeof current !== "object"
        ) {
          return null;
        }
        current = (current as { [key: string]: unknown })[key];
      }
      return current;
    }, [data, field.property]);

    const error = validationErrors.some(
      (err) => err.property === field.property
    );

    const handleChange = useCallback(
      (newValue: string | boolean | number) => {
        if (!onPropertyChange) {
          return;
        }
        onPropertyChange(field.property, newValue);
      },
      [onPropertyChange, field.property]
    );

    const disabled =
      !editing ||
      field.disabled === true ||
      (!isNew && (field.editable === undefined || !field.editable));

    const renderFieldComponent = () => {
      switch (field.type) {
        case "boolean":
          return (
            <BooleanField
              value={Boolean(value)}
              onChange={() => handleChange(!value)}
              disabled={disabled}
            />
          );
        case "select":
          return (
            <SelectField
              value={value as string | undefined}
              onChange={handleChange}
              disabled={disabled}
              options={field.values ?? []}
              error={error}
            />
          );
        case "number":
          return (
            <NumberField
              value={value as number | undefined}
              onChange={handleChange}
              disabled={disabled}
              error={error}
              unit={field.unit}
            />
          );
        case "unitMix":
          return (
            <div className="mt5">
              <UnitMix
                units={value as UnitType[]}
                showSlider={false}
                editPercentage={false}
                setUnits={() => {}}
              />
            </div>
          );
        default:
          return (
            <CustomTextField
              value={value as string}
              onChange={handleChange}
              disabled={disabled}
              error={error}
              unit={field.unit}
            />
          );
      }
    };

    if (field.type === "componentsList") {
      return <ComponentsList components={value as SpaceCodeCounts[]} />;
    }

    return (
      <Grid
        sx={{ marginTop: !editing ? "-10px" : "" }}
        container
        columnSpacing={1}
        className="sidebar-section-property"
      >
        <Grid
          item
          xs={6}
          className={field.type !== "unitMix" ? "center-center" : ""}
        >
          <div
            className={field.type === "unitMix" ? "mt5" : ""}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "flex",
              justifyItems: "start",
            }}
          >
            <LabelWithInfoButton labelKey={field.label} />
          </div>
        </Grid>
        <Grid
          item
          xs={"auto"}
          className={field.type !== "unitMix" ? "center-center" : ""}
        >
          <div className={field.type === "unitMix" ? "mt5" : ""}>{":"}</div>
        </Grid>
        <Grid
          item
          xs
          className="center-center"
          sx={{ display: "inline-grid", width: "100%" }}
        >
          {renderFieldComponent()}
        </Grid>
      </Grid>
    );
  }
);
