import MapboxDraw, { DrawCustomMode } from "@mapbox/mapbox-gl-draw";
import * as turf from "@turf/turf";
import { GetColorPalette } from "../../../../../../../http/foundry/ColorPaletteService";
import { SelectedBuildingsDto } from "../../../../../../../interfaces/foundry/SelectedBuildingsDto";
import {
  LayoutDataDto,
  LayoutDto,
} from "../../../../../../../interfaces/LayoutDto";
import { TrackException } from "../../../../../logging/LoggingManager";
import { ProjectFoundryDto } from "../../../../../types/api";
import ColorPalette from "../../../../../types/ColorPalette";
import DrawedPlot, {
  GetEmptyDrawedPlot,
} from "../../../../../types/DrawedPlot";
import { GenerateGuid } from "../../../../../utils/HelperFunctions";
import { BaseMapDrawControl } from "../BaseMapDrawControl";
import {
  CalculateOffsetGeometry,
  CalculateRdCoordinates,
  GetDrawControlStyles,
  GetDrawedPlot,
  GetPlotOrigin,
  GetPolygonOfString,
} from "../../DrawControlUtils";
import { GetSelectedBuildings } from "./DatacenterDrawUtils";
import RotateMode from "./RotateMode";
import { AxiosError } from "axios";
import { PointLike } from "mapbox-gl";
import { Feature, Geometry, GeoJsonProperties } from "geojson";

type DatacenterDrawControlProps = ConstructorParameters<
  typeof MapboxDraw
>[0] & {
  drawedPlot: DrawedPlot;
  currentProject: ProjectFoundryDto;
  setStylesLoaded: (stylesLoaded: boolean) => void;
  setDrawedPlot: (drawedPlot: DrawedPlot) => void;
  setIsSaveAvailable: (isSaveAvailable: boolean) => void;
  setCurrentProject: (currentProject: ProjectFoundryDto) => void;
  setNavigateDrawingPlot: (navigateDrawingPlot: boolean) => void;
};

export class DatacenterDrawControl extends BaseMapDrawControl {
  private offsetDistance = -4.8;
  private datacenterPlotDrawnTypes = [
    "datacenter",
    "genset",
    "whitespace",
    "cooling",
    "lsr_room",
  ];
  private plotDrawnComponents: GeoJSON.Feature<GeoJSON.Geometry>[] = [];
  private drawControlOptions: DatacenterDrawControlProps;

  constructor(options: DatacenterDrawControlProps) {
    super(options);
    this.drawControlOptions = options;
  }

  public onAdd(map: mapboxgl.Map): HTMLElement {
    this.map = map;
    this.initializeDrawControl();
    this.setupEventListeners();
    return this.drawControl.onAdd(map);
  }

  protected initializeDrawControl(): void {
    const modes = {
      ...MapboxDraw.modes,
      simple_select: this.createCustomSimpleSelectMode(),
      rotate_mode: this.createCustomRotateMode(),
    };

    this.drawControl = new MapboxDraw({
      ...this.options,
      controls: {},
      displayControlsDefault: false,
      userProperties: true,
      modes: modes,
      styles: GetDrawControlStyles(),
    });
  }

  protected setupEventListeners(): void {
    if (!this.map) return;
    this.map.on("load", () => this.handleMapLoad());
    this.map.on("draw.create", (evt) => this.handleCreate(evt));
    this.map.on("draw.update", (evt) => this.handleUpdate(evt));
    this.map.on("draw.delete", () => this.handleDelete());
  }

  protected removeEventListeners(): void {
    if (!this.map) return;
    this.map.off("load", () => this.handleMapLoad());
    this.map.off("draw.create", (evt) => this.handleCreate(evt));
    this.map.off("draw.update", (evt) => this.handleUpdate(evt));
    this.map.off("draw.delete", () => this.handleDelete());
  }

  private handleMapLoad() {
    const tempDrawedPlot = GetDrawedPlot(
      this.drawControlOptions.drawedPlot,
      this.drawControlOptions.currentProject?.plot
    );
    if (!tempDrawedPlot || !tempDrawedPlot.polygon) return;

    this.drawControlOptions.setDrawedPlot(tempDrawedPlot as DrawedPlot);
    this.drawControlOptions.setNavigateDrawingPlot(true);
    this.drawControl.add(tempDrawedPlot.polygon);
    this.updatePolygon(tempDrawedPlot.polygon);

    this.drawControlOptions.setStylesLoaded(true);
  }

  private handleCreate(evt: { features: GeoJSON.Feature[] }) {
    const createdFeature = evt.features[0];
    if (createdFeature) {
      this.updatePolygon(createdFeature);

      setTimeout(() => {
        this.drawControl.changeMode("simple_select", {
          featureIds: [],
        });
      }, 200);
    }
  }

  private handleUpdate(evt: { features: GeoJSON.Feature[] }) {
    try {
      const updatedFeature = evt.features[0];
      if (updatedFeature.properties?.kind === "plot") {
        this.updatePolygon(updatedFeature);
      } else if (updatedFeature.properties?.kind.startsWith("datacenter")) {
        const featureCollection = {
          type: "FeatureCollection",
          features: evt.features,
        } as turf.FeatureCollection<turf.MultiPolygon>;

        const origin = featureCollection.features[0].geometry.coordinates[0][0];
        this.updateDatacenter(evt.features, undefined, [
          Number(origin[0]),
          Number(origin[1]),
        ]);
      }
    } catch (ex) {
      TrackException(ex as AxiosError);
    }
  }

  private handleDelete() {
    this.drawControl.deleteAll();
    this.drawControlOptions.setDrawedPlot(GetEmptyDrawedPlot());
  }

  private updatePolygon(polygon: GeoJSON.Feature<GeoJSON.Geometry>) {
    const { features } = this.drawControl.getAll();
    features
      .filter((x) => x.id !== polygon.id)
      .forEach((feature) => this.drawControl.delete(feature.id + ""));

    this.drawControl.setFeatureProperty(polygon.id + "", "kind", "plot");
    this.drawControl.setFeatureProperty(polygon.id + "", "class_id", 0);

    let unqiueCoordinates: number[][] = [];
    if (polygon.geometry && polygon.geometry.type === "Polygon") {
      unqiueCoordinates = polygon.geometry.coordinates[0];
    }

    const turfPositions: turf.Position[] = [];
    unqiueCoordinates.forEach((point) => {
      turfPositions.push([point[0], point[1]] as turf.Position);
    });

    const positions: number[][] = [];
    const offsetGeometry = CalculateOffsetGeometry(
      turfPositions,
      this.offsetDistance
    );
    if (!offsetGeometry) return;

    const newFeature: GeoJSON.Feature<GeoJSON.Geometry> = {
      type: "Feature",
      geometry: offsetGeometry,
      properties: {
        kind: "buildablePlot",
        class_id: 1,
      },
    };
    this.drawControl.add(newFeature);

    offsetGeometry.coordinates[0].forEach((position) => {
      positions.push([Number(position[0]), Number(position[1])]);
    });

    const drawedPlot = GetDrawedPlot(
      this.drawControlOptions.drawedPlot,
      this.drawControlOptions.currentProject?.plot
    );
    const newDrawedPlot = {
      id: polygon.id,
      polygon: polygon,
      lotSurface: Math.round((turf.area(polygon) * 100) / 100),
      lotCoordinates: unqiueCoordinates,
      lotRdCoordinates: CalculateRdCoordinates(unqiueCoordinates),
      buildableSurface: drawedPlot?.buildableSurface
        ? drawedPlot.buildableSurface
        : Math.round((turf.area(offsetGeometry) * 100) / 100),
      buildableRdCoordinates: CalculateRdCoordinates(positions),
      buildableCoordinates: positions,
    } as DrawedPlot;

    this.drawControlOptions.setDrawedPlot(newDrawedPlot);
    this.drawControlOptions.setIsSaveAvailable(true);
  }

  private updateDatacenter(
    updatedPolygons: GeoJSON.Feature<GeoJSON.Geometry>[],
    rotationAngle: number | undefined = undefined,
    originalOrigin: number[] | undefined = undefined
  ) {
    if (this.map === null) return;

    try {
      const datacenterName = updatedPolygons[0].properties?.kind;
      const datacenterSource = this.map.getSource(
        datacenterName
      ) as mapboxgl.GeoJSONSource;
      if (!datacenterSource) {
        return;
      }

      const newFeature: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
        type: "FeatureCollection",
        features: updatedPolygons,
      };
      datacenterSource.setData(newFeature);

      const selectedBuildings = GetSelectedBuildings(
        this.drawControlOptions.currentProject
      );
      if (!selectedBuildings.datacentersDetails) {
        selectedBuildings.datacentersDetails = {};
      }

      if (!selectedBuildings.datacentersDetails[datacenterName]) {
        selectedBuildings.datacentersDetails[datacenterName] = {};
      }

      if (
        updatedPolygons.length > 0 &&
        updatedPolygons[0].geometry.type === "Polygon"
      ) {
        const datacenterDetails =
          selectedBuildings.datacentersDetails[datacenterName];

        if (originalOrigin) datacenterDetails.origin = originalOrigin;
        if (rotationAngle) datacenterDetails.rotationAngle = rotationAngle;

        selectedBuildings.datacentersDetails[datacenterName] =
          datacenterDetails;
      }

      this.updateProject(selectedBuildings);
    } catch (ex) {
      TrackException(ex as AxiosError);
    }
  }

  private updateProject(selectedBuildings: SelectedBuildingsDto) {
    const currentProject = this.drawControlOptions.currentProject;
    currentProject.buildings = JSON.stringify(selectedBuildings);
    this.drawControlOptions.setCurrentProject(currentProject);
  }

  public async generateDatacenter(
    plotPolygon: GeoJSON.Feature<GeoJSON.Geometry>,
    currentLayouts: LayoutDto[],
    generatedDatacentersIds: { id: number; count: number }[]
  ) {
    if (this.map === null) return;

    try {
      const sources = this.map.getStyle()?.sources;
      if (sources) {
        Object.keys(sources).forEach((source) => {
          if (source.startsWith("datacenter-")) {
            const correspondingLayer = source.replace("source", "layer");
            if (this.map!.getLayer(correspondingLayer)) {
              this.map!.removeLayer(correspondingLayer);
              this.map!.removeSource(source);
            }
          }
        });
      }

      const colorPalette = await GetColorPalette();
      const selectedBuildings = GetSelectedBuildings(
        this.drawControlOptions.currentProject
      );
      if (!selectedBuildings.datacentersDetails) {
        selectedBuildings.datacentersDetails = {};
      }

      const getDatacenterConfigurations = () => {
        const layers = this.map!.getStyle()?.layers;
        const configurations = new Map<number, Set<number>>();

        layers?.forEach((layer: { id: string }) => {
          const match = layer.id.match(/^datacenter-(\d+)-(\d+)-[a-z]+-\d+/);
          if (match) {
            const datacenterIndex = parseInt(match[1]);
            const configIndex = parseInt(match[2]);
            if (!configurations.has(datacenterIndex)) {
              configurations.set(datacenterIndex, new Set());
            }
            configurations.get(datacenterIndex)?.add(configIndex);
          }
        });

        return configurations;
      };

      const configurations = getDatacenterConfigurations();
      for (let j = 0; j < generatedDatacentersIds.length; j++) {
        const currentConfigurations =
          configurations.get(generatedDatacentersIds[j].id) || new Set();
        const currentCount = currentConfigurations.size;
        if (currentCount > generatedDatacentersIds[j].count) {
          const maxConfigIndex = Math.max(...Array.from(currentConfigurations));
          const datacenterToRemove = `datacenter-${generatedDatacentersIds[j].id}-${maxConfigIndex}`;
          const layersToRemove = this.map!.getStyle()!.layers.filter(
            (layer: { id: string }) => layer.id.startsWith(datacenterToRemove)
          );

          layersToRemove.forEach((layer: { id: string }) => {
            if (this.map!.getLayer(layer.id)) {
              this.map!.removeLayer(layer.id);
            }
          });
          this.drawControl.delete(datacenterToRemove);

          if (selectedBuildings.datacentersDetails[datacenterToRemove]) {
            delete selectedBuildings.datacentersDetails[datacenterToRemove];
          }
        } else {
          for (
            let i = currentCount;
            i < generatedDatacentersIds[j].count;
            i++
          ) {
            const datacenterToAdd = `datacenter-${generatedDatacentersIds[j].id}-${i}`;
            const datacenterDetails = selectedBuildings.datacentersDetails[
              datacenterToAdd
            ] || { origin: GetPlotOrigin(plotPolygon) };
            const lastInstanceOfCurrentDcOnMap = `datacenter-${
              generatedDatacentersIds[j].id
            }-${i - 1}`;
            const datacenterRotationAngle = datacenterDetails.rotationAngle
              ? datacenterDetails.rotationAngle
              : selectedBuildings.datacentersDetails[
                  lastInstanceOfCurrentDcOnMap
                ]?.rotationAngle || 0;

            datacenterDetails.rotationAngle = datacenterRotationAngle;

            if (!currentConfigurations.has(i)) {
              const datacenterName = `datacenter-${generatedDatacentersIds[j].id}-${i}`;
              this.plotDrawnComponents = [];

              this.processComponent(
                currentLayouts[j].data,
                datacenterDetails.origin!,
                datacenterRotationAngle,
                datacenterName
              );

              const newFeature: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
                type: "FeatureCollection",
                features: this.plotDrawnComponents,
              };
              this.drawControl.add(newFeature);

              this.map!.addSource(datacenterName, {
                type: "geojson",
                data: newFeature,
              });

              selectedBuildings.datacentersDetails[datacenterToAdd] =
                datacenterDetails;

              this.setupLayers(
                datacenterName,
                colorPalette,
                this.plotDrawnComponents
              );
            }
          }
        }
      }

      this.updateProject(selectedBuildings);
    } catch (ex) {}
  }

  private setupLayers(
    datacenterName: string,
    colorPalette: ColorPalette,
    plotDrawnComponents: Feature<Geometry, GeoJsonProperties>[]
  ) {
    const zIndexDictionary: { [key: string]: number[] } = {};
    plotDrawnComponents.forEach((component) => {
      const type = component.properties?.type_component;
      if (!zIndexDictionary[type]) {
        zIndexDictionary[type] = [];
      }
      zIndexDictionary[type].push(component.properties?.z_index);
    });

    Object.keys(zIndexDictionary).forEach((type) => {
      zIndexDictionary[type].forEach((zIndex, index) => {
        const id = `${datacenterName}-${type}-${index}`;
        const item = colorPalette[type as keyof ColorPalette];
        const fillColor = item ? item.fill_color : "#ffffff";

        if (type !== "datacenter") {
          this.map?.addLayer({
            id: id,
            type: "fill-extrusion",
            source: datacenterName,
            filter: ["==", "type_component", type],
            paint: {
              "fill-extrusion-color": fillColor,
              "fill-extrusion-opacity": 1,
              "fill-extrusion-height":
                zIndex === 0
                  ? ["get", "total_height"]
                  : ["+", ["get", "total_height"], zIndex],
              "fill-extrusion-base": zIndex,
            },
          });
        }
      });
    });

    this.map?.addLayer({
      id: `${datacenterName}-outline`,
      type: "line",
      filter: ["==", "type_component", "datacenter"],
      source: datacenterName,
      paint: {
        "line-color": "rgba(254, 184, 0, 1)",
        "line-dasharray": [2, 1],
      },
    });
  }

  private processComponent(
    component: LayoutDataDto,
    datacenterOrigin: number[],
    rotationAngle: number,
    datacenterKind: string
  ) {
    if (component.object === "dc_lsr_room") component.object = "lsr_room";

    if (this.datacenterPlotDrawnTypes.includes(component.object)) {
      const polygon = GetPolygonOfString(component.polygon2d, datacenterOrigin);
      const rotatedPolygon = turf.transformRotate(polygon, rotationAngle, {
        pivot: datacenterOrigin,
      });

      rotatedPolygon.id = GenerateGuid();
      rotatedPolygon.properties = {
        kind: datacenterKind,
        type_component: component.object,
        total_height: component.height / 1000,
        z_index: component.z / 1000,
        class_id: 1,
      };
      this.plotDrawnComponents.push(rotatedPolygon);
    }

    if (component.children && component.children.length > 0) {
      component.children.forEach((child) => {
        this.processComponent(
          child,
          datacenterOrigin,
          rotationAngle,
          datacenterKind
        );
      });
    }
  }

  private createCustomSimpleSelectMode(): DrawCustomMode {
    const self = this;

    const simpleSelectMode = {
      ...MapboxDraw.modes.simple_select,
      onClick: function (
        _state: SelectionMode,
        event: { point: PointLike | [PointLike, PointLike] }
      ) {
        try {
          const features = self.map?.queryRenderedFeatures(event.point);
          if (
            features &&
            features.length > 0 &&
            features[0].source?.startsWith("datacenter")
          ) {
            const source = self.map?.getSource(
              features[0].source
            ) as mapboxgl.GeoJSONSource;

            if (source && source._data) {
              self.drawControl.add(
                source._data as GeoJSON.FeatureCollection<GeoJSON.Geometry>
              );

              const featureCollection = self.drawControl.getAll();
              const featureCollectionIds: string[] = featureCollection.features
                .filter(
                  (x) =>
                    x.properties != null &&
                    x.properties.kind != null &&
                    x.properties.kind === features[0].source
                )
                .map((x) => x.id!.toString());

              self.drawControl.changeMode("simple_select", {
                featureIds: featureCollectionIds,
              });
              return;
            }
          }

          self.drawControl.changeMode("simple_select", {
            featureIds: [],
          });
        } catch (ex) {}
      },
    };

    return simpleSelectMode;
  }

  private createCustomRotateMode(): DrawCustomMode {
    const self = this;
    interface RotateModeState {
      originalFeature: GeoJSON.Feature<GeoJSON.Geometry>[];
      rotationAngles: { [key: string]: number };
    }
    const rotateMode = {
      ...RotateMode,
      onMouseUp: function (state: RotateModeState) {
        if (state.originalFeature && state.originalFeature.length > 0) {
          const rotationAngle =
            state.rotationAngles[state.originalFeature[0].properties?.kind];
          self.updateDatacenter(state.originalFeature, rotationAngle);
        }
      },
      onSetup: function () {
        const selectedBuildings = GetSelectedBuildings(
          self.drawControlOptions.currentProject
        );

        Object.keys(selectedBuildings?.datacentersDetails).forEach(
          (datacenter) => {
            const source = self.map?.getSource(
              datacenter
            ) as mapboxgl.GeoJSONSource;
            if (source && source._data) {
              self.drawControl.add(
                source._data as GeoJSON.Feature<GeoJSON.Geometry>
              );
            }
          }
        );

        return {
          selectedBuildings: selectedBuildings,
          mode: "rotate",
          feature: "datacenter",
          originalCenter: [0, 0],
          lastMouseDownLngLat: false,
          selectedFeature: null,
          rotationAngle: 0,
        };
      },
    };

    return rotateMode;
  }
}
