import * as turf from "@turf/turf";
import * as L from "leaflet";
import { LngLat, LngLatBounds, Map } from "mapbox-gl";
import { useEffect, useRef } from "react";
import { StoreApi, UseBoundStore } from "zustand";
import {
  NitrogenActions,
  NitrogenState,
} from "../../../../../../../state/NitrogenState/nitrogenState";
import DrawedMarker, {
  GetEmptyDrawedMarker,
} from "../../../../../types/DrawedMarker";
import {
  CalculateDistanceAOrNRoad,
  GetAddressByLongLat,
} from "../../PdokDistanceCalculator";
import { MarkerDrawControl } from "./MarkerDrawControl";
import DrawedPlot from "../../../../../types/DrawedPlot";

interface MarkerDrawControlViewProps {
  map: Map | null;
  currentStore: UseBoundStore<StoreApi<NitrogenState & NitrogenActions>>;
  drawedMarker: DrawedMarker;
  setDrawedMarker: (drawedMarker: DrawedMarker) => void;
  setProjectAddress: (address: string) => void;
}

export default function MarkerDrawControlView({
  map,
  currentStore,
  drawedMarker,
  setDrawedMarker,
  setProjectAddress,
}: MarkerDrawControlViewProps) {
  const drawControlRef = useRef<MarkerDrawControl>();

  const {
    drawControlMode,
    projectAddressFeature,
    navigateDrawingMarker,
    deleteDrawingMarker,
    startDrawingMarker,
    calculateDistance,
    setCalculateDistance,
    setNavigateDrawingMarker,
    setDeleteDrawingMarker,
    setStartDrawingMarker,
    setDistanceBetweenMarkerAndRoad,
  } = currentStore();

  useEffect(() => {
    if (!map) return;

    drawControlRef.current = new MarkerDrawControl({
      drawedMarker: drawedMarker,
      setDrawedMarker: setDrawedMarker,
      setNavigateDrawingMarker: setNavigateDrawingMarker,
      calculateDistanceFeatureToRoad: calculateDistanceFeatureToRoad,
    });

    map.addControl(drawControlRef.current);
  }, [map]);

  useEffect(() => {
    if (
      projectAddressFeature &&
      projectAddressFeature.geometry.coordinates &&
      projectAddressFeature.geometry.coordinates.length > 0 &&
      map
    ) {
      let zoom = 1;
      switch (projectAddressFeature?.properties.feature_type) {
        case "country":
          zoom = 5;
          break;
        case "district":
        case "region":
          zoom = 8;
          break;
        case "neighborhood":
        case "postcode":
        case "locality":
        case "place":
          zoom = 13.5;
          break;
        case "poi":
        case "address":
          zoom = 19;
          break;
      }

      const lngLat: mapboxgl.LngLatLike = {
        lng: projectAddressFeature.geometry.coordinates[0],
        lat: projectAddressFeature.geometry.coordinates[1],
      };
      map.flyTo({ center: lngLat, zoom: zoom, speed: 2 });
    }
  }, [projectAddressFeature]);

  useEffect(() => {
    if (deleteDrawingMarker && map) {
      drawControlRef.current?.getDrawControl().deleteAll();
      if (map.getLayer("route")) {
        map.removeLayer("route");
      }
      if (map.getSource("route")) {
        map.removeSource("route");
      }

      setDrawedMarker(GetEmptyDrawedMarker());
      setDeleteDrawingMarker(false);
      setDistanceBetweenMarkerAndRoad(0);
    }
  }, [deleteDrawingMarker]);

  useEffect(() => {
    if (startDrawingMarker) {
      drawControlRef.current?.getDrawControl().changeMode("draw_point");
      setStartDrawingMarker(false);
    }
  }, [startDrawingMarker]);

  useEffect(() => {
    if (calculateDistance && drawedMarker.marker) {
      calculateDistanceFeatureToRoad(drawedMarker.marker);
      setCalculateDistance(false);
    }
  }, [calculateDistance]);

  useEffect(() => {
    if (drawControlMode && map && drawControlRef.current) {
      drawControlRef.current.getDrawControl().changeMode(drawControlMode);
    }
  }, [drawControlMode]);

  useEffect(() => {
    if (navigateDrawingMarker) {
      if (map && drawedMarker && drawedMarker.lotCoordinates.length > 0) {
        const latLngCoordinates: L.LatLngExpression[] =
          drawedMarker.lotCoordinates.map((coordinate) => {
            return [coordinate[0], coordinate[1]];
          });

        const fit = new L.Polygon(latLngCoordinates).getBounds();
        const southWest = new LngLat(
          fit.getSouthWest().lat,
          fit.getSouthWest().lng
        );
        const northEast = new LngLat(
          fit.getNorthEast().lat,
          fit.getNorthEast().lng
        );

        map.fitBounds(new LngLatBounds(southWest, northEast), {
          zoom: 15,
        });
      }
      setNavigateDrawingMarker(false);
    }
  }, [navigateDrawingMarker]);

  useEffect(() => {
    if (map && drawedMarker && drawedMarker.marker) {
      calculateDistanceFeatureToRoad(drawedMarker.marker);
      setNavigateDrawingMarker(true);

      (async () => {
        const address = await GetAddressByLongLat(
          drawedMarker.lotCoordinates[0][0],
          drawedMarker.lotCoordinates[0][1]
        );
        setProjectAddress(address);
      })();
    }
  }, [drawedMarker]);

  async function calculateDistanceFeatureToRoad(
    feature: turf.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
  ) {
    const coordinates = (feature.geometry as turf.Geometry)
      .coordinates as number[];
    if (coordinates.length === 0 || !map) return;

    CalculateDistanceAOrNRoad(
      map,
      { lotCoordinates: [coordinates] } as DrawedPlot,
      setDistanceBetweenMarkerAndRoad
    );
  }

  return null;
}
