import {
  Box,
  Checkbox,
  Chip,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { t } from "i18next";
import React, { useEffect, useMemo } from "react";
import SliderWithMarks from "../../../../../components/SliderWithMarks/SliderWithMarks";
import {
  AxisConfig,
  createExploreStore,
} from "../../../../sharedLogic/state/ExploreState/ExploreState";
import { ExploreConfigStore } from "../../../../sharedLogic/types/ExploreConfig";
import InfoField, {
  DetailedInfo,
} from "../../../../sharedLogic/types/InfoField";
import { SpaceResidentialDto } from "../../../types/api";
import PCGAxesDto from "../../../../sharedLogic/types/PCGAxesDto";
import CustomFormControlWithInfoButton from "../../../../../components/CustomFormControl/CustomFormControlWithInfoButton";
import LabeledFieldWithInfoButton from "../../../../sharedLogic/components/PropertySectionView/LabeledFieldWithInfoButton";

export const useApartmentConfigStore = (
  accessTypeOptions: string[],
  spaceTypeOptions: string[],
  selectedAccessTypes: string[],
  setSelectedAccessTypes: (options: string[]) => void,
  isSaveAvailable: boolean
): ExploreConfigStore<SpaceResidentialDto> => {
  const currentStore = useMemo(
    () => createExploreStore<SpaceResidentialDto>(),
    []
  );
  const apartmentSpaceTypes = spaceTypeOptions?.filter((spaceType) =>
    spaceType.startsWith("Appartment")
  );
  const {
    objects,
    brushedObjects,
    filteredObjects,
    setFilteredObjects,
    setFilteredObjectsBrushedOnPCG,
  } = currentStore();

  const [filteredObjectsByGo, setFilteredObjectsByGo] = React.useState<
    SpaceResidentialDto[] | undefined
  >(undefined);

  const [selectedSpaceTypes, setSelectedSpaceTypes] = React.useState<string[]>(
    []
  );

  const [minGoApartment, setMinGoApartment] = React.useState<number>(0);
  const [maxGoApartment, setMaxGoApartment] = React.useState<number>(0);
  const [selectedGoApartments, setSelectedGoApartments] = React.useState<
    number[]
  >([minGoApartment, maxGoApartment]);

  useEffect(() => {
    if (objects && objects?.length > 0) {
      const filteredApartments = handleObjectFilters(objects);
      setFilteredObjects(filteredApartments);
      setFilteredObjectsBrushedOnPCG(filteredApartments);
      updateMinMaxGoApartment(filteredApartments);
    }
  }, [objects, selectedAccessTypes, selectedSpaceTypes]);

  useEffect(() => {
    let objectsToBeFiltered;
    if (filteredObjectsByGo && filteredObjectsByGo.length > 0) {
      objectsToBeFiltered = filteredObjects;
    } else if (
      selectedAccessTypes.length === 0 ||
      selectedSpaceTypes.length === 0
    ) {
      if (objects) {
        objectsToBeFiltered = objects;
      }
    } else if (filteredObjects && filteredObjects.length > 0) {
      objectsToBeFiltered = filteredObjects;
    }
    const filteredApartmentsByGo = objectsToBeFiltered
      ? handleObjectFiltersBySelectedGo(
          handleObjectFilters(objectsToBeFiltered)
        )
      : [];
    setFilteredObjectsByGo(filteredApartmentsByGo);
    setFilteredObjectsBrushedOnPCG(filteredApartmentsByGo);
  }, [selectedGoApartments]);

  const sortByOptionsDictionary: { [key: string]: string } = {
    "Best fit rate": "value",
  };

  const defaultSelectedAxes: PCGAxesDto[] = [
    { axis: "grossFloorAreaInM2", isColorReversed: false },
  ];

  const summaryInfo: InfoField[] = [
    { label: "StackedHousing.ModuleType", property: "spaceType", unit: "" },
    { label: "GO", property: "usableAreaInM2", unit: "m²" },
    { label: "BVO", property: "grossFloorAreaInM2", unit: "m²" },
  ];

  const detailedInfo = [] as DetailedInfo[];

  const dimensions = [
    { dimension: "grossFloorAreaInM2", format: "float" },
    { dimension: "usableAreaInM2", format: "float" },
    { dimension: "costInEuro", format: "float" },
    { dimension: "rentalPoints", format: "float" },
    { dimension: "costVsGO", format: "float" },
    { dimension: "costVsBVO", format: "float" },
    { dimension: "co2", format: "float" },
    { dimension: "investmentValue", format: "float" },
    { dimension: "costsVSRevenues", format: "float" },
  ] as AxisConfig[];

  const chartLabels = [
    "StackedHousing.ApartmentGraph.BVO",
    "StackedHousing.ApartmentGraph.GO",
    "StackedHousing.ApartmentGraph.CostInEuro",
    "StackedHousing.ApartmentGraph.RentalPoints",
    "StackedHousing.ApartmentGraph.CostVsGO",
    "StackedHousing.ApartmentGraph.CostVsBVO",
    "StackedHousing.ApartmentGraph.CO₂",
    "StackedHousing.ApartmentGraph.InvestmentValue",
    "StackedHousing.ApartmentGraph.InvestmentValuePerUnitPrice",
  ];

  const clickableAxes = [
    "nrOfAppartments",
    "structuralHeightInM",
    "nrOfFloors",
    "grossFloorAreaInM2",
  ];
  const bestFitOptionsDictionary: PCGAxesDto[] = [
    { axis: "grossFloorAreaInM2", isColorReversed: false },
    { axis: "usableAreaInM2", isColorReversed: false },
    { axis: "costInEuro", isColorReversed: true },
    { axis: "rentalPoints", isColorReversed: false },
    { axis: "co2", isColorReversed: true },
    { axis: "investmentValue", isColorReversed: false },
    { axis: "investmentValuePerUnitPrice", isColorReversed: false },
    { axis: "costVsGO", isColorReversed: true },
    { axis: "costVsBVO", isColorReversed: true },
  ];

  const bestFitOptionsDictionaryReversed = {
    grossFloorAreaInM2: "StackedHousing.ApartmentGraph.BVO",
    usableAreaInM2: "StackedHousing.ApartmentGraph.GO",
    costInEuro: "StackedHousing.ApartmentGraph.CostInEuro",
    rentalPoints: "StackedHousing.ApartmentGraph.RentalPoints",
    co2: "StackedHousing.ApartmentGraph.CO₂",
    investmentValue: "StackedHousing.ApartmentGraph.InvestmentValue",
    investmentValuePerUnitPrice:
      "StackedHousing.ApartmentGraph.InvestmentValuePerUnitPrice",
    costVsGO: "StackedHousing.ApartmentGraph.CostVsGO",
    costVsBVO: "StackedHousing.ApartmentGraph.CostVsBVO",
  };

  const inputPaneContent = () => (
    <>
      <CustomFormControlWithInfoButton labelKey="StackedHousing.AccessType">
        <Select
          multiple
          disabled={!isSaveAvailable}
          value={selectedAccessTypes}
          style={{ height: "auto" }}
          labelId="type-power-rack-label"
          id="type-power-rack-select"
          onChange={handleChangeAccessType}
          label={t("StackedHousing.AccessType")}
          renderValue={(selected) => (
            <Box
              component={"div"}
              sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
            >
              {selected.map((value) => (
                <Chip key={value} label={t(value)} />
              ))}
            </Box>
          )}
        >
          <MenuItem value="all">
            <ListItemIcon>
              <Checkbox
                checked={
                  accessTypeOptions !== undefined &&
                  selectedAccessTypes !== undefined &&
                  accessTypeOptions.length > 0 &&
                  selectedAccessTypes.length === accessTypeOptions.length
                }
                indeterminate={
                  accessTypeOptions !== undefined &&
                  selectedAccessTypes !== undefined &&
                  selectedAccessTypes.length > 0 &&
                  selectedAccessTypes.length < accessTypeOptions.length
                }
              />
            </ListItemIcon>
            <ListItemText primary={t("Select all")} />
          </MenuItem>
          {accessTypeOptions?.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={selectedAccessTypes?.indexOf(name) > -1} />
              <ListItemText primary={t(name)} />
            </MenuItem>
          ))}
        </Select>
      </CustomFormControlWithInfoButton>
      <CustomFormControlWithInfoButton labelKey="StackedHousing.ModuleType">
        <Select
          multiple
          disabled={!isSaveAvailable}
          value={selectedSpaceTypes}
          style={{ height: "auto" }}
          onChange={handleChangeSpaceType}
          label={t("StackedHousing.ModuleType")}
          renderValue={(selected) => (
            <Box
              component={"div"}
              sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
            >
              {selected.map((value) => (
                <Chip key={value} label={t(value)} />
              ))}
            </Box>
          )}
        >
          <MenuItem value="all">
            <ListItemIcon>
              <Checkbox
                checked={
                  apartmentSpaceTypes !== undefined &&
                  selectedSpaceTypes !== undefined &&
                  apartmentSpaceTypes.length > 0 &&
                  selectedSpaceTypes.length === apartmentSpaceTypes.length
                }
                indeterminate={
                  apartmentSpaceTypes !== undefined &&
                  selectedSpaceTypes !== undefined &&
                  selectedSpaceTypes.length > 0 &&
                  selectedSpaceTypes.length < apartmentSpaceTypes.length
                }
              />
            </ListItemIcon>
            <ListItemText primary={t("Select all")} />
          </MenuItem>
          {apartmentSpaceTypes?.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={selectedSpaceTypes?.indexOf(name) > -1} />
              <ListItemText primary={t(name)} />
            </MenuItem>
          ))}
        </Select>
      </CustomFormControlWithInfoButton>

      <LabeledFieldWithInfoButton
        className="mt5"
        labelId="number-of-appartments-label"
        labelKey="StackedHousing.GO"
      >
        <div className="p10">
          <SliderWithMarks
            disabled={!isSaveAvailable}
            value={
              selectedGoApartments[0] !== Infinity
                ? selectedGoApartments
                : [minGoApartment, maxGoApartment]
            }
            setValue={(value) => {
              setSelectedGoApartments(value);
            }}
            min={minGoApartment}
            max={maxGoApartment}
          />
        </div>
      </LabeledFieldWithInfoButton>
    </>
  );

  const handleChangeAccessType = (event: SelectChangeEvent<string[]>) => {
    const selectedOptions = event.target.value;
    const valuesAccessType = Object.values(accessTypeOptions);

    if (selectedOptions.includes("all")) {
      setSelectedAccessTypes(
        selectedAccessTypes.length === valuesAccessType.length
          ? []
          : valuesAccessType
      );
    } else {
      const selectedAccessTypes = valuesAccessType.filter((accessType) =>
        accessType
          ? selectedOptions.includes(accessType.toString())
          : selectedOptions.includes("-1")
      );
      setSelectedAccessTypes(selectedAccessTypes);
    }
  };

  const handleChangeSpaceType = (event: SelectChangeEvent<string[]>) => {
    const selectedOptions = event.target.value;
    const valuesSpaceType = Object.values(apartmentSpaceTypes);

    if (selectedOptions.includes("all")) {
      setSelectedSpaceTypes(
        selectedSpaceTypes.length === valuesSpaceType.length
          ? []
          : valuesSpaceType
      );
    } else {
      const selectedSpaceTypes = valuesSpaceType.filter((spaceType) =>
        spaceType
          ? selectedOptions.includes(spaceType.toString())
          : selectedOptions.includes("-1")
      );
      setSelectedSpaceTypes(selectedSpaceTypes);
    }
  };

  const handleObjectFilters = (objects: SpaceResidentialDto[]) => {
    let filteredApartments = objects;
    if (selectedAccessTypes.length !== 0) {
      filteredApartments = filteredApartments.filter((apartment) => {
        return selectedAccessTypes.includes(apartment.accessType);
      });
    }
    if (selectedSpaceTypes.length !== 0) {
      filteredApartments = filteredApartments.filter((apartment) => {
        return selectedSpaceTypes.includes(apartment.spaceType);
      });
    }
    return filteredApartments;
  };

  const handleObjectFiltersBySelectedGo = (objects: SpaceResidentialDto[]) => {
    let filteredApartments = objects;
    filteredApartments = filteredApartments.filter((apartment) => {
      return (
        apartment.usableAreaInM2 &&
        apartment.usableAreaInM2 >= selectedGoApartments[0] &&
        apartment.usableAreaInM2 <= selectedGoApartments[1]
      );
    });
    return filteredApartments;
  };

  function updateMinMaxGoApartment(objects: SpaceResidentialDto[]) {
    const goSurfaces =
      objects?.map((object) => object.usableAreaInM2 ?? 0) ?? ([] as number[]);
    const min = Math.min(...goSurfaces);
    const max = Math.max(...goSurfaces);
    setMinGoApartment(min);
    setMaxGoApartment(max);
    setSelectedGoApartments([min, max]);
  }

  function handleCustomFilter() {
    const tempWhitespaces = [...brushedObjects];
    return tempWhitespaces;
  }

  return {
    sortByOptionsDictionary,
    currentStore,
    summaryInfo,
    detailedInfo,
    inputPaneContent,
    dimensions,
    chartLabels,
    clickableAxes,
    bestFitOptionsDictionary,
    bestFitOptionsDictionaryReversed,
    handleObjectFilters,
    handleCustomFilter,
    defaultSelectedAxes,
  };
};
