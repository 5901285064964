import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { Map } from "mapbox-gl";
import { StoreApi, UseBoundStore } from "zustand";
import { ExportedLayout } from "../../../../../../HSA/types/api";
import {
  BaseConfiguratorActions,
  BaseConfiguratorState,
} from "../../../../../state/baseConfiguratorState";
import { BuildingLocation } from "../../../../../types/api";
import { BaseMapDrawControl } from "../BaseMapDrawControl";
import {
  CreateDrawedPlot,
  GetDrawControlStyles,
  GetPlotOrigin,
} from "../../DrawControlUtils";
import {
  ApplyBuildingTransformations,
  ProcessFloorElements,
} from "./FeatureDrawControlUtils";
import { SpaceType } from "../../../../../../HSA/types/enums";

interface FeatureDrawControlProps {
  modes: {
    [modeKey: string]: MapboxDraw.DrawCustomMode;
  };
  featureType: string;
  currentConfiguratorStore: UseBoundStore<
    StoreApi<BaseConfiguratorState & BaseConfiguratorActions>
  >;
  projectExternalId: string;
  currentFeatureCollection: GeoJSON.FeatureCollection;
  setStylesLoaded: (loaded: boolean) => void;
  setCurrentFeatureCollection: (
    featureCollection: GeoJSON.FeatureCollection
  ) => void;
}

export class FeatureDrawControl extends BaseMapDrawControl {
  private store: UseBoundStore<
    StoreApi<BaseConfiguratorState & BaseConfiguratorActions>
  >;

  constructor(options: FeatureDrawControlProps) {
    super(options);
    this.store = options.currentConfiguratorStore;
  }

  public onAdd(map: Map): HTMLElement {
    this.map = map;
    this.initializeDrawControl();
    this.setupEventListeners();
    return this.drawControl.onAdd(map);
  }

  protected initializeDrawControl(): void {
    this.drawControl = new MapboxDraw({
      ...this.options,
      controls: {},
      modes: this.options.modes,
      displayControlsDefault: false,
      userProperties: true,
      styles: GetDrawControlStyles(),
    });
  }

  protected setupEventListeners(): void {
    if (!this.map) return;
    this.map.on("load", () => this.handleMapLoad());
  }

  protected removeEventListeners(): void {
    if (!this.map) return;
    this.map.off("load", () => this.handleMapLoad());
  }

  private handleMapLoad = async () => {
    const state = this.store.getState();
    if (!state.drawedPlot || !state.drawedPlot.polygon) return;

    state.setDrawedPlot(state.drawedPlot);
    this.drawControl.add(state.drawedPlot.polygon);
    this.updatePolygon(state.drawedPlot.polygon);
    state.setNavigateDrawingPlot(true);

    await this.generateFeatures(
      state.drawedPlot.polygon,
      state.buildingLocations,
      state.exportedLayouts,
      state.selectedExportedBuilingsIds
    );

    this.options.setStylesLoaded(true);
  };

  public async generateFeatures(
    plotPolygon: GeoJSON.Feature<GeoJSON.Geometry>,
    buildingLocations: BuildingLocation[],
    exportedLayouts: ExportedLayout[],
    selectedExportedBuilingsIds: { code: string; count: number }[]
  ) {
    if (!this.map) return;

    if (
      !plotPolygon ||
      !plotPolygon.geometry ||
      (plotPolygon.geometry.type !== "Polygon" &&
        plotPolygon.geometry.type !== "MultiPolygon")
    ) {
      throw new Error("Invalid plotPolygon");
    }

    if (!buildingLocations || !Array.isArray(buildingLocations)) {
      throw new Error("Invalid buildingLocations");
    }

    if (!exportedLayouts || !Array.isArray(exportedLayouts)) {
      throw new Error("Invalid exportedLayouts");
    }

    if (
      !selectedExportedBuilingsIds ||
      !Array.isArray(selectedExportedBuilingsIds)
    ) {
      throw new Error("Invalid selectedExportedBuilingsIds");
    }

    let currentBuildingBasePoint = GetPlotOrigin(plotPolygon);
    const newFeatures: GeoJSON.Feature[] = [];
    const existingFeatures: GeoJSON.Feature[] =
      this.options.currentFeatureCollection?.features || [];
    const buildingFeatures: Record<string, GeoJSON.Feature[]> = {};
    const updatedBuildingCounts: Record<string, number> = {};

    for (const selectedBuilding of selectedExportedBuilingsIds) {
      const exportedLayout = exportedLayouts.find(
        (layout) => layout.code === selectedBuilding.code
      );
      if (!exportedLayout) continue;

      for (let i = 0; i < selectedBuilding.count; i++) {
        const buildingName = `${this.options.projectExternalId}-${selectedBuilding.code}-${i}`;
        updatedBuildingCounts[buildingName] =
          (updatedBuildingCounts[buildingName] || 0) + 1;
        buildingFeatures[buildingName] = [];

        const buildingLocation = buildingLocations.find(
          (x) => x.name === buildingName
        );

        if (buildingLocation?.gps) {
          currentBuildingBasePoint = [
            buildingLocation.gps[0][0],
            buildingLocation.gps[0][1],
          ];
        }

        exportedLayout.layout.floorPlans.forEach((floorPlan, floorIndex) => {
          const offset = 20;

          const adjustedElements = floorPlan.elements.map((element) => {
            const isCoreSpace =
              element.spaceType === SpaceType.Core1L ||
              element.spaceType === SpaceType.Core2L;

            return {
              ...element,
              spatialLocation: {
                ...element.spatialLocation,
                x: isCoreSpace
                  ? element.spatialLocation.x + offset
                  : element.spatialLocation.x,
                y: isCoreSpace
                  ? element.spatialLocation.y + offset
                  : element.spatialLocation.y,
                z: isCoreSpace
                  ? element.spatialLocation.z + offset
                  : element.spatialLocation.z,
              },
            };
          });

          const floorFeatures = ProcessFloorElements(
            adjustedElements,
            buildingName,
            selectedBuilding.code,
            floorIndex,
            currentBuildingBasePoint
          );

          buildingFeatures[buildingName].push(...floorFeatures);
        });

        if (buildingLocation?.gps) {
          buildingFeatures[buildingName] = ApplyBuildingTransformations(
            buildingFeatures[buildingName],
            buildingLocation.gps!
          );
        }

        newFeatures.push(...buildingFeatures[buildingName]);
      }
    }

    const filteredFeatures = existingFeatures.filter((feature) => {
      const buildingName = feature.properties?.buildingName;
      if (!buildingName) return true;
      const count = updatedBuildingCounts[buildingName] || 0;
      return count > 0;
    });

    if (filteredFeatures.length === 0 && newFeatures.length === 0) {
      return;
    }

    this.options.setCurrentFeatureCollection({
      type: "FeatureCollection",
      features: [...filteredFeatures, ...newFeatures],
    });

    (
      this.map!.getSource(this.options.featureType) as mapboxgl.GeoJSONSource
    )?.setData({
      type: "FeatureCollection",
      features: [...filteredFeatures, ...newFeatures],
    });
  }

  private updatePolygon(polygon: GeoJSON.Feature) {
    const { features } = this.drawControl.getAll();
    features
      .filter((x) => x.id !== polygon.id)
      .forEach((feature) => this.drawControl.delete(feature.id + ""));

    this.drawControl.setFeatureProperty(polygon.id + "", "kind", "plot");
    this.drawControl.setFeatureProperty(polygon.id + "", "class_id", 0);

    const state = this.options.currentConfiguratorStore.getState();
    const newDrawedPlot = CreateDrawedPlot(polygon, state.drawedPlot);
    state.setDrawedPlot(newDrawedPlot);
    state.setIsSaveAvailable(true);
  }
}

export default FeatureDrawControl;
