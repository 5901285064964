import { PumpRoomDto } from "../../../interfaces/foundry/typicals/PumpRoomDto";
import ResponseDto from "../../../interfaces/ResponseDto";
import { TrackException } from "../../../logging/LoggingManager";
import useGeneralDatacenterObjectsStore from "../../../state/DatacenterState/generalDatacenterObjectsState";
import { foundryApiClient } from "../../../modules/sharedLogic/services/AxiosClient";
import { AxiosError } from "axios";

export async function GetPumpRoom(id: number): Promise<PumpRoomDto> {
  return (await GetPumpRooms()).find((x) => x.id === id) as PumpRoomDto;
}

export async function GetPumpRooms(): Promise<PumpRoomDto[]> {
  const store = useGeneralDatacenterObjectsStore.getState();
  let pumpRoom = store.pumpRooms;
  if (pumpRoom.length === 0) {
    pumpRoom = await GetPumpRoomDtos();
    store.setPumpRooms(pumpRoom);
  }
  return pumpRoom;
}

async function GetPumpRoomDtos(): Promise<PumpRoomDto[]> {
  try {
    const pumpRooms = (
      (await foundryApiClient.get<ResponseDto>("/api/datacenter/pumprooms"))
        .data.results as PumpRoomDto[]
    ).map((item: PumpRoomDto) => {
      item = {
        ...item,
        type: item.display_name,
      };
      return item;
    });
    return pumpRooms;
  } catch (ex) {
    TrackException(ex as AxiosError);
    throw ex;
  }
}
