import { PublicClientApplication } from "@azure/msal-browser";
import axios, { AxiosError } from "axios";
import {
  AggregateApiUrl,
  FerroApiUrl,
  ForgeApiUrl,
  FoundryApiUrl,
} from "../../../config";
import useLayoutStore from "../state/layoutState";
import { authConfig, loginRequest } from "../authentication/authConfig";
import { TrackException } from "../logging/LoggingManager";

const acceptLanguage = useLayoutStore.getState().selectedLang;
export const foundryApiClient = axios.create({
  baseURL: FoundryApiUrl,
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": acceptLanguage,
  },
});

export const aggregateApiClient = axios.create({
  baseURL: AggregateApiUrl,
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": acceptLanguage,
  },
});

export const ferroApiClient = axios.create({
  baseURL: FerroApiUrl,
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": acceptLanguage,
  },
});

export const forgeApiClient = axios.create({
  baseURL: ForgeApiUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

const clients = [
  foundryApiClient,
  aggregateApiClient,
  ferroApiClient,
  forgeApiClient,
];
for (const client of clients) {
  client.interceptors.request.use(
    async (config) => {
      const token = await acquireToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  client.interceptors.response.use(
    async (config) => {
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
}

const acquireToken = async () => {
  const msalInstance = new PublicClientApplication(authConfig);
  await msalInstance.initialize();
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    try {
      const response = await msalInstance.acquireTokenSilent({
        scopes: loginRequest.scopes,
        account: accounts[0],
      });
      return response.accessToken;
    } catch (ex) {
      TrackException(ex as AxiosError);
    }
  }
  return null;
};
