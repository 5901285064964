import {
  Dialog,
  DialogTitle,
  IconButton,
  ToggleButtonGroup,
} from "@mui/material";
import MuiToggleButton from "@mui/material/ToggleButton";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { t } from "i18next";
import { useState } from "react";
import { ExportIcon } from "../../../icons/ExportIcon";
import {
  TypeObject,
  TypeObjectLabel,
} from "../../../interfaces/enums/TypeObjectEnum";
import { DatacenterDto } from "../../../interfaces/foundry/DatacenterDto";
import WhitespaceDto from "../../../interfaces/foundry/WhitespaceDto";
import { LowVoltageRoomDto } from "../../../interfaces/foundry/typicals/LowVoltageRoomDto";
import { ExportScenario } from "../../../modules/Analysers/services/ScenarioService";
import { ScenarioDto } from "../../../modules/Analysers/types/api";
import useDatacenterGeneratorStore from "../../../state/DatacenterState/datacenterGeneratorState";
import {
  DTO,
  ExploreActions,
  ExploreState,
  TypicalDTO,
} from "../../../state/ExploreState/ExploreState";
import CancelButton from "../../CancelButton/CancelButton";
import Legend from "../../../modules/sharedLogic/components/Maps/Components/Legenda/Legend";
import LoadingIndicator from "../../LoadingIndicator/LoadingIndicator";
import Notification from "../../Notification/Notification";
import ZoomImage from "../../ZoomImage/ZoomImage";
import SceneCanvas from "../SceneCanvas/SceneCanvas";
import "./SceneDetail.scss";
import SceneDetailDatacenter from "./SceneDetailDatacenter";
import SceneDetailLowVoltageRoom from "./SceneDetailLowVoltageRoom";
import SceneDetailScenario from "./SceneDetailScenario";
import SceneDetailWhitespace from "./SceneDetailWhitespace";

const WS_LEGEND_ITEMS = [
  { color: "#FFFFFF", label: "Whitespace", opacity: 0.8 },
  { color: "#FFFFFF", label: "Rack", opacity: 1, lineStyle: "cross" },
  { color: "#FF8080", label: "Hot alley", lineStyle: "dashed" },
  { color: "#0080C0", label: "Cold alley", lineStyle: "dashed" },
  { color: "#c9c3c3", label: "mdb", opacity: 1 },
  { color: "#66CCF8", label: "Cooling", opacity: 1 },
];

const LVR_LEGEND_ITEMS = [
  { color: "rgb(0,0,0)", label: "Mesh wall", lineStyle: "dashed" },
  { color: "#FFFFFF", label: "Entrance", opacity: 1, lineStyle: "triangle" },
  { color: "rgb(255,217,102)", label: "Battery", opacity: 1 },
  { color: "rgb(244,177,131)", label: "UPS", opacity: 1 },
  { color: "rgb(169,209,142)", label: "LVV", opacity: 1 },
  { color: "rgb(84,130,53)", label: "Transformator", opacity: 1 },
  { color: "rgb(56,87,35)", label: "RMU", opacity: 1 },
  { color: "rgb(157,195,230)", label: "Cooling", opacity: 1 },
  { color: "#CCE0B3", label: "STS", opacity: 1 },
];

const DC_LEGEND_ITEMS = [
  { color: "#FFFFFF", label: "Whitespace", opacity: 0.8 },
  { color: "#FFFFFF", label: "Rack", opacity: 1, lineStyle: "cross" },
  { color: "#c9c3c3", label: "mdb", opacity: 1 },
  { color: "#66CCF8", label: "Cooling", opacity: 1 },
  { color: "rgb(255,217,102)", label: "Battery", opacity: 1 },
  { color: "rgb(244,177,131)", label: "UPS", opacity: 1 },
  { color: "rgb(169,209,142)", label: "LVV", opacity: 1 },
  { color: "rgb(84,130,53)", label: "Transformator", opacity: 1 },
  { color: "rgb(56,87,35)", label: "RMU", opacity: 1 },
  { color: "rgb(157,195,230)", label: "Cooling", opacity: 1 },
  { color: "#CCE0B3", label: "STS", opacity: 1 },
  { color: "#FF8000", label: "Genset", opacity: 1 },
  { color: "rgba(254, 184, 0, 1)", label: "Datacenter", lineStyle: "solid" },
  {
    color: "rgba(0, 52, 102, 1)",
    label: "Datacenter cooltower",
    lineStyle: "solid",
  },
];

interface SceneDetailProps<T extends DTO> {
  currentState: ExploreState<T> & ExploreActions<T>;
}
export default function SceneDetail<T extends DTO>({
  currentState,
}: SceneDetailProps<T>) {
  const [loadedCanvas, setLoadedCanvas] = useState(false);
  const {
    currentObject,
    setCurrentObject,
    isOpenedDetailedView,
    setIsOpenedDetailedView,
  } = currentState;

  const baseDatacenterObject = currentObject as TypicalDTO;
  const { currentPageType } = useDatacenterGeneratorStore();

  const handleClose = () => {
    setIsOpenedDetailedView(false);
    setCurrentObject(null);
    setSelectedView("2D");
    setLoadedCanvas(false);
  };

  const [selectedView, setSelectedView] = useState("2D");

  const ToggleButton = styled(MuiToggleButton)({
    color: "rgba(0, 52, 102, 1)",
    backgroundColor: "white",
    borderWidth: "3px",
    borderRadius: "20px",
    minWidth: "50px",
    maxHeight: "40px",
    borderColor: "rgba(0, 52, 102, 1)",
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: "rgba(0, 52, 102, 1)",
    },
  });

  return (
    <Dialog
      maxWidth="xl"
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={isOpenedDetailedView}
      PaperProps={{
        style: {
          borderRadius: 20,
          width: "75%",
        },
      }}
    >
      <DialogTitle
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: 16,
          fontWeight: 900,
        }}
        sx={{ display: "flex", alignItems: "center" }}
      >
        {t(TypeObjectLabel.get(currentPageType!) ?? "") + " details"}
        <Box component={"div"} sx={{ ml: "auto", display: "flex" }}>
          {currentPageType === TypeObject.NitrogenAnalyser && (
            <IconButton
              sx={{ marginRight: "-8px" }}
              onClick={() => {
                ExportScenario(baseDatacenterObject as unknown as ScenarioDto);
              }}
            >
              <ExportIcon size={27} className="svg" />
            </IconButton>
          )}
          <CancelButton onClick={handleClose} />
        </Box>
      </DialogTitle>
      <div className="scene-details">
        {!baseDatacenterObject ? (
          <div className="loading-indicator-scene-details">
            <LoadingIndicator />
          </div>
        ) : (
          <>
            {baseDatacenterObject.typeObject !==
              TypeObject.NitrogenAnalyser && (
              <div className="tab-view">
                <div className="image-view">
                  <div className="toggle-button">
                    <ToggleButtonGroup
                      color="primary"
                      value={selectedView}
                      exclusive
                      onChange={(event, value) => {
                        if (value && value !== "" && value !== selectedView) {
                          setSelectedView(value as string);
                          window.dispatchEvent(
                            new KeyboardEvent("keyup", { keyCode: 32 })
                          );
                        }
                      }}
                      aria-label="Platform"
                    >
                      <ToggleButton
                        sx={{ zIndex: 1000, boxShadow: 3 }}
                        value="2D"
                      >
                        2D
                      </ToggleButton>
                      <ToggleButton
                        sx={{ zIndex: 1000, boxShadow: 3 }}
                        value="3D"
                      >
                        3D
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>

                  {(baseDatacenterObject.typeObject === TypeObject.Whitespace ||
                    baseDatacenterObject.typeObject ===
                      TypeObject.WhitespaceTooling) && (
                    <>
                      {baseDatacenterObject &&
                        (selectedView === "2D" ? (
                          baseDatacenterObject.view2d_png &&
                          !baseDatacenterObject.view2d_png.endsWith("png") ? (
                            <ZoomImage
                              image={baseDatacenterObject.view2d_png}
                              objectType={baseDatacenterObject.typeObject}
                              objectScope={baseDatacenterObject.scope}
                              objectWidth={baseDatacenterObject.width}
                            />
                          ) : (
                            <div></div>
                          )
                        ) : (
                          <SceneCanvas
                            setLoadedCanvas={setLoadedCanvas}
                            currentObject={baseDatacenterObject}
                          />
                        ))}
                      <Legend
                        objectScope={baseDatacenterObject.scope}
                        items={WS_LEGEND_ITEMS}
                      ></Legend>
                    </>
                  )}

                  {(baseDatacenterObject.typeObject ===
                    TypeObject.LowVoltageRoom ||
                    baseDatacenterObject.typeObject ===
                      TypeObject.LowVoltageRoomTooling) && (
                    <>
                      {baseDatacenterObject &&
                        (selectedView === "2D" ? (
                          baseDatacenterObject.view2d_png &&
                          !baseDatacenterObject.view2d_png.endsWith("png") ? (
                            <ZoomImage
                              image={baseDatacenterObject.view2d_png}
                              objectType={baseDatacenterObject.typeObject}
                              objectScope={baseDatacenterObject.scope}
                              objectWidth={baseDatacenterObject.width}
                            />
                          ) : (
                            <div></div>
                          )
                        ) : (
                          <SceneCanvas
                            setLoadedCanvas={setLoadedCanvas}
                            currentObject={baseDatacenterObject}
                          />
                        ))}
                      <Legend
                        objectScope={baseDatacenterObject.scope}
                        items={LVR_LEGEND_ITEMS}
                      ></Legend>
                    </>
                  )}
                  {baseDatacenterObject.typeObject ===
                    TypeObject.Datacenter && (
                    <>
                      {!loadedCanvas && (
                        <div className="loading-indicator-scene-details">
                          <LoadingIndicator />
                        </div>
                      )}
                      <SceneCanvas
                        setLoadedCanvas={setLoadedCanvas}
                        currentObject={baseDatacenterObject}
                      />
                      <Notification
                        message="GenerateDatacenterPage.NotificationMessage"
                        bottom={10}
                        left={220}
                      />
                      <Legend
                        objectScope={baseDatacenterObject.scope}
                        items={DC_LEGEND_ITEMS}
                      ></Legend>
                    </>
                  )}
                </div>
                <div className="scene-sidebar">
                  {(baseDatacenterObject.typeObject === TypeObject.Whitespace ||
                    baseDatacenterObject.typeObject ===
                      TypeObject.WhitespaceTooling) && (
                    <SceneDetailWhitespace
                      whitespace={
                        baseDatacenterObject as unknown as WhitespaceDto
                      }
                    />
                  )}
                  {(baseDatacenterObject.typeObject ===
                    TypeObject.LowVoltageRoom ||
                    baseDatacenterObject.typeObject ===
                      TypeObject.LowVoltageRoomTooling) && (
                    <SceneDetailLowVoltageRoom
                      lowVoltageRoom={
                        baseDatacenterObject as unknown as LowVoltageRoomDto
                      }
                    />
                  )}
                  {baseDatacenterObject.typeObject ===
                    TypeObject.Datacenter && (
                    <SceneDetailDatacenter
                      datacenter={baseDatacenterObject as DatacenterDto}
                    />
                  )}
                </div>
              </div>
            )}
            {baseDatacenterObject.typeObject ===
              TypeObject.NitrogenAnalyser && (
              <SceneDetailScenario
                scenario={baseDatacenterObject as unknown as ScenarioDto}
              />
            )}
          </>
        )}
      </div>
    </Dialog>
  );
}
