import CancelIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CancelButton from "../../../components/CancelButton/CancelButton";
import FormControlSelect from "../../../components/CustomFormControl/FormControlSelect";
import {
  ExportTableToCSV,
  ImportTableFromCSV,
} from "../../../http/aggregate/ImportExportService";
import {
  DeleteInputData,
  GetInputDataByType,
  SaveInputData,
  UpdateInputData,
} from "../../../http/aggregate/InputDataService";
import { PenIcon } from "../../../icons/PenIcon";
import { SaveIcon } from "../../../icons/SaveIcon";
import { TrashIcon } from "../../../icons/TrashIcon";
import { BaseDatabaseOptionDto } from "../../../interfaces/aggregate/BaseDatabaseOptionDto";
import { ExportEnum } from "../../../interfaces/aggregate/enums/ExportEnum";
import { InputDataCatalogEnum } from "../../../interfaces/aggregate/enums/InputDataCatalogEnum";
import { TrackException } from "../../../logging/LoggingManager";
import useDatabaseStore from "../../../state/DatabaseState/databaseState";
import AddExportImportView from "./AddExportImportView/AddExportImportView";
import "./OptionsView.scss";
import { AxiosError } from "axios";
import useUserStore from "../../../modules/sharedLogic/state/userState";

interface OptionViewControlProps {
  currentValue: BaseDatabaseOptionDto;
}

export default function OptionsView() {
  const { t } = useTranslation();
  const { appAbility } = useUserStore();
  const { options, currentDatabaseOptions, setCurrentDatabaseOptions } =
    useDatabaseStore();

  const [currentOption, setCurrentOption] =
    React.useState<InputDataCatalogEnum>();

  useEffect(() => {
    if (options.length > 0) {
      setCurrentOption(options[0]);
    }
  }, [options]);

  useEffect(() => {
    const fetchData = async () => {
      await LoadItems();
    };

    fetchData().catch(console.error);
  }, [currentOption]);

  async function LoadItems() {
    try {
      setCurrentDatabaseOptions(
        (await GetInputDataByType(currentOption!)).sort((a, b) =>
          t(a.name).localeCompare(t(b.name))
        )
      );
    } catch (ex) {
      TrackException(ex as AxiosError);
    }
  }

  const OptionViewControl = ({ currentValue }: OptionViewControlProps) => {
    const [newOptionName, setNewOptionName] = React.useState<string>(
      currentValue.name
    );
    const [isEditOrNewNode, setIsEditOrNewNode] = React.useState<boolean>(
      currentValue.id === 0 ? true : false
    );

    const canEditMaterialTypes = appAbility?.can(
      "manage",
      "databases.equipmentkeyfigures"
    );

    const [openVerificationDialog, setOpenVerificationDialog] =
      React.useState<boolean>(false);

    const handleClose = () => {
      setOpenVerificationDialog(false);
    };

    return (
      <div className="option-view">
        {isEditOrNewNode && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "-2px",
            }}
          >
            <TextField
              fullWidth
              value={newOptionName}
              onChange={(event) => setNewOptionName(event.target.value)}
              variant="standard"
              sx={{
                width: "300px",
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
                "& .MuiInputBase-root.Mui-disabled:before": {
                  borderBottomStyle: "solid",
                },
              }}
            ></TextField>
            <Tooltip title={t("Save")}>
              <IconButton
                sx={{ marginLeft: "3px" }}
                onClick={async () => {
                  if (currentOption === undefined) {
                    return;
                  }

                  currentValue.name = newOptionName;
                  currentValue.type = InputDataCatalogEnum[currentOption];
                  if (currentValue.id === 0) {
                    await SaveInputData(currentValue);
                  } else {
                    await UpdateInputData(currentValue);
                  }

                  await LoadItems();
                }}
              >
                <SaveIcon size={18} />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("Cancel")}>
              <IconButton
                sx={{ marginLeft: "-5px" }}
                onClick={() => {
                  if (currentValue.id === 0) {
                    setCurrentDatabaseOptions(
                      currentDatabaseOptions.filter(
                        (x) => x.id !== currentValue.id
                      )
                    );
                  }
                  setIsEditOrNewNode(false);
                }}
              >
                <CancelIcon sx={{ fontSize: "1.25rem" }} />
              </IconButton>
            </Tooltip>
          </div>
        )}

        {!isEditOrNewNode && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{ minWidth: "290px !important" }}
              onClick={() => {}}
              component="div"
            >
              {currentValue.name}
            </Typography>

            {!canEditMaterialTypes &&
            currentOption === InputDataCatalogEnum.InputDataEquipmentType ? (
              <div></div>
            ) : (
              <ButtonGroup
                sx={{
                  minWidth: "100px",
                  marginLeft: "20px",
                }}
                disableElevation
              >
                <Tooltip title={t("Edit")}>
                  <IconButton
                    sx={{ marginLeft: "-5px" }}
                    onClick={() => {
                      setIsEditOrNewNode(true);
                    }}
                  >
                    <PenIcon size={18} className="icon" />
                  </IconButton>
                </Tooltip>

                <Tooltip title={t("Delete")}>
                  <IconButton
                    sx={{ marginLeft: "-5px" }}
                    onClick={() => {
                      setOpenVerificationDialog(true);
                    }}
                  >
                    <TrashIcon size={18} className="icon" />
                  </IconButton>
                </Tooltip>
              </ButtonGroup>
            )}
          </div>
        )}

        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={openVerificationDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: 16,
              fontWeight: 900,
            }}
          >
            {t("Delete option")}
            <Box component={"div"} sx={{ ml: "auto" }}>
              <CancelButton onClick={handleClose} />
            </Box>
          </DialogTitle>

          <DialogContent>
            <DialogContentText
              sx={{ marginTop: "-10px" }}
              id="alert-dialog-description"
            >
              {t("Are you sure you want to delete the option") + "'?"}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose}> {t("No")}</Button>
            <Button
              onClick={async () => {
                handleClose();
                await DeleteInputData(currentValue.id);
                await LoadItems();
              }}
            >
              {t("Yes")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  return (
    <div className="options-view">
      <div className="options-view-header">
        <FormControlSelect
          labelText={t("Choose an option")}
          toolTipText={""}
          value={
            currentOption !== undefined
              ? InputDataCatalogEnum[currentOption]
              : ""
          }
          showInfoButton={false}
          setValue={(value) => {
            setCurrentOption(
              InputDataCatalogEnum[value as keyof typeof InputDataCatalogEnum]
            );
          }}
          selectItems={options.map((x) => InputDataCatalogEnum[x])}
        />
      </div>
      <hr className="hr" />
      <div className="options-view-grid">
        <Grid container spacing={0} rowSpacing={0}>
          {currentDatabaseOptions.map((item, index) => (
            <Grid key={index} item xs={12}>
              <OptionViewControl currentValue={item} />
            </Grid>
          ))}
        </Grid>

        <AddExportImportView
          currentValues={currentDatabaseOptions}
          setCurrentValues={setCurrentDatabaseOptions}
          exportToCsv={async () => {
            if (currentOption !== undefined) {
              await ExportTableToCSV(
                "input-data",
                currentOption,
                ExportEnum.InputData
              );
            }
          }}
          importFromCsv={async (formData) => {
            return await ImportTableFromCSV(formData, "input-data");
          }}
          reloadResources={LoadItems}
        />
      </div>
    </div>
  );
}
