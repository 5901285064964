import { AxiosError } from "axios";
import { BaseDatabaseOptionDto } from "../../interfaces/aggregate/BaseDatabaseOptionDto";
import { InputDataCatalogEnum } from "../../interfaces/aggregate/enums/InputDataCatalogEnum";
import { TrackException } from "../../logging/LoggingManager";
import { aggregateApiClient } from "../../modules/sharedLogic/services/AxiosClient";

const endpoint = "/api/input-data";
export async function GetInputDataByType(
  type: InputDataCatalogEnum | undefined
): Promise<BaseDatabaseOptionDto[]> {
  try {
    if (type === undefined) {
      return [];
    }
    const url = `${endpoint}/${InputDataCatalogEnum[type]}?pageSize=500`;
    const response = await aggregateApiClient.get<BaseDatabaseOptionDto[]>(url);
    return response.data;
  } catch (ex) {
    TrackException(ex as AxiosError);
    return [];
  }
}

export async function GetInputData(
  id: number
): Promise<BaseDatabaseOptionDto[]> {
  try {
    const response = await aggregateApiClient.get<BaseDatabaseOptionDto[]>(
      `${endpoint}/${id}`
    );
    return response.data;
  } catch (ex) {
    TrackException(ex as AxiosError);
    return [];
  }
}

export async function UpdateInputData(
  dto: BaseDatabaseOptionDto
): Promise<BaseDatabaseOptionDto[]> {
  try {
    const response = await aggregateApiClient.put<BaseDatabaseOptionDto[]>(
      `${endpoint}/${dto.id}`,
      dto
    );
    return response.data;
  } catch (ex) {
    TrackException(ex as AxiosError);
    return [];
  }
}

export async function SaveInputData(
  dto: BaseDatabaseOptionDto
): Promise<BaseDatabaseOptionDto[]> {
  try {
    const response = await aggregateApiClient.post<BaseDatabaseOptionDto[]>(
      endpoint,
      dto
    );
    return response.data;
  } catch (ex) {
    TrackException(ex as AxiosError);
    return [];
  }
}

export async function DeleteInputData(
  id: number
): Promise<BaseDatabaseOptionDto[]> {
  try {
    const response = await aggregateApiClient.delete<BaseDatabaseOptionDto[]>(
      `${endpoint}/${id}`
    );
    return response.data;
  } catch (ex) {
    TrackException(ex as AxiosError);
    return [];
  }
}
