import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { Map } from "mapbox-gl";

export abstract class BaseMapDrawControl {
  protected map: Map | null = null;
  protected drawControl: MapboxDraw = {} as MapboxDraw;
  protected options;

  constructor(options: any) {
    this.options = options;
  }

  public getDrawControl = () => this.drawControl;

  public abstract onAdd(map: Map): HTMLElement;

  public onRemove(map: Map): void {
    if (this.map) {
      this.removeEventListeners();
    }
    this.drawControl.onRemove(map);
  }

  protected abstract initializeDrawControl(): void;
  protected abstract setupEventListeners(): void;
  protected abstract removeEventListeners(): void;
}
