import { ChevronRight } from "@mui/icons-material";
import { Collapse, IconButton, Tooltip } from "@mui/material";
import {
  GridColDef,
  GridColumnGroupingModel,
  GridRowId,
  GridRowModel,
} from "@mui/x-data-grid";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFilePicker } from "use-file-picker";
import { ShowSuccess } from "../../http/NotificationService";
import {
  ExportKeyFigureToCSV,
  ImportKeyFigures,
} from "../../http/aggregate/KeyFigureService";
import { ExportIcon } from "../../icons/ExportIcon";
import { ImportIcon } from "../../icons/ImportIcon";
import { PenIcon } from "../../icons/PenIcon";
import { BaseKeyFigureDto } from "../../interfaces/aggregate/BaseKeyFigureDto";
import { AggregatePageEnum } from "../../interfaces/aggregate/enums/AggregatePageEnum";
import { TypeObject } from "../../interfaces/enums/TypeObjectEnum";
import { Subjects } from "../../modules/sharedLogic/authentication/PermissionsManager";
import useUserStore from "../../modules/sharedLogic/state/userState";
import useDatabaseStore from "../../state/DatabaseState/databaseState";
import "./BaseDatabasePage.scss";
import DatabaseDataGrid from "./components/DatabaseDataGrid";
import NavigationSidebar from "./components/NavigationSidebar/NavigationSidebar";

interface BaseDatabasePageProps {
  pageType?: TypeObject;
  columns: GridColDef[];
  columnGroupingModel: GridColumnGroupingModel;
  data: { [key: string]: BaseKeyFigureDto[] };
  addNewItem(object?: string): void;
  handleCancel(id: GridRowId): void;
  handleDelete(id: GridRowId): void;
  baseProcessRowUpdate?(newRow: GridRowModel): Promise<BaseKeyFigureDto>;
}

export function groupBy<T>(
  array: T[],
  getKey: (item: T) => string
): { [key: string]: T[] } {
  return array.reduce((result, currentValue) => {
    const groupKey = getKey(currentValue);
    (result[groupKey] = result[groupKey] || []).push(currentValue);
    return result;
  }, {} as { [key: string]: T[] });
}

export function BaseDatabasePage({
  pageType,
  data,
  columns,
  columnGroupingModel,
  addNewItem,
  handleCancel,
  handleDelete,
  baseProcessRowUpdate,
}: BaseDatabasePageProps) {
  const { t } = useTranslation();
  const { appAbility } = useUserStore();
  const {
    currentPage,
    categories,
    selectedNode,
    isEditMode,
    isInputPaneOpen,
    setIsInputPaneOpen,
    setSelectedNode,
    setIsEditMode,
  } = useDatabaseStore();

  const subjectMap: { [key in AggregatePageEnum]?: string } = {
    [AggregatePageEnum.CostKeyFigure]: "databases.costskeyfigures",
    [AggregatePageEnum.EquipmentKeyFigure]: "databases.equipmentkeyfigures",
    [AggregatePageEnum.ProductionKeyFigure]: "databases.productionkeyfigures",
    [AggregatePageEnum.Activities]: "databases.activities",
  };
  const subject = subjectMap[currentPage] as Subjects;
  const haveCreateRights = appAbility?.can("create", subject) ?? false;
  const haveEditRights = appAbility?.can("update", subject) ?? false;
  const haveAdminRights = appAbility?.can("manage", subject) ?? false;

  const { openFilePicker } = useFilePicker({
    accept: ".csv",
    onFilesSuccessfullySelected: async ({
      plainFiles,
    }: {
      plainFiles: File[];
    }) => {
      const formData = new FormData();
      formData.append("file", plainFiles[0]);
      const importedData = await ImportKeyFigures(
        currentPage,
        selectedNode,
        formData
      );
      if (importedData) {
        ShowSuccess(t("Data imported successfully"));
        setSelectedNode("");
        setSelectedNode(selectedNode);
      }
    },
  });

  useEffect(() => {
    if (Object.entries(data).length === 0) {
      data[""] = {} as BaseKeyFigureDto[];
    }
  }, []);

  return (
    <div className="base-database-page">
      {selectedNode !== "" && pageType !== TypeObject.NitrogenAnalyser && (
        <div className="button-group">
          {haveCreateRights && (
            <Tooltip title={t("Import")}>
              <IconButton
                sx={{
                  zIndex: 1,
                  borderRadius: "50%",
                  maxHeight: "45px",
                }}
                onClick={async () => {
                  openFilePicker();
                }}
              >
                <ImportIcon size={23} />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title={t("Export")}>
            <IconButton
              sx={{
                zIndex: 1,
                borderRadius: "50%",
                maxHeight: "45px",
              }}
              onClick={async () => {
                await ExportKeyFigureToCSV(currentPage, selectedNode);
              }}
            >
              <ExportIcon className="svg" size={25} />
            </IconButton>
          </Tooltip>

          {haveCreateRights && (
            <Tooltip title={t("Edit")}>
              <IconButton
                sx={{
                  zIndex: 1,
                  marginLeft: "-5px",
                  borderRadius: "50%",
                  maxHeight: "45px",
                }}
                onClick={() => {
                  setIsEditMode(!isEditMode);
                }}
              >
                <PenIcon size={22} />
              </IconButton>
            </Tooltip>
          )}
        </div>
      )}

      {!isInputPaneOpen && (
        <div className="open-input-pane-button">
          <IconButton
            style={{ backgroundColor: "transparent", zIndex: 1 }}
            onClick={() => setIsInputPaneOpen(!isInputPaneOpen)}
          >
            <ChevronRight sx={{ fontSize: "2.5rem" }} />
          </IconButton>
        </div>
      )}

      <div
        className={
          pageType !== TypeObject.NitrogenAnalyser
            ? `base-database-inputpane-view ${
                isInputPaneOpen ? "side-bar-open" : ""
              }`
            : ""
        }
      >
        {pageType !== TypeObject.NitrogenAnalyser && (
          <Collapse orientation="horizontal" in={isInputPaneOpen}>
            <NavigationSidebar haveAdminRights={haveAdminRights} />
          </Collapse>
        )}

        <div className="base-database-page-view">
          <div className="title ml10">
            {selectedNode === ""
              ? t("No data selected")
              : categories.find((x) => x.id === Number(selectedNode))?.name}
          </div>

          {selectedNode && (
            <DatabaseDataGrid
              data={data}
              columns={columns}
              columnGroupingModel={columnGroupingModel}
              haveEditRights={haveEditRights}
              haveAdminRights={haveAdminRights}
              handleCancel={handleCancel}
              handleDelete={handleDelete}
              addNewItem={addNewItem}
              baseProcessRowUpdate={baseProcessRowUpdate}
            />
          )}
        </div>
      </div>
    </div>
  );
}
