import { DataGrid, DataGridProps, GridColDef } from "@mui/x-data-grid";
import { enUS, nlNL } from "@mui/x-data-grid/locales";
import useLayoutStore from "../../modules/sharedLogic/state/layoutState";

interface StyledDataGridProps extends DataGridProps {
  rows: unknown[];
  columns: GridColDef[];
  hideFooter?: boolean;
  isEditMode?: boolean;
  showCellHorizontalBorders?: boolean;
}

const StyledDataGrid: React.FC<StyledDataGridProps> = ({
  rows,
  columns,
  hideFooter = true,
  isEditMode = false,
  showCellHorizontalBorders = true,
  ...props
}) => {
  const { selectedLang } = useLayoutStore();

  return (
    <DataGrid
      {...props}
      rows={rows}
      columns={columns}
      editMode="row"
      hideFooter={hideFooter}
      localeText={
        selectedLang === "nl-NL"
          ? nlNL.components.MuiDataGrid.defaultProps.localeText
          : enUS.components.MuiDataGrid.defaultProps.localeText
      }
      sx={{
        border: 0,
        boxShadow: 0,
        "--DataGrid-overlayHeight": "60px",
        "& .MuiDataGrid-cell": {
          borderTop: "none",
          borderBottom: showCellHorizontalBorders
            ? "1px solid #e0e0e0"
            : "none",
        },
        "& .MuiDataGrid-cell:focus": {
          outline: "none",
        },
        "& .MuiDataGrid-row": {
          borderTop: "none",
        },
        "& .MuiDataGrid-row.first-in-group:not(.add-row)": {
          marginTop: "-2px",
          borderTop: "1px solid rgb(224 224 224)",
        },
        "& .MuiDataGrid-row.last-in-group:not(.add-row)": {
          borderBottom: isEditMode ? "1px solid #e0e0e0" : "none",
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: "bold",
        },
        "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus": {
          outline: "none",
        },
        "& .MuiDataGrid-columnHeader:focus-within": {
          outline: "none",
        },
        "& .MuiDataGrid-withBorderColor": {
          borderColor: "black",
        },
        "& .MuiDataGrid-row.add-row": {
          borderBottom: "none",
          "& .MuiDataGrid-cell": {
            border: "none",
          },
          "&:hover": {
            backgroundColor: "transparent !important",
          },
        },
        "& .MuiDataGrid-columnHeaders": {
          borderBottom: "2px solid #000",
        },
      }}
    />
  );
};

export default StyledDataGrid;
