import EnergySupplyStationDto from "../../../interfaces/foundry/typicals/EnergySupplyStationDto";
import ResponseDto from "../../../interfaces/ResponseDto";
import { TrackException } from "../../../logging/LoggingManager";
import useGeneralDatacenterObjectsStore from "../../../state/DatacenterState/generalDatacenterObjectsState";
import { foundryApiClient } from "../../../modules/sharedLogic/services/AxiosClient";
import { AxiosError } from "axios";

export async function GetEnergySupplyStation(
  id: number
): Promise<EnergySupplyStationDto> {
  return (await GetEnergySupplyStations()).find(
    (x) => x.id === id
  ) as EnergySupplyStationDto;
}

export async function GetEnergySupplyStations(): Promise<
  EnergySupplyStationDto[]
> {
  const store = useGeneralDatacenterObjectsStore.getState();
  let energySupplyStations = store.energySupplyStations;
  if (energySupplyStations.length === 0) {
    energySupplyStations = await GetEnergySupplyStationDtos();
    store.setEnergySupplyStations(energySupplyStations);
  }
  return energySupplyStations;
}

async function GetEnergySupplyStationDtos(): Promise<EnergySupplyStationDto[]> {
  try {
    const coolings = (
      (
        await foundryApiClient.get<ResponseDto>(
          "/api/general/energy_supply_stations"
        )
      ).data.results as EnergySupplyStationDto[]
    ).map((item: EnergySupplyStationDto) => {
      item = {
        ...item,
        type: item.display_name,
      };
      return item;
    });
    return coolings;
  } catch (ex) {
    TrackException(ex as AxiosError);
    throw ex;
  }
}
