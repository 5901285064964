import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Bar,
  BarChart,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import {
  EquipmentEmissionDto,
  MonthlyEmissionsDto,
  ScenarioDto,
  ScenarioPeriodsDto,
} from "../../../modules/Analysers/types/api";
import useLayoutStore from "../../../modules/sharedLogic/state/layoutState";
import useNitrogenInputPaneStore from "../../../state/NitrogenState/nitrogenInputPaneState";
import FormControlTextField from "../../CustomFormControl/FormControlTextField";
import "./SceneDetailScenario.scss";

interface SceneDetailScenrioProps {
  scenario: ScenarioDto;
}

interface ChartDataItem {
  name: string;
  [key: string]: number | string;
}

const CustomLegend: React.FC<{
  payload?: Array<{ value: string; color: string }>;
}> = ({ payload }) => {
  return (
    <ul
      style={{
        listStyle: "none",
        padding: "5px",
        margin: 0,
        border: "1px solid #D3D3D3",
        borderRadius: "10px",
      }}
    >
      {payload?.map((entry, index) => (
        <li
          key={`item-${index}`}
          style={{
            marginTop: 5,
            marginBottom: 5,
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: 16,
              height: 16,
              borderRadius: "50%",
              backgroundColor: entry.color,
              border: "1px solid #D3D3D3",
              marginRight: 8,
            }}
          />
          <span>{entry.value}</span>
        </li>
      ))}
    </ul>
  );
};

export default function SceneDetailScenario({
  scenario,
}: SceneDetailScenrioProps) {
  const { t } = useTranslation();
  const { selectedLang } = useLayoutStore();
  const { annualNOxLimit, projectObjectiveNOx } = useNitrogenInputPaneStore();
  const [selectedPeriod, setSelectedPeriod] =
    useState<ScenarioPeriodsDto | null>(null);
  const [selectedMonth, setSelectedMonth] =
    useState<MonthlyEmissionsDto | null>(null);

  const getMonthYearString = (
    date: Date,
    format: string | Intl.DateTimeFormatOptions = "long"
  ) => {
    const newDate = new Date(date);
    const month = newDate.toLocaleString(selectedLang, {
      month: format as "numeric" | "2-digit" | "long" | "short" | "narrow",
    });
    return `${month} ${newDate.getFullYear()}`;
  };

  const stringToColor = (str: string) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const hue = hash % 360;
    const saturation = 60 + (hash % 20);
    const lightness = 80 + (hash % 10);

    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

  const allEquipmentTypes = useMemo(() => {
    const types = new Set<string>();
    scenario.periods.forEach((period) => {
      period.monthEmissions.forEach((month) => {
        month.equipmentEmissions.forEach((equipment) => {
          types.add(equipment.name);
        });
      });
    });
    return Array.from(types);
  }, [scenario]);

  const equipmentTypeColors = useMemo(() => {
    const colors: { [key: string]: string } = {};
    scenario.periods.forEach((period) => {
      period.monthEmissions.forEach((month) => {
        month.equipmentEmissions.forEach((equipment) => {
          if (!colors[equipment.name]) {
            colors[equipment.name] = stringToColor(equipment.name);
          }
        });
      });
    });
    return colors;
  }, [scenario]);

  const phaseColors = [
    "#FFADAD",
    "#FFD6A5",
    "#FDFFB6",
    "#E4F1EE",
    "#DEDAF4",
    "#D9EDF8",
    "#A85FA8",
    "#800080",
  ];

  const calculateBarchartWidth = (numberOfItem = 9) => {
    if (numberOfItem > 20) return numberOfItem * 70;
    return "100%";
  };

  const CustomTooltip: React.FC<TooltipProps<number, string>> = ({
    active,
    payload,
    label,
  }) => {
    if (!active || !payload || !payload.length) {
      return null;
    }

    const period = scenario.periods.find((x) => x.name === label);
    if (period) {
      const startDate = getMonthYearString(period.startDate, "short");
      const endDate = getMonthYearString(period.endDate, "short");
      label = `${label} (${startDate} - ${endDate})`;
    }

    return (
      <div
        style={{
          backgroundColor: "white",
          padding: "5px",
          border: "1px solid #D3D3D3",
          borderRadius: "10px",
        }}
      >
        <p style={{ margin: "0 0 5px 0" }}>{`${label}`}</p>
        <ul
          style={{
            listStyle: "none",
            padding: 0,
            margin: 0,
          }}
        >
          {payload.map((entry, index) => (
            <li
              key={`item-${index}`}
              style={{
                marginTop: 5,
                marginBottom: 5,
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: 16,
                  height: 16,
                  borderRadius: "50%",
                  backgroundColor: entry.color,
                  border: "1px solid #D3D3D3",
                  marginRight: 8,
                }}
              />
              <span>{`${entry.name}: ${entry.value}`}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const PeriodDetailChart: React.FC<{ period: ScenarioPeriodsDto }> = ({
    period,
  }) => {
    const chartData: ChartDataItem[] = period.monthEmissions.map(
      (monthEmission: MonthlyEmissionsDto) => {
        const monthName = getMonthYearString(monthEmission.month, "short");
        const monthData: ChartDataItem = { name: monthName };
        monthEmission.equipmentEmissions.forEach(
          (equipment: EquipmentEmissionDto) => {
            monthData[equipment.name] =
              equipment.nitrogenEmissionInKg.toFixed(2);
          }
        );
        return monthData;
      }
    );

    return (
      <div className="scenario-graph">
        <ResponsiveContainer
          className="overflow-x-scroll"
          width="100%"
          height="100%"
        >
          <BarChart data={chartData}>
            <XAxis dataKey="name" />
            <YAxis hide />
            <Tooltip content={<CustomTooltip />} />
            <Legend
              content={<CustomLegend />}
              layout="vertical"
              verticalAlign="middle"
              align="right"
              wrapperStyle={{
                top: 20,
                paddingLeft: "20px",
              }}
            />
            {allEquipmentTypes.map((equipmentType, index) => (
              <Bar
                index={index}
                key={equipmentType}
                dataKey={equipmentType}
                cursor="pointer"
                stackId="a"
                isAnimationActive={false}
                fill={equipmentTypeColors[equipmentType]}
                onClick={(data) => {
                  const month = period.monthEmissions.find(
                    (p) => getMonthYearString(p.month, "short") === data.name
                  );
                  setSelectedMonth(month!);
                }}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };

  const ScenarioPhasesDiagram: React.FC<{ scenario: ScenarioDto }> = ({
    scenario,
  }) => {
    const chartData: ChartDataItem[] = scenario.periods.map(
      (period: ScenarioPeriodsDto) => {
        const periodData: ChartDataItem = { name: period.name };
        period.phaseEmissions.forEach((phase) => {
          periodData[phase.name] = phase.nitrogenEmissionInKg.toFixed(2);
        });
        return periodData;
      }
    );

    const phaseNamesWithStartAndEnd: {
      name: string;
      start: string;
      end: string;
    }[] = scenario.periods.reduce(
      (names: { name: string; start: string; end: string }[], period) => {
        period.phaseEmissions.forEach((phase) => {
          if (!names.some((n) => n.name === phase.name)) {
            names.push({
              name: phase.name,
              start: new Date(period.startDate).toLocaleDateString(),
              end: new Date(period.endDate).toLocaleDateString(),
            });
          }
        });
        return names;
      },
      []
    );

    interface CustomizedLabelProps {
      y: number;
      value: number;
      labelText: string;
    }

    const CustomizedLabel: React.FC<CustomizedLabelProps> = ({
      y,
      value,
      labelText,
    }) => {
      return (
        <text x={5} y={y - 5} fill="red" fontSize={12} textAnchor="start">
          {t(labelText)}: {value}
        </text>
      );
    };

    const CustomTick = (props: {
      x: number;
      y: number;
      payload: {
        value: string;
        coordinate: number;
      };
      index: number;
    }) => {
      const { x, y, payload, index } = props;
      const period = scenario.periods[index];

      if (selectedPeriod && period.name === selectedPeriod.name) {
        const startDate = getMonthYearString(period.startDate, "short");
        const endDate = getMonthYearString(period.endDate, "short");
        return (
          <g>
            <text
              x={x - 10}
              y={y - 30}
              textAnchor="middle"
              fill="#666"
              transform={`rotate(-90, ${x}, ${y})`}
              fontSize={7}
            >
              {startDate}
            </text>
            <text
              x={x}
              y={y + 7}
              textAnchor="middle"
              fill="#666"
              fontSize={11}
              fontWeight="bold"
            >
              {payload.value}
            </text>
            <text
              x={x - 10}
              y={y + 35}
              textAnchor="middle"
              fill="#666"
              transform={`rotate(-90, ${x}, ${y})`}
              fontSize={7}
            >
              {endDate}
            </text>
          </g>
        );
      }
      return (
        <text x={x} y={y + 7} textAnchor="middle" fill="#666" fontSize={11}>
          {payload.value}
        </text>
      );
    };

    return (
      <div className="scenario-graph">
        <ResponsiveContainer
          width={calculateBarchartWidth(chartData.length)}
          height="100%"
        >
          <BarChart data={chartData}>
            <XAxis
              dataKey="name"
              tick={
                <CustomTick
                  x={0}
                  y={0}
                  payload={{ value: "", coordinate: 0 }}
                  index={0}
                />
              }
            />
            <YAxis hide />
            <Tooltip content={<CustomTooltip />} />
            <Legend
              content={<CustomLegend />}
              layout="vertical"
              verticalAlign="middle"
              align="right"
              wrapperStyle={{
                top: 20,
                paddingLeft: "20px",
              }}
            />

            {phaseNamesWithStartAndEnd.map(
              (phaseNameWithStartAndEnd, index) => (
                <Bar
                  key={phaseNameWithStartAndEnd.name}
                  dataKey={phaseNameWithStartAndEnd.name}
                  stackId="a"
                  isAnimationActive={false}
                  fill={phaseColors[index % phaseColors.length]}
                  onClick={(data) => {
                    const period = scenario.periods.find(
                      (p) => p.name === data.name
                    );
                    setSelectedPeriod(period!);
                    setSelectedMonth(null);
                  }}
                  cursor="pointer"
                ></Bar>
              )
            )}

            {annualNOxLimit && (
              <ReferenceLine
                y={annualNOxLimit}
                stroke="red"
                strokeDasharray="3 3"
                label={({ viewBox }) => (
                  <CustomizedLabel
                    y={viewBox.y}
                    value={annualNOxLimit}
                    labelText="NitrogenPage.AnnualNOxLimit"
                  />
                )}
                ifOverflow="extendDomain"
              />
            )}
            {projectObjectiveNOx && (
              <ReferenceLine
                y={projectObjectiveNOx}
                stroke="red"
                label={({ viewBox }) => (
                  <CustomizedLabel
                    y={viewBox.y}
                    value={projectObjectiveNOx}
                    labelText="NitrogenPage.ProjectGoalNOx"
                  />
                )}
                ifOverflow="extendDomain"
              />
            )}
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };

  const renderEquipmentDetails = (equipmentDetails: EquipmentEmissionDto[]) => {
    const totalNitrogenEmission = equipmentDetails.reduce(
      (total, equipment) => total + equipment.nitrogenEmissionInKg,
      0
    );

    const groupedEquipment = equipmentDetails.reduce((acc, equipment) => {
      if (!acc[equipment.name]) {
        acc[equipment.name] = {};
      }
      if (!acc[equipment.name][equipment.description]) {
        acc[equipment.name][equipment.description] = [];
      }
      acc[equipment.name][equipment.description].push(equipment);
      return acc;
    }, {} as { [type: string]: { [name: string]: EquipmentEmissionDto[] } });

    const sortedTypes = Object.entries(groupedEquipment).map(
      ([type, equipments]) => {
        const sortedEquipments = Object.entries(equipments)
          .map(([name, eqs]) => {
            const totalEmissionForEquipment = eqs.reduce(
              (sum, eq) => sum + eq.nitrogenEmissionInKg,
              0
            );
            const percentage =
              (totalEmissionForEquipment / totalNitrogenEmission) * 100;
            return { name, percentage };
          })
          .sort((a, b) => b.percentage - a.percentage);

        return { type, equipments: sortedEquipments };
      }
    );

    return (
      <>
        <div className="title mt20">
          {`${t("Details")} - ${selectedPeriod?.name}`}
          {selectedMonth !== null
            ? ` / ${getMonthYearString(selectedMonth?.month)}`
            : ""}
        </div>
        {sortedTypes.map((typeGroup, typeIndex) => (
          <div key={typeIndex} className="equipment-type-group">
            <li
              style={{
                marginTop: 5,
                marginBottom: 5,
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: 16,
                  height: 16,
                  borderRadius: "50%",
                  backgroundColor: equipmentTypeColors[typeGroup.type],
                  border: "1px solid #D3D3D3",
                  marginRight: 8,
                }}
              />
              <span>{typeGroup.type}</span>
            </li>
            {typeGroup.equipments.map((equipment, equipmentIndex) => (
              <div key={equipmentIndex} className="equipment-detail">
                <span>{`${equipment.percentage.toFixed(2)}% ${
                  equipment.name
                }`}</span>
              </div>
            ))}
            <hr />
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="scenario-details-view tab-view">
      <div className="scenario-graphs">
        <ScenarioPhasesDiagram scenario={scenario} />
        {selectedPeriod && <PeriodDetailChart period={selectedPeriod} />}
      </div>
      <div className="scene-sidebar">
        <div className="title">{t("General")}</div>
        <FormControlTextField labelText={t("Feature")} value={scenario?.id} />
        <FormControlTextField
          labelText={t("NitrogenPage.TotalNitrogenEmission")}
          value={scenario?.nitrogenEmissionInKg?.toFixed(2)}
        />
        <FormControlTextField
          labelText={t("NitrogenPage.TotalAmmoniaEmission")}
          value={scenario?.ammoniaEmissionInKg?.toFixed(2)}
        />
        <FormControlTextField
          labelText={t("NitrogenPage.TotalCarbonEmission")}
          value={scenario?.carbonEmissionInKg?.toFixed(2)}
        />
        {!selectedMonth &&
          selectedPeriod &&
          renderEquipmentDetails(
            selectedPeriod.monthEmissions.flatMap((x) => x.equipmentEmissions)
          )}
        {selectedMonth &&
          renderEquipmentDetails(selectedMonth.equipmentEmissions)}
      </div>
    </div>
  );
}
