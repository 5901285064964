import { Canvas } from "@react-three/fiber";
import React, { useImperativeHandle, useMemo, useRef } from "react";
import * as THREE from "three";
import { SpaceDto } from "../../../../HSA/types/api";
import { DTO } from "../../../state/ExploreState/ExploreState";
import ThreeBoxComponent from "./ThreeBoxComponent";
import { Center } from "@react-three/drei";

interface SceneCanvasProps {
  currentObject: DTO | undefined;
}

const SceneCanvas = React.forwardRef<
  { downloadImage: () => Promise<string> },
  SceneCanvasProps
>(({ currentObject }, ref) => {
  const currentSpace = currentObject as SpaceDto;
  const threeBoxRef = useRef<{ downloadImage: () => string }>(null);

  const calculateCameraDistance = (size: number) => {
    if (size < 10) return size * 2.1; // Small building
    if (size < 15) return size * 1.6; // Medium building
    if (size < 20) return size * 1.4; // Medium building
    return size * 1.1; // Big building
  };

  const buildingMetrics = useMemo(() => {
    let minX = Infinity,
      maxX = -Infinity;
    let minY = Infinity,
      maxY = -Infinity;
    let minZ = Infinity,
      maxZ = -Infinity;

    currentSpace?.layout?.floorPlans.forEach((floorPlan) => {
      floorPlan.elements.forEach((element) => {
        const { x, y, z } = element.spatialLocation;
        const { xSizeInMm, ySizeInMm, zSizeInMm } = element.dimensions;

        const xEnd = x + (xSizeInMm ?? 0);
        const yEnd = y + (typeof ySizeInMm === "number" ? ySizeInMm : 0);
        const zEnd = z + (typeof zSizeInMm === "number" ? zSizeInMm : 0);

        minX = Math.min(minX, x);
        maxX = Math.max(maxX, xEnd);
        minY = Math.min(minY, y);
        maxY = Math.max(maxY, yEnd);
        minZ = Math.min(minZ, z);
        maxZ = Math.max(maxZ, zEnd);
      });
    });

    const centerX = (minX + maxX) / 1500;
    const centerY = (minY + maxY) / 1500;
    const centerZ = (minZ + maxZ) / 1500;

    const sizeX = (maxX - minX) / 1000;
    const sizeY = (maxY - minY) / 1000;
    const sizeZ = (maxZ - minZ) / 500;

    const cameraDistance = calculateCameraDistance(sizeZ);

    return {
      center: { x: centerX, y: centerZ, z: centerY },
      size: { x: sizeX, y: sizeZ, z: sizeY },
      cameraDistance,
    };
  }, [currentObject]);

  useImperativeHandle(
    ref,
    () => ({
      downloadImage: async () => {
        await new Promise((resolve) => setTimeout(resolve, 500));
        return threeBoxRef.current?.downloadImage() || "";
      },
    }),
    []
  );

  const camera = new THREE.OrthographicCamera();

  React.useEffect(() => {
    const distance = buildingMetrics.cameraDistance;
    const center = buildingMetrics.center;

    camera.position.set(
      center.x + distance,
      center.y + distance,
      center.z + distance
    );

    camera.lookAt(center.x, center.y, center.z);
    camera.zoom = 13;

    camera.updateProjectionMatrix();
  }, [camera, buildingMetrics]);

  return (
    <Canvas gl={{ preserveDrawingBuffer: true }} shadows camera={camera}>
      <Center>
        {currentSpace && (
          <ThreeBoxComponent
            ref={threeBoxRef}
            currentSpace={currentSpace}
            buildingCenter={buildingMetrics.center}
          />
        )}
      </Center>
    </Canvas>
  );
});

export default SceneCanvas;
