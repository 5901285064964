import { AxiosError } from "axios";
import { downloadFile } from "../../../http/aggregate/ImportExportService";
import { TypeObject } from "../../../interfaces/enums/TypeObjectEnum";
import { TrackException } from "../../../logging/LoggingManager";
import {
  ExploreActions,
  ExploreState
} from "../../../state/ExploreState/ExploreState";
import useNitrogenInputPaneStore from "../../../state/NitrogenState/nitrogenInputPaneState";
import useNitrogenStore from "../../../state/NitrogenState/nitrogenState";
import { aggregateApiClient } from "../../sharedLogic/services/AxiosClient";
import useProjectsStore from "../../sharedLogic/state/projectsState";
import { IsStringUndefinedOrNullOrEmpty } from "../../sharedLogic/utils/stringUtils";
import { ScenarioDto } from "../types/api";
import { MapToPlanningActivityDto } from "../types/mapper";

const baseUrl = "/api/emission";
export async function GetNitrogenScenarios(
  signal: AbortSignal,
  currentState?: ExploreState<ScenarioDto> & ExploreActions<ScenarioDto>
): Promise<ScenarioDto[]> {
  try {
    const nitrogenInputPaneState = useNitrogenInputPaneStore.getState();
    const tasks = useNitrogenStore.getState().activities;
    const workingDays = useNitrogenStore.getState().workingDays;
    const filteredTasks = tasks.filter(
      (x) =>
        x.id.startsWith("task-") &&
        x.selectedEquipments &&
        x.selectedEquipments.length > 0
    );

    if (filteredTasks.length === 0) {
      return [];
    }

    const planningActivities = JSON.stringify(
      filteredTasks.map((x) => MapToPlanningActivityDto(x))
    );

    const pageSize = 5000;
    let allScenarios: ScenarioDto[] = [];
    const fetchPage = async (page: number): Promise<ScenarioDto[]> => {
      try {
        const response = await aggregateApiClient.post<ScenarioDto[]>(
          `${baseUrl}/calculate/scenarios?page=${page}&pageSize=${pageSize}`,
          {
            annualNOxLimit: nitrogenInputPaneState.annualNOxLimit,
            annualNH3Limit: nitrogenInputPaneState.annualNH3Limit,
            annualCO2Limit: nitrogenInputPaneState.annualCO2Limit,
            workingDays: workingDays,
            planningActivities: planningActivities,
            equipmentPerActivities: []
          },
          {
            headers: {
              Accept: "application/json",
              "Content-type": "application/json",
            },
            signal,
          }
        );
        return response.data;
      } catch (error) {
        console.warn(`Fout bij ophalen van pagina ${page}:`, error);
        return [];
      }
    };

    const firstPageData = await fetchPage(1);
    allScenarios = [...firstPageData];

    if (firstPageData.length === pageSize) {
      const maxConcurrentRequests = 3;
      let currentPage = 2;
      let hasMoreData = true;

      while (hasMoreData) {
        const pagePromises = [];
        for (let i = 0; i < maxConcurrentRequests && hasMoreData; i++) {
          pagePromises.push(fetchPage(currentPage++));
        }

        const results = await Promise.all(pagePromises);
        hasMoreData = false;
        for (const pageData of results) {
          if (pageData.length > 0) {
            allScenarios = [...allScenarios, ...pageData];
            if (pageData.length === pageSize) {
              hasMoreData = true;
            }
          }
        }

        if (!hasMoreData) {
          break;
        }
      }
    }

    const scenarios = allScenarios
      .sort((a, b) => a.value - b.value)
      .map((s, index) => ({
        ...s,
        id: index + 1,
        typeObject: TypeObject.NitrogenAnalyser,
        color: "#FF0000",
        renderType: "chart" as "chart" | "image" | "other",
        fuelConsumptionInL: Number.parseInt(s.fuelConsumptionInL.toFixed()),
      }));

    currentState?.setObjectsTotalCount(scenarios.length);
    return scenarios;
  } catch (ex) {
    console.error("Fout bij het ophalen van stikstofscenario's:", ex);
    TrackException(ex as AxiosError);
    return [];
  }
}

export async function GetScenario(
  scenarioDto: ScenarioDto
): Promise<ScenarioDto> {
  try {
    const nitrogenInputPaneState = useNitrogenInputPaneStore.getState();
    const tasks = useNitrogenStore.getState().activities;
    const workingDays = useNitrogenStore.getState().workingDays;
    const filteredTasks = tasks.filter(
      (x) =>
        x.id.startsWith("task-") &&
        x.selectedEquipments &&
        x.selectedEquipments.length > 0
    );

    if (filteredTasks.length === 0) {
      return {} as ScenarioDto;
    }

    const planningActivities = JSON.stringify(
      filteredTasks.map((x) => MapToPlanningActivityDto(x))
    );

    const response = await aggregateApiClient.post<ScenarioDto[]>(
      `${baseUrl}/calculate/scenario-details`,
      {
        annualNOxLimit: nitrogenInputPaneState.annualNOxLimit,
        annualNH3Limit: nitrogenInputPaneState.annualNH3Limit,
        annualCO2Limit: nitrogenInputPaneState.annualCO2Limit,
        workingDays: workingDays,
        planningActivities: planningActivities,
        equipmentPerActivities: scenarioDto.equipmentPerActivities,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      }
    );

    return response.data[0]; // Only one scenario is currently needed
  } catch (ex) {
    TrackException(ex as AxiosError);
  }
  return {} as ScenarioDto;
}

export async function ExportScenario(scenario: ScenarioDto): Promise<void> {
  try {
    var projectName = useProjectsStore.getState().currentProject?.name;
    if (IsStringUndefinedOrNullOrEmpty(projectName)) {
      projectName = "(unknown)";
    }

    const response = await aggregateApiClient.post<ScenarioDto[]>(
      `${baseUrl}/export/scenario?projectName=${projectName}`,
      scenario,
      {
        responseType: "arraybuffer",
        headers: {
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "Content-Type": "application/json",
        },
      }
    );

    await downloadFile(response, "scenario", "excel");
  } catch (ex) {
    TrackException(ex as AxiosError);
  }
}
