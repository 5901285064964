import { useMemo } from "react";
import { ExploreConfigStore } from "../../../../../interfaces/exploreConfig";
import { LowVoltageRoomDto } from "../../../../../interfaces/foundry/typicals/LowVoltageRoomDto";
import SummaryInfoDto from "../../../../../interfaces/SummaryInfoDto";
import useInputPaneStore from "../../../../../state/DatacenterState/inputPaneState";
import { createExploreStore } from "../../../../../state/ExploreState/ExploreState";
import ClickableAxesDto from "../../../../../interfaces/ClickableAxisDto";

export const useLowVoltageRoomConfigStore =
  (): ExploreConfigStore<LowVoltageRoomDto> => {
    const {
      buildingType,
      tranfoType,
      autonomyInMin,
      coolingPrinciple,
      selectedBatteryTypes,
      includeGenset,
      includeRedNsa,
      selectedDistributionTypes,
      powerLSRs,
      hasMultipleTransformators,
    } = useInputPaneStore();

    const currentStore = useMemo(
      () => createExploreStore<LowVoltageRoomDto>(),
      []
    );

    const {
      brushedObjects,
      setLoadedObjects,
      setFilteredObjects,
      setFilteredObjectsWithCoolingTypes,
    } = currentStore();

    const sortByOptionsDictionary: { [key: string]: string } = {
      "Best fit rate": "value",
      "kw/m²": "kw_m2",
      "kw/m³": "kw_m3",
      CostsCurrency: "cost",
      "Construction time": "construction_time",
      "Whitespace cooling ratio": "cooling_area_effectivity",
    };

    const defaultSelectedAxes = ["kw_m2"];

    const summaryInfo: SummaryInfoDto[] = [
      { label: "Total power", property: "total_power_in_w", unit: "MW" },
      {
        label: "Emergency power",
        property: "emergency_power_in_w",
        unit: "MW",
      },
      { label: "Transformator", property: "trafo_display_name", unit: "" },
    ];

    const dimensions = [
      "total_power_in_w",
      "length",
      "width",
      "area",
      "kw_m2",
      "kw_m3",
      "transformator_amount",
      "emergency_power_in_w",
      "battery_vs_total_power",
      "cost",
      "co2_footprint_in_euros",
    ];
    const chartLabels = [
      "Total power kW",
      "Net width(m)",
      "Net length(m)",
      "Area(m²)",
      "kW/m²",
      "kW/m³",
      "Number of transformators",
      "Emergency power",
      "Emergency power %",
      "CostsCurrency",
      "LowVoltageRoomPage.CO₂",
    ];

    const clickableAxes: ClickableAxesDto[] = [
      { axis: "area", isColorReversed: true },
      { axis: "kw_m2", isColorReversed: false },
      { axis: "kw_m3", isColorReversed: false },
      { axis: "battery_vs_total_power", isColorReversed: false },
      { axis: "cost", isColorReversed: true },
      { axis: "co2_footprint_in_euros", isColorReversed: true },
    ];

    const filterControlOptionsDictionary = {
      "Total power": "total_power_in_w",
      "Length(m)": "length",
      "Width(m)": "width",
      Area: "area",
      "kW/m²": "kw_m2",
      "kW/m³": "kw_m3",
      Transformator: "transformator_amount",
      "Emergency power": "emergency_power_in_w",
      "Emergency power %": "battery_vs_total_power",
      CostsCurrency: "cost",
      "LowVoltageRoomPage.CO₂": "co2_footprint_in_euros",
    };

    const filterControlOptionsDictionaryReversed = {
      total_power_in_w: "Total power",
      it_power_in_w: "Power IT",
      length: "Length(m)",
      width: "Width(m)",
      area: "Area",
      kw_m2: "kW/m²",
      kw_m3: "kW/m³",
      transformator_amount: "Transformator",
      emergency_power_in_w: "Emergency power",
      cost: "CostsCurrency",
      construction_time_in_days: "Construction time",
      battery_vs_total_power: "Emergency power %",
      co2_footprint_in_euros: "LowVoltageRoomPage.CO₂",
    };

    const handleObjectFilters = (objects: LowVoltageRoomDto[]) => {
      const lowVoltageRooms = objects as LowVoltageRoomDto[];
      const filteredLowVoltageRooms = lowVoltageRooms.filter(
        (lowVoltageRoom) =>
          (!tranfoType.length ||
            tranfoType.some(
              (tranfo) =>
                tranfo.display_name === lowVoltageRoom.trafo_display_name
            )) &&
          (!buildingType.length ||
            buildingType.some((bt) =>
              bt && bt.id !== -1
                ? lowVoltageRoom.building_types.some(
                    (lvrBt) => lvrBt.id === bt.id
                  )
                : lowVoltageRoom.building_types.length === 0
            )) &&
          (!autonomyInMin ||
            lowVoltageRoom.autonomy_in_min === autonomyInMin) &&
          (!selectedBatteryTypes.length ||
            selectedBatteryTypes.some(
              (bt) => bt === (lowVoltageRoom.battery_type as string)
            )) &&
          (includeGenset === null ||
            lowVoltageRoom.incl_genset === includeGenset) &&
          (includeRedNsa === null ||
            lowVoltageRoom.incl_red_nsa === includeRedNsa) &&
          (!selectedDistributionTypes.length ||
            (selectedDistributionTypes.includes("Busbar") &&
              lowVoltageRoom.incl_busbar === true) ||
            (selectedDistributionTypes.includes("Bedraad") &&
              lowVoltageRoom.incl_busbar === false) ||
            selectedDistributionTypes.includes("All")) &&
          (!powerLSRs.length ||
            (lowVoltageRoom.total_power_in_w >= Math.min(...powerLSRs) &&
              lowVoltageRoom.total_power_in_w <= Math.max(...powerLSRs))) &&
          (hasMultipleTransformators === null ||
            (hasMultipleTransformators &&
              lowVoltageRoom.transformator_amount > 1) ||
            (!hasMultipleTransformators &&
              lowVoltageRoom.transformator_amount === 1))
      );

      const filteredLowVoltageRoomsByCoolingType = lowVoltageRooms.filter(
        () => {
          return !coolingPrinciple.length;
        }
      );

      if (filteredLowVoltageRoomsByCoolingType.length === 0) {
        setLoadedObjects([]);
      }
      setFilteredObjects(filteredLowVoltageRooms);
      setFilteredObjectsWithCoolingTypes(filteredLowVoltageRooms);

      return filteredLowVoltageRoomsByCoolingType;
    };

    const handleCustomFilter = (): LowVoltageRoomDto[] => {
      return [...brushedObjects] as LowVoltageRoomDto[];
    };

    return {
      sortByOptionsDictionary,
      currentStore,
      summaryInfo,
      dimensions,
      chartLabels,
      clickableAxes,
      filterControlOptionsDictionary,
      filterControlOptionsDictionaryReversed,
      handleObjectFilters,
      handleCustomFilter,
      defaultSelectedAxes,
    };
  };
