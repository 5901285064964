import {
  GridColDef,
  GridColumnGroupingModel,
  GridRowModel,
} from "@mui/x-data-grid";
import { AxiosError } from "axios";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GetCategories } from "../../../http/aggregate/CategoriesService";
import { GetInputDataByType } from "../../../http/aggregate/InputDataService";
import {
  DeleteCostKeyFigure,
  GetCostKeyFigures,
  SaveCostKeyFigure,
  UpdateCostKeyFigure,
} from "../../../http/aggregate/KeyFigureService";
import { BaseDatabaseOptionDto } from "../../../interfaces/aggregate/BaseDatabaseOptionDto";
import { CostKeyFigureDto } from "../../../interfaces/aggregate/CostKeyFigureDto";
import { AggregatePageEnum } from "../../../interfaces/aggregate/enums/AggregatePageEnum";
import { InputDataCatalogEnum } from "../../../interfaces/aggregate/enums/InputDataCatalogEnum";
import { TrackException } from "../../../logging/LoggingManager";
import useUserStore from "../../../modules/sharedLogic/state/userState";
import useCostKeyFiguresStore from "../../../state/DatabaseState/costKeyFiguresState";
import useDatabaseStore from "../../../state/DatabaseState/databaseState";
import useObjectTypeStore from "../../../state/DatabaseState/objectTypesState";
import {
  FormatEuroToNumber,
  FormatNumberToEuro,
  safeNumber,
} from "../../../utils/HelperFunctions";
import { BaseDatabasePage, groupBy } from "../BaseDatabasePage";
import { AutoComplete } from "../components/AutoComplete/AutoComplete";

export function CostKeyFiguresPage() {
  const { t } = useTranslation();
  const {
    costCategories,
    costCodes,
    costKeyFigures,
    costUnits,
    setCostCodes,
    setCostKeyFigures,
    setCostUnits,
    setCostCategories,
  } = useCostKeyFiguresStore();

  const { objectTypes, setObjectTypes } = useObjectTypeStore();

  const {
    selectedNode,
    isEditMode,
    owners,
    setCurrentPage,
    setCategories,
    setDepartments,
    setCurrentDatabaseOptions,
  } = useDatabaseStore();

  const columns: GridColDef[] = [
    {
      field: "code",
      headerName: t("Cost code"),
      editable: isEditMode,
      flex: 1,
      type: "singleSelect",
      valueFormatter(value, row) {
        return row?.code?.name;
      },
      getOptionLabel: (value) => (value as BaseDatabaseOptionDto).name,
      getOptionValue: (value) => (value as BaseDatabaseOptionDto).id,
      renderCell: (params) => {
        try {
          const costKeyFigureDto = params.row as CostKeyFigureDto;
          if (
            !costKeyFigureDto ||
            (costKeyFigureDto.id === 0 && !costKeyFigureDto.code)
          ) {
            return "";
          }

          const costKeyFiguresWithSameName = costKeyFigures.filter(
            (x) => x.code?.name === costKeyFigureDto.code?.name && x.id !== 0
          );
          if (costKeyFiguresWithSameName.length === 1) {
            return costKeyFigureDto.code?.name;
          }

          const index = Math.floor(costKeyFiguresWithSameName.length / 2);
          if (costKeyFiguresWithSameName[index].id === costKeyFigureDto?.id) {
            return costKeyFigureDto.code?.name;
          } else return "";
        } catch (ex) {
          return ""; //Handle exception of clicking fast in the TreeView
        }
      },
      valueOptions: costCodes.sort((a, b) => a.name.localeCompare(b.name)),
      renderEditCell: (params) => {
        return (
          <AutoComplete
            params={params}
            renderOptions={costCodes.sort((a, b) =>
              a.name.localeCompare(b.name)
            )}
          />
        );
      },
    },
    {
      field: "costCategory",
      headerName: t("Cost category"),
      editable: isEditMode,
      flex: 1,
      type: "singleSelect",
      getOptionLabel: (value) => (value as BaseDatabaseOptionDto).name,
      getOptionValue: (value) => (value as BaseDatabaseOptionDto).id,
      valueFormatter(value, row) {
        return row.costCategory?.name;
      },
      valueOptions: costCategories.sort((a, b) => a.name.localeCompare(b.name)),
      renderEditCell: (params) => {
        return (
          <AutoComplete
            params={params}
            renderOptions={costCategories.sort((a, b) =>
              a.name.localeCompare(b.name)
            )}
          />
        );
      },
    },
    {
      field: "description",
      headerName: t("Description"),
      editable: isEditMode,
      flex: 1,
    },
    {
      field: "objectType",
      headerName: t("Object type"),
      editable: isEditMode,
      flex: 1,
      type: "singleSelect",
      valueFormatter(value, row) {
        return row.objectType?.name;
      },
      valueOptions: objectTypes.sort((a, b) => a.name.localeCompare(b.name)),
      getOptionLabel: (value) => (value as BaseDatabaseOptionDto).name,
      getOptionValue: (value) => (value as BaseDatabaseOptionDto).id,
      renderEditCell: (params) => {
        return (
          <AutoComplete
            params={params}
            renderOptions={objectTypes.sort((a, b) =>
              a.name.localeCompare(b.name)
            )}
          />
        );
      },
    },
    {
      field: "unit",
      headerName: t("Cost unit"),
      editable: isEditMode,
      flex: 1,
      type: "singleSelect",
      valueFormatter(value, row) {
        return row.unit?.name;
      },
      valueOptions: costUnits.sort((a, b) => a.name.localeCompare(b.name)),
      getOptionLabel: (value) => (value as BaseDatabaseOptionDto).name,
      getOptionValue: (value) => (value as BaseDatabaseOptionDto).id,
      renderEditCell: (params) => {
        return (
          <AutoComplete
            params={params}
            renderOptions={costUnits.sort((a, b) =>
              a.name.localeCompare(b.name)
            )}
          />
        );
      },
    },
    {
      field: "costPerUnit",
      headerName: t("CostPerUnit"),
      editable: isEditMode,
      flex: 1,
      valueFormatter(params, row) {
        return row?.costPerUnit ?? 0;
      },
      renderCell: (params) => {
        if (params.cellMode === "view") {
          return FormatNumberToEuro(params.row.costPerUnit);
        }
        return FormatEuroToNumber(params.row.costPerUnit);
      },
    },
  ];

  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: t("Element data"),
      children: [
        { field: "code" },
        { field: "costCategory" },
        { field: "description" },
        { field: "objectType" },
      ],
    },
    {
      groupId: t("Production data"),
      children: [{ field: "unit" }, { field: "costPerUnit" }],
    },
  ];

  async function processRowUpdate(
    gridRowModel: GridRowModel
  ): Promise<CostKeyFigureDto> {
    const costKeyFigure = gridRowModel as CostKeyFigureDto;
    costKeyFigure.categoryId =
      costKeyFigure.category?.id || Number(selectedNode);
    costKeyFigure.departmentId = costKeyFigure.department?.id || 0;
    costKeyFigure.costUnitId = costKeyFigure.unit?.id || 0;
    costKeyFigure.costCategoryId = costKeyFigure.costCategory?.id || 0;
    costKeyFigure.objectTypeId = costKeyFigure.objectType?.id || 0;
    costKeyFigure.costCodeId = costKeyFigure.code?.id || 0;
    costKeyFigure.ownerId = costKeyFigure.owner?.id || 0;
    costKeyFigure.costPerUnit = safeNumber(costKeyFigure.costPerUnit);

    if (costKeyFigure.id === 0) {
      costKeyFigure.id = await SaveCostKeyFigure(costKeyFigure);
      setCostKeyFigures(
        costKeyFigures.map((item: CostKeyFigureDto) =>
          item.id === 0 ? costKeyFigure : item
        )
      );
    } else {
      const oldKeyFigure = costKeyFigures.find(
        (x) => x.id === costKeyFigure.id
      )!;
      costKeyFigure.categoryId = oldKeyFigure.category.id;
      await UpdateCostKeyFigure(costKeyFigure);
      setCostKeyFigures(
        costKeyFigures.map((item: CostKeyFigureDto) =>
          item.id === costKeyFigure.id ? costKeyFigure : item
        )
      );
    }

    if (costKeyFigure.id !== 0) {
      setTimeout(() => {
        LoadCostKeyFigures();
      }, 500);
    }

    return costKeyFigure;
  }

  useEffect(() => {
    setCurrentPage(AggregatePageEnum.CostKeyFigure);
    (async () => {
      await LoadItems();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (selectedNode !== "") {
        await LoadCostKeyFigures();
      }
    })();
  }, [selectedNode]);

  async function LoadItems() {
    try {
      setCategories(await GetCategories(AggregatePageEnum.CostKeyFigure));
      const costCategories = await GetInputDataByType(
        InputDataCatalogEnum.InputDataCostCategory
      );

      setCurrentDatabaseOptions(costCategories);
      setCostCategories(costCategories);
      setCostUnits(
        await GetInputDataByType(InputDataCatalogEnum.InputDataCostUnit)
      );
      setCostCodes(
        await GetInputDataByType(InputDataCatalogEnum.InputDataCostCode)
      );
      setObjectTypes(
        await GetInputDataByType(InputDataCatalogEnum.InputDataObjectType)
      );
      setDepartments(
        await GetInputDataByType(InputDataCatalogEnum.InputDataDepartment)
      );
    } catch (ex) {
      TrackException(ex as AxiosError);
    }
  }

  async function LoadCostKeyFigures() {
    setCostKeyFigures(await GetCostKeyFigures(selectedNode));
  }

  return (
    <BaseDatabasePage
      columns={columns}
      data={groupBy(costKeyFigures, (item) => item.code?.name)}
      columnGroupingModel={columnGroupingModel}
      addNewItem={(groupKey: string) => {
        const costKeyFigure = costKeyFigures.find(
          (x) => x.code?.name === groupKey
        );
        setCostKeyFigures([
          ...costKeyFigures,
          {
            id: 0,
            code: costKeyFigure?.code,
            costCategory: costKeyFigure?.costCategory,
            objectType: costKeyFigure?.objectType ?? objectTypes[0],
            category: costKeyFigure?.category,
            owner: owners.find(
              (x) => x.emailAddress === useUserStore.getState().emailAddress
            ),
          } as CostKeyFigureDto,
        ]);
      }}
      baseProcessRowUpdate={processRowUpdate}
      handleCancel={() => {
        setCostKeyFigures(costKeyFigures.filter((x) => x.id !== 0));
      }}
      handleDelete={(params) => {
        DeleteCostKeyFigure(Number(params));
        setCostKeyFigures(costKeyFigures.filter((x) => x.id !== params));
      }}
    />
  );
}
