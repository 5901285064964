import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import CustomInputLabel from "../../../../../components/InputLabel/CustomInputLabel";
import { SaveProject } from "../../../../../http/foundry/ProjectService";
import { CloseIcon } from "../../../../../icons/CloseIcon";
import { SelectedBuildingsDto } from "../../../../../interfaces/foundry/SelectedBuildingsDto";
import useDatacenterGeneratorStore from "../../../../../state/DatacenterState/datacenterGeneratorState";
import useInputPaneStore, {
  InputPaneState,
} from "../../../../../state/DatacenterState/inputPaneState";
import { ExploreConfigStore } from "../../../../../interfaces/exploreConfig";
import WhitespaceDto from "../../../../../interfaces/foundry/WhitespaceDto";
import { DatacenterDto } from "../../../../../interfaces/foundry/DatacenterDto";
import { LowVoltageRoomDto } from "../../../../../interfaces/foundry/typicals/LowVoltageRoomDto";

interface CreateOrUpdateProjectViewProps {
  rename: boolean;
  isProjectViewOpen: boolean;
  setIsProjectViewOpen: (isOpen: boolean) => void;
  onSumbit: () => void;
  whitespaceConfigStore?: ExploreConfigStore<WhitespaceDto> | undefined;
  datacenterConfigStore?: ExploreConfigStore<DatacenterDto> | undefined;
  lowVoltageRoomConfigStore?: ExploreConfigStore<LowVoltageRoomDto> | undefined;
}

export default function CreateOrUpdateProjectView({
  rename,
  isProjectViewOpen,
  onSumbit,
  setIsProjectViewOpen,
  whitespaceConfigStore,
  datacenterConfigStore,
  lowVoltageRoomConfigStore,
}: CreateOrUpdateProjectViewProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { drawedPlot, currentProject, setCurrentProject } =
    useDatacenterGeneratorStore();

  const inputPaneStore = useInputPaneStore();
  const whiteSpaceStore = whitespaceConfigStore?.currentStore();
  const datacenterStore = datacenterConfigStore?.currentStore();
  const lowVoltageRoomStore = lowVoltageRoomConfigStore?.currentStore();

  const [errorText, setErrorText] = React.useState<string>("");
  const isChangingNameAvailable = currentProject.id === 0 || rename;

  useEffect(() => {
    setErrorText("");
  }, [isProjectViewOpen]);

  async function handleSumbit() {
    try {
      if (currentProject.name.length === 0) {
        setErrorText(t("Project name cannot be empty"));
        return;
      }

      if (!rename) {
        currentProject.type_of = "Datacenters";
        currentProject.plot = `POLYGON((${drawedPlot.lotCoordinates
          .map((coord) => coord.join(" "))
          .join(", ")}))`;

        let buildings = {} as SelectedBuildingsDto;
        if (currentProject.buildings) {
          buildings = JSON.parse(currentProject.buildings);
        }

        buildings.whitespaces = whiteSpaceStore?.selectedIds ?? [];
        buildings.selectedItemsWhitespaceGraph =
          whiteSpaceStore?.filtersOnAxes ?? [];

        buildings.datacenters = datacenterStore?.selectedIds ?? [];
        buildings.selectedItemsDatacenterGraph =
          datacenterStore?.filtersOnAxes ?? [];

        buildings.lowVoltageRooms = lowVoltageRoomStore?.selectedIds ?? [];
        buildings.selectedItemsLVRGraph =
          lowVoltageRoomStore?.filtersOnAxes ?? [];

        currentProject.buildings = JSON.stringify(buildings);
        currentProject.params = JSON.stringify(
          inputPaneStore as InputPaneState
        );
      }

      const savedProject = await SaveProject(currentProject);
      if (typeof savedProject === "string") {
        setErrorText(t(savedProject));
        return;
      }

      if (currentProject.id === 0) {
        navigate(`/configurators/datacenter/${currentProject.name}`, {
          replace: true,
        });
      }
      onSumbit();
      setCurrentProject(savedProject);
    } catch (ex) {
      console.error(ex);
      setErrorText(t("Error occurred while saving project"));
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={isProjectViewOpen}
      onClose={() => setIsProjectViewOpen(false)}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          paddingRight: "15px",
          paddingBottom: "5px",
          fontWeight: 700,
        }}
      >
        {currentProject.id === 0 ? t("Create new project") : t("Save project")}

        <IconButton
          sx={{ ml: "auto" }}
          onClick={() => setIsProjectViewOpen(false)}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: "25px" }}>
        {isChangingNameAvailable && (
          <FormControl fullWidth variant="standard">
            <CustomInputLabel
              showInfoButton={false}
              labelText="Project name"
              tooltipText=""
              id=""
            />
            <TextField
              fullWidth
              error={errorText.length > 0}
              helperText={errorText}
              value={currentProject.name}
              style={{ marginTop: "15px", width: "400px" }}
              variant="standard"
              onChange={(e) => {
                const newValue = e.target.value;
                if (newValue.match(/[%<>? \\$'"/]/)) {
                  setErrorText(t("Forbidden characters") + ": %/? <>$'\"");
                  return;
                }
                setErrorText("");
                setCurrentProject({
                  ...currentProject,
                  name: e.target.value,
                });
              }}
            />
          </FormControl>
        )}
        {currentProject.id !== 0 && !rename && <div>{t("Save changes")}</div>}
      </DialogContent>
      <DialogActions style={{ paddingRight: "20px" }}>
        <Button onClick={() => setIsProjectViewOpen(false)}>
          {t("Cancel")}
        </Button>
        <Button onClick={handleSumbit}>{t("Save")}</Button>
      </DialogActions>
    </Dialog>
  );
}
