import { create } from "zustand";
import { RackDto } from "../../interfaces/foundry/typicals/RackDto";
import { TransformatorDto } from "../../interfaces/foundry/typicals/TransformatorDto";
import { UpsDto } from "../../interfaces/foundry/typicals/UPSDto";
import { BatteryDto } from "../../interfaces/foundry/typicals/BatteryDto";

interface ComponentGeneratorState {
  upss: UpsDto[];
  racks: RackDto[];
  batteries: BatteryDto[];
  transformators: TransformatorDto[];
  filteredUpss: UpsDto[] | null;
  filteredRacks: RackDto[] | null;
  filteredBatteries: BatteryDto[] | null;
  filteredTransformators: TransformatorDto[] | null;
  showGridView: boolean;
  rackAddress: string;
  rackAddressCoordinates: number[];
  maxBuildSize: number | null;
  desiredITCapability: number | null;
  typeDataCentre: string;
  powerWhitespaces: number[];
  powerRack: number[];
  coolingPrinciple: string[];
  destinationPlan: string;
  multipleFloors: boolean;
  restrictionOnGensets: boolean;
  expansionOnCampus: boolean;
  purchasingStation: boolean;
  sprinklerBasin: boolean;
  waterTreatment: boolean;
  adminBuilding: boolean;
  startDrawingPlot: boolean;
  editDrawingPlot: boolean;
  deleteDrawingPlot: boolean;
  navigateDrawingPlot: boolean;
  isInputPaneOpen: boolean;
  currentTabPageIndex: number;
  preselectionWhitespaceTabDisabled: boolean;
  preselectionLowVoltageRoomTabDisabled: boolean;
  preselectionComponentTabDisabled: boolean;
  powerRackOptions: number[];
  coolingPrincipleOptions: string[];
  desiredPowerOptions: number[];
  dcScopes: string[];
  destinationPlans: string[];
  calculateDistance: boolean;
  distanceBetweenPlotAndRoad: number;
  filterControlSelectedAxes: string[];
  filterControlOptionsDictionary: { [key: string]: string };
  filterControlOptionsDictionaryReversed: { [key: string]: string };
}

interface ComponentGeneratorActions {
  setShowGridView: (showGridView: boolean) => void;
  setRacks: (racks: RackDto[]) => void;
  setFilteredRacks: (filteredRacks: RackDto[]) => void;
  setTransformators: (transformators: TransformatorDto[]) => void;
  setFilteredTransformators: (
    filteredTransformators: TransformatorDto[]
  ) => void;

  setUpss: (upss: UpsDto[]) => void;
  setFilteredUpss: (filteredUpss: UpsDto[]) => void;

  setBatteries: (batteries: BatteryDto[]) => void;
  setFilteredBatteries: (filteredBatteries: BatteryDto[]) => void;

  setRackAddress: (rackAdress: string) => void;
  setRackAddressCoordinates: (rackAddressCoordinates: number[]) => void;
  setMaxBuildSize: (maxBuildSize: number) => void;
  setDesiredITCapability: (desiredITCapability: number) => void;
  setTypeDataCentre: (typeDataCentre: string) => void;
  setPowerWhitespaces: (powerWhitespaces: number[]) => void;
  setPowerRack: (powerRack: number[]) => void;
  setCoolingPrinciple: (coolingPrinciple: string[]) => void;
  setDestinationPlan: (destinationPlan: string) => void;
  setMultipleFloors: (boolValue: boolean) => void;
  setRestrictionOnGensets: (boolValue: boolean) => void;
  setExpansionOnCampus: (boolValue: boolean) => void;
  setPurchasingStation: (boolValue: boolean) => void;
  setSprinklerBasin: (boolValue: boolean) => void;
  setWaterTreatment: (boolValue: boolean) => void;
  setAdminBuilding: (boolValue: boolean) => void;
  setStartDrawingPlot: (newValue: boolean) => void;
  setEditDrawingPlot: (boolValue: boolean) => void;
  setDeleteDrawingPlot: (boolValue: boolean) => void;
  setNavigateDrawingPlot: (newValue: boolean) => void;
  setIsInputPaneOpen: (boolValue: boolean) => void;
  setCurrentTabPageIndex: (newValue: number) => void;
  setPreselectionWhitespaceTabDisabled: (boolValue: boolean) => void;
  setPreselectionLowVoltageRoomTabDisabled: (boolValue: boolean) => void;
  setPreselectionComponentTabDisabled: (newValue: boolean) => void;
  setPowerRackOptions: (values: number[]) => void;
  setCoolingPrincipleOptions: (values: string[]) => void;
  setDesiredPowerOptions: (values: number[]) => void;
  setDcScopes: (values: string[]) => void;
  setDestinationPlans: (values: string[]) => void;
  setDistanceBetweenPlotAndRoad: (values: number) => void;
  setCalculateDistance: (values: boolean) => void;
  setFilterControlSelectedAxes: (value: string[]) => void;
  setFilterControlOptionsDictionary: (value: { [key: string]: string }) => void;
  setFilterControlOptionsDictionaryReversed: (value: {
    [key: string]: string;
  }) => void;
  resetState: () => void;
}

const initialState: ComponentGeneratorState = {
  showGridView: false,
  racks: [],
  filteredRacks: null,
  transformators: [],
  filteredTransformators: null,
  upss: [],
  filteredUpss: null,
  batteries: [],
  filteredBatteries: null,
  rackAddress: "",
  rackAddressCoordinates: [],
  maxBuildSize: null,
  desiredITCapability: null,
  coolingPrinciple: [],
  destinationPlan: "",
  powerWhitespaces: [],
  typeDataCentre: "",
  powerRack: [],
  multipleFloors: true,
  restrictionOnGensets: false,
  expansionOnCampus: false,
  purchasingStation: false,
  sprinklerBasin: false,
  waterTreatment: false,
  adminBuilding: false,
  startDrawingPlot: false,
  editDrawingPlot: false,
  deleteDrawingPlot: false,
  navigateDrawingPlot: false,
  isInputPaneOpen: true,
  currentTabPageIndex: 0,
  preselectionComponentTabDisabled: false,
  preselectionLowVoltageRoomTabDisabled: false,
  preselectionWhitespaceTabDisabled: false,
  powerRackOptions: [],
  coolingPrincipleOptions: [],
  dcScopes: [],
  desiredPowerOptions: [],
  destinationPlans: [],
  distanceBetweenPlotAndRoad: 0,
  calculateDistance: false,
  filterControlSelectedAxes: [],
  filterControlOptionsDictionary: {
    "kw/m²": "kw_m2",
    "kw/m³": "kw_m3",
    "Cost value": "cost_value",
    "Construction time": "construction_time",
    "Carbon footprint": "carbon_footprint",
  },
  filterControlOptionsDictionaryReversed: {
    kw_m2: "kw/m²",
    kw_m3: "kw/m³",
    cost_value: "Cost value",
    construction_time: "Construction time",
    carbon_footprint: "Carbon footprint",
  },
};

const useComponentGeneratorStore = create<
  ComponentGeneratorState & ComponentGeneratorActions
>()((set) => ({
  ...initialState,
  setShowGridView: (newValue) => set({ showGridView: newValue }),
  setRacks: (newValue) => set({ racks: newValue }),
  setFilteredRacks: (newValue) => set({ filteredRacks: newValue }),
  setTransformators: (newValue) => set({ transformators: newValue }),
  setFilteredTransformators: (newValue) =>
    set({ filteredTransformators: newValue }),
  setUpss: (newValue) => set({ upss: newValue }),
  setFilteredUpss: (newValue) => set({ filteredUpss: newValue }),
  setBatteries: (newValue) => set({ batteries: newValue }),
  setFilteredBatteries: (newValue) => set({ filteredBatteries: newValue }),
  setRackAddress: (newRackAdress) => set({ rackAddress: newRackAdress }),
  setRackAddressCoordinates: (cordinates) =>
    set({ rackAddressCoordinates: cordinates }),
  setMaxBuildSize: (newMaxBuildSize) => set({ maxBuildSize: newMaxBuildSize }),
  setDesiredITCapability: (newDesiredITCapability) =>
    set({ desiredITCapability: newDesiredITCapability }),
  setTypeDataCentre: (newTypeDataCentre) =>
    set({ typeDataCentre: newTypeDataCentre }),
  setPowerWhitespaces: (newPowerWhitespaces) =>
    set({ powerWhitespaces: newPowerWhitespaces }),
  setPowerRack: (newPowerRack) => set({ powerRack: newPowerRack }),
  setCoolingPrinciple: (newCoolingPrinciple) =>
    set({ coolingPrinciple: newCoolingPrinciple }),
  setDestinationPlan: (newDestinationPlan) =>
    set({ destinationPlan: newDestinationPlan }),
  setMultipleFloors: (newMultipleFloors) =>
    set({ multipleFloors: newMultipleFloors }),
  setRestrictionOnGensets: (newRestrictionOnGensets) =>
    set({ restrictionOnGensets: newRestrictionOnGensets }),
  setExpansionOnCampus: (newExpansionOnCampus) =>
    set({ expansionOnCampus: newExpansionOnCampus }),
  setPurchasingStation: (newSubstation) =>
    set({ purchasingStation: newSubstation }),
  setSprinklerBasin: (newSprinklerBasin) =>
    set({ sprinklerBasin: newSprinklerBasin }),
  setWaterTreatment: (newWaterTreatment) =>
    set({ waterTreatment: newWaterTreatment }),
  setAdminBuilding: (newAdminBuilding) =>
    set({ adminBuilding: newAdminBuilding }),
  setStartDrawingPlot: (newValue) => set({ startDrawingPlot: newValue }),
  setEditDrawingPlot: (newValue) => set({ editDrawingPlot: newValue }),
  setDeleteDrawingPlot: (newValue) => set({ deleteDrawingPlot: newValue }),
  setNavigateDrawingPlot: (newValue) => set({ navigateDrawingPlot: newValue }),
  setIsInputPaneOpen: (newValue) => set({ isInputPaneOpen: newValue }),
  setCoolingPrincipleOptions: (newValue) =>
    set({ coolingPrincipleOptions: newValue }),
  setPowerRackOptions: (newValue) => set({ powerRackOptions: newValue }),
  setCurrentTabPageIndex: (newValue) => set({ currentTabPageIndex: newValue }),
  setPreselectionComponentTabDisabled: (newValue) =>
    set({ preselectionComponentTabDisabled: newValue }),
  setPreselectionLowVoltageRoomTabDisabled: (newValue) =>
    set({ preselectionLowVoltageRoomTabDisabled: newValue }),
  setPreselectionWhitespaceTabDisabled: (newValue) =>
    set({ preselectionWhitespaceTabDisabled: newValue }),
  setDcScopes: (newValue) => set({ dcScopes: newValue }),
  setDesiredPowerOptions: (newValue) => set({ desiredPowerOptions: newValue }),
  setDestinationPlans: (newValue) => set({ destinationPlans: newValue }),
  setDistanceBetweenPlotAndRoad: (newValue) =>
    set({ distanceBetweenPlotAndRoad: newValue }),
  setCalculateDistance: (newValue) => set({ calculateDistance: newValue }),
  setFilterControlSelectedAxes: (newValue) =>
    set({ filterControlSelectedAxes: newValue }),
  setFilterControlOptionsDictionary: (newValue) =>
    set({ filterControlOptionsDictionary: newValue }),
  setFilterControlOptionsDictionaryReversed: (newValue) =>
    set({ filterControlOptionsDictionaryReversed: newValue }),
  resetState: () => set(initialState),
}));

export default useComponentGeneratorStore;
