import { create } from "zustand";
import ColorPalette from "../../interfaces/ColorPalette";
import { TypeObject } from "../../interfaces/enums/TypeObjectEnum";
import { DatacenterDto } from "../../interfaces/foundry/DatacenterDto";
import { LayoutDto } from "../../interfaces/LayoutDto";
import {
  ProjectAddressFeatureDto,
  ProjectFoundryDto,
} from "../../modules/sharedLogic/types/api";
import DrawedPlot, {
  GetEmptyDrawedPlot,
} from "../../modules/sharedLogic/types/DrawedPlot";

export type DatacenterGeneratorState = {
  currentPageType: TypeObject | null;
  projects: ProjectFoundryDto[];
  currentProject: ProjectFoundryDto;
  showGridView: boolean;
  projectAddress: string;
  projectAddressFeature: ProjectAddressFeatureDto | null;
  drawedPlot: DrawedPlot;
  startDrawingPlot: boolean;
  editDrawingPlot: boolean;
  deleteDrawingPlot: boolean;
  navigateDrawingPlot: boolean;
  isInputPaneOpen: boolean;
  currentTabPageIndex: number;
  exportTabDisabled: boolean;
  calculateDistance: boolean;
  distanceBetweenPlotAndRoad: number;
  filterControlSelectedAxes: string[];
  filterControlOptionsDictionary: { [key: string]: string };
  filterControlOptionsDictionaryReversed: { [key: string]: string };
  isSaveAvailable: boolean;
  currentLayouts: LayoutDto[];
  currentDatacenters: DatacenterDto[];
  drawControlMode: string;
  colorPalette: ColorPalette | undefined;
  generatedDatacentersIds: { id: number; count: number }[];
};

export type DatacenterGeneratorActions = {
  setShowGridView: (showGridView: boolean) => void;
  setProjects: (projects: ProjectFoundryDto[]) => void;
  setCurrentProject: (currentProject: ProjectFoundryDto) => void;
  setProjectAddress: (projectAdress: string) => void;
  setProjectAddressFeature: (
    projectAddressFeature: ProjectAddressFeatureDto
  ) => void;
  setDrawedPlot: (newValue: DrawedPlot) => void;
  setStartDrawingPlot: (newValue: boolean) => void;
  setEditDrawingPlot: (boolValue: boolean) => void;
  setDeleteDrawingPlot: (boolValue: boolean) => void;
  setNavigateDrawingPlot: (newValue: boolean) => void;
  setIsInputPaneOpen: (boolValue: boolean) => void;
  setCurrentTabPageIndex: (newValue: number) => void;
  setExportTabDisabled: (boolValue: boolean) => void;
  setDistanceBetweenPlotAndRoad: (values: number) => void;
  setCalculateDistance: (values: boolean) => void;
  setFilterControlSelectedAxes: (value: string[]) => void;
  setFilterControlOptionsDictionary: (value: { [key: string]: string }) => void;
  setFilterControlOptionsDictionaryReversed: (value: {
    [key: string]: string;
  }) => void;
  setCurrentPageType: (value: TypeObject | null) => void;
  setIsSaveAvailable: (value: boolean) => void;
  setCurrentLayouts: (value: LayoutDto[]) => void;
  setCurrentDatacenters: (value: DatacenterDto[]) => void;
  setDrawControlMode: (value: string) => void;
  setColorPalette: (colorPalette: ColorPalette) => void;
  resetDatacenterGeneratorState: () => void;
  setGeneratedDatacentersIds: (
    newValue: { id: number; count: number }[]
  ) => void;
};

const initialState: DatacenterGeneratorState = GetInitialState();

function GetInitialState(): DatacenterGeneratorState {
  return {
    currentPageType: null,
    showGridView: false,
    projects: [],
    currentProject: {} as ProjectFoundryDto,
    projectAddress: "",
    projectAddressFeature: null,
    drawedPlot: GetEmptyDrawedPlot(),
    startDrawingPlot: false,
    editDrawingPlot: false,
    deleteDrawingPlot: false,
    navigateDrawingPlot: false,
    isInputPaneOpen: true,
    currentTabPageIndex: 0,
    exportTabDisabled: false,
    distanceBetweenPlotAndRoad: 0,
    calculateDistance: false,
    filterControlSelectedAxes: ["kw_m2"],
    filterControlOptionsDictionary: {
      "kw/m²": "kw_m2",
      "kw/m³": "kw_m3",
      "Cost value": "cost_value",
      "Construction time": "construction_time",
      "Carbon footprint": "carbon_footprint",
    },
    filterControlOptionsDictionaryReversed: {
      kw_m2: "kw/m²",
      kw_m3: "kw/m³",
      cost_value: "Cost value",
      construction_time: "Construction time",
      carbon_footprint: "Carbon footprint",
    },
    isSaveAvailable: true,
    drawControlMode: "",
    currentLayouts: [] as LayoutDto[],
    currentDatacenters: [] as DatacenterDto[],
    colorPalette: undefined,
    generatedDatacentersIds: [],
  };
}
const useDatacenterGeneratorStore = create<
  DatacenterGeneratorState & DatacenterGeneratorActions
>()((set) => ({
  ...initialState,
  setShowGridView: (newValue) => set({ showGridView: newValue }),
  setProjects: (newValue) => set({ projects: newValue }),
  setCurrentProject: (newValue) => set({ currentProject: newValue }),
  setProjectAddress: (newProjectAdress) =>
    set({ projectAddress: newProjectAdress }),
  setProjectAddressFeature: (feature) =>
    set({
      projectAddressFeature: feature,
    }),
  setDrawedPlot: (newValue) => set({ drawedPlot: newValue }),
  setStartDrawingPlot: (newValue) => set({ startDrawingPlot: newValue }),
  setEditDrawingPlot: (newValue) => set({ editDrawingPlot: newValue }),
  setDeleteDrawingPlot: (newValue) => set({ deleteDrawingPlot: newValue }),
  setNavigateDrawingPlot: (newValue) => set({ navigateDrawingPlot: newValue }),
  setIsInputPaneOpen: (newValue) => set({ isInputPaneOpen: newValue }),
  setCurrentTabPageIndex: (newValue) => set({ currentTabPageIndex: newValue }),
  setExportTabDisabled: (newValue) => set({ exportTabDisabled: newValue }),
  setDistanceBetweenPlotAndRoad: (newValue) =>
    set({ distanceBetweenPlotAndRoad: newValue }),
  setCalculateDistance: (newValue) => set({ calculateDistance: newValue }),
  setFilterControlSelectedAxes: (newValue) =>
    set({ filterControlSelectedAxes: newValue }),
  setFilterControlOptionsDictionary: (newValue) =>
    set({ filterControlOptionsDictionary: newValue }),
  setFilterControlOptionsDictionaryReversed: (newValue) =>
    set({ filterControlOptionsDictionaryReversed: newValue }),
  setIsSaveAvailable: (newValue) => set({ isSaveAvailable: newValue }),
  setCurrentPageType: (value: TypeObject | null) =>
    set({ currentPageType: value }),
  setCurrentLayouts: (value: LayoutDto[]) => set({ currentLayouts: value }),
  setCurrentDatacenters: (value: DatacenterDto[]) =>
    set({ currentDatacenters: value }),
  setDrawControlMode: (value: string) => set({ drawControlMode: value }),
  setColorPalette: (colorPalette: ColorPalette) =>
    set({ colorPalette: colorPalette }),
  resetDatacenterGeneratorState: () => {
    set(GetInitialState());
  },
  setGeneratedDatacentersIds: (newValue) =>
    set({ generatedDatacentersIds: newValue }),
}));

export default useDatacenterGeneratorStore;
