import { OrbitControls } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import React, { memo, useEffect, useState } from "react";
import * as THREE from "three";
import { GetColorPalette } from "../../../http/foundry/ColorPaletteService";
import {
  GetLayout,
  UpdateDatacenterLayoutWithWhitespaceLSR,
} from "../../../http/foundry/GeometryService";
import { TypeObject } from "../../../interfaces/enums/TypeObjectEnum";
import { DatacenterDto } from "../../../interfaces/foundry/DatacenterDto";
import { LayoutDataDto, LayoutDto } from "../../../interfaces/LayoutDto";
import { TrackException } from "../../../logging/LoggingManager";
import useDatacenterGeneratorStore from "../../../state/DatacenterState/datacenterGeneratorState";
import { TypicalDTO } from "../../../state/ExploreState/ExploreState";
import { getFeaturesForObject } from "../../../utils/HelperFunctions";
import Camera from "./Camera";
import { AxiosError } from "axios";

interface SceneCanvasProps {
  currentObject: TypicalDTO;
  setLoadedCanvas: (loaded: boolean) => void;
}

const SceneCanvas: React.FC<SceneCanvasProps> = memo(
  ({ currentObject, setLoadedCanvas }) => {
    const { colorPalette, setColorPalette } = useDatacenterGeneratorStore();
    const [selectedLayout, setSelectedLayout] = useState<LayoutDto>();
    const meshStandardMaterials: { [key: string]: THREE.MeshStandardMaterial } =
      {};
    const lineBasicMaterials: { [key: string]: THREE.LineBasicMaterial } = {};

    let minZ: number | undefined = 0;

    const BoxComponent: React.FC<{
      layoutData: LayoutDataDto;
      onLoad?: () => void;
    }> = ({ layoutData, onLoad }) => {
      const processedData = mirrorObject();
      const { x, y, z, width, height, length, object, children, rotation } =
        processedData;

      const boxGeometry = new THREE.BoxGeometry(
        Math.abs(width) / 1000,
        height / 1000,
        length / 1000
      );
      boxGeometry.computeBoundingBox();

      useEffect(() => {
        if (onLoad) onLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      function mirrorObject(): LayoutDataDto {
        if (layoutData.mirror === 1) {
          return {
            ...layoutData,
            width: -layoutData.width,
            z: minZ ? layoutData.z + minZ : layoutData.z,
          };
        } else
          return {
            ...layoutData,
            rotation: -layoutData.rotation,
          };
      }

      function validateColor(color: string | undefined) {
        if (/^#[0-9A-F]{6}$/i.test(color ?? "")) return color;
        return "#ffffff";
      }

      function getBasicMaterial(objectType: string): THREE.LineBasicMaterial {
        const features = getFeaturesForObject(colorPalette, object);
        let material = lineBasicMaterials[objectType];

        if (!material && features) {
          material = new THREE.LineBasicMaterial();
          material.color = new THREE.Color(validateColor(features?.line_color));
          material.opacity = features?.opacity ?? 0;
          material.transparent = true;
          material.linewidth = 0;
          material.depthTest = true;
          lineBasicMaterials[objectType] = material;
        }
        return material;
      }

      function getMeshMaterial(objectType: string): THREE.MeshStandardMaterial {
        const features = getFeaturesForObject(colorPalette, object);
        let material = meshStandardMaterials[objectType];

        if (!material && features) {
          material = new THREE.MeshStandardMaterial();
          material.color = new THREE.Color(validateColor(features?.fill_color));
          material.opacity = features?.opacity ?? 0;
          material.transparent = features?.isTransparrent ? true : false;
          material.polygonOffset = features?.isTransparrent;
          material.polygonOffsetFactor = features?.isTransparrent ? 1000000 : 1;
          material.polygonOffsetUnits = features?.isTransparrent ? 1000000 : 1;
          material.depthTest = true;
          meshStandardMaterials[objectType] = material;
        }
        return material;
      }

      return (
        <>
          <group
            position={[x / 1000, -z / 1000, y / 1000]}
            rotation={[0, THREE.MathUtils.degToRad(rotation), 0]}
          >
            {/* Edges */}
            {object !== "cold_alley" &&
              object !== "row" &&
              object !== "hot_alley" && (
                <lineSegments
                  position={[width / 2000, -height / 2000, length / 2000]}
                  material={getBasicMaterial(layoutData.object)}
                >
                  <edgesGeometry args={[boxGeometry]} />
                </lineSegments>
              )}

            {/* Box */}
            {object !== "datacenter" && object !== "datacenter_cooltower" && (
              <mesh
                geometry={boxGeometry}
                position={[width / 2000, -height / 2000, length / 2000]}
                material={getMeshMaterial(layoutData.object)}
              />
            )}
          </group>

          {children.map((child, idx) => (
            <BoxComponent layoutData={child} key={idx} />
          ))}
        </>
      );
    };

    const ThreeBox: React.FC<{
      data: LayoutDataDto;
    }> = ({ data }) => {
      minZ = findMinimumZ(data);
      const [childLoadStatus, setChildLoadStatus] = useState<boolean[]>([]);
      useEffect(() => {
        if (childLoadStatus.every((loaded) => loaded)) {
          setLoadedCanvas(true);
        }
      }, [childLoadStatus]);

      const camera = new THREE.PerspectiveCamera(90, 1.5, 0.1, 10000);
      camera.position.set(0, 20, 20);
      camera.zoom = 100;

      return (
        <Canvas
          shadows
          style={{ background: "#ffffff" }}
          camera={
            currentObject && currentObject.typeObject === TypeObject.Datacenter
              ? undefined
              : camera
          }
        >
          {currentObject &&
          currentObject.typeObject === TypeObject.Datacenter ? (
            <Camera />
          ) : (
            <OrbitControls
              enableDamping
              dampingFactor={0.1}
              rotateSpeed={0.5}
              mouseButtons={{
                RIGHT: THREE.MOUSE.ROTATE,
                MIDDLE: THREE.MOUSE.PAN,
                LEFT: THREE.MOUSE.PAN,
              }}
            />
          )}

          <ambientLight />
          <directionalLight position={[20, 30, 10]}></directionalLight>
          <directionalLight position={[-20, -30, -10]}></directionalLight>

          <group rotation={[Math.PI, Math.PI * 4, 0]}>
            <BoxComponent
              layoutData={data}
              onLoad={() => setChildLoadStatus((prev) => [...prev, true])}
            />
          </group>
        </Canvas>
      );
    };

    useEffect(() => {
      (async () => {
        try {
          setColorPalette(await GetColorPalette());

          if (currentObject) {
            let layout = await GetLayout(currentObject.layout);
            if (currentObject.typeObject === TypeObject.Datacenter) {
              layout = await UpdateDatacenterLayoutWithWhitespaceLSR(
                currentObject as DatacenterDto,
                layout
              );
            }
            setSelectedLayout(layout);
          }
        } catch (ex) {
          TrackException(ex as AxiosError);
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (selectedLayout) return <ThreeBox data={selectedLayout.data}></ThreeBox>;

    function findMinimumZ(LayoutData: LayoutDataDto): number | undefined {
      if (
        LayoutData.children.length === 0 ||
        LayoutData.children[0].children.length === 0
      ) {
        return undefined;
      }

      const filteredChildren = LayoutData.children[0].children.filter(
        (child: { z: number }) => child.z > 0
      );
      if (filteredChildren.length === 0) {
        return undefined;
      }

      const minZ = filteredChildren.reduce(
        (min: { z: number }, child: { z: number }) =>
          child.z < min.z ? child : min,
        filteredChildren[0]
      ).z;
      return minZ;
    }
  }
);

export default SceneCanvas;
