import { FormControl } from "@mui/material";
import { ReactNode } from "react";
import CustomInputLabelWithInfoButton from "../InputLabel/CustomInputLabelWithInfoButton";

interface CustomFormControlProps {
  labelKey: string;
  children: ReactNode; 
}

export default function CustomFormControlWithInfoButton(props: CustomFormControlProps) {
  return (
    <FormControl fullWidth variant="standard" style={{ marginTop: "15px" }}>
      <CustomInputLabelWithInfoButton
        id={props.labelKey + "-label"}
        labelKey={props.labelKey}
      />
      {props.children}
    </FormControl>
  );
}
