import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { SpaceBuildingDto } from "../../types/api";
import "./BestFitRate.scss";
import { ExploreConfigStore } from "../../../sharedLogic/types/ExploreConfig";
import PCGAxesDto from "../../../sharedLogic/types/PCGAxesDto";
import CustomInputLabelWithInfoButton from "../../../../components/InputLabel/CustomInputLabelWithInfoButton";

interface InputPaneProps<T extends SpaceBuildingDto> {
  currentTypicalConfigStore: ExploreConfigStore<T>;
}

export default function BestFitRate<T extends SpaceBuildingDto>({
  currentTypicalConfigStore,
}: InputPaneProps<T>) {
  const { t } = useTranslation();
  const currentState = currentTypicalConfigStore?.currentStore();

  const options: PCGAxesDto[] = Object.values(
    currentTypicalConfigStore?.bestFitOptionsDictionary ?? []
  );

  const getDisplayName = (axis: string) =>
    t(currentTypicalConfigStore.bestFitOptionsDictionaryReversed![axis]);

  const handleChangeBestFitRateOptions = (
    event: SelectChangeEvent<string[]>
  ) => {
    if (!currentState) return;

    const selectedValues: string[] = event.target.value as string[];
    let selectedAxes: PCGAxesDto[] = [];

    if (selectedValues.includes("all")) {
      selectedAxes =
        currentState.selectedAxes.length === options.length ? [] : options;
    } else {
      selectedAxes = selectedValues
        .map((axis) => options.find((opt) => opt.axis === axis))
        .filter((opt): opt is PCGAxesDto => opt !== undefined);
    }

    currentState.setSelectedAxes(selectedAxes);
  };

  return (
    <FormControl
      fullWidth
      variant="standard"
      style={{ marginTop: "20px", marginBottom: "10px" }}
    >
      <CustomInputLabelWithInfoButton
        id="best-fit-rate-label"
        labelKey="Best fit rate"
      />
      <Select
        multiple
        style={{ height: "auto" }}
        labelId="type-best-fit-rate-label"
        id="type-best-fit-rate-select"
        value={currentState.selectedAxes.map((sa) => sa.axis)}
        onChange={handleChangeBestFitRateOptions}
        renderValue={(selected) => (
          <Box
            component={"div"}
            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
          >
            {(selected as string[]).map((axis) => (
              <Chip key={axis} label={getDisplayName(axis)} />
            ))}
          </Box>
        )}
      >
        <MenuItem value="all">
          <ListItemIcon>
            <Checkbox
              checked={
                options.length > 0 &&
                currentState.selectedAxes.length === options.length
              }
              indeterminate={
                currentState.selectedAxes.length > 0 &&
                currentState.selectedAxes.length < options.length
              }
            />
          </ListItemIcon>
          <ListItemText primary={t("Select all")} />
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.axis} value={option.axis}>
            <Checkbox
              checked={currentState?.selectedAxes
                .map((sa) => sa.axis)
                .includes(option.axis)}
            />
            <ListItemText primary={getDisplayName(option.axis)} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
