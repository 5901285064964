import { Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import SliderWithMarks from "../../../../components/SliderWithMarks/SliderWithMarks";
import PercentageInput from "../../../sharedLogic/components/PercentageInput/PercentageInput";
import { UnitType } from "../../../sharedLogic/types/UnitType";
import "./UnitMix.scss";

interface UnitMixProps {
  units: UnitType[];
  showSlider?: boolean;
  editPercentage?: boolean;
  isUsedforInfoCards?: boolean;
  disabled?: boolean;
  setUnits?: (units: UnitType[]) => void;
}

const UnitMix: React.FC<UnitMixProps> = ({
  units,
  editPercentage = true,
  showSlider = true,
  isUsedforInfoCards = false,
  disabled = false,
  setUnits,
}) => {
  const { t } = useTranslation();

  const handlePercentageChange = (index: number, newValue: number | null) => {
    if (!setUnits) return;

    const newUnits = [...units];
    const otherUnitsTotal = newUnits.reduce(
      (sum, unit, i) =>
        i !== index && unit.ratio !== null && unit.ratio !== undefined
          ? sum + unit.ratio
          : sum,
      0
    );

    if (newValue === null) {
      newUnits[index].ratio = undefined;
    } else {
      if (otherUnitsTotal + newValue > 100) {
        newUnits[index].ratio = 100 - otherUnitsTotal;
      } else {
        newUnits[index].ratio = newValue;
      }
    }

    setUnits(newUnits);
  };

  const handleSliderChange = (index: number, newValue: number[]) => {
    const newUnits = [...units];
    newUnits[index].value = newValue;
    if (setUnits) {
      setUnits(newUnits);
    }
  };

  return (
    <Grid
      container
      spacing={0.5}
      alignItems="center"
      sx={{ minWidth: "200px" }}
    >
      {units.map((unit, index) => (
        <Grid
          item
          xs={isUsedforInfoCards ? 3 : 12}
          key={index}
          sx={{ marginRight: "-7px" }}
        >
          <Grid container>
            {unit.count !== undefined && !isUsedforInfoCards && (
              <Grid item xs={2}>
                <span className="unit-mix__count">
                  {Math.round(unit.count)}st
                </span>
              </Grid>
            )}

            {!editPercentage && unit.ratio !== undefined && (
              <Grid
                item
                xs={isUsedforInfoCards ? 12 : 2}
                style={{ display: "flex", alignItems: "center" }}
              >
                {isUsedforInfoCards && (
                  <div
                    className="unit-mix__color-indicator"
                    style={{ backgroundColor: unit.color, marginRight: "5px" }}
                  />
                )}
                <span className="unit-mix__percentage">
                  {unit.ratio === undefined || unit.ratio === null
                    ? ""
                    : `${Math.round(unit.ratio)}%`}
                </span>
              </Grid>
            )}

            {editPercentage && (
              <div className="unit-mix__percentage-input">
                <PercentageInput
                  data-testid={`percentage-input-${index}`}
                  disabled={disabled || !editPercentage}
                  value={unit.ratio}
                  onChange={(value) => handlePercentageChange(index, value)}
                />
              </div>
            )}

            {!isUsedforInfoCards && (
              <Grid item xs>
                <div className="unit-mix__name-container">
                  <div
                    className="unit-mix__color-indicator"
                    style={{ backgroundColor: unit.color, marginRight: "5px" }}
                  />
                  <Typography
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: "1",
                      WebkitBoxOrient: "vertical",
                    }}
                    variant="body2"
                    className="unit-mix__item-name"
                  >
                    {t(unit.name)}
                  </Typography>
                </div>
              </Grid>
            )}

            {showSlider && (
              <div className="unit-mix__slider-container">
                <SliderWithMarks
                  value={unit.value}
                  min={unit.minValue}
                  max={unit.maxValue}
                  disabled={disabled}
                  setValue={(value) => handleSliderChange(index, value)}
                  aria-labelledby={`${unit.name}-slider`}
                />
              </div>
            )}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default UnitMix;
