import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import {
  Button,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List, ListChildComponentProps } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import FormControlTextField from "../../../../../components/CustomFormControl/FormControlTextField";
import CustomInputLabel from "../../../../../components/InputLabel/CustomInputLabel";
import LoadingIndicator from "../../../../../components/LoadingIndicator/LoadingIndicator";
import ParallelCoordinatesPlot from "../../../../../components/ParallelCoordinatesPlot";
import HoverContext from "../../../../../components/Scene/HoverContext";
import Scene from "../../../../../components/Scene/Scene";
import SceneDetail from "../../../../../components/Scene/SceneDetail/SceneDetail";
import { ArrowDown } from "../../../../../icons/ArrowDownIcon";
import { ArrowUp } from "../../../../../icons/ArrowUpIcon";
import { TypeObject } from "../../../../../interfaces/enums/TypeObjectEnum";
import { ExploreConfigStore } from "../../../../../interfaces/exploreConfig";
import { DatacenterDto } from "../../../../../interfaces/foundry/DatacenterDto";
import { SelectedBuildingsDto } from "../../../../../interfaces/foundry/SelectedBuildingsDto";
import SummaryInfoDto from "../../../../../interfaces/SummaryInfoDto";
import useDatacenterGeneratorStore from "../../../../../state/DatacenterState/datacenterGeneratorState";
import useInputPaneStore from "../../../../../state/DatacenterState/inputPaneState";
import {
  DTO,
  ExploreActions,
  ExploreState,
} from "../../../../../state/ExploreState/ExploreState";
import { theme } from "../../../../../utils/HelperFunctions";
import AddToSetDialog from "../DcAddToSetDialog";
import { LoadItems } from "./ExploreHelper";
import "./ExploreView.scss";
import useUserStore from "../../../../../modules/sharedLogic/state/userState";
import ClickableAxesDto from "../../../../../interfaces/ClickableAxisDto";
import useProjectsStore from "../../../../../modules/sharedLogic/state/projectsState";

interface ExploreViewProps<T extends DTO> {
  type: TypeObject;
  summaryInfo: SummaryInfoDto[];
  currentState: ExploreState<T> & ExploreActions<T>;
  sortByOptionsDictionary: { [key: string]: string };
  displayParallelCoordinatesPlot?: boolean;
  dimensions?: string[];
  chartLabels?: string[];
  clickableAxes?: ClickableAxesDto[];
  filterControlOptionsDictionary?: {
    [key: string]: string;
  };
  filterControlOptionsDictionaryReversed?: {
    [key: string]: string;
  };
  handleCustomFilter: () => T[];
  handleObjectFilters: (objects: T[]) => T[];
  orderObjects: (
    objects: T[],
    sortProperty: string,
    isSortOrderAscending: boolean
  ) => void;
  datacenterConfigStore?: ExploreConfigStore<DatacenterDto>;
  notAllowedToUpdate?: boolean;
}

export default function ExploreView<T extends DTO>({
  type,
  summaryInfo,
  currentState,
  sortByOptionsDictionary,
  displayParallelCoordinatesPlot = true,
  dimensions,
  chartLabels,
  clickableAxes,
  orderObjects,
  handleCustomFilter,
  handleObjectFilters,
  datacenterConfigStore,
  notAllowedToUpdate = false,
}: ExploreViewProps<T>) {
  const { t } = useTranslation();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const rafRef = useRef<number | null>(null);
  const { appAbility } = useUserStore();
  const {
    setObjects: setDatacenters,
    setSelectedObject: setSelectedDatacenter,
  } = datacenterConfigStore?.currentStore() || {};

  const { selectedIds: selectedTypicalIds } = currentState;
  const {
    currentProject,
    currentPageType,
    isInputPaneOpen,
    drawedPlot,
    setCurrentProject,
  } = useDatacenterGeneratorStore();

  const {
    powerWhitespaces,
    powerRack,
    coolingPrinciple,
    tranfoType,
    buildingType,
    includeGenset,
    includeRedNsa,
    selectedDistributionTypes,
    powerLSRs,
    hasMultipleTransformators,
    autonomyInMin,
    destinationPlan,
    typeDataCentre,
    desiredITCapability,
    maxBuildHeight,
    adminBuilding,
    waterTreatment,
    sprinklerBasin,
    purchasingStation,
    expansionOnCampus,
    selectedBatteryTypes,
  } = useInputPaneStore();

  const { currentProject: currentPlanningProject } = useProjectsStore();

  const [hoverdObject, setHoveredObject] = useState<T | null>(null);
  const [addToSetOpen, setAddToSetOpen] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);

  useEffect(() => {
    if (currentState.objects !== null) return;

    const abortController = new AbortController();
    const signal = abortController.signal;

    (async () => {
      await LoadItems({
        signal,
        currentState,
        currentProject,
        type,
        drawedPlot,
        setIsSidebarOpen,
        handleObjectFilters,
        t,
      });
    })();

    return () => {
      if (rafRef.current !== null) {
        cancelAnimationFrame(rafRef.current);
      }

      abortController.abort();
    };
  }, [currentState.objects]);

  useEffect(() => {
    if (type === TypeObject.MapView && setDatacenters) {
      setDatacenters(null);
    }
  }, [drawedPlot.buildableSurface, setDatacenters, type]);

  useEffect(() => {
    if (currentState?.objects !== null) {
      setIsLoaded(true);
      handleObjectFilters(currentState?.objects ?? []);

      if (isLoaded) {
        resetSelectedItemsGraph();
      }
    }
  }, [
    powerWhitespaces,
    powerRack,
    typeDataCentre,
    coolingPrinciple,
    currentState?.objects,
    currentState?.isReset,
    destinationPlan,
    desiredITCapability,
    tranfoType,
    buildingType,
    includeGenset,
    includeRedNsa,
    selectedDistributionTypes,
    powerLSRs,
    hasMultipleTransformators,
    autonomyInMin,
    maxBuildHeight,
    adminBuilding,
    waterTreatment,
    sprinklerBasin,
    expansionOnCampus,
    purchasingStation,
    selectedBatteryTypes,
    drawedPlot.buildableSurface,
  ]);

  useEffect(() => {
    if (currentState?.objects !== null) {
      setIsLoaded(true);
      handleObjectFilters(currentState?.objects ?? []);

      if (isLoaded) {
        resetSelectedItemsGraph();
      }
    }
  }, []);

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleBrushObjects = useCallback(
    (data: T[], sortProperty: string, isSortOrderAscending: boolean) => {
      currentState?.setBrushedObjects(data);
      setHasMoreData(true);
      orderObjects(data, sortProperty, isSortOrderAscending);
    },
    []
  );

  function resetDatacenters() {
    if (
      setDatacenters &&
      (type === TypeObject.Whitespace || type === TypeObject.LowVoltageRoom)
    ) {
      setDatacenters(null);
    }
  }

  const handleObjectClick = (selectedObject: T) => {
    resetDatacenters();
    setIsSidebarOpen(true);
    if (type === TypeObject.Datacenter && setSelectedDatacenter) {
      setSelectedDatacenter(selectedObject as DatacenterDto);
    }

    let selectedIds = [(selectedObject as DatacenterDto).id];
    if (selectedTypicalIds.length > 0) {
      const isAlreadyClicked = selectedTypicalIds.some(
        (id) => id === (selectedObject as DatacenterDto).id
      );
      selectedIds = isAlreadyClicked
        ? selectedTypicalIds.filter(
            (item) => item !== (selectedObject as DatacenterDto).id
          )
        : [...selectedTypicalIds, ...[(selectedObject as DatacenterDto).id]];
    }
    currentState?.setSelectedIds(selectedIds);
  };

  useEffect(() => {
    if (
      type === TypeObject.NitrogenAnalyser &&
      currentPlanningProject?.configuration
    ) {
      const selectedScenarioIds = JSON.parse(
        currentPlanningProject?.configuration
      ).selectedScenarioIds;
      currentState?.setSelectedIds(selectedScenarioIds ?? []);
    }
  }, []);

  const removeCandidateObject = (selectedObject: T) => {
    resetDatacenters();
    if (type === TypeObject.Datacenter && setSelectedDatacenter) {
      setSelectedDatacenter(null);
    }

    currentState?.setSelectedIds(
      selectedTypicalIds.filter(
        (id) => id !== (selectedObject as DatacenterDto).id
      )
    );
  };

  const objectHover = (hoveredData: T | null) => {
    setHoveredObject(hoveredData);
  };

  const handleReset = () => {
    currentState?.setIsReset(!currentState?.isReset);
    setHasMoreData(true);
    resetSelectedItemsGraph();
  };

  function resetSelectedItemsGraph() {
    const selectedBuildings: SelectedBuildingsDto = currentProject.buildings
      ? JSON.parse(currentProject.buildings)
      : ({} as SelectedBuildingsDto);

    switch (currentPageType) {
      case TypeObject.LowVoltageRoom:
        selectedBuildings.selectedItemsLVRGraph = [];
        break;
      case TypeObject.Datacenter:
        selectedBuildings.selectedItemsDatacenterGraph = [];
        break;
      case TypeObject.Whitespace:
        selectedBuildings.selectedItemsWhitespaceGraph = [];
        break;
      case TypeObject.Export:
        selectedBuildings.selectedItemsDatacenterGraph = [];
        break;
      default:
        break;
    }

    setCurrentProject({
      ...currentProject,
      buildings: JSON.stringify(selectedBuildings),
    });
  }

  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isMd = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLg = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isXl = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isXxl = useMediaQuery(theme.breakpoints.up("xl"));
  let itemsPerRow = 1;
  if (isXs) {
    itemsPerRow = 1;
  } else if (isSm) {
    itemsPerRow = 3 - (isSidebarOpen ? 1 : 0) - (isInputPaneOpen ? 1 : 0);
  } else if (isMd) {
    itemsPerRow = 4 - (isSidebarOpen ? 1 : 0) - (isInputPaneOpen ? 1 : 0);
  } else if (isLg) {
    itemsPerRow = 5 - (isSidebarOpen ? 1 : 0) - (isInputPaneOpen ? 1 : 0);
  } else if (isXl) {
    itemsPerRow = 8 - (isSidebarOpen ? 1 : 0) - (isInputPaneOpen ? 1 : 0);
  } else if (isXxl) {
    itemsPerRow = 12; // Example for xl screens
  }

  function handleMouseLeave() {
    setHoveredObject(null);
  }
  const Row = React.memo(({ index, style }: ListChildComponentProps) => {
    // Customizing the theme to include xxs and xxl breakpoints
    const startIndex = index * itemsPerRow;
    const itemsForRow = currentState?.loadedObjects.slice(
      startIndex,
      startIndex + itemsPerRow
    );
    return (
      <div style={style}>
        <Grid container>
          {itemsForRow.map((data) => (
            <Grid
              item
              xs={12 / itemsPerRow} // For xs screens
              sm={12 / itemsPerRow} // For sm screens
              md={12 / itemsPerRow} // For md screens
              lg={12 / itemsPerRow} // For lg screens
              xl={12 / itemsPerRow} // For xl screens
              key={data.id}
              onMouseEnter={() => objectHover(data)}
              onMouseLeave={() => handleMouseLeave()}
            >
              <Scene
                notAllowedToUpdate={notAllowedToUpdate}
                currentState={currentState}
                currentObject={data}
                summaryInfo={summaryInfo}
                onClickObject={handleObjectClick}
                isCandidate={selectedTypicalIds.includes(data.id)}
                isInOriginalList={true}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    );
  });

  const isItemLoaded = (index: number) => {
    return index < Math.ceil(currentState?.loadedObjects.length / itemsPerRow);
  };

  const loadMoreItems = (
    startIndex: number,
    stopIndex: number
  ): Promise<void> => {
    return new Promise((resolve) => {
      if (!hasMoreData) {
        resolve();
        return;
      }
      const actualStart = (startIndex + 1) * itemsPerRow;
      const actualEnd = (stopIndex + 1) * itemsPerRow;

      const tempFilteredObjects = handleCustomFilter();
      if (tempFilteredObjects && tempFilteredObjects.length === 0) {
        currentState?.setLoadedObjects([]);
        resolve();
        return;
      }

      const nextBatch = currentState?.brushedObjects.slice(
        actualStart,
        actualEnd
      );

      if (nextBatch.length > 0) {
        currentState?.setLoadedObjects([
          ...currentState?.loadedObjects,
          ...nextBatch,
        ]);
        setHasMoreData(
          currentState?.loadedObjects.length + nextBatch.length !==
            currentState?.loadedObjects.length
        );

        resolve();
      } else {
        setHasMoreData(false);
        resolve();
      }
    });
  };

  useEffect(() => {
    orderObjects(
      currentState?.brushedObjects,
      currentState?.objectSortProperty,
      currentState?.objectSortAscending
    );
  }, [
    currentState?.objectSortProperty,
    currentState?.objectSortAscending,
    currentState?.selectedAxes,
  ]);

  if (currentState?.objects === null) {
    return (
      <div className="loading-indicator-base-select-object">
        <LoadingIndicator />
      </div>
    );
  } else {
    return (
      <HoverContext.Provider
        value={{ hovered: hoverdObject, setHovered: setHoveredObject }}
      >
        <div className="base-select-object-view-container">
          <div className="graph-view">
            <div className="reset-sidebar">
              <h5>
                <FormControlTextField
                  labelText={t("Configurations total")}
                  value={currentState?.objectsTotalCount}
                />
              </h5>
              <h5>
                <FormControlTextField
                  labelText={t("Filtered")}
                  value={currentState?.brushedObjects?.length}
                />
              </h5>

              <Button
                className="button"
                variant="contained"
                color="secondary"
                onClick={handleReset}
              >
                {t("Reset")}
              </Button>
            </div>
            {displayParallelCoordinatesPlot && (
              <div className="graph">
                <ParallelCoordinatesPlot
                  currentState={currentState}
                  dimensions={dimensions ?? []}
                  chartLabels={chartLabels ?? []}
                  clickableAxes={clickableAxes ?? []}
                  onBrushObjects={handleBrushObjects}
                />
              </div>
            )}
            <div
              className={
                isSidebarOpen ? "filter-view-open" : "filter-view-closed"
              }
            >
              <FormControl
                style={{ height: "auto", minWidth: "180px", maxWidth: "220px" }}
                variant="standard"
              >
                <CustomInputLabel
                  id="type-datacentre-label"
                  showInfoButton={false}
                  labelText={t("Sort by")}
                  tooltipText={t("Sort by")}
                />
                <Select
                  id="type-sort-by-select"
                  value={currentState?.objectSortProperty}
                  onChange={(event) =>
                    currentState?.setObjectSortProperty(event.target.value)
                  }
                >
                  {Object.keys(sortByOptionsDictionary).map((name) => (
                    <MenuItem key={name} value={name}>
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className="center-center mt">
                <IconButton
                  onClick={() =>
                    currentState?.setObjectSortAscending(
                      !currentState?.objectSortAscending
                    )
                  }
                >
                  {currentState?.objectSortAscending ? (
                    <ArrowUp />
                  ) : (
                    <ArrowDown />
                  )}
                </IconButton>
              </div>

              <div className="full-width" />
              {type !== TypeObject.Datacenter && (
                <Button
                  disabled={notAllowedToUpdate}
                  sx={{
                    width: "auto",
                    minWidth: "100px",
                    marginLeft: "10px",
                    marginTop: "20px",
                  }}
                  className="button"
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    const mergedObjects = [
                      ...selectedTypicalIds,
                      ...currentState?.brushedObjects.map((x) => x.id),
                    ];
                    const uniqueObjectsSet = new Set(mergedObjects);
                    currentState?.setSelectedIds(Array.from(uniqueObjectsSet));
                    resetDatacenters();
                    setIsSidebarOpen(true);
                  }}
                >
                  {t("Select all")}
                </Button>
              )}
            </div>
          </div>
          <div className="scene-view">
            <IconButton
              style={{
                zIndex: 100,
                position: "absolute",
                marginRight: "-10px",
                right: "0px",
              }}
              onClick={handleSidebarToggle}
            >
              <ChevronLeft sx={{ fontSize: "2.5rem" }} />{" "}
            </IconButton>

            <div
              className={
                isSidebarOpen
                  ? "infinite-scroll-view-open"
                  : "infinite-scroll-view-closed"
              }
            >
              {currentState?.loadedObjects ? (
                <AutoSizer>
                  {({ height, width }) => (
                    <InfiniteLoader
                      isItemLoaded={isItemLoaded}
                      itemCount={
                        currentState?.brushedObjects.length / itemsPerRow
                      }
                      loadMoreItems={loadMoreItems}
                    >
                      {({ onItemsRendered, ref }) => (
                        <List
                          ref={ref}
                          width={width}
                          itemSize={140}
                          onItemsRendered={onItemsRendered}
                          itemCount={Math.ceil(
                            currentState?.brushedObjects.length / itemsPerRow
                          )}
                          height={height}
                        >
                          {Row}
                        </List>
                      )}
                    </InfiniteLoader>
                  )}
                </AutoSizer>
              ) : (
                <div className="empty-conf">
                  {t("There are no configuration left!")}
                </div>
              )}
            </div>

            <Drawer
              variant="persistent"
              anchor="right"
              open={isSidebarOpen}
              onClose={handleSidebarToggle}
              PaperProps={{
                style: {
                  width: "410px",
                  backgroundColor: "none",
                  marginLeft: "5px",
                  marginTop: "0px",
                  position: "absolute",
                },
              }}
            >
              <div className="sidebar-view">
                <div className="sidebar-button">
                  <IconButton
                    style={{
                      marginLeft: "0px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                    onClick={handleSidebarToggle}
                  >
                    <ChevronRight sx={{ fontSize: "2.5rem" }} />{" "}
                  </IconButton>
                </div>
                <div className="title sidebar-title">{t("Selected items")}</div>
                {(currentPageType === TypeObject.LowVoltageRoomTooling ||
                  currentPageType === TypeObject.WhitespaceTooling) && (
                  <div className="sidebar-add-to-set">
                    <Button
                      disabled={notAllowedToUpdate}
                      className="button"
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        setAddToSetOpen(true);
                      }}
                    >
                      {t("Add to set")}
                    </Button>
                  </div>
                )}

                <div className="sidebar-reset-button">
                  <Button
                    disabled={notAllowedToUpdate}
                    className="button"
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      if (
                        type === TypeObject.Datacenter &&
                        setSelectedDatacenter
                      ) {
                        setSelectedDatacenter(null);
                      }
                      currentState?.setSelectedIds([]);
                      currentState?.setFilterOnAxes([]);
                      resetDatacenters();
                    }}
                  >
                    {t("Reset")}
                  </Button>
                </div>
              </div>
              <div className="sidebar-selected">
                {selectedTypicalIds?.length > 0 ? (
                  selectedTypicalIds.map((id) => (
                    <Grid item xl={2} key={id}>
                      <Scene
                        currentState={currentState}
                        currentObject={
                          (currentState?.objects ?? []).find(
                            (x) => x.id === id
                          )!
                        }
                        summaryInfo={summaryInfo}
                        onHoverObject={objectHover}
                        onClickObject={removeCandidateObject}
                        isCandidate={selectedTypicalIds.includes(id)}
                        isInOriginalList={false}
                        notAllowedToUpdate={notAllowedToUpdate}
                      />
                    </Grid>
                  ))
                ) : (
                  <div className="empty-conf">
                    {currentPageType === TypeObject.Whitespace
                      ? t("No whitespaces selected")
                      : currentPageType === TypeObject.LowVoltageRoom
                      ? t("No low voltage rooms selected")
                      : t("No datacenters selected")}
                  </div>
                )}
              </div>
            </Drawer>
          </div>
          <SceneDetail currentState={currentState} />
          <AddToSetDialog
            currentState={currentState}
            open={addToSetOpen}
            onClose={() => setAddToSetOpen(false)}
            onSave={() => setAddToSetOpen(false)}
          />
        </div>
      </HoverContext.Provider>
    );
  }
}
