import { Input, InputAdornment, InputProps } from "@mui/material";
import React, { useRef } from "react";

interface PercentageInputProps extends Omit<InputProps, "onChange"> {
  value?: number | null;
  dataTestId?: string;
  showEndAdornment?: boolean;
  onChange: (value: number | null) => void;
}

const PercentageInput: React.FC<PercentageInputProps> = ({
  value,
  dataTestId,
  onChange,
  showEndAdornment = true,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (!/^\d*$/.test(inputValue) && inputValue !== "") {
      return;
    }

    if (inputValue === "") {
      onChange(null);
      return;
    }

    const cleanedValue = inputValue.replace(/^0+(?=\d)/, "");
    const newValue = Number(cleanedValue);

    if (!isNaN(newValue) && newValue <= 100) {
      onChange(newValue);
    }
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setTimeout(() => {
      if (inputRef.current) {
        const length = inputRef.current.value.length;
        inputRef.current.setSelectionRange(length, length);
      }
    }, 0);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      !/[\d]/.test(e.key) &&
      e.key !== "Backspace" &&
      e.key !== "Delete" &&
      e.key !== "ArrowLeft" &&
      e.key !== "ArrowRight"
    ) {
      e.preventDefault();
    }
  };

  return (
    <Input
      inputRef={inputRef}
      sx={{
        border: "1px solid #acacac",
        marginTop: "2px",
        maxWidth: "40px",
        padding: "0px 5px",
        borderRadius: "10px",
        marginRight: "5px",
        "& .MuiInputBase-root:before": {
          borderBottomStyle: "none",
        },
        "& .MuiInputBase-root": {
          borderBottomStyle: "none !important",
        },
      }}
      type="text"
      value={value}
      onChange={handleChange}
      onFocus={handleFocus}
      onKeyDown={handleKeyPress}
      inputProps={{
        "data-testid": dataTestId,
        inputMode: "numeric",
        style: {
          textAlign: "center",
          paddingRight: showEndAdornment ? "5px" : "0px",
        },
        pattern: "[0-9]*",
      }}
      disableUnderline={true}
      endAdornment={
        showEndAdornment ? (
          <div style={{ position: "relative" }}>
            <InputAdornment
              sx={{
                position: "absolute",
                top: -0.5,
                left: -15,
                color: "black",
              }}
              position="end"
            >
              %
            </InputAdornment>
          </div>
        ) : null
      }
      {...props}
    />
  );
};

export default PercentageInput;
