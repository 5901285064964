import Checkbox from "@mui/material/Checkbox";
import { GridColDef } from "@mui/x-data-grid";
import { useEffect } from "react";
import { GetCategories } from "../../../../http/aggregate/CategoriesService";
import {
  GetEquipmentKeyFigures,
  GetProductionKeyFigure,
} from "../../../../http/aggregate/KeyFigureService";
import { AggregatePageEnum } from "../../../../interfaces/aggregate/enums/AggregatePageEnum";
import { TypeObject } from "../../../../interfaces/enums/TypeObjectEnum";
import useDatabaseStore from "../../../../state/DatabaseState/databaseState";
import useEquipmentKeyFiguresStore from "../../../../state/DatabaseState/equipmentKeyFiguresState";
import useNitrogenStore from "../../../../state/NitrogenState/nitrogenState";
import {
  BaseDatabasePage,
  groupBy,
} from "../../../../pages/Databases/BaseDatabasePage";
import {
  GridColDefComponent,
  useColumnGroupingModel,
} from "../../../../pages/Databases/EquipmentKeyFigures/EquipmentColumnsDef";
import { BaseKeyFigureDto } from "../../../../interfaces/aggregate/BaseKeyFigureDto";
import { EquipmentKeyFigureDto } from "../../../../interfaces/aggregate/EquipmentKeyFigureDto";

interface EquipmentKeyFiguresTableProps {
  pageType?: TypeObject;
  notAllowedToUpdate?: boolean;
}

export function EquipmentKeyFiguresTable({
  pageType,
  notAllowedToUpdate = false,
}: EquipmentKeyFiguresTableProps) {
  const { equipmentKeyFigures, setEquipmentKeyFigures } =
    useEquipmentKeyFiguresStore();

  const { setCategories } = useDatabaseStore();

  const {
    activities,
    selectedActivity,
    currentSelectedEquipments,
    setActivities,
    setCurrentSelectedEquipments,
  } = useNitrogenStore();

  useEffect(() => {
    if (selectedActivity) {
      setCurrentSelectedEquipments(selectedActivity.selectedEquipments);
    }
  }, [selectedActivity]);

  const equipmentKeyFiguresCache: Record<string, EquipmentKeyFigureDto[]> = {};

  const extraFields: GridColDef[] = [
    {
      field: "selection",
      headerName: "",
      width: 50,
      renderCell: (params: { row: { id: { toString: () => string } } }) => {
        return (
          <Checkbox
            disabled={notAllowedToUpdate}
            style={{ width: "30px", height: "30px", alignItems: "center" }}
            checked={
              currentSelectedEquipments?.includes(params.row.id.toString()) ??
              false
            }
            onChange={(event) => {
              //update the selectedEquipments array of the selectedActivity
              if (selectedActivity) {
                const updatedSelectedEquipments: string[] = event.target.checked
                  ? currentSelectedEquipments
                    ? [...currentSelectedEquipments, params.row.id.toString()]
                    : [params.row.id.toString()]
                  : (currentSelectedEquipments?.filter(
                      (x) => x !== params.row.id.toString()
                    ) as string[]);

                setCurrentSelectedEquipments(updatedSelectedEquipments);

                const updatedActivities = activities.map((activity) =>
                  activity.id === selectedActivity.id
                    ? {
                        ...activity,
                        selectedEquipments: updatedSelectedEquipments,
                      }
                    : activity
                );
                setActivities(updatedActivities);
              }
            }}
          />
        );
      },
    },
  ];

  const baseColumns: GridColDef[] = GridColDefComponent(pageType);
  const columns = extraFields.concat(baseColumns);

  const filteredColumns =
    pageType !== TypeObject.NitrogenAnalyser
      ? columns.filter((col) => col.field !== "selection")
      : columns;

  const columnGroupingModel = useColumnGroupingModel();

  useEffect(() => {
    (async () => {
      setCategories(await GetCategories(AggregatePageEnum.EquipmentKeyFigure));
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await LoadEquipmentKeyFigures();
    })();
  }, [selectedActivity]);

  async function LoadEquipmentKeyFigures() {
    var productionKeyFigure = selectedActivity?.productionKeyFigure;
    if (!productionKeyFigure && selectedActivity?.productionKeyFigureId) {
      productionKeyFigure = await GetProductionKeyFigure(
        selectedActivity?.productionKeyFigureId
      );
    }

    if (productionKeyFigure?.equipmentType.id) {
      const equipmentTypeId = productionKeyFigure.equipmentType.id.toString();
      if (equipmentKeyFiguresCache[equipmentTypeId]) {
        setEquipmentKeyFigures(equipmentKeyFiguresCache[equipmentTypeId]);
      } else {
        const keyFigures = await GetEquipmentKeyFigures(
          undefined,
          equipmentTypeId
        );
        equipmentKeyFiguresCache[equipmentTypeId] = keyFigures;
        setEquipmentKeyFigures(keyFigures);
      }
    } else if (selectedActivity?.name && selectedActivity?.type !== "project") {
      setEquipmentKeyFigures(await GetEquipmentKeyFigures());
    } else {
      setEquipmentKeyFigures([]);
    }
  }

  const processedData =
    pageType !== TypeObject.NitrogenAnalyser
      ? groupBy(equipmentKeyFigures, (item) => item.description)
      : equipmentKeyFigures.reduce((acc, item) => {
          acc[item.id] = [item];
          return acc;
        }, {} as { [key: string]: BaseKeyFigureDto[] });

  return (
    <BaseDatabasePage
      pageType={pageType}
      columns={filteredColumns}
      data={processedData}
      columnGroupingModel={columnGroupingModel}
      addNewItem={() => {}}
      handleCancel={() => {}}
      handleDelete={() => {}}
    />
  );
}
