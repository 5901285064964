import { BaseTypicalDto } from "./BaseTypicalDto";
import { TypeObject } from "../../enums/TypeObjectEnum";
import { BuildingTypeDto } from "../BuildingTypeDto";

export interface UpsDto extends BaseTypicalDto {
  id: number;
  power_in_kva: number;
  auto_incoming: number;
  auto_outgoing: number;
  heatload_in_w: number;
  efficiency: number;
  type: string;
  display_name: string;
  supplier_name: string;
  supplier_component_type: string;
  typical_types: BuildingTypeDto[];
  typeObject: TypeObject;
  co2_footprint_in_euros: number;
}

export function GetEmptyUpsDto(): UpsDto {
  return {
    id: 0,
    length: 0,
    width: 0,
    height: 0,
    supplier: 1,
    power_in_kva: 0,
    efficiency: 0,
    auto_incoming: 0,
    auto_outgoing: 0,
    heatload_in_w: 0,
    type: "test",
    co2_footprint_in_euros: 0
  } as UpsDto;
}
