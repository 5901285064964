import AddIcon from "@mui/icons-material/Add";
import { Button, Grid, IconButton, Popover, Typography } from "@mui/material";
import { GridColDef, GridEventListener } from "@mui/x-data-grid";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LoadingIndicator from "../../../components/LoadingIndicator/LoadingIndicator";
import StyledDataGrid from "../../../components/StyledDataGrid/StyledDataGrid";
import {
  DeleteProject,
  GetProjects,
} from "../../../http/foundry/ProjectService";
import { EllipseIcon } from "../../../icons/EllipseIcon";
import { TrackException } from "../../../logging/LoggingManager";
import useUserStore from "../../../modules/sharedLogic/state/userState";
import { ProjectFoundryDto } from "../../../modules/sharedLogic/types/api";
import useDatacenterGeneratorStore from "../../../state/DatacenterState/datacenterGeneratorState";
import useLayoutStore from "../../../modules/sharedLogic/state/layoutState";
import { GenerateGuid } from "../../../utils/HelperFunctions";
import CreateOrUpdateProjectView from "./components/CreateOrUpdateProjectView/CreateOrUpdateProjectView";
import ProjectCard from "./components/ProjectCard/ProjectCard";
import "./DatacenterProjectsPage.scss";
import { AxiosError } from "axios";

export default function DatacenterGeneratorProjectsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setShowBreadcrumb } = useLayoutStore();
  const {
    showGridView,
    projects,
    currentProject,
    setCurrentProject,
    setProjects,
    setShowGridView,
  } = useDatacenterGeneratorStore();

  const { appAbility } = useUserStore();

  const [isProjectViewOpen, setIsProjectViewOpen] =
    React.useState<boolean>(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [popOverOpen, setPopOverOpen] = React.useState<boolean>(false);
  const [refreshData, setRefreshData] = React.useState<boolean>(false);
  const [showLoadingIndicator, setShowLoadingIndicator] =
    React.useState<boolean>(false);

  const handleRenamingProject = (project: ProjectFoundryDto) => {
    setPopOverOpen(false);
    setCurrentProject(project);
    setIsProjectViewOpen(true);
  };

  const handleSharingProject = (project: ProjectFoundryDto) => {
    //TODO
  };

  const handleDeletingProject = (project: ProjectFoundryDto) => {
    try {
      (async () => {
        await DeleteProject(project);
        setRefreshData(true);
      })();
    } catch (ex) {
      TrackException(ex as AxiosError);
    }
  };

  const columns: GridColDef[] = [
    { field: "name", headerName: t("Name"), flex: 1 },
    {
      field: "buttons",
      width: 75,
      headerName: "",
      renderCell: ({ row }) => (
        <div>
          <IconButton
            style={{
              display: "flex",
              marginRight: "-20px",
              placeItems: "center end",
            }}
            aria-describedby={popOverOpen ? "simple-popover" : undefined}
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
              setPopOverOpen(true);
            }}
          >
            <EllipseIcon className="icon" size={25} />
          </IconButton>
          <Popover
            open={popOverOpen}
            anchorEl={anchorEl}
            onClose={() => {
              setPopOverOpen(false);
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              ".MuiPaper-root": {
                boxShadow:
                  "0px 5px 5px -3px rgba(0,0,0,0), 0px 8px 10px 1px rgba(0,0,0,0), 0px 3px 14px 2px rgba(0,0,0,0.05)",
              },
            }}
          >
            <Typography
              style={{ cursor: "pointer" }}
              sx={{ p: 2 }}
              onClick={() => {
                handleRenamingProject(currentProject);
              }}
            >
              {t("Rename")}
            </Typography>

            <Typography
              style={{ cursor: "pointer" }}
              sx={{ p: 2 }}
              onClick={() => {
                handleDeletingProject(currentProject);
              }}
            >
              {t("Delete")}
            </Typography>
          </Popover>
        </div>
      ),
    },
  ];

  React.useEffect(() => {
    (async () => {
      setShowBreadcrumb(true);
      await LoadItems();
    })();
  }, []);

  React.useEffect(() => {
    if (refreshData) {
      setProjects([]);
      setRefreshData(false);
      setPopOverOpen(false);
      setIsProjectViewOpen(false);

      (async () => {
        await LoadItems();
      })();
    }
  }, [refreshData]);

  async function LoadItems() {
    try {
      setShowLoadingIndicator(true);
      setProjects(await GetProjects());
    } finally {
      setShowLoadingIndicator(false);
    }
  }

  const onRowClick: GridEventListener<"rowClick"> = (params) => {
    setCurrentProject(params.row as ProjectFoundryDto);
  };

  const onCellClick: GridEventListener<"cellClick"> = (params) => {
    const project = params.row as ProjectFoundryDto;
    if (params.field !== "buttons") {
      navigate(project.name);
    }
  };

  return (
    <div className="projects-view">
      <div className="projects-view-header">
        <div className="projects-view-title">
          <div className="titel">{t("Datacenter")}</div>
          <div className="sub-titel">
            {t("Choose a project or create a new project")}
          </div>
        </div>
        <Button
          disabled={appAbility?.cannot("create", "configurators.datacenter")}
          onClick={() => {
            setTimeout(() => navigate(GenerateGuid()), 750);
          }}
          style={{ width: "120px", height: "40px" }}
          className="add-rule-button"
          startIcon={<AddIcon sx={{ fontSize: "20px" }} />}
          type="submit"
          variant="text"
        >
          {t("Create")}
        </Button>
        <Button
          onClick={() => setShowGridView(!showGridView)}
          style={{ marginLeft: "10px", width: "150px", height: "40px" }}
          className="add-rule-button"
          type="submit"
          variant="text"
        >
          {t("Change view")}
        </Button>
      </div>

      {showLoadingIndicator && (
        <div className="loading-indicator-datacenter-projects">
          <LoadingIndicator />
        </div>
      )}

      {!showGridView && (
        <Grid className="project-grid" container spacing={2}>
          {projects.map((project) => (
            <ProjectCard
              key={project.id}
              project={project}
              onRemameProject={handleRenamingProject}
              onDeleteProject={handleDeletingProject}
              onShareProject={handleSharingProject}
            />
          ))}
        </Grid>
      )}

      {showGridView && (
        <div className="data-grid-view">
          <StyledDataGrid
            disableRowSelectionOnClick
            columns={columns}
            rows={projects}
            onRowClick={onRowClick}
            onCellClick={onCellClick}
          />
        </div>
      )}

      <CreateOrUpdateProjectView
        rename={true}
        isProjectViewOpen={isProjectViewOpen}
        onSumbit={() => setRefreshData(true)}
        setIsProjectViewOpen={setIsProjectViewOpen}
      />
    </div>
  );
}
