import {
  AbilityBuilder,
  MongoAbility,
  createMongoAbility,
} from "@casl/ability";

type Actions = "read" | "create" | "update" | "manage";
export type Subjects =
  | ""
  | "main"
  | "analysers"
  | "analysers.planning"
  | "analysers.cost"
  | "analysers.mpg/co2"
  | "analysers.nitrogen"
  | "analysers.beng"
  | "calculations"
  | "catalog"
  | "catalog.datacenter"
  | "catalog.stackedhousing"
  | "catalog.datacenter.primarysystems"
  | "catalog.datacenter.spatialtypicals"
  | "catalog.datacenter.secondarysystems"
  | "catalog.datacenter.modules"
  | "catalog.datacenter.components"
  | "catalog.datacenter.components.serverracks"
  | "catalog.datacenter.components.upss"
  | "catalog.datacenter.components.batteries"
  | "catalog.datacenter.components.crah's"
  | "catalog.datacenter.components.structuralfloors"
  | "catalog.datacenter.components.steelprofiles"
  | "catalog.datacenter.components.transformators"
  | "configurators"
  | "configurators.datacenter"
  | "configurators.stackedhousing"
  | "company"
  | "databases"
  | "databases.activities"
  | "databases.productionkeyfigures"
  | "databases.equipmentkeyfigures"
  | "databases.equipmentkeyfigures.settings"
  | "databases.costskeyfigures"
  | "databases.material"
  | "electrotechnics"
  | "electrotechnics.content"
  | "generators"
  | "generators.datacenter"
  | "generators.stackedhousing"
  | "generators.whitespaceandcooling"
  | "generators.lowvoltageroom"
  | "generators.apartments"
  | "generators.mainsupportingstructure"
  | "heijmansintelligence"
  | "heijmansintelligence.stackedhousing"
  | "heijmansintelligence.stackedhousing.corridor"
  | "heijmansintelligence.stackedhousing.porchway"
  | "heijmansintelligence.stackedhousing.gallery"
  | "heijmansintelligence.stackedhousing.singlefamilyhome"
  | "mechanicalengineering"
  | "mechanicalengineering.content"
  | "overview"
  | "planning";

export type AppAbility = MongoAbility<[Actions, Subjects]>;

type Role =
  | "Synergy.PlannerViewer"
  | "Synergy.PlannerEditor"
  | "Synergy.PlannerAdmin"
  | "Synergy.NitrogenViewer"
  | "Synergy.NitrogenEditor"
  | "Synergy.NitrogenAdmin"
  | "Synergy.CostKFViewer"
  | "Synergy.CostKFEditor"
  | "Synergy.CostKFAdmin"
  | "Synergy.ProductionKFViewer"
  | "Synergy.ProductionKFEditor"
  | "Synergy.ProductionKFAdmin"
  | "Synergy.EquipmentKFViewer"
  | "Synergy.EquipmentKFEditor"
  | "Synergy.EquipmentKFAdmin"
  | "Synergy.DCViewer"
  | "Synergy.DCEditor"
  | "Synergy.DCAdmin"
  | "Synergy.LSRViewer"
  | "Synergy.LSREditor"
  | "Synergy.LSRAdmin"
  | "Synergy.HSAViewer"
  | "Synergy.HSAEditor"
  | "Synergy.HSAAdmin"
  | "Synergy.WSViewer"
  | "Synergy.WSEditor"
  | "Synergy.WSAdmin";

const rolePermissions: Record<Role, [Actions, Subjects][]> = {
  "Synergy.PlannerViewer": [
    ["read", "analysers"],
    ["read", "analysers.nitrogen"],
    ["read", "analysers.planning"],
  ],
  "Synergy.PlannerEditor": [
    ["read", "analysers"],
    ["read", "analysers.nitrogen"],
    ["update", "analysers.planning"],
  ],
  "Synergy.PlannerAdmin": [
    ["read", "analysers"],
    ["read", "analysers.nitrogen"],
    ["manage", "analysers.planning"],
  ],
  "Synergy.NitrogenViewer": [
    ["read", "analysers"],
    ["read", "analysers.nitrogen"],
  ],
  "Synergy.NitrogenEditor": [
    ["read", "analysers"],
    ["read", "analysers.nitrogen"],
    ["create", "analysers.nitrogen"],
    ["update", "analysers.nitrogen"],
  ],
  "Synergy.NitrogenAdmin": [
    ["read", "analysers"],
    ["manage", "analysers.nitrogen"],
  ],
  "Synergy.CostKFViewer": [
    ["read", "databases"],
    ["read", "databases.costskeyfigures"],
  ],
  "Synergy.CostKFEditor": [
    ["read", "databases"],
    ["read", "databases.costskeyfigures"],
    ["create", "databases.costskeyfigures"],
    ["update", "databases.costskeyfigures"],
  ],
  "Synergy.CostKFAdmin": [
    ["read", "databases"],
    ["manage", "databases.costskeyfigures"],
  ],
  "Synergy.EquipmentKFViewer": [
    ["read", "databases"],
    ["read", "databases.equipmentkeyfigures"],
  ],
  "Synergy.EquipmentKFEditor": [
    ["read", "databases"],
    ["read", "databases.equipmentkeyfigures"],
    ["create", "databases.equipmentkeyfigures"],
    ["update", "databases.equipmentkeyfigures"],
  ],
  "Synergy.EquipmentKFAdmin": [
    ["read", "databases"],
    ["read", "databases.equipmentkeyfigures.settings"],
    ["manage", "databases.equipmentkeyfigures"],
  ],
  "Synergy.ProductionKFViewer": [
    ["read", "databases"],
    ["read", "databases.productionkeyfigures"],
    ["read", "databases.activities"],
  ],
  "Synergy.ProductionKFEditor": [
    ["read", "databases"],
    ["read", "databases.productionkeyfigures"],
    ["create", "databases.productionkeyfigures"],
    ["update", "databases.productionkeyfigures"],
    ["read", "databases.activities"],
    ["create", "databases.activities"],
    ["update", "databases.activities"],
  ],
  "Synergy.ProductionKFAdmin": [
    ["read", "databases"],
    ["manage", "databases.productionkeyfigures"],
    ["manage", "databases.activities"],
  ],
  "Synergy.HSAViewer": [
    ["read", "configurators"],
    ["read", "configurators.stackedhousing"],
    ["read", "catalog"],
    ["read", "catalog.stackedhousing"],
    ["read", "heijmansintelligence"],
    ["read", "heijmansintelligence.stackedhousing"],
    ["read", "heijmansintelligence.stackedhousing.corridor"],
    ["read", "heijmansintelligence.stackedhousing.porchway"],
    ["read", "heijmansintelligence.stackedhousing.gallery"],
    ["read", "heijmansintelligence.stackedhousing.singlefamilyhome"],
  ],
  "Synergy.HSAEditor": [
    ["read", "configurators"],
    ["read", "configurators.stackedhousing"],
    ["create", "configurators.stackedhousing"],
    ["update", "configurators.stackedhousing"],
    ["read", "catalog"],
    ["manage", "catalog.stackedhousing"],
    ["read", "heijmansintelligence"],
    ["read", "heijmansintelligence.stackedhousing"],
    ["manage", "heijmansintelligence.stackedhousing.corridor"],
    ["manage", "heijmansintelligence.stackedhousing.porchway"],
    ["manage", "heijmansintelligence.stackedhousing.gallery"],
    ["manage", "heijmansintelligence.stackedhousing.singlefamilyhome"],
  ],
  "Synergy.HSAAdmin": [
    ["read", "configurators"],
    ["manage", "configurators.stackedhousing"],
    ["read", "catalog"],
    ["manage", "catalog.stackedhousing"],
    ["read", "heijmansintelligence"],
    ["read", "heijmansintelligence.stackedhousing"],
    ["manage", "heijmansintelligence.stackedhousing.corridor"],
    ["manage", "heijmansintelligence.stackedhousing.porchway"],
    ["manage", "heijmansintelligence.stackedhousing.gallery"],
    ["manage", "heijmansintelligence.stackedhousing.singlefamilyhome"],
  ],
  "Synergy.DCViewer": [
    ["read", "configurators"],
    ["read", "configurators.datacenter"],
    ["read", "generators"],
    ["read", "generators.datacenter"],
    ["read", "catalog"],
    ["read", "catalog.datacenter"],
    ["read", "catalog.datacenter.components"],
    ["read", "catalog.datacenter.components.serverracks"],
    ["read", "catalog.datacenter.components.batteries"],
    ["read", "catalog.datacenter.components.upss"],
    ["read", "catalog.datacenter.components.transformators"],
  ],
  "Synergy.DCEditor": [
    ["read", "configurators"],
    ["read", "configurators.datacenter"],
    ["create", "configurators.datacenter"],
    ["update", "configurators.datacenter"],
    ["read", "generators"],
    ["read", "generators.datacenter"],
    ["read", "catalog"],
    ["read", "catalog.datacenter"],
    ["read", "catalog.datacenter.components"],
    ["read", "catalog.datacenter.components.serverracks"],
    ["read", "catalog.datacenter.components.batteries"],
    ["read", "catalog.datacenter.components.upss"],
    ["read", "catalog.datacenter.components.transformators"],
  ],
  "Synergy.DCAdmin": [
    ["read", "configurators"],
    ["manage", "configurators.datacenter"],
    ["read", "generators"],
    ["read", "generators.datacenter"],
    ["read", "catalog"],
    ["read", "catalog.datacenter"],
    ["read", "catalog.datacenter.components"],
    ["read", "catalog.datacenter.components.serverracks"],
    ["read", "catalog.datacenter.components.batteries"],
    ["read", "catalog.datacenter.components.upss"],
    ["read", "catalog.datacenter.components.transformators"],
  ],
  "Synergy.LSRViewer": [
    ["read", "generators"],
    ["read", "generators.lowvoltageroom"],
    ["read", "catalog"],
    ["read", "catalog.datacenter"],
    ["read", "catalog.datacenter.components"],
    ["read", "catalog.datacenter.components.batteries"],
    ["read", "catalog.datacenter.components.upss"],
    ["read", "catalog.datacenter.components.transformators"],
  ],
  "Synergy.LSREditor": [
    ["read", "generators"],
    ["read", "generators.lowvoltageroom"],
    ["create", "generators.lowvoltageroom"],
    ["update", "generators.lowvoltageroom"],
    ["read", "catalog"],
    ["read", "catalog.datacenter"],
    ["read", "catalog.datacenter.components"],
    ["manage", "catalog.datacenter.components.batteries"],
    ["manage", "catalog.datacenter.components.upss"],
    ["manage", "catalog.datacenter.components.transformators"],
  ],
  "Synergy.LSRAdmin": [
    ["read", "generators"],
    ["manage", "generators.lowvoltageroom"],
    ["read", "catalog"],
    ["read", "catalog.datacenter"],
    ["read", "catalog.datacenter.components"],
    ["manage", "catalog.datacenter.components.batteries"],
    ["manage", "catalog.datacenter.components.upss"],
    ["manage", "catalog.datacenter.components.transformators"],
  ],
  "Synergy.WSViewer": [
    ["read", "generators"],
    ["read", "generators.whitespaceandcooling"],
    ["read", "catalog"],
    ["read", "catalog.datacenter"],
    ["read", "catalog.datacenter.components"],
    ["read", "catalog.datacenter.components.serverracks"],
  ],
  "Synergy.WSEditor": [
    ["read", "generators"],
    ["read", "generators.whitespaceandcooling"],
    ["create", "generators.whitespaceandcooling"],
    ["update", "generators.whitespaceandcooling"],
    ["read", "catalog"],
    ["read", "catalog.datacenter"],
    ["read", "catalog.datacenter.components"],
    ["manage", "catalog.datacenter.components.serverracks"],
  ],
  "Synergy.WSAdmin": [
    ["read", "generators"],
    ["manage", "generators.whitespaceandcooling"],
    ["read", "catalog"],
    ["read", "catalog.datacenter"],
    ["read", "catalog.datacenter.components"],
    ["manage", "catalog.datacenter.components.serverracks"],
  ],
};

const defaultPermissions: [Actions, Subjects][] = [
  ["read", "main"],
  ["read", "overview"],
  ["read", "generators.apartments"],
  ["read", "generators.mainsupportingstructure"],
  ["read", "generators.stackedhousing"],
  ["read", "analysers.beng"],
  ["read", "analysers.cost"],
  ["read", "analysers.mpg/co2"],
  ["read", "analysers.planning"],
  ["read", "databases.material"],
];

export function defineAbilityFor(roles: string[]): AppAbility {
  const { can, build } = new AbilityBuilder<AppAbility>(createMongoAbility);
  if (roles.length !== 0) {
    defaultPermissions.forEach(([action, subject]) => {
      can(action as Actions, subject);
    });

    roles.forEach((role) => {
      const permissions = rolePermissions[role as Role];
      if (permissions) {
        permissions.forEach(([action, subject]) => {
          can(action as Actions, subject);
        });
      }
    });
  }
  return build();
}
