export enum BuildingTypeEnum {
  Datacenter = 1,
  Whitespace = 2,
  LVR = 3,
}

export enum MapType {
  CreatePlot = 0,
  CreateMarker = 1,
  GenerateDatacenter = 2,
  GenerateFeature = 3,
}

export enum MapDrawFeatureType {
  Datacenter = "datacenters",
  HSABuilding = "building",
}

export enum ProjectTypeEnum {
  Datacenter = 1,
  Analyser = 2,
  HSABuilding = 3,
}

export enum TypeObject {
  HSABuilding = 0,
  MapView = 1,
  ExportView = 2,
  HSAResidential = 3,
  HSACountingModel = 4,
}

export const TypeObjectLabel = new Map<number, string>([
  [TypeObject.HSABuilding, "Building"],
  [TypeObject.MapView, "MapView"],
  [TypeObject.ExportView, "ExportView"],
  [TypeObject.HSAResidential, "StackedHousing.Apartment"],
]);

export enum CarParkingType {
  Unknown = "Unknown",
  GarageParking = "GarageParking",
  NonPublicParking = "NonPublicParking",
  PublicParking = "PublicParking",
}
