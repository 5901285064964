import { Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDialog } from "../../../sharedLogic/components/Dialog/CustomDialog";
import CustomFieldWithPercentage from "../../../sharedLogic/components/InputPane/MapView/CustomFieldWithPercentage";
import LabeledField from "../../../sharedLogic/components/PropertySectionView/LabeledField";
import NumberField from "../../../sharedLogic/components/PropertySectionView/NumberField";
import SceneDetail from "../../../sharedLogic/components/Scene/SceneDetail/SceneDetail";
import {
  ParkingDto,
  ResidentialInformation,
} from "../../../sharedLogic/types/api";
import DrawedPlot from "../../../sharedLogic/types/DrawedPlot";
import { UnitType } from "../../../sharedLogic/types/UnitType";
import { convertSquareMilimetersToSquareMeters } from "../../../sharedLogic/utils/format";
import { SpaceBuildingDto } from "../../types/api";
import { SpaceType } from "../../types/enums";
import { getColor } from "../../utils/colors";
import { BuildingDetailedInfo } from "../ConfiguratorPage/Configs/BuildingConfig";
import ProjectUnitMix from "../UnitMix/ProjectUnitMix";
import LabeledFieldWithInfoButton from "../../../sharedLogic/components/PropertySectionView/LabeledFieldWithInfoButton";

interface ExportedBuildingDetailsPaneProps {
  drawedPlot: DrawedPlot;
  selectedExportedBuilingsIds: {
    code: string;
    count: number;
  }[];
  residentialInformation: ResidentialInformation[];
  currentApartmentBuildings: SpaceBuildingDto[];
  parking: ParkingDto | undefined;
}

const calculateTotal = (
  buildings: SpaceBuildingDto[],
  key: keyof SpaceBuildingDto
) =>
  buildings.reduce(
    (acc, building) =>
      acc + (typeof building[key] === "number" ? building[key] : 0),
    0
  );

export default function ExportedBuildingDetailsPane({
  drawedPlot,
  parking,
  residentialInformation,
  currentApartmentBuildings,
  selectedExportedBuilingsIds,
}: ExportedBuildingDetailsPaneProps) {
  const { t } = useTranslation();
  const { openDialog } = useDialog();

  const repeatedApartmentBuildings = currentApartmentBuildings.reduce(
    (acc, building) => {
      const exportedBuilding = selectedExportedBuilingsIds.find(
        (exported) => exported.code === building.code
      );
      if (exportedBuilding) {
        return [...acc, ...Array(exportedBuilding.count).fill(building)];
      }
      return acc;
    },
    [] as SpaceBuildingDto[]
  );

  const calculateUnitMix = (buildings: SpaceBuildingDto[]): UnitType[] => {
    const totalApartments = buildings.reduce((total, building) => {
      return total + building.nrOfAppartments;
    }, 0);

    const unitCounts = new Map<SpaceType, number>();
    buildings.forEach((building) => {
      building.residentialInformation.forEach((info) => {
        const currentCount = unitCounts.get(info.moduleType) || 0;
        unitCounts.set(info.moduleType, currentCount + info.count);
      });
    });

    const units = Array.from(unitCounts.entries()).map(
      ([spaceType, count]) =>
        ({
          name: spaceType,
          color: getColor(spaceType),
          count,
          ratio: Math.round((count / totalApartments) * 100),
        } as UnitType)
    );

    units.forEach((unit) => {
      const residentialInfo = residentialInformation?.find(
        (info) => info.moduleType === unit.name
      );
      unit.inputRatio = residentialInfo?.ratio;
    });

    return units.sort((a, b) => a.name.localeCompare(b.name));
  };

  const totalBuildingSurface = calculateTotal(
    repeatedApartmentBuildings,
    "grossFootprintAreaInM2"
  );
  const totalBuildingCosts = calculateTotal(
    repeatedApartmentBuildings,
    "costInEuro"
  );
  const totalApartments = calculateTotal(
    repeatedApartmentBuildings,
    "nrOfAppartments"
  );
  const bvoTotal = calculateTotal(
    repeatedApartmentBuildings,
    "grossFloorAreaInM2"
  );
  const bvoLiving = calculateTotal(
    repeatedApartmentBuildings,
    "grossFloorAreaLivingInM2"
  );
  const bvoOutdoor = calculateTotal(
    repeatedApartmentBuildings,
    "grossFloorAreaOutdoorInM2"
  );
  const go = calculateTotal(repeatedApartmentBuildings, "usableAreaInM2");
  const bbo = calculateTotal(
    repeatedApartmentBuildings,
    "grossFootprintAreaInM2"
  );
  const bgo = calculateTotal(repeatedApartmentBuildings, "usableAreaInM2");
  const bdo = calculateTotal(repeatedApartmentBuildings, "grossRoofAreaInM2");

  const projectUnits = calculateUnitMix(repeatedApartmentBuildings);

  const onClick = (building: SpaceBuildingDto) => {
    openDialog({
      title: `${building.name} details`,
      content: (
        <SceneDetail
          currentObject={building}
          detailedInfo={BuildingDetailedInfo}
        />
      ),
      width: "75%",
      height: "85%",
    });
  };

  return (
    <>
      <div className="titel">{t("Total")}</div>
      <div className="titel-no-bold">{t("MapViewPage.Lot")}</div>
      <LabeledFieldWithInfoButton
        labelId="buildable-plot-label"
        labelKey="MapViewPage.BuildablePlot"
      >
        <NumberField
          unit={"m²"}
          disabled={true}
          showBorderBottomStyle={true}
          value={drawedPlot.lotSurface}
        />
      </LabeledFieldWithInfoButton>
      <CustomFieldWithPercentage
        labelKey="StackedHousing.ExportView.BuildableArea"
        disabled={true}
        percentage={Math.round(
          (drawedPlot.buildableSurface / drawedPlot.lotSurface) * 100
        )}
        value={Math.round(drawedPlot.buildableSurface)}
      />
      <LabeledFieldWithInfoButton
        className="mt5"
        labelId="buildings-surface-label"
        labelKey="StackedHousing.ExportView.RequiredBuildingSurfaceArea"
      >
        <NumberField
          unit={"m²"}
          disabled={true}
          showBorderBottomStyle={true}
          value={totalBuildingSurface}
        />
      </LabeledFieldWithInfoButton>
      <LabeledFieldWithInfoButton
        className="mt5"
        labelId="building-parking-label"
        labelKey="StackedHousing.ExportView.RequiredParkingArea"
      >
        <NumberField unit={"m²"} disabled={true} showBorderBottomStyle={true} />
      </LabeledFieldWithInfoButton>
      <CustomFieldWithPercentage
        labelKey="StackedHousing.ExportView.BuiltUpArea"
        disabled={true}
        percentage={Math.round(
          (totalBuildingSurface / drawedPlot.lotSurface) * 100
        )}
        value={totalBuildingSurface}
      />

      <hr className="no-padding mt20" />
      <div className="titel-no-bold mt20">
        {t("StackedHousing.ExportView.Parking")}
      </div>
      <LabeledFieldWithInfoButton
        labelId="parking-count-label"
        labelKey="StackedHousing.ExportView.ParkingplacesCar"
      >
        <NumberField
          unit={"st"}
          disabled={true}
          showBorderBottomStyle={true}
          value={parking?.nrOfParkingSpots}
        />
      </LabeledFieldWithInfoButton>
      <LabeledFieldWithInfoButton
        className="mt5"
        labelId="parking-surface-label"
        labelKey="StackedHousing.ExportView.SurfaceParkingplacesCar"
      >
        <NumberField
          unit={"m²"}
          disabled={true}
          showBorderBottomStyle={true}
          value={convertSquareMilimetersToSquareMeters(
            parking?.parkingLotInMm2
          )}
        />
      </LabeledFieldWithInfoButton>
      <LabeledFieldWithInfoButton
        className="mt5"
        labelId="parking-places-bike-label"
        labelKey="StackedHousing.ExportView.ParkingPlacesBike"
      >
        <NumberField
          unit={"st"}
          disabled={true}
          showBorderBottomStyle={true}
          value={parking?.nrOfBicycleParkingSpots}
        />
      </LabeledFieldWithInfoButton>
      <LabeledFieldWithInfoButton
        className="mt5"
        labelId="parking-surface-bike-lot-label"
        labelKey="StackedHousing.ExportView.SurfaceParkingPlacesBikeLot"
      >
        <NumberField
          unit={"m²"}
          disabled={true}
          showBorderBottomStyle={true}
          value={convertSquareMilimetersToSquareMeters(
            parking?.totalBicycleParkingLotInMm2
          )}
        />
      </LabeledFieldWithInfoButton>
      <LabeledFieldWithInfoButton
        className="mt5"
        labelId="parking-surface-bike-building-label"
        labelKey="StackedHousing.ExportView.SurfaceParkingPlacesBikeBuilding"
      >
        <NumberField
          unit={"m²"}
          disabled={true}
          showBorderBottomStyle={true}
          value={convertSquareMilimetersToSquareMeters(
            parking?.buildingCoveredBicycleParkingLotInMm2
          )}
        />
      </LabeledFieldWithInfoButton>
      <hr className="no-padding mt20" />
      <div className="titel-no-bold mt20">{t("Valuation")}</div>
      <LabeledFieldWithInfoButton
        labelId="indication-construction-budget-label"
        labelKey="StackedHousing.ExportView.IndicationOfConstructionBudget"
      >
        <NumberField
          unit={"€"}
          disabled={true}
          showBorderBottomStyle={true}
          value={totalBuildingCosts}
        />
      </LabeledFieldWithInfoButton>
      <LabeledFieldWithInfoButton
        className="mt5"
        labelId="indication-yields-label"
        labelKey="StackedHousing.ExportView.IndicationOfInvestmentValue"
      >
        <NumberField unit={"€"} disabled={true} showBorderBottomStyle={true} />
      </LabeledFieldWithInfoButton>
      <LabeledFieldWithInfoButton
        className="mt5"
        labelId="rental-price-label"
        labelKey="StackedHousing.ExportView.RentalPrice"
      >
        <NumberField unit={"€"} disabled={true} showBorderBottomStyle={true} />
      </LabeledFieldWithInfoButton>
      <LabeledFieldWithInfoButton
        className="mt5"
        labelId="co2-footprint-label"
        labelKey="StackedHousing.ExportView.CO2Footprint"
      >
        <NumberField unit={"kg"} disabled={true} showBorderBottomStyle={true} />
      </LabeledFieldWithInfoButton>
      <hr className="no-padding mt20" />
      <div className="titel-no-bold mt20">{t("Buildings")}</div>
      <LabeledFieldWithInfoButton
        labelId="total-quantity-apartments-label"
        labelKey="StackedHousing.ExportView.TotalQuantityApartments"
      >
        <NumberField
          unit={"st"}
          disabled={true}
          showBorderBottomStyle={true}
          value={totalApartments}
        />
      </LabeledFieldWithInfoButton>
      <LabeledFieldWithInfoButton
        className="mt5"
        labelId="unit-mix-label"
        labelKey="StackedHousing.ExportView.UnitMix"
      >
        <div className="mt5">
          <ProjectUnitMix projectUnits={projectUnits} />
        </div>
      </LabeledFieldWithInfoButton>
      <LabeledFieldWithInfoButton
        className="mt5"
        labelId="bvo-label"
        labelKey="StackedHousing.ExportView.BVOTotal"
      >
        <NumberField
          unit={"m²"}
          value={bvoTotal}
          disabled={true}
          showBorderBottomStyle={true}
        />
      </LabeledFieldWithInfoButton>
      <LabeledFieldWithInfoButton
        className="mt5"
        labelId="bvo-living-label"
        labelKey="StackedHousing.ExportView.BVOHousing"
      >
        <NumberField
          unit={"m²"}
          value={bvoLiving}
          disabled={true}
          showBorderBottomStyle={true}
        />
      </LabeledFieldWithInfoButton>
      <LabeledFieldWithInfoButton
        className="mt5"
        labelId="bvo-outdoor-label"
        labelKey="StackedHousing.ExportView.BVOOutdoor"
      >
        <NumberField
          unit={"m²"}
          value={bvoOutdoor}
          disabled={true}
          showBorderBottomStyle={true}
        />
      </LabeledFieldWithInfoButton>
      <LabeledFieldWithInfoButton
        className="mt5"
        labelId="go-label"
        labelKey="StackedHousing.ExportView.GOHousing"
      >
        <NumberField
          unit={"m²"}
          value={go}
          disabled={true}
          showBorderBottomStyle={true}
        />
      </LabeledFieldWithInfoButton>
      <LabeledFieldWithInfoButton
        className="mt5"
        labelId="bbo-label"
        labelKey="StackedHousing.ExportView.BBO"
      >
        <NumberField
          unit={"m²"}
          value={bbo}
          disabled={true}
          showBorderBottomStyle={true}
        />
      </LabeledFieldWithInfoButton>
      <LabeledFieldWithInfoButton
        className="mt5"
        labelId="bgo-label"
        labelKey="StackedHousing.ExportView.BGO"
      >
        <NumberField
          unit={"m²"}
          value={bgo}
          disabled={true}
          showBorderBottomStyle={true}
        />
      </LabeledFieldWithInfoButton>
      <LabeledFieldWithInfoButton
        className="mt5"
        labelId="bdo-label"
        labelKey="StackedHousing.ExportView.BDO"
      >
        <NumberField
          unit={"m²"}
          value={bdo}
          disabled={true}
          showBorderBottomStyle={true}
        />
      </LabeledFieldWithInfoButton>
      <div className="mt10">
        {currentApartmentBuildings.map((building) => {
          return (
            <Typography component="div">
              <Link
                onClick={() => onClick(building)}
                component="button"
                sx={{
                  cursor: "pointer",
                  color: "black",
                  fontWeight: "bold",
                  textDecoration: "underline",
                  marginTop: "5px",
                }}
              >
                {`${t("Building")} ${building.name}`}
              </Link>
            </Typography>
          );
        })}
      </div>
    </>
  );
}
