import { useRef, useEffect, useState, useCallback } from "react";
import { ControlledCameras } from "./ControlledCameras";
import CameraControls from "camera-controls";

export default function Camera() {
  const controls = useRef<CameraControls>(null!);

  const [mode, setMode] = useState<"perspective" | "orthographic">(
    "orthographic"
  );

  useEffect(() => {
    const changeView = (e: KeyboardEvent) => {
      if (e.keyCode !== 32) return;

      setMode((state) => {
        const newMode =
          state === "perspective" ? "orthographic" : "perspective";
        return newMode;
      });
    };

    window.addEventListener("keyup", changeView);
    return () => window.removeEventListener("keyup", changeView);
  }, []);

  const onRest = useCallback(() => {
    // Need to wait a frame here because the camera is also being changed when
    // the rest event fires
    requestAnimationFrame(() => {
      const currentMode =
        controls.current!.camera.type === "PerspectiveCamera"
          ? "perspective"
          : "orthographic";

      const currentView = document.querySelector("#currentView");
      if (currentView) {
        currentView.textContent = `Current view: ${currentMode}`;
      }
    });
  }, [controls]);

  return (
    <ControlledCameras
      ref={controls}
      mode={mode}
      maxPolarAngle={Math.PI / 2}
      restThreshold={0.01}
      smoothTime={0.05}
      perspectivePosition={[
        154.240574578795, 59.65375508103456, 49.662484644169744,
      ]}
      perspectiveTarget={[
        40.67912224577891, -3.9161587940685387, -38.600025280887714,
      ]}
      orthographicPosition={[
        42.947143593620424, 2.000038221145949, -38.22114597036275,
      ]}
      orthographicTarget={[
        42.947143593620424, 0.00003822114594925528, -38.22114597036275,
      ]}
      perspectiveCameraProps={{
        fov: 50,
        near: 1,
        far: 10000,
        zoom: 1,
      }}
      orthographicCameraProps={{
        zoom: 5.7,
        near: -1000,
        far: 1000,
      }}
      onRest={onRest}
      mouseButtons={{
        left:
          mode === "perspective"
            ? CameraControls.ACTION.TRUCK
            : CameraControls.ACTION.TRUCK,
        middle:
          mode === "perspective"
            ? CameraControls.ACTION.DOLLY
            : CameraControls.ACTION.ZOOM,
        right:
          mode === "perspective"
            ? CameraControls.ACTION.ROTATE
            : CameraControls.ACTION.TRUCK,
        wheel:
          mode === "perspective"
            ? CameraControls.ACTION.DOLLY
            : CameraControls.ACTION.ZOOM,
      }}
      touches={{
        one: CameraControls.ACTION.TOUCH_ROTATE,
        two:
          mode === "perspective"
            ? CameraControls.ACTION.TOUCH_DOLLY_TRUCK
            : CameraControls.ACTION.TOUCH_ZOOM_TRUCK,
      }}
    />
  );
}
