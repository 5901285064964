import MapboxDraw from "@mapbox/mapbox-gl-draw";

interface CustomSimpleSelectModeProps {
  map: mapboxgl.Map;
  featureType: string;
  setCurrentFeatureCollection: React.Dispatch<
    React.SetStateAction<
      GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
    >
  >;
}

export const createCustomSimpleSelectMode = ({
  map,
  featureType,
  setCurrentFeatureCollection,
}: CustomSimpleSelectModeProps) => {
  const simpleSelectMode = { ...MapboxDraw.modes.simple_select };

  let isDragging = false;
  let dragStartCoords: [number, number] | null = null;
  let selectedBuildingName: string | null = null;

  simpleSelectMode.onClick = function (_state) {
    return;
  };

  simpleSelectMode.onMouseDown = function (_state, e: mapboxgl.MapMouseEvent) {
    if (e.originalEvent.button !== 0) return;

    const clickedFeatures = map.queryRenderedFeatures(e.point, {
      layers: [`${featureType}-3d`],
    });

    const selectedFeature = clickedFeatures[0];
    if (selectedFeature && selectedFeature.properties?.buildingName) {
      selectedBuildingName = selectedFeature.properties.buildingName;
      isDragging = true;
      dragStartCoords = [e.lngLat.lng, e.lngLat.lat];

      map.dragPan.disable();
      map.scrollZoom.disable();
      map.doubleClickZoom.disable();

      map.on("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    } else {
      e.features = undefined;
    }
  };

  const handleMouseMove = (e: mapboxgl.MapMouseEvent) => {
    if (isDragging && dragStartCoords && selectedBuildingName) {
      const deltaX = e.lngLat.lng - dragStartCoords[0];
      const deltaY = e.lngLat.lat - dragStartCoords[1];

      setCurrentFeatureCollection((prevFeatures) => {
        const newFeatures = prevFeatures.features.map((feature: any) => {
          if (feature.properties?.buildingName === selectedBuildingName) {
            const newCoordinates = (
              feature.geometry as GeoJSON.Polygon
            ).coordinates[0].map(([x, y]) => [x + deltaX, y + deltaY]);
            return {
              ...feature,
              geometry: {
                ...feature.geometry,
                coordinates: [newCoordinates],
              },
            };
          }
          return feature;
        });
        return { ...prevFeatures, features: newFeatures };
      });

      dragStartCoords = [e.lngLat.lng, e.lngLat.lat];
    }
  };

  const handleMouseUp = () => {
    if (isDragging) {
      isDragging = false;
      dragStartCoords = null;
      selectedBuildingName = null;

      map.dragPan.enable();
      map.scrollZoom.enable();
      map.off("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    }
  };

  return simpleSelectMode;
};
