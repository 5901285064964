import { Collapse, Grid, Typography } from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import React from "react";
import { useTranslation } from "react-i18next";
import ExpandMore from "../../../../components/ExpandMore/ExpandMore";
import CustomInputLabel from "../../../../components/InputLabel/CustomInputLabel";
import PercentageInput from "../../../sharedLogic/components/PercentageInput/PercentageInput";
import BooleanField from "../../../sharedLogic/components/PropertySectionView/BooleanField";
import LabeledField from "../../../sharedLogic/components/PropertySectionView/LabeledField";
import NumberField from "../../../sharedLogic/components/PropertySectionView/NumberField";
import SelectField from "../../../sharedLogic/components/PropertySectionView/SelectField";
import {
  BicycleParkingInputDto,
  ParkingDto,
} from "../../../sharedLogic/types/api";
import { CarParkingType } from "../../../sharedLogic/types/enums";
import { SpaceType } from "../../types/enums";
import { getColor } from "../../utils/colors";
import "./ProjectRequirements.scss";
import LabeledFieldWithInfoButton from "../../../sharedLogic/components/PropertySectionView/LabeledFieldWithInfoButton";
import CustomInputLabelWithInfoButton from "../../../../components/InputLabel/CustomInputLabelWithInfoButton";

interface ProjectRequirementsProps {
  parkingDto: ParkingDto | undefined;
  isSaveAvailable?: boolean;
  setParkingDto: (value: ParkingDto) => void;
}

export default function ProjectRequirements({
  parkingDto,
  isSaveAvailable = true,
  setParkingDto,
}: ProjectRequirementsProps) {
  const { t } = useTranslation();
  const [bicycleParkingExpanded, setBicycleParkingExpanded] =
    React.useState(false);

  const parkingOptions = [
    { label: t("StackedHousing.Garage"), value: "GarageParking" },
    { label: t("StackedHousing.NonPublic"), value: "NonPublicParking" },
  ];

  const defaultBicycleParkingInput = [
    {
      numLow: 2,
      numHigh: 0,
      moduleSpaceType: SpaceType.Appartment1K,
    },
    {
      numLow: 2,
      numHigh: 1,
      moduleSpaceType: SpaceType.Appartment2K,
    },
    {
      numLow: 2,
      numHigh: 1,
      moduleSpaceType: SpaceType.Appartment3K,
    },
    {
      numLow: 2,
      numHigh: 2,
      moduleSpaceType: SpaceType.Appartment4K,
    },
  ] as BicycleParkingInputDto[];

  const updateBicycleParking = (
    index: number,
    field: "numLow" | "numHigh",
    value: number | null
  ) => {
    const newBicycleParkingInput = parkingDto?.bicycleParkingInput?.map(
      (input, i) => (i === index ? { ...input, [field]: value } : input)
    ) ?? [...defaultBicycleParkingInput];

    setParkingDto({
      ...(parkingDto ?? ({} as ParkingDto)),
      bicycleParkingInput: newBicycleParkingInput,
    });
  };

  React.useEffect(() => {
    if (
      !parkingDto?.bicycleParkingInput ||
      parkingDto.bicycleParkingInput.length === 0
    ) {
      setParkingDto({
        ...(parkingDto ?? ({} as ParkingDto)),
        bicycleParkingInput: defaultBicycleParkingInput,
      });
    }
  }, [parkingDto]);

  return (
    <div className="project-requirements">
      <hr className="no-padding mt20" />
      <div className="titel-no-bold mt20">
        {t("MapViewPage.ProjectRequirements")}
      </div>
      <LabeledFieldWithInfoButton
        labelId="parking-standard-label"
        labelKey="MapViewPage.ParkingStandard"
      >
        <NumberField
          dataTestId="car-per-house-test-id"
          disabled={!isSaveAvailable}
          unit={t("MapViewPage.CarPerHouse")}
          value={parkingDto?.parkingSpotPerResidentialFactor}
          onChange={(value) => {
            setParkingDto({
              ...(parkingDto ?? ({} as ParkingDto)),
              parkingSpotPerResidentialFactor: parseFloat(value ?? "0"),
            });
          }}
        />
      </LabeledFieldWithInfoButton>
      <LabeledFieldWithInfoButton
        className="mt5"
        labelId="parking-covered-label"
        labelKey="MapViewPage.ParkingCovered"
      >
        <BooleanField
          disabled={!isSaveAvailable}
          value={parkingDto?.covered ?? false}
          onChange={(value) => {
            setParkingDto({
              ...(parkingDto ?? ({} as ParkingDto)),
              covered: value,
            });
          }}
        />
      </LabeledFieldWithInfoButton>
      <LabeledFieldWithInfoButton
        className="mt5"
        labelId="type-parking-label"
        labelKey="MapViewPage.ParkingType"
      >
        <SelectField
          dataTestId="parking-type-test-id"
          disabled={!isSaveAvailable}
          value={
            parkingOptions.find((opt) => opt.value === parkingDto?.type)
              ?.label || ""
          }
          options={parkingOptions.map((opt) => opt.label)}
          onChange={(selectedLabel) => {
            const selectedOption = parkingOptions.find(
              (opt) => opt.label === selectedLabel
            );
            if (selectedOption) {
              setParkingDto({
                ...(parkingDto ?? ({} as ParkingDto)),
                type: selectedOption.value as CarParkingType,
              });
            }
          }}
        />
      </LabeledFieldWithInfoButton>
      <div className="mt5 bicycle-parking-header">
        <div className="mt15 flex-grow">
          <CustomInputLabelWithInfoButton
            id="type-parking-label"
            labelKey="MapViewPage.BicycleParkingTitle"
          />
        </div>
        <ExpandMore
          style={{ marginRight: "-10px" }}
          expand={bicycleParkingExpanded}
          onClick={() => setBicycleParkingExpanded(!bicycleParkingExpanded)}
          aria-expanded={bicycleParkingExpanded}
          aria-label="show more"
        >
          <GridExpandMoreIcon />
        </ExpandMore>
      </div>
      <Collapse in={bicycleParkingExpanded} timeout="auto" unmountOnExit>
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <Grid container>
              <Grid item width={45}>
                <div className="bicycle-column-title">
                  {t("MapViewPage.BicycleParkingLow")}
                </div>
              </Grid>
              <Grid item width={45}>
                <div className="bicycle-column-title">
                  {t("MapViewPage.BicycleParkingHigh")}
                </div>
              </Grid>
            </Grid>
          </Grid>
          {parkingDto?.bicycleParkingInput?.map((bicycleParking, index) => (
            <Grid item xs={12} key={index}>
              <Grid container spacing={1}>
                <Grid item>
                  <PercentageInput
                    disabled={!isSaveAvailable}
                    dataTestId={`bicycle-parking-low-${index}`}
                    showEndAdornment={false}
                    value={bicycleParking.numLow}
                    onChange={(value: number | null) => {
                      updateBicycleParking(index, "numLow", value);
                    }}
                  />
                  <PercentageInput
                    disabled={!isSaveAvailable}
                    dataTestId={`bicycle-parking-high-${index}`}
                    showEndAdornment={false}
                    value={bicycleParking.numHigh}
                    onChange={(value: number | null) => {
                      updateBicycleParking(index, "numHigh", value);
                    }}
                  />
                </Grid>

                <Grid item xs>
                  <div className="unit-mix__name-container">
                    <div
                      className="unit-mix__color-indicator"
                      style={{
                        backgroundColor: getColor(
                          bicycleParking.moduleSpaceType
                        ),
                      }}
                    />
                    <Typography variant="body2" className="unit-mix__item-name">
                      {t(bicycleParking.moduleSpaceType)}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Collapse>
      <hr className="no-padding mt20" />
    </div>
  );
}
