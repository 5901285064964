import { Map } from "mapbox-gl";
import { useEffect, useRef, useState } from "react";
import { StoreApi, UseBoundStore } from "zustand";
import useProjectsStore from "../../../../../state/projectsState";
import { MapDrawFeatureType } from "../../../../../types/enums";
import { ExtractBuildingCoordinates } from "../../DrawControlUtils";
import { createCustomRotateMode } from "./CustomRotateMode";
import { createCustomSimpleSelectMode } from "./CustomSelectMode";
import FeatureDrawControl from "./FeatureDrawControl";

interface FeatureDrawControlViewProps {
  map: Map | null;
  mapDrawFeatureType: MapDrawFeatureType;
  currentStore: UseBoundStore<StoreApi<any>>;
}

export default function FeatureDrawControlView({
  map,
  mapDrawFeatureType,
  currentStore,
}: FeatureDrawControlViewProps) {
  const { currentProject } = useProjectsStore();
  const [stylesLoaded, setStylesLoaded] = useState<boolean>(false);
  const drawControlRef = useRef<FeatureDrawControl>();
  const [currentFeatureCollection, setCurrentFeatureCollection] =
    useState<GeoJSON.FeatureCollection>({
      type: "FeatureCollection",
      features: [],
    });

  const {
    drawedPlot,
    buildingLocations,
    drawControlMode,
    exportedLayouts,
    selectedExportedBuilingsIds,
    setBuildingLocations,
  } = currentStore();

  useEffect(() => {
    if (!map) return;

    const modes = {
      simple_select: createCustomSimpleSelectMode({
        map,
        featureType: mapDrawFeatureType,
        setCurrentFeatureCollection,
      }),
      rotate_mode: createCustomRotateMode({
        map,
        featureType: mapDrawFeatureType,
        setCurrentFeatureCollection,
      }),
    };

    drawControlRef.current = new FeatureDrawControl({
      modes: modes,
      featureType: mapDrawFeatureType,
      currentFeatureCollection: currentFeatureCollection,
      setCurrentFeatureCollection: setCurrentFeatureCollection,
      projectExternalId: currentProject.externalId!,
      currentConfiguratorStore: currentStore,
      setStylesLoaded: setStylesLoaded,
    });

    map.addControl(drawControlRef.current);
  }, [map]);

  useEffect(() => {
    if (map && drawControlRef.current) {
      drawControlRef.current.generateFeatures(
        drawedPlot.polygon,
        buildingLocations,
        exportedLayouts,
        selectedExportedBuilingsIds
      );
    }
  }, [selectedExportedBuilingsIds]);

  useEffect(() => {
    const isCountOfAllExportedBuildingsZero = selectedExportedBuilingsIds.every(
      (building: any) => building.count === 0
    );
    if (
      !stylesLoaded ||
      !map ||
      currentFeatureCollection.features.length === 0 ||
      isCountOfAllExportedBuildingsZero
    ) {
      return;
    }

    if (map.getSource(mapDrawFeatureType)) {
      (map.getSource(mapDrawFeatureType) as mapboxgl.GeoJSONSource).setData(
        currentFeatureCollection
      );
      setBuildingLocations(
        ExtractBuildingCoordinates(currentFeatureCollection)
      );
    } else {
      map.addSource(mapDrawFeatureType, {
        type: "geojson",
        data: currentFeatureCollection,
      });

      map.addLayer({
        id: `${mapDrawFeatureType}-3d`,
        slot: "top",
        type: "fill-extrusion",
        source: mapDrawFeatureType,
        paint: {
          "fill-extrusion-color": ["get", "color"],
          "fill-extrusion-height": ["get", "height"],
          "fill-extrusion-base": ["get", "baseHeight"],
          "fill-extrusion-opacity": 1,
        },
      });

      map.addLayer({
        id: "outline",
        slot: "top",
        type: "line",
        source: mapDrawFeatureType,
        layout: {
          "line-join": "round",
          "line-z-offset": ["get", "height"],
        },
        paint: {
          "line-color": "#808080",
          "line-width": 0.5,
        },
      });
    }
  }, [currentFeatureCollection, stylesLoaded]);

  useEffect(() => {
    if (
      drawControlMode &&
      drawControlMode !== "" &&
      map &&
      drawControlRef.current
    ) {
      drawControlRef.current.getDrawControl().changeMode(drawControlMode);
    }
  }, [drawControlMode]);

  return null;
}
