import { TypeObject } from "../../interfaces/enums/TypeObjectEnum";
import { foundryApiClient } from "../../modules/sharedLogic/services/AxiosClient";
import { TypicalDTO } from "../../state/ExploreState/ExploreState";

interface ApiResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: TypicalDTO[];
}

export async function FetchPaginatedData<T>(
  url: string,
  processFunction: (item: TypicalDTO, param?: TypeObject) => T,
  param?: TypeObject | undefined,
  signal?: AbortSignal
): Promise<T[]> {
  // Fetch the first page to get the total count
  const initialResponse = await foundryApiClient.get<ApiResponse>(
    `${url}&page=1`,
    { signal: signal }
  );
  const totalItems = initialResponse.data.count;
  const resultsPerPage = initialResponse.data.results.length;
  const totalPages = Math.ceil(totalItems / resultsPerPage);
  let allResults: T[] = initialResponse.data.results.map((item) =>
    processFunction(item, param)
  );

  // Fetch each subsequent page sequentially
  for (let page = 2; page <= totalPages; page++) {
    const pageUrl = `${url}&page=${page}`;
    const response = await foundryApiClient.get<ApiResponse>(pageUrl, {
      signal: signal,
    });
    const pageResults = response.data.results.map((item) =>
      processFunction(item, param)
    );
    allResults = allResults.concat(pageResults);
  }

  return allResults;
}
