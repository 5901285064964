import { create } from "zustand";
import { DatacenterDto } from "../../../../interfaces/foundry/DatacenterDto";
import { ScenarioDto } from "../../../Analysers/types/api";
import { SpaceDto } from "../../../HSA/types/api";
import { TypeObject } from "../../types/enums";
import PCGAxesDto from "../../types/PCGAxesDto";
import { ProjectAddressFeatureDto } from "../../types/api";

export interface AxisConfig {
  dimension: string;
  format: "integer" | "float" | "percentage";
}

export type DTOWhithChart = ScenarioDto;
export type DTO = SpaceDto | DTOWhithChart;

export interface ExploreState<T extends DTO> {
  objects: T[] | null;
  loadedObjects: T[];
  selectedIds: string[];
  brushedObjects: T[];
  filteredObjects: T[];
  filteredObjectsBrushedOnPCG: T[];
  hoveredObject: T | null;
  isObjectsLoaded: boolean;
  objectSortProperty: string;
  objectSortAscending: boolean;
  isReset: boolean;
  isAdjustScale: boolean;
  dimensions: AxisConfig[];
  chartLabels: string[];
  clickableAxes: string[];
  selectedAxes: PCGAxesDto[];
  filterControlOptionsDictionary: PCGAxesDto[];
  filterControlOptionsDictionaryReversed: { [key: string]: string };
  filterControlSelectedAxes: string[];
  objectsTotalCount: number;
  currentObject: T | null;
  isOpenedDetailedView: boolean;
  selectedObject: DatacenterDto | null;
  selectedModulesCodes: string[];
  currentPageType: TypeObject | null;
  projectAddressFeature: ProjectAddressFeatureDto | null;
  startDrawingPlot: boolean;
  editDrawingPlot: boolean;
  deleteDrawingPlot: boolean;
  navigateDrawingPlot: boolean;
  isInputPaneOpen: boolean;
  currentTabPageIndex: number;
  exportTabDisabled: boolean;
  calculateDistance: boolean;
  distanceBetweenPlotAndRoad: number;
  isSaveAvailable: boolean;
  drawControlMode: string;
  generatedBuildingsIds: { code: string; count: number }[];
  selectedExportedBuilingsIds: { code: string; count: number }[];
}

export interface ExploreActions<T extends DTO> {
  setObjects: (objects: T[] | null) => void;
  setObjectSortProperty: (values: string) => void;
  setObjectSortAscending: (value: boolean) => void;
  setBrushedObjects: (objects: T[]) => void;
  setLoadedObjects: (objects: T[]) => void;
  setSelectedIds: (objects: string[]) => void;
  setFilteredObjects: (objects: T[]) => void;
  setFilteredObjectsBrushedOnPCG: (object: T[]) => void;
  setFilterControlOptionsDictionary: (object: PCGAxesDto[]) => void;
  setFilterControlOptionsDictionaryReversed: (object: {
    [key: string]: string;
  }) => void;
  setFilterControlSelectedAxes: (object: string[]) => void;
  setIsObjectsLoaded: (newValue: boolean) => void;
  setHoveredObject: (newValue: T | null) => void;
  setIsReset: (boolValue: boolean) => void;
  setIsAdjustScale: (boolValue: boolean) => void;
  setClickableAxes: (newValue: string[]) => void;
  setSelectedAxes: (newValue: PCGAxesDto[]) => void;
  setObjectsTotalCount: (objectsTotalCount: number) => void;
  setCurrentObject: (newValue: T | null) => void;
  setIsOpenedDetailedView: (newValue: boolean) => void;
  setProjectAddressFeature: (
    projectAddressFeature: ProjectAddressFeatureDto
  ) => void;
  setSelectedObject: (newValue: DatacenterDto | null) => void;
  setSelectedModulesCodes: (newValue: string[]) => void;
  setStartDrawingPlot: (newValue: boolean) => void;
  setEditDrawingPlot: (boolValue: boolean) => void;
  setDeleteDrawingPlot: (boolValue: boolean) => void;
  setNavigateDrawingPlot: (newValue: boolean) => void;
  setIsInputPaneOpen: (boolValue: boolean) => void;
  setCurrentTabPageIndex: (newValue: number) => void;
  setExportTabDisabled: (boolValue: boolean) => void;
  setDistanceBetweenPlotAndRoad: (values: number) => void;
  setCalculateDistance: (values: boolean) => void;
  setCurrentPageType: (value: TypeObject | null) => void;
  setIsSaveAvailable: (value: boolean) => void;
  setDrawControlMode: (value: string) => void;
  setGeneratedBuildingsIds: (
    newValue: { code: string; count: number }[]
  ) => void;
  setSelectedExportedBuildingsIds: (
    values: { code: string; count: number }[]
  ) => void;
  resetState: () => void;
}

export function getBaseInitialState<T extends DTO>(): ExploreState<T> {
  return {
    objects: null,
    loadedObjects: [],
    selectedIds: [],
    brushedObjects: [],
    filteredObjects: [],
    filteredObjectsBrushedOnPCG: [],
    hoveredObject: null,
    isObjectsLoaded: false,
    objectSortProperty: "Best fit rate",
    objectSortAscending: true,
    isReset: false,
    isAdjustScale: false,
    dimensions: [],
    chartLabels: [],
    clickableAxes: [],
    selectedAxes: [],
    filterControlOptionsDictionary: [],
    filterControlOptionsDictionaryReversed: {},
    filterControlSelectedAxes: [],
    objectsTotalCount: 0,
    currentObject: null,
    isOpenedDetailedView: false,
    selectedObject: null,
    selectedModulesCodes: [],
    currentPageType: null,
    projectAddressFeature: null,
    startDrawingPlot: false,
    editDrawingPlot: false,
    deleteDrawingPlot: false,
    navigateDrawingPlot: false,
    isInputPaneOpen: true,
    currentTabPageIndex: 0,
    exportTabDisabled: false,
    calculateDistance: false,
    distanceBetweenPlotAndRoad: 0,
    isSaveAvailable: false,
    drawControlMode: "",
    generatedBuildingsIds: [],
    selectedExportedBuilingsIds: [],
  };
}

export function createExploreStore<T extends DTO>() {
  return create<ExploreState<T> & ExploreActions<T>>((set, get) => ({
    ...getBaseInitialState<T>(),
    setSelectedAxes: (newValue) => set({ selectedAxes: newValue }),
    setObjects: (newObjects) =>
      set({
        objects: newObjects,
        loadedObjects: newObjects === null ? [] : get().loadedObjects,
        filteredObjects: newObjects === null ? [] : get().filteredObjects,
        filteredObjectsBrushedOnPCG:
          newObjects === null ? [] : get().filteredObjectsBrushedOnPCG,
        isObjectsLoaded: newObjects !== null,
        brushedObjects: newObjects === null ? [] : get().brushedObjects,
      }),
    setObjectsTotalCount: (newValue) => set({ objectsTotalCount: newValue }),
    setObjectSortProperty: (newValue) => set({ objectSortProperty: newValue }),
    setObjectSortAscending: (newValue) =>
      set({ objectSortAscending: newValue }),
    setBrushedObjects: (newValue) => set({ brushedObjects: newValue }),
    setLoadedObjects: (newObjects) => set({ loadedObjects: newObjects }),
    setSelectedIds: (newValue) => set({ selectedIds: newValue }),
    setFilteredObjects: (newValue) => set({ filteredObjects: newValue }),
    setFilteredObjectsBrushedOnPCG: (newValue) =>
      set({ filteredObjectsBrushedOnPCG: newValue }),
    setFilterControlOptionsDictionary: (newValue) =>
      set({ filterControlOptionsDictionary: newValue }),
    setFilterControlOptionsDictionaryReversed: (newValue) =>
      set({ filterControlOptionsDictionaryReversed: newValue }),
    setFilterControlSelectedAxes: (newValue) =>
      set({ filterControlSelectedAxes: newValue }),
    setIsObjectsLoaded: (newValue) => set({ isObjectsLoaded: newValue }),
    setHoveredObject: (newValue) => set({ hoveredObject: newValue }),
    setIsReset: (newValue) => set({ isReset: newValue }),
    setIsAdjustScale: (newValue) => set({ isAdjustScale: newValue }),
    setClickableAxes: (newValue) => set({ clickableAxes: newValue }),
    setCurrentObject: (newValue) => set({ currentObject: newValue }),
    setIsOpenedDetailedView: (newValue) =>
      set({ isOpenedDetailedView: newValue }),
    setSelectedObject: (newValue) => set({ selectedObject: newValue }),
    setSelectedModulesCodes: (newValue) =>
      set({ selectedModulesCodes: newValue }),
    setCurrentPageType: (newValue) => set({ currentPageType: newValue }),
    setProjectAddressFeature: (newValue) =>
      set({ projectAddressFeature: newValue }),
    setStartDrawingPlot: (newValue) => set({ startDrawingPlot: newValue }),
    setEditDrawingPlot: (newValue) => set({ editDrawingPlot: newValue }),
    setDeleteDrawingPlot: (newValue) => set({ deleteDrawingPlot: newValue }),
    setNavigateDrawingPlot: (newValue) =>
      set({ navigateDrawingPlot: newValue }),
    setIsInputPaneOpen: (newValue) => set({ isInputPaneOpen: newValue }),
    setCurrentTabPageIndex: (newValue) =>
      set({ currentTabPageIndex: newValue }),
    setExportTabDisabled: (newValue) => set({ exportTabDisabled: newValue }),
    setDistanceBetweenPlotAndRoad: (newValue) =>
      set({ distanceBetweenPlotAndRoad: newValue }),
    setCalculateDistance: (newValue) => set({ calculateDistance: newValue }),
    setIsSaveAvailable: (newValue) => set({ isSaveAvailable: newValue }),
    setDrawControlMode: (newValue) => set({ drawControlMode: newValue }),
    setGeneratedBuildingsIds: (newValue) =>
      set({ generatedBuildingsIds: newValue }),
    setSelectedExportedBuildingsIds: (newValue) =>
      set({ selectedExportedBuilingsIds: newValue }),
    resetState: () => {
      set({
        ...getBaseInitialState<T>(),
      });
    },
  }));
}
