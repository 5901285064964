import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";

type SelectFieldProps = {
  dataTestId?: string;
  value?: string;
  onChange?: (value: string) => void;
  valueMultiple?: string[];
  onChangeMultiple?: (value: string[]) => void;
  options: string[];
  multiple?: boolean;
  disabled?: boolean;
  error?: boolean;
};

const SelectField = React.memo(
  ({
    value,
    dataTestId,
    onChange,
    valueMultiple,
    onChangeMultiple,
    options,
    multiple = false,
    disabled = false,
    error = false,
  }: SelectFieldProps) => {
    const { t } = useTranslation();
    const safeValue = options && value && options.includes(value) ? value : "";
    return (
      <FormControl fullWidth variant="standard" error={error}>
        <Select
          inputProps={{ "data-testid": dataTestId }}
          sx={{
            marginTop: "5px",
            "&.Mui-disabled": {
              "&:before": { borderBottomStyle: "none" },
              "& .MuiSelect-icon": { display: "none" },
              "& .MuiInputBase-input": {
                color: "#000000",
                WebkitTextFillColor: "#000000",
                whiteSpaceCollapse: "nowrap",
              },
            },
          }}
          multiple={multiple}
          disabled={disabled}
          value={multiple ? valueMultiple : safeValue}
          onChange={(event) =>
            multiple
              ? onChangeMultiple!(event.target.value as string[])
              : onChange!(event.target.value as string)
          }
          renderValue={(selected) => {
            return multiple ? (
              <Box
                component={"div"}
                sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
              >
                {(selected as string[]).map((value) => (
                  <Chip key={value} label={t(value)} />
                ))}
              </Box>
            ) : (
              <ListItemText primary={t(selected)} />
            );
          }}
        >
          {multiple && (
            <MenuItem value="all">
              <ListItemIcon>
                <Checkbox
                  checked={
                    options &&
                    options.length > 0 &&
                    valueMultiple &&
                    valueMultiple.length === options.length
                  }
                  indeterminate={
                    valueMultiple &&
                    options &&
                    valueMultiple.length > 0 &&
                    valueMultiple.length < options.length
                  }
                />
              </ListItemIcon>
              <ListItemText primary={t("Select all")} />
            </MenuItem>
          )}
          {options &&
            options
              .sort((a, b) => i18next.t(a)?.localeCompare(i18next.t(b)))
              .map((name) => (
                <MenuItem key={name} value={name}>
                  {multiple && valueMultiple && (
                    <Checkbox checked={valueMultiple.indexOf(name) > -1} />
                  )}
                  <ListItemText primary={t(name)} />
                </MenuItem>
              ))}
        </Select>
        {error && (
          <FormHelperText>{i18next.t("Field is required")}</FormHelperText>
        )}
      </FormControl>
    );
  }
);

export default SelectField;
