import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import LoadingIndicator from "../../../../components/LoadingIndicator/LoadingIndicator";
import { ForgeApiUrl } from "../../../../config";
import DetailedViewSidebar from "../../../sharedLogic/components/DetailedViewSidebar/DetailedViewSidebar";
import FileUpload from "../../../sharedLogic/components/FileUpload/FileUpload";
import PdfViewer from "../../../sharedLogic/components/PdfViewer/PdfViewer";
import DialogLayout from "../../../sharedLogic/layouts/DialogLayout";
import { useGetApiHook } from "../../../sharedLogic/services/forge/useGridApiHook";
import { DetailedInfo } from "../../../sharedLogic/types/InfoField";
import { MapModule } from "../../services/ModuleService";
import { useModuleDetailsStore } from "../../state/moduleDetailsState";
import { ModuleDto, SupportedValueTypesDto } from "../../types/api";
import { isSpaceCommonAreaDto, isSpaceResidentialDto } from "../../utils/utils";
import { AccesType, ModuleType } from "../../types/enums";

interface ModuleDetailedViewProps {
  currentModule?: ModuleDto | undefined;
  currentModuleCode?: string | undefined;
}

export function ModuleDetailedView({
  currentModule,
  currentModuleCode,
}: ModuleDetailedViewProps) {
  const { t } = useTranslation();
  const moduleUrl = "api/v1/modules";
  const {
    file,
    module,
    isEditing,
    validationErrors,
    selectedAccessType,
    setModule,
    setModuleCode,
    setFile,
    handlePropertyChange,
  } = useModuleDetailsStore();
  const moduleType =
    module === null
      ? ModuleType.Undefined
      : isSpaceResidentialDto(module)
      ? ModuleType.Residential
      : isSpaceCommonAreaDto(module)
      ? ModuleType.CommonArea
      : ModuleType.Component;

  const { data: supportedValues } = useGetApiHook<SupportedValueTypesDto>({
    useCache: true,
    baseUrl: ForgeApiUrl,
    url:
      moduleType === ModuleType.Undefined
        ? ""
        : `${moduleUrl}/supported-values/types/${moduleType}`,
  }) as {
    data: SupportedValueTypesDto;
  };

  const { data: moduleData, loading } = useGetApiHook<ModuleDto>({
    baseUrl: ForgeApiUrl,
    url: currentModuleCode ? `${moduleUrl}/${currentModuleCode}` : "",
  });

  const getModuleSpecificProperties = () => {
    if (module === null) return [];

    if (isSpaceResidentialDto(module)) {
      return [
        {
          label: "StackedHousing.Position",
          property: "residentPosition",
          values: supportedValues.residentPositions,
          required: true,
          type: "select",
        },
        {
          label: "StackedHousing.OutdoorSpace",
          property: "outsideSpaceType",
          values: supportedValues.outdoorSpaceTypes,
          disabled: isSingleFamily(selectedAccessType),
          required: true,
          type: "select",
        },
        {
          label: "StackedHousing.NrOfFloors",
          property: "nrOfFloors",
          type: "number",
          editable: true,
        },
      ];
    }
    return [];
  };

  const isSingleFamily = (accessType: string) => {
    return accessType === AccesType.HSASingleFamilyHome;
  };

  const getModuleSpecificDimensions = () => {
    if (module === null) return [];

    if (isSpaceResidentialDto(module)) {
      return [
        {
          label: "StackedHousing.BayWidth1",
          property: "bayWidth1",
          unit: "mm",
          required: true,
          type: "number",
        },
        {
          label: "StackedHousing.BayWidth2",
          property: "bayWidth2",
          unit: "mm",
          required: true,
          type: "number",
        },
        {
          label: "BVO",
          property: "grossFloorAreaInM2",
          unit: "m²",
          type: "number",
          editable: true,
        },
        {
          label: "GO",
          property: "usableAreaInM2",
          unit: "m²",
          required: true,
          type: "number",
          editable: true,
        },
        {
          label: "StackedHousing.BVOOutdoor",
          property: "grossFloorAreaOutdoorInM2",
          unit: "m²",
          required: true,
          type: "number",
          editable: true,
        },
      ];
    } else if (isSpaceCommonAreaDto(module)) {
      return [
        {
          label: "StackedHousing.BayWidth1",
          property: "bayWidth1",
          unit: "mm",
          required: true,
          type: "number",
        },
        {
          label: "StackedHousing.BayWidth2",
          property: "bayWidth2",
          unit: "mm",
          required: true,
          type: "number",
        },
        {
          label: "BVO",
          property: "grossFloorAreaInM2",
          unit: "m²",
          type: "number",
          editable: true,
        },
      ];
    }
    return [
      {
        label: "BVO",
        property: "grossFloorAreaInM2",
        unit: "m²",
        type: "number",
        editable: true,
      },
    ];
  };

  const getModuleSpecificCosts = () => {
    if (module === null) return [];

    if (isSpaceResidentialDto(module)) {
      return [
        {
          label: "StackedHousing.RentalPoints",
          property: "rentalPoints",
          unit: "st",
          type: "number",
          editable: true,
        },
      ];
    }
    return [];
  };

  const detailedInfo: DetailedInfo[] = [
    {
      category: "Properties",
      fields: [
        {
          label: "StackedHousing.TypeHSA",
          property: "codeBusiness",
          required: true,
          type: "text",
          editable: true,
        },
        {
          label: "Description",
          property: "description",
          required: true,
          type: "text",
          editable: true,
        },
        {
          label: "StackedHousing.ModuleDetails.AccessType",
          property: "accessType",
          values: supportedValues?.accessTypes?.filter((x) => x !== "None"),
          required: true,
          type: "select",
        },
        {
          label: "StackedHousing.ModuleDetails.ModuleType",
          property: "spaceType",
          values: supportedValues?.spaceTypes?.filter((x) => x !== "None"),
          required: true,
          type: "select",
        },
        {
          label: "StackedHousing.BuildingLayer",
          property: "buildingLayer",
          values: supportedValues?.buildingLayers,
          required: true,
          type: "select",
          disabled: isSingleFamily(selectedAccessType),
        },
        ...getModuleSpecificProperties(),
        {
          label: "StackedHousing.Rotatable",
          property: "isRotatable",
          defaultValue: isSingleFamily(selectedAccessType) ? false : undefined,
          disabled: isSingleFamily(selectedAccessType) ? true : false,
          type: "boolean",
          editable: true,
        },
        {
          label: "StackedHousing.Mirrorable",
          property: "isMirrorable",
          defaultValue: isSingleFamily(selectedAccessType) ? true : undefined,
          disabled: isSingleFamily(selectedAccessType) ? true : false,
          type: "boolean",
          editable: true,
        },
      ],
    },
    {
      category: "Dimensions",
      fields: [
        {
          label: "Width",
          property: "dimensions.xSizeInMm",
          unit: "mm",
          required: true,
          type: "number",
        },
        {
          label: "Depth",
          property: "dimensions.ySizeInMm",
          unit: "mm",
          required: true,
          type: "number",
        },
        {
          label: "Height",
          property: "dimensions.zSizeInMm",
          unit: "mm",
          required: true,
          type: "number",
        },
        ...getModuleSpecificDimensions(),
      ],
    },
    {
      category: "Sustainability",
      fields: [
        {
          label: "StackedHousing.CO2LCA1",
          property: "cO2LCA1",
          unit: "kg",
          type: "number",
          editable: true,
        },
        {
          label: "StackedHousing.CO2LCA2",
          property: "cO2LCA2",
          unit: "kg",
          type: "number",
          editable: true,
        },
        {
          label: "StackedHousing.CO2DGBC",
          property: "cO2DGBC",
          unit: "kg",
          type: "number",
          editable: true,
        },
        {
          label: "StackedHousing.LifeSpan",
          property: "lifeSpan",
          unit: t("StackedHousing.Year").toLocaleLowerCase(),
          type: "number",
          editable: true,
        },
      ],
    },
    {
      category: "Costs",
      fields: [
        {
          label: "StackedHousing.UnitPrice",
          property: "costInEuro",
          unit: "€",
          type: "number",
          editable: true,
        },
        ...getModuleSpecificCosts(),
      ],
    },
    {
      category: "Owner",
      fields: [
        {
          label: "StackedHousing.Internal",
          property: "owner",
          disabled: true,
          type: "text",
        },
      ],
    },
  ] as DetailedInfo[];

  useEffect(() => {
    const moduleDetailed = moduleData as ModuleDto;
    if (moduleDetailed && moduleDetailed.code && moduleDetailed.code !== "") {
      setModule(MapModule(moduleDetailed));
    } else {
      setModule(currentModule as ModuleDto);
    }
  }, [moduleData, setModule]);

  useEffect(() => {
    if (currentModuleCode) {
      setModuleCode(currentModuleCode);
    }
  }, [currentModuleCode]);

  if (loading || !module) {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <DialogLayout
      dialogView={
        file ? (
          <PdfViewer file={file} />
        ) : (
          <FileUpload
            isEditing={isEditing}
            onFilesSelected={(files: File[]) => {
              const nextFile = files?.[0];
              if (nextFile) {
                setFile(nextFile);
              }
            }}
          />
        )
      }
      dialogSidebar={
        <DetailedViewSidebar
          data={module}
          editing={isEditing}
          isNew={!module.code || module.code === ""}
          detailedInfo={detailedInfo}
          validationErrors={validationErrors}
          onPropertyChange={(
            path: string,
            newValue: string | boolean | number
          ) => handlePropertyChange(path, newValue)}
        />
      }
    />
  );
}
