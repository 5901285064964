import { ValveRoomDto } from "../../../interfaces/foundry/typicals/ValveRoomDto";
import ResponseDto from "../../../interfaces/ResponseDto";
import { TrackException } from "../../../logging/LoggingManager";
import useGeneralDatacenterObjectsStore from "../../../state/DatacenterState/generalDatacenterObjectsState";
import { foundryApiClient } from "../../../modules/sharedLogic/services/AxiosClient";
import { AxiosError } from "axios";

export async function GetValveRoom(id: number): Promise<ValveRoomDto> {
  return (await GetValveRooms()).find((x) => x.id === id) as ValveRoomDto;
}

export async function GetValveRooms(): Promise<ValveRoomDto[]> {
  const store = useGeneralDatacenterObjectsStore.getState();
  let valveRooms = store.valveRooms;
  if (valveRooms.length === 0) {
    valveRooms = await GetValveRoomDtos();
    store.setValveRooms(valveRooms);
  }
  return valveRooms;
}

async function GetValveRoomDtos(): Promise<ValveRoomDto[]> {
  try {
    const valveRooms = (
      (await foundryApiClient.get<ResponseDto>("/api/datacenter/valverooms"))
        .data.results as ValveRoomDto[]
    ).map((item: ValveRoomDto) => {
      item = {
        ...item,
        type: item.display_name,
      };
      return item;
    });
    return valveRooms;
  } catch (ex) {
    TrackException(ex as AxiosError);
    throw ex;
  }
}
