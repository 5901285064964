import { TypeObject } from "../../../interfaces/enums/TypeObjectEnum";
import { RackDto } from "../../../interfaces/foundry/typicals/RackDto";
import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import { EllipseIcon } from "../../../icons/EllipseIcon";
import { useTranslation } from "react-i18next";
import { TransformatorDto } from "../../../interfaces/foundry/typicals/TransformatorDto";
import { UpsDto } from "../../../interfaces/foundry/typicals/UPSDto";
import { BatteryDto } from "../../../interfaces/foundry/typicals/BatteryDto";
import { BaseTypicalDto } from "../../../interfaces/foundry/typicals/BaseTypicalDto";
import useGeneralDatacenterObjectsStore from "../../../state/DatacenterState/generalDatacenterObjectsState";

interface ComponentInfoCardProps {
  selected: boolean;
  canEdit?: boolean;
  canSelect?: boolean;
  currentObject: BaseTypicalDto;
  onRename?: (project: RackDto | TransformatorDto) => void;
  onDelete?: (project: RackDto | TransformatorDto) => void;
  onSelect: (selectedObject: BaseTypicalDto) => void;
}

export default function ComponentInfoCard({
  selected,
  canEdit = true,
  canSelect = true,
  currentObject,
  onRename,
  onDelete,
  onSelect,
}: ComponentInfoCardProps) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [popOverOpen, setPopOverOpen] = React.useState<boolean>(false);
  const { suppliers } = useGeneralDatacenterObjectsStore();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setPopOverOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPopOverOpen(false);
  };

  const handleRenamingProject = () => {
    setPopOverOpen(false);
    if (onRename) {
      currentObject.typeObject === TypeObject.Rack
        ? onRename(currentObject as RackDto)
        : onRename(currentObject as TransformatorDto);
    }
  };

  const handleDeletingProject = () => {
    setPopOverOpen(false);
    if (onDelete) onDelete(currentObject as RackDto);
  };

  function getRackDetails(currentRack: RackDto) {
    const rack = currentObject as RackDto;
    return (
      <>
        <CardHeader
          sx={{ paddingLeft: "2px" }}
          title={
            <Typography
              variant="h6"
              component="h2"
              style={{ fontWeight: "bold" }}
            >
              {canSelect && (
                <Checkbox
                  checked={selected}
                  onChange={() => onSelect(currentRack)}
                />
              )}
               {currentRack.display_name
                ? currentRack.display_name
                : `${t("Server rack")} ${currentRack.id}`}
              {getObjectsBuildingTypes()}
            </Typography>
          }
        />
        <CardContent style={{ marginTop: "-16px" }}>
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <Typography variant="body1">{t("Power")}:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{rack.power_in_w} kW</Typography>
                </Grid>

                <Grid item xs={6}>
                  <Divider />
                  <Typography variant="body1">{t("Length")}:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Divider />
                  <Typography variant="body1">{rack.length} mm</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Divider />
                  <Typography variant="body1">{t("Width")}:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Divider />
                  <Typography variant="body1">{rack.width} mm</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Divider />
                  <Typography variant="body1">{t("Height")}:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Divider />
                  <Typography variant="body1">{rack.height} mm</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "-30px",
              }}
            >
              <img
                src={
                  "/assets/tile_images/componenten/Componenten_Image_server racks.png"
                }
                alt="Rack"
                className="max-width-img"
              />
            </Grid>
          </Grid>
        </CardContent>
      </>
    );
  }

  function getLabelColor(buildingType: string) {
    switch (buildingType) {
      case "Whitespaces":
        return "green";
      case "LSRs":
        return "Orange";
      case "Offices":
        return "Red";
      default:
        return "none";
    }
  }
  function getObjectsBuildingTypes() {
    let component: BaseTypicalDto | null = null;
    switch (currentObject.typeObject) {
      case TypeObject.Transformator:
        component = currentObject as TransformatorDto;
        break;
      case TypeObject.Rack:
        component = currentObject as RackDto;
        break;
      case TypeObject.Ups:
        component = currentObject as UpsDto;
        break;
      case TypeObject.Battery:
        component = currentObject as BatteryDto;
        break;
      default:
        break;
    }

    const BuildingTypeelabels: JSX.Element[] = [];

    for (let i = 0; i < component?.typical_types?.length!; i++) {
      const style = {
        maxWidth: "10px !important",
        height: "10px !important",
        backgroundColor: getLabelColor(component?.typical_types[i]?.name!),
        borderRadius: "50%", // Makes the div a circle
        display: "inline-block",
        marginLeft: "5px !important",
      };
      const tooltipTitle = component?.typical_types[i].name;
      BuildingTypeelabels.push(
        <Tooltip title={tooltipTitle} key={`buildingType-${i}`}>
          <Grid container xs={1} sx={style}></Grid>
        </Tooltip>
      );
    }
    return BuildingTypeelabels;
  }

  function getTransformatorDetails(currentTransformator: TransformatorDto) {
    return (
      <>
        <CardHeader
          sx={{ paddingLeft: "2px" }}
          title={
            <Typography
              variant="h6"
              component="h2"
              style={{ fontWeight: "bold" }}
            >
              {canSelect && (
                <Checkbox
                  checked={selected}
                  onChange={() => onSelect(currentTransformator)}
                />
              )}
              {currentTransformator.supplier_component_type
                ? currentTransformator.supplier_component_type
                : `${t("Transformator")} ${currentTransformator.id}`}
              {getObjectsBuildingTypes()}
            </Typography>
          }
        />
        <CardContent style={{ marginTop: "-16px" }}>
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <Typography variant="body1">{t("Supplier")}:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {
                      suppliers.find(
                        (x) => x.id === currentTransformator.supplier
                      )?.name
                    }
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Divider />
                  <Typography variant="body1">{t("Power")}:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Divider />
                  <Typography variant="body1">
                    {currentTransformator.power_in_kva} kW
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Divider />
                  <Typography variant="body1">{t("Efficiency")}:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Divider />
                  <Typography variant="body1">
                    {currentTransformator.efficiency}%
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Divider />
                  <Typography variant="body1">{t("Length")}:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Divider />
                  <Typography variant="body1">
                    {currentTransformator.length} mm
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Divider />
                  <Typography variant="body1">{t("Width")}:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Divider />
                  <Typography variant="body1">
                    {currentTransformator.width} mm
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Divider />
                  <Typography variant="body1">{t("Height")}:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Divider />
                  <Typography variant="body1">
                    {currentTransformator.height} mm
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "-25px",
              }}
            >
              <img
                src={
                  "/assets/tile_images/componenten/Componenten_Image_transformatoren.jpg"
                }
                alt="Rack"
                className="max-width-img"
              />
            </Grid>
          </Grid>
        </CardContent>
      </>
    );
  }

  function getUpsDetails(currentUps: UpsDto) {
    return (
      <>
        <CardHeader
          sx={{ paddingLeft: "2px" }}
          title={
            <Typography
              variant="h6"
              component="h2"
              style={{ fontWeight: "bold" }}
            >
              {canSelect && (
                <Checkbox
                  checked={selected}
                  onChange={() => onSelect(currentUps)}
                />
              )}
              {currentUps.supplier_component_type
                ? currentUps.supplier_component_type
                : `${t("Ups")} ${currentUps.id}`}
              {getObjectsBuildingTypes()}
            </Typography>
          }
        />
        <CardContent style={{ marginTop: "-16px" }}>
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <Grid container spacing={0}>
                <Grid item xs={7}>
                  <Typography variant="body1">{t("Supplier")}:</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body1">
                    {suppliers.find((x) => x.id === currentUps.supplier)?.name}
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Divider />
                  <Typography variant="body1">{t("Power")}:</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Divider />
                  <Typography variant="body1">
                    {currentUps.power_in_kva} kW
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Divider />
                  <Typography variant="body1">{t("Heatload")}:</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Divider />
                  <Typography variant="body1">
                    {currentUps.heatload_in_w} kW
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Divider />
                  <Typography variant="body1">{t("Length")}:</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Divider />
                  <Typography variant="body1">
                    {currentUps.length} mm
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Divider />
                  <Typography variant="body1">{t("Width")}:</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Divider />
                  <Typography variant="body1">{currentUps.width} mm</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Divider />
                  <Typography variant="body1">{t("Height")}:</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Divider />
                  <Typography variant="body1">
                    {currentUps.height} mm
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "-25px",
              }}
            >
              <img
                src={
                  "/assets/tile_images/componenten/Componenten_Image_ups.png"
                }
                alt="Ups"
                className="max-width-img"
              />
            </Grid>
          </Grid>
        </CardContent>
      </>
    );
  }

  function getBatteryDetails(currentBattery: BatteryDto) {
    return (
      <>
        <CardHeader
          sx={{ paddingLeft: "2px" }}
          title={
            <Typography
              variant="h6"
              component="h2"
              style={{ fontWeight: "bold" }}
            >
              {canSelect && (
                <Checkbox
                  checked={selected}
                  onChange={() => onSelect(currentBattery)}
                />
              )}
              {currentBattery.supplier_component_type
                ? currentBattery.supplier_component_type
                : `${t("Battery")} ${currentBattery.id}`
              }
              {getObjectsBuildingTypes()}
            </Typography>
          }
        />
        <CardContent style={{ marginTop: "-16px" }}>
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <Grid container spacing={0}>
                <Grid item xs={6}>
                  <Typography variant="body1">{t("Supplier")}:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {
                      suppliers.find((x) => x.id === currentBattery.supplier)
                        ?.name
                    }
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Divider />
                  <Typography variant="body1">{t("Power")}:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Divider />
                  <Typography variant="body1">
                    {currentBattery.power_in_kw_5_min} kW
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Divider />
                  <Typography variant="body1">{t("Length")}:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Divider />
                  <Typography variant="body1">
                    {currentBattery.length} mm
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Divider />
                  <Typography variant="body1">{t("Width")}:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Divider />
                  <Typography variant="body1">
                    {currentBattery.width} mm
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Divider />
                  <Typography variant="body1">{t("Height")}:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Divider />
                  <Typography variant="body1">
                    {currentBattery.height} mm
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "-25px",
              }}
            >
              <img
                src={
                  "/assets/tile_images/componenten/Componenten_Image_batteries.png"
                }
                alt="Battery"
                className="max-width-img"
              />
            </Grid>
          </Grid>
        </CardContent>
      </>
    );
  }

  return (
    <Card
      sx={{
        minWidth: "350px",
        maxWidth: "350px",
        margin: "8px",
        padding: "8px",
      }}
    >
      {currentObject.typeObject === TypeObject.Rack &&
        getRackDetails(currentObject as RackDto)}
      {currentObject.typeObject === TypeObject.Transformator &&
        getTransformatorDetails(currentObject as TransformatorDto)}
      {currentObject.typeObject === TypeObject.Ups &&
        getUpsDetails(currentObject as UpsDto)}
      {currentObject.typeObject === TypeObject.Battery &&
        getBatteryDetails(currentObject as BatteryDto)}

      {canEdit && (
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button size="small">Details</Button>
          <IconButton
            style={{
              display: "flex",
              marginRight: "-20px",
              placeItems: "center end",
            }}
            aria-describedby={popOverOpen ? "simple-popover" : undefined}
            onClick={handleClick}
          >
            <EllipseIcon className="icon" size={25} />
          </IconButton>

          <Popover
            id="simple-popover"
            open={popOverOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div>
              <Typography
                style={{ cursor: "pointer" }}
                sx={{ p: 2 }}
                onClick={handleRenamingProject}
              >
                {t("Edit")}
              </Typography>

              <Typography
                style={{ cursor: "pointer" }}
                sx={{ p: 2 }}
                onClick={handleDeletingProject}
              >
                {t("Remove")}
              </Typography>
            </div>
          </Popover>
        </CardActions>
      )}
    </Card>
  );
}
