import {
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridFilterItem,
  GridRenderCellParams,
  GridRowModel,
} from "@mui/x-data-grid";
import { enUS, nlNL } from "@mui/x-data-grid/locales";
import { useCallback, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useLayoutStore from "../../../sharedLogic/state/layoutState";
import { DataGridContainer } from "../../../sharedLogic/components/DataGridContainer/DataGridContainer";
import { useDialog } from "../../../sharedLogic/components/Dialog/CustomDialog";
import { FilterPanel } from "../../../sharedLogic/components/FilterPanel/FilterPanel";
import LeftSidebarViewLayout from "../../../sharedLogic/layouts/LeftSidebarViewLayout";
import { TrackException } from "../../../sharedLogic/logging/LoggingManager";
import useUserStore from "../../../sharedLogic/state/userState";
import {
  FilterOptions,
  RelationMatrixEntry,
  RelationshipType,
  relationshipTypeData,
} from "../../../sharedLogic/types/RelationshipDto";
import {
  EditModuleRelation,
  GetFilteredModuleRelations,
  GetFilterOptions,
  GetModuleRelations,
} from "../../services/ModuleRelationsService";
import { useModuleDetailsStore } from "../../state/moduleDetailsState";
import { ModuleDetailedView } from "../ModuleDetailedView/ModuleDetailedView";
import { ModuleDetailedDialogHeader } from "../ModuleDetailedView/ModuleDetailedViewHeader";
import { FilterDefinition } from "../../../sharedLogic/components/FilterableDataGrid/FilterableDataGrid";
import { AccesType } from "../../types/enums";

interface BaseMatrixProps {
  accessType: AccesType;
}

export function BaseMatrix({ accessType }: BaseMatrixProps) {
  const { t } = useTranslation();
  const { appAbility } = useUserStore();
  const { openDialog, closeDialog } = useDialog();
  const navigate = useNavigate();
  const { selectedLang } = useLayoutStore();
  const [uniqueReferences, setUniqueReferences] = useState<string[]>([]);
  const [relationships, setRelationships] = useState<
    RelationMatrixEntry[] | null
  >(null);
  const selectedValueRef = useRef<RelationshipType[]>([]);
  const { module, newModuleCode, setNewModuleCode } = useModuleDetailsStore();
  const [data, setData] = useState<RelationMatrixEntry[] | null>(null);
  const [filterOptions, setFilterOptions] = useState<FilterOptions | null>();
  const [filterDefinitions, setFilterDefinitions] = useState<
    FilterDefinition[]
  >([]);
  const [selectedFilterOptions, setSelectedFilterOptions] =
    useState<FilterOptions>();
  const [rows, setRows] = useState<GridRowModel[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await GetModuleRelations(accessType);
        setData(data);
        const filterOptions = await GetFilterOptions(accessType);
        setFilterOptions(filterOptions);
      } catch (error) {
        TrackException(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (filterOptions) {
      const newFilterItems: FilterDefinition[] = [];
      if (filterOptions.moduleTypes) {
        newFilterItems.push({
          key: "moduleType",
          label: "Type",
          type: "checkbox",
          options: filterOptions.moduleTypes,
        });
      }
      if (filterOptions.spaceTypes) {
        newFilterItems.push({
          key: "spaceType",
          label: "StackedHousing.ModuleType",
          type: "checkbox",
          options: filterOptions.spaceTypes,
        });
      }
      if (filterOptions.buildingLayers) {
        newFilterItems.push({
          key: "buildingLayer",
          label: "StackedHousing.BuildingLayer",
          type: "checkbox",
          options: filterOptions.buildingLayers,
        });
      }

      setFilterDefinitions(newFilterItems);
    }
  }, [filterOptions]);

  useEffect(() => {
    if (!data) return;

    setRelationships(data);

    const allReferences = data.flatMap((entry) => {
      const relationKeys = Object.keys(entry.moduleCodeRelations);
      return relationKeys.length === 0 ? [entry.moduleCode] : relationKeys;
    });

    const currentModuleCodes = data.map((entry) => entry.moduleCode);
    const uniqueReferences = [...new Set(allReferences)].filter((ref) =>
      currentModuleCodes.includes(ref)
    );

    setUniqueReferences(uniqueReferences);
  }, [module, data]);

  useEffect(() => {
    if (relationships) {
      if (newModuleCode !== "") {
        const newModuleEntry = relationships.find(
          (entry) => entry.moduleCode === newModuleCode
        );
        if (newModuleEntry) {
          const newRow = {
            id: newModuleCode,
            moduleCode: newModuleEntry.moduleCode,
            codeBusiness: newModuleEntry.codeBusiness,
            ...newModuleEntry.moduleCodeRelations,
          };
          setRows([newRow]);

          return;
        }
      }

      const rows = relationships.map((entry) => ({
        id: entry.moduleCode,
        moduleCode: entry.moduleCode,
        codeBusiness: entry.codeBusiness,
        ...entry.moduleCodeRelations,
      }));
      setRows(rows);

      setNewModuleCode("");
    }
  }, [relationships, newModuleCode]);

  const fetchModuleRelationsData = async (
    selectedFilterOptions: FilterOptions | undefined
  ) => {
    try {
      const data =
        selectedFilterOptions &&
        (selectedFilterOptions.buildingLayers.length > 0 ||
          selectedFilterOptions.moduleTypes.length > 0 ||
          selectedFilterOptions.spaceTypes.length > 0)
          ? await GetFilteredModuleRelations(accessType, selectedFilterOptions)
          : await GetModuleRelations(accessType);
      setData(data);
    } catch (error) {
      TrackException(error);
    }
  };

  const onChangeCurrentFilterItems = (filterItems: GridFilterItem[]) => {
    const selectedFilterOptions: FilterOptions = {
      spaceTypes: filterItems
        .filter((item) => item.field === "spaceType")
        .map((item) => item.value)
        .flat(),
      buildingLayers: filterItems
        .filter((item) => item.field === "buildingLayer")
        .map((item) => item.value)
        .flat(),
      moduleTypes: filterItems
        .filter((item) => item.field === "moduleType")
        .map((item) => item.value)
        .flat(),
    };

    setSelectedFilterOptions(selectedFilterOptions);
    fetchModuleRelationsData(selectedFilterOptions);
  };

  const updateRelation = async (
    refSpaceCode: string,
    neighbourSpaceCode: string,
    directions: RelationshipType[]
  ) => {
    try {
      await EditModuleRelation({
        refSpaceCode: refSpaceCode,
        neighbourSpaceCode: neighbourSpaceCode,
        directions: directions,
      });
      fetchModuleRelationsData(selectedFilterOptions);
    } catch (error) {
      console.error("Error updating relation", error);
    }
  };

  const relationshipOptions = [
    "Above",
    "Below",
    "Left",
    "Right",
    "Front",
    "Back",
    "InOtherSpace",
  ];

  function getColorForCombination(letters: string): string {
    const normalizedLetters = letters.split("").sort().join("");

    // Search for the matching entry in relationshipTypeData
    for (const key in relationshipTypeData) {
      const dataLetters = relationshipTypeData[key as RelationshipType].letter
        .split("")
        .sort()
        .join("");
      if (dataLetters === normalizedLetters) {
        return relationshipTypeData[key as RelationshipType].baseColor;
      }
    }

    return "RGB(255, 255, 255)";
  }

  const moduleCodeOrder = relationships
    ? relationships.reduce((acc, entry, index) => {
        acc[entry.moduleCode] = index;
        return acc;
      }, {} as Record<string, number>)
    : {};

  uniqueReferences.sort((a, b) => {
    const indexA =
      moduleCodeOrder[a] !== undefined ? moduleCodeOrder[a] : Infinity;
    const indexB =
      moduleCodeOrder[b] !== undefined ? moduleCodeOrder[b] : Infinity;
    return indexA - indexB;
  });

  const referenceToCodeBusiness = uniqueReferences.reduce((acc, moduleCode) => {
    const entry = relationships?.find(
      (entry) => entry.moduleCode === moduleCode
    );
    if (entry) {
      acc[moduleCode] = entry.codeBusiness;
    }
    return acc;
  }, {} as Record<string, string>);

  const columns: GridColDef[] = [
    {
      field: "codeBusiness",
      headerName: "Headers",
      width: 120,
      renderHeader: () => (
        <div
          style={{
            width: "120px",
            height: "100px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-end",
            textAlign: "right",
            paddingRight: "8px",
          }}
        >
          <Typography
            variant="body1"
            fontWeight="bold"
            sx={{
              writingMode: "vertical-rl",
              transform: "rotate(180deg)",
            }}
          >
            {t("StackedHousing.SwitchMatrix.RelationObject")}
          </Typography>
          <Typography variant="body1" fontWeight="bold">
            {t("StackedHousing.SwitchMatrix.ReferenceObject")}
          </Typography>
        </div>
      ),
      editable: false,
      renderCell: (params: GridRenderCellParams) => (
        <div
          onClick={() => handleModuleClick(params.row.moduleCode)}
          style={{
            cursor: "pointer",
            padding: "5px",
            width: "100%",
            minWidth: "50px",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              overflow: "visible",
              minWidth: "50px !important",
            }}
          >
            {params.value}
          </Typography>
        </div>
      ),
    },
    ...uniqueReferences.map((colKey) => ({
      field: colKey,
      headerName: referenceToCodeBusiness[colKey] || colKey,
      sortable: false,
      resizable: false,
      editable: true,
      minWidth: 20,
      maxWidth: 60,
      width: 60,
      renderHeader: (params: { field: string }) => (
        <div
          onClick={() => handleModuleClick(params.field)}
          style={{
            cursor: "pointer",
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-end",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              transform: "rotate(-90deg)",
              transformOrigin: "40px 20px",
              whiteSpace: "nowrap",
              overflow: "visible",
              transition: "text-decoration 0.3s",
              minWidth: "50px",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            {referenceToCodeBusiness[colKey] || colKey}
          </Typography>
        </div>
      ),
      renderCell: (params: GridRenderCellParams) => {
        const relationships = params.value as RelationshipType[];
        if (!relationships || relationships.length === 0) {
          return (
            <div
              style={{
                padding: "4px",
                textAlign: "center",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "0.6rem",
                whiteSpace: "nowrap",
                fontWeight: "bold",
              }}
            >
              -
            </div>
          );
        }

        const relationshipLetters = relationships
          .map((rel) => relationshipTypeData[rel].letter)
          .sort((a, b) => a.localeCompare(b))
          .join("");

        const relationshipLettersWithTranslation = relationships
          .map((rel) => t(relationshipTypeData[rel].letter))
          .sort((a, b) => a.localeCompare(b))
          .join("");

        const color = getColorForCombination(relationshipLetters);
        return (
          <div
            style={{
              backgroundColor: color,
              padding: "4px",
              textAlign: "center",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "0.8rem",
              whiteSpace: "nowrap",
              fontWeight: "bold",
            }}
          >
            {relationshipLettersWithTranslation}
          </div>
        );
      },
      renderEditCell: (params: GridRenderCellParams) => (
        <Select
          multiple
          value={params.value || []}
          onChange={(event) => {
            const value = event.target.value;
            const updatedData = [...value];
            params.api.setEditCellValue(
              { id: params.id, field: colKey, value: updatedData },
              event
            );
            selectedValueRef.current = value;
          }}
          onClose={async () => {
            const rowKey = params.id.toString();
            const colKey = params.field;
            params.api.setEditCellValue({
              id: params.id,
              field: colKey,
              value: selectedValueRef.current,
            });
            await updateRelation(rowKey, colKey, selectedValueRef.current);

            // Close the dropdown menu
            params.api.stopCellEditMode({ id: params.id, field: colKey });
          }}
          renderValue={(selected) => (selected as string[]).join(", ")}
          displayEmpty
          variant="outlined"
          size="small"
          fullWidth
        >
          {relationshipOptions.map((option) => (
            <MenuItem key={option} value={option}>
              <Checkbox checked={(params.value || []).indexOf(option) > -1} />
              <ListItemText primary={t(option)} />
            </MenuItem>
          ))}
        </Select>
      ),
    })),
  ];

  const handleProcessRowUpdate = (newRow: GridRowModel) => {
    if (!relationships) return newRow;

    const updatedData = relationships.map((entry) => {
      if (entry.moduleCode === newRow.moduleCode) {
        const updatedModuleCodeRelations = { ...entry.moduleCodeRelations };
        Object.keys(newRow).forEach((colKey) => {
          if (
            colKey !== "id" &&
            colKey !== "moduleCode" &&
            colKey !== "codeBusiness"
          ) {
            updatedModuleCodeRelations[colKey] = newRow[colKey] || [];
          }
        });
        return {
          ...entry,
          ModuleCodeRelations: updatedModuleCodeRelations,
        };
      }
      return entry;
    });

    setRelationships(updatedData);
    return newRow;
  };

  const closeCurrentDialog = useCallback(() => {
    closeDialog();
  }, [closeDialog]);

  const handleModuleClick = (moduleCode: string) => {
    openDialog({
      title: moduleCode,
      content: <ModuleDetailedView currentModuleCode={moduleCode} />,
      width: "75%",
      height: "85%",
      dialogCustomHeader: (
        <ModuleDetailedDialogHeader onClose={closeCurrentDialog} />
      ),
    });
  };

  const MatrixDataGrid = () => {
    return (
      <DataGrid
        rows={rows}
        columns={columns}
        isCellEditable={(params) =>
          appAbility?.can("manage", "catalog.stackedhousing") ?? false
        }
        processRowUpdate={handleProcessRowUpdate}
        disableColumnResize
        disableColumnSelector
        disableColumnFilter
        disableColumnMenu
        disableColumnSorting
        hideFooterPagination
        rowHeight={30}
        localeText={
          selectedLang === "nl-NL"
            ? nlNL.components.MuiDataGrid.defaultProps.localeText
            : enUS.components.MuiDataGrid.defaultProps.localeText
        }
        sx={{
          border: 0,
          ".MuiDataGrid-cell": {
            padding: "0 !important",
            border: "none",
            lineHeight: "1.7rem",
            minHeight: "20px !important",
            maxHeight: "30px !important",
          },
          ".MuiDataGrid-columnHeaders": {
            backgroundColor: "#f0f0f0",
            display: "flex",
            alignItems: "flex-start !important",
          },
          "& .MuiDataGrid-columnHeader:not(:first-of-type)": {
            padding: "0px",
            display: "flex",
            overflow: "visible",
            height: "120px !important",
          },
          ".MuiDataGrid-columnHeaderTitle": {
            transform: "rotate(-90deg)",
            overflow: "visible",
            transformOrigin: "40px 20px",
          },
          ".MuiDataGrid-footerContainer": {
            display: "none",
          },
          ".MuiDataGrid-columnHeaderTitleContainerContent": {
            height: "100%",
          },
          ".MuiDataGrid-iconButtonContainer": {
            display: "none",
          },
          ".MuiDataGrid-columnHeaderTitleContainer": {
            overflow: "visible !important",
          },
        }}
      />
    );
  };

  return (
    <LeftSidebarViewLayout
      withCollapse={true}
      columnOne={
        <FilterPanel
          filters={filterDefinitions}
          setCurrentFilterItems={onChangeCurrentFilterItems}
        />
      }
      columnTwo={
        <DataGridContainer
          title={"StackedHousing.SwitchingMatrix"}
          subtitle={"StackedHousing.ModulesSubtitle"}
          buttons={[
            {
              label: "StackedHousing.ModulesTitle",
              onClick: () => {
                navigate("../catalog/stackedhousing");
              },
            },
            {
              label: "Info",
              onClick: () => {
                openDialog({
                  title: "StackedHousing.SwitchMatrix.TitleInfo",
                  content: (
                    <Grid container xs={12}>
                      <Grid container item xs={6} sx={{ padding: "60px" }}>
                        <Typography variant="body1">
                          <Trans
                            i18nKey="StackedHousing.SwitchMatrix.Info"
                            components={{ br: <br />, u: <u /> }}
                          />
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={6}
                        sx={{
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        {" "}
                        <Grid item xs={8}>
                          <img
                            width={"100%"}
                            src="/assets/tile_images/matrices/matrixInfo.png"
                            alt={t("matrixInfo")}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <img
                            width={"80%"}
                            src="/assets/tile_images/matrices/matrixInfoLegend.png"
                            alt={t("matrixInfo2")}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ),
                  width: "65vw",
                  height: "80vh",
                });
              },
            },
          ]}
          GridComponent={MatrixDataGrid}
        />
      }
    />
  );
}
