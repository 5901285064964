import { GridColDef, GridRowModel } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GetCategories } from "../../../http/aggregate/CategoriesService";
import { GetInputDataByType } from "../../../http/aggregate/InputDataService";
import {
  DeleteEquipmentKeyFigure,
  GetEquipmentKeyFigures,
  SaveEquipmentKeyFigure,
  UpdateEquipmentKeyFigure,
} from "../../../http/aggregate/KeyFigureService";
import { EquipmentKeyFigureDto } from "../../../interfaces/aggregate/EquipmentKeyFigureDto";
import { AggregatePageEnum } from "../../../interfaces/aggregate/enums/AggregatePageEnum";
import { InputDataCatalogEnum } from "../../../interfaces/aggregate/enums/InputDataCatalogEnum";
import { TypeObject } from "../../../interfaces/enums/TypeObjectEnum";
import useUserStore from "../../../modules/sharedLogic/state/userState";
import useDatabaseStore from "../../../state/DatabaseState/databaseState";
import useEquipmentKeyFiguresStore from "../../../state/DatabaseState/equipmentKeyFiguresState";
import { safeNumber } from "../../../utils/HelperFunctions";
import { BaseDatabasePage, groupBy } from "../BaseDatabasePage";
import {
  GridColDefComponent,
  useColumnGroupingModel,
} from "./EquipmentColumnsDef";

interface EquipmentKeyFiguresPageProps {
  pageType?: TypeObject;
}

export function EquipmentKeyFiguresPage({
  pageType,
}: EquipmentKeyFiguresPageProps) {
  const { t } = useTranslation();
  const {
    equipmentKeyFigures,
    setBrands,
    setEquipmentTypes,
    setEquipmentKeyFigures,
  } = useEquipmentKeyFiguresStore();

  const {
    owners,
    selectedNode,
    setCurrentPage,
    setCategories,
    setCurrentDatabaseOptions,
  } = useDatabaseStore();

  const columns: GridColDef[] = GridColDefComponent();

  const filteredColumns =
    pageType !== TypeObject.NitrogenAnalyser
      ? columns.filter((col) => col.field !== "selection")
      : columns;

  const columnGroupingModel = useColumnGroupingModel();

  async function processRowUpdate(
    gridRowModel: GridRowModel
  ): Promise<EquipmentKeyFigureDto> {
    const equipmentKeyFigure = gridRowModel as EquipmentKeyFigureDto;
    equipmentKeyFigure.categoryId =
      equipmentKeyFigure.category?.id || Number(selectedNode);
    equipmentKeyFigure.departmentId = equipmentKeyFigure.department?.id || 0;
    equipmentKeyFigure.brandId = equipmentKeyFigure.brand?.id || 0;
    equipmentKeyFigure.equipmentTypeId =
      equipmentKeyFigure.equipmentType?.id ?? 0;
    equipmentKeyFigure.ownerId = equipmentKeyFigure.owner?.id || 0;
    equipmentKeyFigure.hvoSuitable =
      gridRowModel.hvoSuitable === t("Yes") ? true : false;
    equipmentKeyFigure.adBlueConsumption = safeNumber(
      gridRowModel.adBlueConsumption
    );
    equipmentKeyFigure.carbonEmission = safeNumber(gridRowModel.carbonEmission);
    equipmentKeyFigure.fuelConsumption = safeNumber(
      gridRowModel.fuelConsumption
    );
    equipmentKeyFigure.loadPercentage = safeNumber(gridRowModel.loadPercentage);
    equipmentKeyFigure.powerInKw = safeNumber(gridRowModel.powerInKw);
    equipmentKeyFigure.manufacturedYear = safeNumber(
      gridRowModel.manufacturedYear
    );
    equipmentKeyFigure.fuel = equipmentKeyFigure.fuel;
    equipmentKeyFigure.stageClass = equipmentKeyFigure.stageClass;

    equipmentKeyFigure.stageClass =
      equipmentKeyFigure.stageClass === "Electric"
        ? "SElectric"
        : equipmentKeyFigure.stageClass;

    if (equipmentKeyFigure.id === 0) {
      equipmentKeyFigure.id = await SaveEquipmentKeyFigure(equipmentKeyFigure);
      setEquipmentKeyFigures(
        equipmentKeyFigures.map((item: EquipmentKeyFigureDto) =>
          item.id === 0 ? equipmentKeyFigure : item
        )
      );
    } else {
      const oldKeyFigure = equipmentKeyFigures.find(
        (x) => x.id === equipmentKeyFigure.id
      )!;
      equipmentKeyFigure.categoryId = oldKeyFigure.category.id;

      await UpdateEquipmentKeyFigure(equipmentKeyFigure);
      setEquipmentKeyFigures(
        equipmentKeyFigures.map((item: EquipmentKeyFigureDto) =>
          item.id === equipmentKeyFigure.id ? equipmentKeyFigure : item
        )
      );
    }

    if (equipmentKeyFigure.id !== 0) {
      setTimeout(() => {
        LoadEquipmentKeyFigures();
      }, 500);
    }

    return equipmentKeyFigure;
  }

  useEffect(() => {
    setCurrentPage(AggregatePageEnum.EquipmentKeyFigure);

    (async () => {
      setCategories(await GetCategories(AggregatePageEnum.EquipmentKeyFigure));
      await LoadItems();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (selectedNode !== "") {
        await LoadEquipmentKeyFigures();
      }
    })();
  }, [selectedNode]);

  async function LoadItems() {
    const brands = await GetInputDataByType(
      InputDataCatalogEnum.InputDataBrand
    );
    setBrands(brands);
    setCurrentDatabaseOptions(brands);
    setEquipmentTypes(
      await GetInputDataByType(InputDataCatalogEnum.InputDataEquipmentType)
    );
  }

  async function LoadEquipmentKeyFigures() {
    setEquipmentKeyFigures(await GetEquipmentKeyFigures(selectedNode));
  }

  return (
    <BaseDatabasePage
      pageType={pageType}
      columns={filteredColumns}
      data={groupBy(equipmentKeyFigures, (item) => item.equipmentType?.name)}
      columnGroupingModel={columnGroupingModel}
      addNewItem={(groupKey: string) => {
        const equipmentKeyFigureDto = equipmentKeyFigures.find(
          (x) => x.equipmentType?.name === groupKey
        );
        setEquipmentKeyFigures([
          ...equipmentKeyFigures,
          {
            id: 0,
            description: equipmentKeyFigureDto?.description,
            brand: equipmentKeyFigureDto?.brand,
            equipmentType: equipmentKeyFigureDto?.equipmentType,
            category: equipmentKeyFigureDto?.category,
            owner: owners.find(
              (x) => x.emailAddress === useUserStore.getState().emailAddress
            ),
          } as EquipmentKeyFigureDto,
        ]);
      }}
      baseProcessRowUpdate={processRowUpdate}
      handleCancel={() => {
        setEquipmentKeyFigures(equipmentKeyFigures.filter((x) => x.id !== 0));
      }}
      handleDelete={(params) => {
        DeleteEquipmentKeyFigure(Number(params));
        setEquipmentKeyFigures(
          equipmentKeyFigures.filter((x) => x.id !== params)
        );
      }}
    />
  );
}
