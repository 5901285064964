import React from "react";
import { TrackException } from "../logging/LoggingManager";
import AvailableComponentDto from "../interfaces/AvailableComponentDto";
import { CalculatorIcon } from "../icons/CalculatorIcon";
import { ScrewDriverIcon } from "../icons/ScrewDriverIcon";
import { AnalyserIcon } from "../icons/AnalyserIcon";
import { CatalogIcon } from "../icons/CatalogIcon";
import { DatabaseIcon } from "../icons/DatabaseIcon";
import { HeijmansIntelligenceIcon } from "../icons/HeijmansIntelligenceIcon";
import { AxiosError } from "axios";

export function GetAvailableConfigurators(): AvailableComponentDto[] {
  const datacenterGenerator = {
    title: "Datacenter",
    details: "DatacenterDetails",
    to: "/configurators/datacenter",
    iconComponent: null,
    imageUrl: "/assets/tile_images/generatoren/Generator_Image_Datacenter.png",
    underConstruction: false,
  } as AvailableComponentDto;

  const gestapeldewoningbouwGenerator = {
    title: "Stacked housing",
    details: "StackedHousingDetails",
    to: "/configurators/stackedhousing",
    iconComponent: null,
    imageUrl:
      "/assets/tile_images/generatoren/Generator_Image_Gestapelde_woningbouw.png",
    underConstruction: false,
  } as AvailableComponentDto;

  return [datacenterGenerator, gestapeldewoningbouwGenerator];
}

export function GetAvailableAnalysers(): AvailableComponentDto[] {
  const planning = {
    title: "Planning",
    details: "PlanningDetails",
    to: "/analysers/planning",
    iconComponent: null,
    imageUrl: "/assets/timemanagement.jpg",
    underConstruction: true,
  } as AvailableComponentDto;
  const cost = {
    title: "Cost",
    details: "CostDetails",
    to: "/analysers/cost",
    iconComponent: null,
    imageUrl: "/assets/timemanagement.jpg",
    underConstruction: true,
  } as AvailableComponentDto;
  const mpg_co2 = {
    title: "MPG/CO2",
    details: "MPG/CO2Details",
    to: "/analysers/mpgco2",
    iconComponent: null,
    imageUrl: "/assets/timemanagement.jpg",
    underConstruction: true,
  } as AvailableComponentDto;
  const nitrogen = {
    title: "Nitrogen",
    details: "NitrogenPage.NitrogenDetails",
    to: "/analysers/nitrogen",
    iconComponent: null,
    imageUrl: "/assets/tile_images/analysers/stikstof.png",
    underConstruction: false,
  } as AvailableComponentDto;
  const beng = {
    title: "BENG",
    details: "BENGDetails",
    to: "/analysers/beng",
    iconComponent: null,
    imageUrl: "/assets/timemanagement.jpg",
    underConstruction: true,
  } as AvailableComponentDto;
  return [planning, cost, mpg_co2, nitrogen, beng];
}

export function GetAvailableDatabases(): AvailableComponentDto[] {
  const productionKeyFigures = {
    title: "Production key figures",
    details: "ProductionKeyFiguresDetails",
    to: "/databases/productionkeyfigures",
    iconComponent: null,
    imageUrl:
      "/assets/tile_images/databases/Databases_Image_Productie kengetallen.jpg",
    underConstruction: false,
  } as AvailableComponentDto;
  const materialKeyFigures = {
    title: "Equipment key figures",
    details: "EquipmentKeyFiguresDetails",
    to: "/databases/equipmentkeyfigures",
    iconComponent: null,
    imageUrl:
      "/assets/tile_images/databases/Databases_Image_Materieel kengetallen.png",
    underConstruction: false,
  } as AvailableComponentDto;
  const costKeyFigures = {
    title: "Costs key figures",
    details: "CostKeyFigures",
    to: "/databases/costskeyfigures",
    iconComponent: null,
    imageUrl:
      "/assets/tile_images/databases/Databases_Image_Kosten kengetallen.png",
    underConstruction: false,
  } as AvailableComponentDto;
  const material = {
    title: "Material",
    details: "MaterialDetails",
    to: "/databases/material",
    iconComponent: null,
    imageUrl: "/assets/tile_images/databases/Databases_Image_Materiaal.png",
    underConstruction: true,
  } as AvailableComponentDto;
  return [productionKeyFigures, materialKeyFigures, costKeyFigures, material];
}

export function GetAvailableHeijmansIntelligences(): AvailableComponentDto[] {
  const gestapeldewoningbouwIntelligence = {
    title: "Stacked housing",
    details: "StackedHousingDetailsTooling",
    to: "/heijmansintelligence/stackedhousing",
    iconComponent: null,
    imageUrl:
      "/assets/tile_images/generatoren/Generator_Image_Gestapelde_woningbouw.png",
    underConstruction: false,
    children: GetAvailableStackedHousingIntelligence(),
  } as AvailableComponentDto;

  return [gestapeldewoningbouwIntelligence];
}

export function GetAvailableStackedHousingIntelligence(): AvailableComponentDto[] {
  const galleryMatrix = {
    title: "Gallery",
    details: "StackedHousing.SwitchMatrix.GalleryMatrixDetails",
    to: "/heijmansintelligence/stackedhousing/gallery",
    iconComponent: null,
    imageUrl: "/assets/tile_images/matrices/galleryMatrix.png",
    underConstruction: false,
  } as AvailableComponentDto;
  const corridorMatrix = {
    title: "Corridor",
    details: "StackedHousing.SwitchMatrix.CorridorMatrixDetails",
    to: "/heijmansintelligence/stackedhousing/corridor",
    iconComponent: null,
    imageUrl: "/assets/tile_images/matrices/corridorMatrix.png",
    underConstruction: false,
  } as AvailableComponentDto;
  const porchwayMatrix = {
    title: "Porchway",
    details: "StackedHousing.SwitchMatrix.PorchMatrixDetails",
    to: "/heijmansintelligence/stackedhousing/porchway",
    iconComponent: null,
    imageUrl: "/assets/tile_images/matrices/porchMatrix.png",
    underConstruction: false,
  } as AvailableComponentDto;
  const singleFamilyHomeMatrix = {
    title: "Singlefamilyhome",
    details: "StackedHousing.SwitchMatrix.SingleFamilyHomeMatrixDetails",
    to: "/heijmansintelligence/stackedhousing/singlefamilyhome",
    iconComponent: null,
    imageUrl: "/assets/tile_images/matrices/singleFamlyMatrix.png",
    underConstruction: false,
  } as AvailableComponentDto;

  return [
    galleryMatrix,
    corridorMatrix,
    porchwayMatrix,
    singleFamilyHomeMatrix,
  ];
}

export function GetAvailableGenerators(): AvailableComponentDto[] {
  try {
    const datacenterGenerator = {
      title: "Datacenter",
      details: "DatacenterDetailsTooling",
      to: "/configurators/datacenter",
      iconComponent: null,
      imageUrl:
        "/assets/tile_images/generatoren/Generator_Image_Datacenter.png",
      underConstruction: false,
    } as AvailableComponentDto;
    const gestapeldewoningbouwGenerator = {
      title: "Stacked housing",
      details: "StackedHousingDetailsTooling",
      to: "/configurators/stackedhousing",
      iconComponent: null,
      imageUrl:
        "/assets/tile_images/generatoren/Generator_Image_Gestapelde_woningbouw.png",
      underConstruction: true,
    } as AvailableComponentDto;
    const WhitespaceAndCoolingGenerator = {
      title: "Whitespace and cooling",
      details: "WhitespaceAndCoolingDetailsTooling",
      to: "/generators/whitespace&cooling",
      iconComponent: null,
      imageUrl:
        "/assets/tile_images/generatoren/Generator_Image_Whitespace&koeling.png",
      underConstruction: false,
    } as AvailableComponentDto;
    const lowvoltageRoom = {
      title: "Low voltage room",
      details: "LowVoltageRoomDetailsTooling",
      to: "/generators/lowvoltagerooms",
      iconComponent: null,
      imageUrl:
        "/assets/tile_images/generatoren/Generator_Image_Laagspanningsruimte.png",
      underConstruction: false,
    } as AvailableComponentDto;
    const apartments = {
      title: "Apartments",
      details: "ApartmentsDetailsTooling",
      to: "/generators/apartments",
      iconComponent: null,
      imageUrl:
        "/assets/tile_images/generatoren/Generator_Image_Appartementen.png",
      underConstruction: true,
    } as AvailableComponentDto;
    const mainSupportingStructure = {
      title: "Main supporting structure",
      details: "MainSupportingStructureDetailsTooling",
      to: "/generators/mainsupportingstructure",
      iconComponent: null,
      imageUrl:
        "/assets/tile_images/generatoren/Generator_Image_Hoofddraagconstructie.png",
      underConstruction: true,
    } as AvailableComponentDto;

    return [
      datacenterGenerator,
      gestapeldewoningbouwGenerator,
      WhitespaceAndCoolingGenerator,
      lowvoltageRoom,
      apartments,
      mainSupportingStructure,
    ];
  } catch (ex) {
    TrackException(ex as AxiosError);
    throw ex;
  }
}

export function GetAvailableOverview(): AvailableComponentDto[] {
  const configurator = {
    title: "Configurators",
    details: "ConfiguratorsPageSubtitle",
    to: "/configurators",
    iconComponent: <CalculatorIcon className="icon" size={100} />,
    imageUrl: "",
    underConstruction: false,
    maxWidth: 200,
  } as AvailableComponentDto;

  const generator = {
    title: "Generators",
    details: "GeneratorsPageSubtitle",
    to: "/generators",
    iconComponent: <ScrewDriverIcon className="icon" size={125} />,
    imageUrl: "",
    underConstruction: false,
    maxWidth: 200,
  } as AvailableComponentDto;

  const analyser = {
    title: "Analysers",
    details: "AnalysersPageSubtitle",
    to: "/analysers",
    iconComponent: <AnalyserIcon className="icon" size={100} />,
    imageUrl: "",
    underConstruction: false,
    maxWidth: 200,
  } as AvailableComponentDto;

  const catalog = {
    title: "Catalog",
    details: "CatalogPageSubtitle",
    to: "/catalog",
    iconComponent: <CatalogIcon className="icon" size={100} />,
    imageUrl: "",
    underConstruction: false,
    maxWidth: 200,
  } as AvailableComponentDto;

  const database = {
    title: "Databases",
    details: "DatabasesPageSubtitle",
    to: "/databases",
    iconComponent: <DatabaseIcon className="icon" size={100} />,
    imageUrl: "",
    underConstruction: false,
    maxWidth: 200,
  } as AvailableComponentDto;

  const heijmansIntelligence = {
    title: "Heijmans intelligence",
    details: "HeijmansIntelligencePageSubtitle",
    to: "/heijmansintelligence",
    iconComponent: <HeijmansIntelligenceIcon className="icon" size={100} />,
    imageUrl: "",
    underConstruction: false,
    maxWidth: 200,
  } as AvailableComponentDto;
  return [
    configurator,
    generator,
    analyser,
    catalog,
    database,
    heijmansIntelligence,
  ];
}

export function GetAvailableCatalog(): AvailableComponentDto[] {
  const datacenterCatalog = {
    title: "Datacenter",
    details: "DatacenterDetailsTooling",
    to: "/catalog/datacenter",
    iconComponent: null,
    imageUrl: "/assets/tile_images/generatoren/Generator_Image_Datacenter.png",
    underConstruction: false,
    children: GetAvailableDatacenterCatalog(),
  } as AvailableComponentDto;

  const gestapeldewoningbouwCatalog = {
    title: "Stacked housing",
    details: "StackedHousingDetailsTooling",
    to: "/catalog/stackedhousing",
    iconComponent: null,
    imageUrl:
      "/assets/tile_images/generatoren/Generator_Image_Gestapelde_woningbouw.png",
    underConstruction: false,
  } as AvailableComponentDto;

  return [datacenterCatalog, gestapeldewoningbouwCatalog];
}

export function GetAvailableDatacenterCatalog(): AvailableComponentDto[] {
  const buildings = {
    title: "Buildings",
    details: "BuildingDetails",
    to: "/catalog/datacenter/buildings",
    iconComponent: null,
    imageUrl: "/assets/tile_images/catalogus/Catalogus_Image_Gebouwen.png",
    underConstruction: true,
  } as AvailableComponentDto;
  const primarySystems = {
    title: "Primary systems",
    details: "PrimarySystemsDetails",
    to: "/catalog/datacenter/primarysystems",
    iconComponent: null,
    imageUrl:
      "/assets/tile_images/catalogus/Catalogus_Image_Primaire systemen.jpg",
    underConstruction: true,
  } as AvailableComponentDto;
  const spatialTypicals = {
    title: "Spatial typicals",
    details: "SpatialTypicalsDetails",
    to: "/catalog/datacenter/spatialtypicals",
    iconComponent: null,
    imageUrl:
      "/assets/tile_images/catalogus/Catalogus_Image_Ruimtelijke typicals.png",
    underConstruction: true,
  } as AvailableComponentDto;
  const secondarySystems = {
    title: "Secondary systems",
    details: "SecondarySystemsDetails",
    to: "/catalog/datacenter/secondarysystems",
    iconComponent: null,
    imageUrl:
      "/assets/tile_images/catalogus/Catalogus_Image_secundaire systemen.jpg",
    underConstruction: true,
  } as AvailableComponentDto;
  const modules = {
    title: "Modules",
    details: "ModulesDetails",
    to: "/catalog/datacenter/modules",
    iconComponent: null,
    imageUrl: "/assets/tile_images/catalogus/Catalogus_Image_Modules.png",
    underConstruction: true,
  } as AvailableComponentDto;
  const components = {
    title: "Components",
    details: "ComponentsDetails",
    to: "/catalog/datacenter/components",
    iconComponent: null,
    imageUrl: "/assets/tile_images/catalogus/Catalogus_Image_Componenten.jpg",
    underConstruction: false,
  } as AvailableComponentDto;
  return [
    buildings,
    primarySystems,
    spatialTypicals,
    secondarySystems,
    modules,
    components,
  ];
}

export function GetAvailableDatacentersCatalogComponents(): AvailableComponentDto[] {
  return [
    {
      id: 1,
      title: "Server racks",
      imageUrl:
        "/assets/tile_images/componenten/Componenten_Image_server racks.png",
      details: "Server racks",
      to: "/catalog/datacenter/components/serverracks",
      underConstruction: false,
      iconComponent: null,
      maxWidth: null,
    },
    {
      id: 2,
      title: "Transformators",
      imageUrl:
        "/assets/tile_images/componenten/Componenten_Image_transformatoren.jpg",
      details: "Transformators",
      to: "/catalog/datacenter/components/transformators",
      underConstruction: false,
      iconComponent: null,
      maxWidth: null,
    },
    {
      id: 3,
      title: "UPSs",
      imageUrl: "/assets/tile_images/componenten/Componenten_Image_ups.png",
      details: "UPSs",
      to: "/catalog/datacenter/components/upss",
      underConstruction: false,
      iconComponent: null,
      maxWidth: null,
    },
    {
      id: 4,
      title: "Batteries",
      imageUrl:
        "/assets/tile_images/componenten/Componenten_Image_batteries.png",
      details: "Batteries",
      to: "/catalog/datacenter/components/batteries",
      underConstruction: false,
      iconComponent: null,
      maxWidth: null,
    },
    {
      id: 5,
      title: "CRAH’s",
      imageUrl: "/assets/tile_images/componenten/Componenten_Image_CRAH's.png",
      details: "CRAH’s",
      to: "/catalog/datacenter/components/crahs",
      underConstruction: true,
      iconComponent: null,
      maxWidth: null,
    },
    {
      id: 6,
      title: "Structural floors",
      imageUrl:
        "/assets/tile_images/componenten/Componenten_Image_constructieve vloeren.jpeg",
      details: "Structural floors",
      to: "/catalog/datacenter/components/structuralfloors",
      underConstruction: true,
      iconComponent: null,
      maxWidth: null,
    },
    {
      id: 7,
      title: "Steel profiles",
      imageUrl:
        "/assets/tile_images/componenten/Componenten_Image_staalprofielen.jpg",
      details: "Steel profiles",
      to: "/catalog/datacenter/components/steelprofiles",
      underConstruction: true,
      iconComponent: null,
      maxWidth: null,
    },
  ];
}
