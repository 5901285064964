import { GridColDef, GridFilterItem, GridSortModel } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import LeftSidebarViewLayout from "../../sharedLogic/layouts/LeftSidebarViewLayout";
import { DataGridContainer } from "../../sharedLogic/components/DataGridContainer/DataGridContainer";
import {
  FilterableDataGrid,
  FilterDefinition,
} from "../../sharedLogic/components/FilterableDataGrid/FilterableDataGrid";
import { FilterPanel } from "../../sharedLogic/components/FilterPanel/FilterPanel";
import { ButtonDefinition } from "../../sharedLogic/types/ButtonDefinition";
import { GridApiCommunity } from "@mui/x-data-grid/internals";

interface ModuleListViewProps<T> {
  data: T[];
  title: string;
  subtitle?: string;
  loading: boolean;
  columns: GridColDef[];
  filters: FilterDefinition[];
  idPropertyName: string;
  buttons?: ButtonDefinition[];
  sortModel?: GridSortModel;
  gridRef?: React.MutableRefObject<GridApiCommunity>;
  openTypicalDetails: (id: string) => void;
  deleteTypical?: (id: string) => void;
  onSortModelChange?: (model: GridSortModel) => void;
}

export function ModuleListView<T>({
  gridRef,
  columns,
  data,
  title,
  subtitle,
  filters,
  loading,
  buttons,
  idPropertyName,
  sortModel,
  onSortModelChange,
  openTypicalDetails,
  deleteTypical,
}: ModuleListViewProps<T>) {
  const [filteredData, setFilteredData] = useState(data);
  const [filterItems, setFilterItems] = useState<GridFilterItem[]>([]);

  useEffect(() => {
    handleFilterItemChange(filterItems);
  }, [data]);

  const handleFilterItemChange = (filterItems: GridFilterItem[]) => {
    setFilterItems(filterItems);

    const newFilteredData = data.filter((row) =>
      filterItems.every((filter) => {
        const value = (row as { [key: string]: string })[filter.field];
        switch (filter.operator) {
          case "contains":
            return value?.toLowerCase()?.includes(filter.value.toLowerCase());
          case "equals":
            return value === filter.value;
          case "is":
            if (Array.isArray(value)) {
              return (value as string[]).some((v) => filter.value.includes(v));
            } else {
              return filter.value.includes(value);
            }
          case "between":
            return value >= filter.value[0] && value <= filter.value[1];
          default:
            return true;
        }
      })
    );
    setFilteredData(newFilteredData);
  };

  const FilterDataGrid = () => {
    return (
      <FilterableDataGrid
        gridRef={gridRef}
        data={filteredData}
        loading={loading}
        columns={columns}
        sortModel={sortModel}
        idPropertyName={idPropertyName}
        openDetails={openTypicalDetails}
        onSortModelChange={onSortModelChange}
      />
    );
  };

  return (
    <LeftSidebarViewLayout
      withCollapse={true}
      columnOne={
        <FilterPanel
          filters={filters}
          setCurrentFilterItems={handleFilterItemChange}
        />
      }
      columnTwo={
        <DataGridContainer
          title={title}
          subtitle={subtitle}
          buttons={buttons}
          GridComponent={FilterDataGrid}
        />
      }
    />
  );
}
