import { RackDto } from "../../../interfaces/foundry/typicals/RackDto";
import ResponseDto from "../../../interfaces/ResponseDto";
import { TypeObject } from "../../../interfaces/enums/TypeObjectEnum";
import { TrackException } from "../../../logging/LoggingManager";
import { foundryApiClient } from "../../../modules/sharedLogic/services/AxiosClient";
import useGeneralDatacenterObjectsStore from "../../../state/DatacenterState/generalDatacenterObjectsState";
import { AxiosError } from "axios";

export async function GetRack(id: number): Promise<RackDto> {
  return (await GetRacks()).find((x) => x.id === id) as RackDto;
}

export async function GetRacks(update?: boolean): Promise<RackDto[]> {
  const store = useGeneralDatacenterObjectsStore.getState();
  let racks = store.racks;
  if (racks.length === 0 || update) {
    racks = await GetRackDtos();
    store.setRacks(racks);
  }
  return racks;
}

async function GetRackDtos(): Promise<RackDto[]> {
  try {
    return (
      (await foundryApiClient.get<ResponseDto>("/api/datacenter/racks")).data
        .results as RackDto[]
    ).map((item: RackDto) => {
      item = {
        ...item,
        power_in_w: item.power_in_w / 1000,
        type: item.display_name,
        typeObject: TypeObject.Rack,
      };
      return item;
    });
  } catch (ex) {
    TrackException(ex as AxiosError);
    throw ex;
  }
}

export async function SaveRack(rack: RackDto) {
  try {
    rack = { ...rack, power_in_w: rack.power_in_w * 1000 };
    const response = await foundryApiClient.post<RackDto>(
      "/api/datacenter/racks/",
      rack
    );

    await GetRacks(true);

    return response.data;
  } catch (ex) {
    TrackException(ex as AxiosError);
    throw ex;
  }
}

export async function UpdateRack(rack: RackDto) {
  try {
    rack = {
      ...rack,
      power_in_w: rack.power_in_w * 1000,
    };
    const response = await foundryApiClient.patch<RackDto>(
      `/api/datacenter/racks/${rack.id}/`,
      { ...rack, typical_types: rack.typical_types.map((bt) => bt.id) }
    );

    await GetRacks(true);

    return response.data;
  } catch (ex) {
    TrackException(ex as AxiosError);
    throw ex;
  }
}

export async function DeleteRack(rack: RackDto) {
  try {
    const response = await foundryApiClient.delete<RackDto>(
      `/api/datacenter/racks/${rack.id}`
    );

    await GetRacks(true);

    return response.data;
  } catch (ex) {
    TrackException(ex as AxiosError);
    throw ex;
  }
}

export async function UpdateRackBuildingTypes(
  rackId: number,
  buildingTypesIds: number[]
): Promise<RackDto> {
  try {
    const response = await foundryApiClient.patch<RackDto>(
      `/api/datacenter/racks/${rackId}/`,
      { typical_types: buildingTypesIds }
    );

    await GetRacks(true);

    return {
      ...response.data,
      power_in_w: response.data.power_in_w / 1000,
      typeObject: TypeObject.Rack,
    };
  } catch (ex) {
    TrackException(ex as AxiosError);
    throw ex;
  }
}
