import { FormControl } from "@mui/material";
import React from "react";
import CustomInputLabelWithInfoButton from "../../../../components/InputLabel/CustomInputLabelWithInfoButton";

interface LabeledFieldProps {
  className?: string;
  labelId: string;
  labelKey: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

const LabeledFieldWithInfoButton: React.FC<LabeledFieldProps> = ({
  className = "",
  labelId,
  labelKey,
  style = {},
  children,
}) => {
  return (
    <div className={className}>
      <FormControl
        fullWidth
        variant="standard"
        style={{ marginTop: "10px", ...style }}
      >
        <CustomInputLabelWithInfoButton
          id={labelId}
          labelKey={labelKey}
        />
        <div className="mt10"></div>
        {children}
      </FormControl>
    </div>
  );
};

/* This component should eventually replace `LabeledField`. When that's done, we can drop the `WithInfoButton`-suffix*/
export default LabeledFieldWithInfoButton;
