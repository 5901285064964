import { create } from "zustand";

interface LayoutState {
  selectedLang: string;
  showBreadcrumb: boolean;
  isSidebarOpen: boolean;
  isSidebarPersistent: boolean;
  setSelectedLang: (stringValue: string) => void;
  setShowBreadcrumb: (boolValue: boolean) => void;
  setIsSidebarOpen: (boolValue: boolean) => void;
  setIsSidebarPersistent: (boolValue: boolean) => void;
}

const getInitialLang = () => {
  const stored = localStorage.getItem("selectedLang");
  return stored || "nl-NL";
};

const useLayoutStore = create<LayoutState>()((set) => ({
  selectedLang: getInitialLang(),
  showBreadcrumb: true,
  isSidebarOpen: true,
  isSidebarPersistent: true,
  setSelectedLang: (newValue) => {
    localStorage.setItem("selectedLang", newValue);
    set({ selectedLang: newValue });
  },
  setShowBreadcrumb: (newValue) => set({ showBreadcrumb: newValue }),
  setIsSidebarOpen: (newValue) => set({ isSidebarOpen: newValue }),
  setIsSidebarPersistent: (newValue) => set({ isSidebarPersistent: newValue }),
}));

export default useLayoutStore;