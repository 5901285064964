import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { StoreApi, UseBoundStore } from "zustand";
import SliderWithMarks from "../../../../../components/SliderWithMarks/SliderWithMarks";
import MapViewInputPane from "../../../../sharedLogic/components/InputPane/MapView/MapViewInputPane";
import NumberField from "../../../../sharedLogic/components/PropertySectionView/NumberField";
import SelectField from "../../../../sharedLogic/components/PropertySectionView/SelectField";
import {
  BaseConfiguratorActions,
  BaseConfiguratorState,
} from "../../../../sharedLogic/state/baseConfiguratorState";
import { createExploreStore } from "../../../../sharedLogic/state/ExploreState/ExploreState";
import { ParkingDto, ProjectForgeDto } from "../../../../sharedLogic/types/api";
import { UnitType } from "../../../../sharedLogic/types/UnitType";
import {
  convertMilimetersToMeters,
  convertSquareMetersToSquareMilimeters,
  convertSquareMilimetersToSquareMeters,
} from "../../../../sharedLogic/utils/format";
import { IsStringUndefinedOrNullOrEmpty } from "../../../../sharedLogic/utils/stringUtils";
import { GenerationService } from "../../../services/GenerationService";
import { HsaConfiguratorMapViewInputPaneState } from "../../../state/hsaConfiguratorMapViewInputPaneState";
import { SpaceBuildingDto, SupportedValueNumbersDto } from "../../../types/api";
import { SpaceType } from "../../../types/enums";
import { getColor } from "../../../utils/colors";
import ProjectRequirements from "../../ProjectRequirements/ProjectRequirements";
import UnitMix from "../../UnitMix/UnitMix";
import { TrackException } from "../../../../sharedLogic/logging/LoggingManager";
import { AxiosError } from "axios";
import LabeledFieldWithInfoButton from "../../../../sharedLogic/components/PropertySectionView/LabeledFieldWithInfoButton";
import CustomInputLabelWithInfoButton from "../../../../../components/InputLabel/CustomInputLabelWithInfoButton";

export const useUpdateSupportedValues = (selectedAccessTypes: string[]) => {
  const [supportedValuesPerAccessType, setSupportedValuesPerAccessType] =
    useState<Record<string, SupportedValueNumbersDto>>({});
  const [numberOfApartments, setNumberOfApartments] = useState<number[]>([
    0, 250,
  ]);
  const [minMaxNumberOfApartments, setMinMaxNumberOfApartments] = useState<
    number[]
  >([0, 250]);
  const prevAccessTypesRef = useRef<string[]>([]);

  useEffect(() => {
    const fetchValues = async () => {
      if (
        JSON.stringify(prevAccessTypesRef.current) ===
        JSON.stringify(selectedAccessTypes)
      ) {
        return;
      }

      try {
        const generationService = new GenerationService();
        const newSupportedValues = { ...supportedValuesPerAccessType };

        for (const accessType of selectedAccessTypes) {
          if (!newSupportedValues[accessType]) {
            newSupportedValues[accessType] =
              await generationService.getSupportedValueNumbers(accessType);
          }
        }

        setSupportedValuesPerAccessType(newSupportedValues);

        const filteredValues = selectedAccessTypes
          .map((accessType) => newSupportedValues[accessType])
          .flat();
        if (filteredValues.length !== 0) {
          const min = Math.min(...filteredValues.map((v) => v.minApartments));
          const max = Math.max(...filteredValues.map((v) => v.maxApartments));
          setMinMaxNumberOfApartments([min, max]);

          const newNumberOfApartments = [...numberOfApartments];
          if (newNumberOfApartments[0] < min) {
            newNumberOfApartments[0] = min;
          }
          if (newNumberOfApartments[1] > max) {
            newNumberOfApartments[1] = max;
          }
          setNumberOfApartments(newNumberOfApartments);
        }
        prevAccessTypesRef.current = selectedAccessTypes;
      } catch (ex) {
        TrackException(ex as AxiosError);
      }
    };

    fetchValues();
  }, [selectedAccessTypes, supportedValuesPerAccessType]);

  return {
    supportedValuesPerAccessType,
    numberOfApartments,
    minMaxNumberOfApartments,
    setNumberOfApartments,
  };
};

export const useMapViewConfigStore = (
  accessTypesOptions: string[],
  selectedAccessTypes: string[],
  setSelectedAccessTypes: (options: string[]) => void,
  configuratorStore: UseBoundStore<
    StoreApi<BaseConfiguratorState & BaseConfiguratorActions>
  >,
  isSaveAvailable: boolean
) => {
  const { t } = useTranslation();
  const currentStore = useMemo(
    () => createExploreStore<SpaceBuildingDto>(),
    []
  );
  const [maxBuildingHeight, setMaxBuildingHeight] = useState<
    string | undefined
  >();
  const [maxGutterHeight, setMaxGutterHeight] = useState<string | undefined>();
  const [gutterHeightError, setGutterHeightError] = useState<string>("");
  const [typedMaxGutterHeight, setTypedMaxGutterHeight] = useState<
    string | undefined
  >("");
  const [typedMaxBuildingHeight, setTypedMaxBuildingHeight] = useState<
    string | undefined
  >("");

  const [parking, setParking] = useState<ParkingDto | undefined>();

  const [units, setUnits] = useState<UnitType[]>([
    {
      name: SpaceType.Appartment1K,
      color: getColor(SpaceType.Appartment1K),
      minValue: 0,
      maxValue: 250,
      value: [0, 250],
    },
    {
      name: SpaceType.Appartment2K,
      color: getColor(SpaceType.Appartment2K),
      minValue: 0,
      maxValue: 250,
      value: [0, 250],
    },
    {
      name: SpaceType.Appartment3K,
      color: getColor(SpaceType.Appartment3K),
      minValue: 0,
      maxValue: 250,
      value: [0, 250],
    },
    {
      name: SpaceType.Appartment4K,
      color: getColor(SpaceType.Appartment4K),
      minValue: 0,
      maxValue: 250,
      value: [0, 250],
    },
  ] as UnitType[]);

  const {
    numberOfApartments,
    minMaxNumberOfApartments,
    setNumberOfApartments,
  } = useUpdateSupportedValues(selectedAccessTypes);

  const setCurrentState = (forgeProject: ProjectForgeDto) => {
    const maxBuildingHeight = convertMilimetersToMeters(
      forgeProject.maxBuildingHeightInMm
    );
    const maxGutterHeight = convertMilimetersToMeters(
      forgeProject.maxBuildingGutterHeightInMm
    );

    setMaxBuildingHeight(
      maxBuildingHeight > 0 ? maxBuildingHeight.toString() : undefined
    );
    setMaxGutterHeight(
      maxGutterHeight > 0 ? maxGutterHeight.toString() : undefined
    );

    if (forgeProject.residentialRange) {
      setNumberOfApartments([
        forgeProject.residentialRange.min,
        forgeProject.residentialRange.max,
      ]);
    }

    setParking(forgeProject.parking);

    if (forgeProject.residentialInformation.length > 0) {
      setUnits((prevUnits) =>
        prevUnits.map((unit) => {
          const updatedUnit = forgeProject.residentialInformation.find(
            (info) => info.moduleType === unit.name
          );

          if (updatedUnit) {
            return {
              ...unit,
              color: getColor(updatedUnit.moduleType as SpaceType),
              minValue: 0,
              maxValue: 250,
              value: [
                convertSquareMilimetersToSquareMeters(
                  updatedUnit.minUsableAreaInMm2
                ),
                convertSquareMilimetersToSquareMeters(
                  updatedUnit.maxUsableAreaInMm2
                ),
              ],
              ratio: updatedUnit.ratio,
            };
          }

          return unit;
        })
      );
    }
  };

  const getCurrentState = (): HsaConfiguratorMapViewInputPaneState => {
    return {
      maxBuildingHeight: maxBuildingHeight,
      maxGutterHeight: maxGutterHeight,
      residentialRange: numberOfApartments,
      residentialInformation: units
        .filter(
          (unit) =>
            !IsStringUndefinedOrNullOrEmpty(
              unit.ratio === undefined ? "" : unit.ratio!.toString()
            )
        )
        .map((unit) => ({
          moduleType: unit.name,
          ratio: unit.ratio,
          minUsableAreaInMm2: convertSquareMetersToSquareMilimeters(
            unit.value[0]
          ),
          maxUsableAreaInMm2: convertSquareMetersToSquareMilimeters(
            unit.value[1]
          ),
        })),
      parking: parking,
    } as HsaConfiguratorMapViewInputPaneState;
  };

  const inputPaneContent = () => (
    <>
      <MapViewInputPane
        isSaveAvailable={isSaveAvailable}
        currentConfiguratorStore={configuratorStore}
      />
      <LabeledFieldWithInfoButton
        className="mt10"
        labelId="max-building-height-label"
        labelKey="MapViewPage.MaxBuildingHeight"
      >
        <NumberField
          unit="m"
          disabled={!isSaveAvailable}
          value={typedMaxBuildingHeight || maxBuildingHeight}
          onChange={(value) => {
            setTypedMaxBuildingHeight(value);

            const newMaxBuildingHeight = parseFloat(value || "0");
            const currentMaxGutterHeight = parseFloat(
              typedMaxGutterHeight || maxGutterHeight || "0"
            );

            if (currentMaxGutterHeight > newMaxBuildingHeight) {
              setGutterHeightError(
                t("MapViewPage.MaxGutterHeightExceedsMaxBuildingHeight")
              );
            } else {
              setMaxBuildingHeight(value);
              setTypedMaxBuildingHeight("");
              if (
                typedMaxGutterHeight &&
                currentMaxGutterHeight <= newMaxBuildingHeight
              ) {
                setMaxGutterHeight(typedMaxGutterHeight);
                setTypedMaxGutterHeight("");
              }
              setGutterHeightError("");
            }
          }}
        />
      </LabeledFieldWithInfoButton>

      <LabeledFieldWithInfoButton
        className="mt10"
        labelId="total-gutter-height-label"
        labelKey="MapViewPage.MaxGutterHeight"
      >
        <NumberField
          unit="m"
          disabled={!isSaveAvailable}
          value={typedMaxGutterHeight || maxGutterHeight}
          onChange={(value) => {
            setTypedMaxGutterHeight(value);

            const newMaxGutterHeight = parseFloat(value || "0");
            const currentMaxBuildingHeight = parseFloat(
              maxBuildingHeight || "0"
            );

            if (newMaxGutterHeight > currentMaxBuildingHeight) {
              setGutterHeightError(
                t("MapViewPage.MaxGutterHeightExceedsMaxBuildingHeight")
              );
            } else {
              setMaxGutterHeight(value);
              setTypedMaxGutterHeight("");
              if (
                typedMaxBuildingHeight &&
                parseFloat(typedMaxBuildingHeight || "0") >= newMaxGutterHeight
              ) {
                setMaxBuildingHeight(typedMaxBuildingHeight);
                setTypedMaxBuildingHeight("");
              }
              setGutterHeightError("");
            }
          }}
        />
        {gutterHeightError && (
          <div className="error-message" style={{ color: "red" }}>
            {gutterHeightError}
          </div>
        )}
      </LabeledFieldWithInfoButton>

      <ProjectRequirements
        isSaveAvailable={isSaveAvailable}
        parkingDto={parking}
        setParkingDto={setParking}
      />

      <div className="titel-no-bold mt20">
        {`${t("StackedHousing.BuildingBasicPrinciples")}`}
      </div>
      <LabeledFieldWithInfoButton
        labelId="type-parking-label"
        labelKey="StackedHousing.AccessType"
      >
        <SelectField
          multiple={true}
          disabled={!isSaveAvailable}
          options={accessTypesOptions}
          valueMultiple={selectedAccessTypes}
          onChangeMultiple={(value) => setSelectedAccessTypes(value)}
        />
      </LabeledFieldWithInfoButton>

      <LabeledFieldWithInfoButton
        className="mt5"
        labelId="number-of-appartments-label"
        labelKey="StackedHousing.NumberOfAppartments"
      >
        <div className="p10">
          <SliderWithMarks
            value={numberOfApartments}
            disabled={!isSaveAvailable}
            setValue={(value) => {
              setNumberOfApartments(value);
            }}
            min={minMaxNumberOfApartments[0]}
            max={minMaxNumberOfApartments[1]}
          />
        </div>
      </LabeledFieldWithInfoButton>

      <div className="mt20 mb40">
        <CustomInputLabelWithInfoButton
          id="unit-mix-label"
          labelKey="StackedHousing.UnitMixBuilding"
        />
        <UnitMix
          disabled={!isSaveAvailable}
          units={units}
          setUnits={setUnits}
        />
      </div>
    </>
  );

  return {
    currentStore,
    inputPaneContent,
    setCurrentState,
    getCurrentState,
  };
};
