import "./Scene.scss";
import React from "react";
import { Grid } from "@mui/material";

import { useTranslation } from "react-i18next";
import SummaryInfoDto from "../../interfaces/SummaryInfoDto";

interface SummaryViewProps<T> {
  currentObject: T;
  summaryInfo: SummaryInfoDto[];
}

export default function SummaryView<T>({
  currentObject,
  summaryInfo,
}: SummaryViewProps<T>) {
  const { t } = useTranslation();

  const renderValue = (value: string | number) => {
    return value;
  };

  return (
    <>
      {summaryInfo.map((info) => (
        <Grid container key={info.property as string}>
          <Grid item xs={4}>
            <div className="sub-titel truncated-div">{t(info.label)}:</div>
          </Grid>
          <Grid item xs={8}>
            <div className="sub-titel">
              {renderValue(
                currentObject[info.property as keyof T] as string | number
              )}
            </div>
          </Grid>
        </Grid>
      ))}
    </>
  );
}
