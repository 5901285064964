import { SupplierDto } from "../../../interfaces/foundry/typicals/SupplierDto";
import ResponseDto from "../../../interfaces/ResponseDto";
import { foundryApiClient } from "../../../modules/sharedLogic/services/AxiosClient";
import { TrackException } from "../../../logging/LoggingManager";
import useGeneralDatacenterObjectsStore from "../../../state/DatacenterState/generalDatacenterObjectsState";
import { AxiosError } from "axios";

export async function GetSuppliers(): Promise<SupplierDto[]> {
  const store = useGeneralDatacenterObjectsStore.getState();
  let suppliers = store.suppliers;
  if (store.suppliers.length === 0) {
    suppliers = await GetSupplierDtos();
    store.setSuppliers(suppliers);
  }
  return suppliers;
}

async function GetSupplierDtos(): Promise<SupplierDto[]> {
  try {
    const suppliers = (
      (await foundryApiClient.get<ResponseDto>("/api/suppliers/suppliers/"))
        .data.results as SupplierDto[]
    ).map((item: SupplierDto) => {
      return item;
    });

    return suppliers;
  } catch (ex) {
    TrackException(ex as AxiosError);
    throw ex;
  }
}
