import { create } from "zustand";

interface CacheStore {
  cache: Record<
    string,
    {
      data: unknown;
      timestamp: number;
      expiresIn?: number;
    }
  >;
  setCache: (key: string, data: unknown, expiresIn?: number) => void;
  getCache: (key: string) => unknown | null;
  clearCache: (key?: string) => void;
}

export const useApiCache = create<CacheStore>((set, get) => ({
  cache: {},
  setCache: (key, data, expiresIn = 5 * 60 * 1000) => {
    set((state) => ({
      cache: {
        ...state.cache,
        [key]: {
          data,
          timestamp: Date.now(),
          expiresIn,
        },
      },
    }));
  },
  getCache: (key) => {
    const cached = get().cache[key];
    if (!cached) return null;

    const isExpired = Date.now() - cached.timestamp > (cached.expiresIn ?? 0);
    if (isExpired) {
      get().clearCache(key);
      return null;
    }

    return cached.data;
  },
  clearCache: (key?) => {
    if (key) {
      set((state) => {
        const newCache = { ...state.cache };
        delete newCache[key];
        return { cache: newCache };
      });
    } else {
      set({ cache: {} });
    }
  },
}));
