import { AxiosError } from "axios";
import { TypeObject } from "../../interfaces/enums/TypeObjectEnum";
import { CombinationsResponseDto } from "../../interfaces/foundry/CombinationsResponseDto";
import { DatacenterDto } from "../../interfaces/foundry/DatacenterDto";
import { DatacenterGenerationDto } from "../../interfaces/foundry/DatacenterGenerationDto";
import AdministrationDto from "../../interfaces/foundry/typicals/AdministrationDto";
import { BaseTypicalDto } from "../../interfaces/foundry/typicals/BaseTypicalDto";
import CoolingBlockDto from "../../interfaces/foundry/typicals/CoolingBlockDto";
import CoolingTowerDto from "../../interfaces/foundry/typicals/CoolingTowerDto";
import EnergySupplyStationDto from "../../interfaces/foundry/typicals/EnergySupplyStationDto";
import FiberDistributionRoomDto from "../../interfaces/foundry/typicals/FiberDistributionRoomDto";
import { FireRiserDto } from "../../interfaces/foundry/typicals/FireRiserDto";
import { GensetDto } from "../../interfaces/foundry/typicals/GensetDto";
import { LoadbankDto } from "../../interfaces/foundry/typicals/LoadbankDto";
import { PumpRoomDto } from "../../interfaces/foundry/typicals/PumpRoomDto";
import { SprinklerBassinDto } from "../../interfaces/foundry/typicals/SprinklerBassinDto";
import { ValveRoomDto } from "../../interfaces/foundry/typicals/ValveRoomDto";
import { WaterBufferDto } from "../../interfaces/foundry/typicals/WaterBufferDto";
import { WaterThreatmentDto } from "../../interfaces/foundry/typicals/WaterThreatmentDto";
import { TrackException } from "../../logging/LoggingManager";
import { foundryApiClient } from "../../modules/sharedLogic/services/AxiosClient";
import {
  ExploreActions,
  ExploreState,
  TypicalDTO,
} from "../../state/ExploreState/ExploreState";
import { DownloadFile } from "../../utils/HelperFunctions";
import { GetLowVoltageRoom } from "./LowVoltageRoomService";
import { FetchPaginatedData } from "./SharedFunctions";
import { GetWhitespace } from "./WhitespaceService";
import { GetAdministration } from "./typicals/AdministrationService";
import { GetCoolingBlock } from "./typicals/CoolingBlockService";
import { GetCoolingTower } from "./typicals/CoolingTowerService";
import { GetEnergySupplyStation } from "./typicals/EnergySupplyStationService";
import { GetFiberDistributionRoom } from "./typicals/FiberDistributionRoomService";
import { GetFireRiser } from "./typicals/FireRiserService";
import { GetGenset } from "./typicals/GensetService";
import { GetLoadbank } from "./typicals/LoadbankService";
import { GetPumpRoom } from "./typicals/PumpRoomService";
import { GetSprinklerBassin } from "./typicals/SprinklerBassinService";
import { GetValveRoom } from "./typicals/ValveRoomService";
import { GetWaterBuffer } from "./typicals/WaterBufferService";
import { GetWaterThreatmentRoom } from "./typicals/WaterThreatmentRoomService";

export async function GetDatacenters(
  signal: AbortSignal,
  currentState?: ExploreState<DatacenterDto> & ExploreActions<DatacenterDto>,
  filterQuery: string = ""
): Promise<DatacenterDto[]> {
  let url = "/api/datacenter/datacenters?layout_exists=True";
  if (filterQuery !== "") {
    url += `&${filterQuery}`;
  }
  const allDatacenters = await FetchPaginatedData<DatacenterDto>(
    url,
    processDatacenter,
    undefined,
    signal
  );

  currentState?.setObjectsTotalCount(allDatacenters.length);
  return allDatacenters;
}

export async function GetDatacenter(id: number): Promise<DatacenterDto> {
  try {
    const response = await foundryApiClient.get<DatacenterDto>(
      `/api/datacenter/datacenters/${id}/?detail_view=True/`
    );

    const datacenter = processDatacenter(response.data);
    return await fillComponents(datacenter);
  } catch (ex) {
    TrackException(ex as AxiosError);
    throw ex;
  }
}

export async function CreateDatacenterGeneration(
  data: DatacenterGenerationDto
) {
  try {
    await foundryApiClient.post(
      "/api/datacenter/create_custom_dc_reqsets/",
      data
    );
  } catch (ex) {
    throw ex;
  }
}

export async function CalculateDatacenterGenerationCombinations(
  data: DatacenterGenerationDto
): Promise<CombinationsResponseDto> {
  try {
    const response = await foundryApiClient.post<CombinationsResponseDto>(
      "/api/datacenter/calculate_custom_dc_reqsets/",
      data
    );
    const combinationResponse = response.data;
    if (combinationResponse.estimated_dcs < 0) {
      combinationResponse.estimated_dcs = 0;
    }
    if (combinationResponse.max_dcs < 0) {
      combinationResponse.max_dcs = 0;
    }
    if (combinationResponse.min_dcs < 0) {
      combinationResponse.min_dcs = 0;
    }
    if (combinationResponse.total_combinations < 0) {
      combinationResponse.total_combinations = 0;
    }
    return combinationResponse;
  } catch (ex) {
    TrackException(ex as AxiosError);
    throw ex;
  }
}

export async function ExportCostDetails(datacenterId: number) {
  try {
    const response = await foundryApiClient.post<string>(
      "/api/datacenter/calculate-cost-details/",
      { dc_id: datacenterId }
    );

    DownloadFile(
      JSON.stringify(response.data),
      "application/json",
      "cost_details",
      "json"
    );
    return response.data;
  } catch (ex) {
    TrackException(ex as AxiosError);
  }
}

export async function ExportConstructionTime(datacenterId: number) {
  try {
    const response = await foundryApiClient.post<string>(
      "/api/datacenter/calculate-building-time-detail/",
      { dc_id: datacenterId }
    );

    DownloadFile(
      JSON.stringify(response.data),
      "application/json",
      "construction_time",
      "json"
    );
    return response.data;
  } catch (ex) {
    TrackException(ex as AxiosError);
  }
}

function processDatacenter(datacenter: TypicalDTO): DatacenterDto {
  datacenter = datacenter as DatacenterDto;
  const constructionTimeInMonths =
    !datacenter.construction_time_in_months ||
    datacenter.construction_time_in_months === null
      ? 0
      : datacenter.construction_time_in_months;

  return {
    ...datacenter,
    length: datacenter.length / 1000,
    width: datacenter.width / 1000,
    height: datacenter.height / 1000,
    cost_per_kw: datacenter.cost / (datacenter.true_power_in_w / 1000),
    total_building_height_mm: datacenter.total_building_height_mm / 1000,
    rack_type_watts: datacenter.rack_type_watts / 1000,
    desired_power_in_w: datacenter.desired_power_in_w / 1000000,
    true_power_in_w: datacenter.true_power_in_w / 1000000,
    building_height_mm: datacenter.building_height_mm / 1000,
    ground_footprint: datacenter.ground_footprint / 1000000,
    hallway_area: datacenter.hallway_area / 1000000,
    whitespace_true_power_in_w: datacenter.whitespace_true_power_in_w / 1000000,
    typeObject: TypeObject.Datacenter,
    whitespace_cooling_type: mapCoolingType(datacenter.whitespace_cooling_type),
    facade_area: datacenter.facade_area / 1000000,
    volume: datacenter.volume / 1000000000,
    total_floor_space: datacenter.total_floor_space / 1000000,
    total_bvo: (datacenter.total_bvo ?? 0) / 1000000,
    construction_time_in_months: constructionTimeInMonths,
    renderType: "image",
  };
}

function mapCoolingType(coolingType: string): string {
  switch (coolingType) {
    case "CRAH_SINGLE":
      return "Floor - mechanical (1-sided)";
    case "CRAH_DOUBLE":
      return "Floor - mechanical (2-sided)";
    case "AHU":
      return "Wall - natural";
    case "COOLWALL":
      return "Wall - mechanical";
    default:
      return coolingType;
  }
}

async function fillComponents(
  datacenter: DatacenterDto
): Promise<DatacenterDto> {
  datacenter.whitespace = await GetWhitespace(datacenter.whitespace_type);
  datacenter.lowVoltageRoom = await GetLowVoltageRoom(datacenter.lsr_type);

  datacenter.genset = datacenter.genset_type
    ? ({
        ...(await GetGenset(datacenter.genset_type)),
        amount: datacenter.genset_amount,
      } as GensetDto)
    : undefined;

  datacenter.adminComponent = datacenter.admin_type
    ? ({
        ...(await GetAdministration(datacenter.admin_type)),
        amount: datacenter.admin_amount,
      } as AdministrationDto)
    : undefined;

  datacenter.valveRoomComponent = datacenter.valveroom_type
    ? ({
        ...(await GetValveRoom(datacenter.valveroom_type)),
        amount: datacenter.valveroom_amount,
      } as ValveRoomDto)
    : undefined;

  datacenter.sprinklerBassinComponent = datacenter.sprinklerbassin_type
    ? ({
        ...(await GetSprinklerBassin(datacenter.sprinklerbassin_type)),
        amount: datacenter.sprinklerbassin_amount,
      } as SprinklerBassinDto)
    : undefined;

  datacenter.waterTreatmentComponent = datacenter.watertreatment_type
    ? ({
        ...(await GetWaterThreatmentRoom(datacenter.watertreatment_type)),
        amount: datacenter.watertreatment_amount,
      } as WaterThreatmentDto)
    : undefined;

  datacenter.pumpRoomComponent = datacenter.pumproom_type
    ? ({
        ...(await GetPumpRoom(datacenter.pumproom_type)),
        amount: datacenter.pumproom_amount,
      } as PumpRoomDto)
    : undefined;

  datacenter.fireRiserComponent = datacenter.fireriser_type
    ? ({
        ...(await GetFireRiser(datacenter.fireriser_type)),
        amount: datacenter.fireriser_amount,
      } as FireRiserDto)
    : undefined;

  datacenter.waterBufferComponent = datacenter.waterbuffer_type
    ? ({
        ...(await GetWaterBuffer(datacenter.waterbuffer_type)),
        amount: datacenter.waterbuffer_amount,
      } as WaterBufferDto)
    : undefined;

  datacenter.coolingBlockComponent = datacenter.coolingblock_type
    ? ({
        ...(await GetCoolingBlock(datacenter.coolingblock_type)),
        amount: datacenter.coolingblock_amount,
      } as CoolingBlockDto)
    : undefined;

  datacenter.coolingTowerComponent = datacenter.coolingtower_type
    ? ({
        ...(await GetCoolingTower(datacenter.coolingtower_type)),
        amount: datacenter.coolingtower_amount,
      } as CoolingTowerDto)
    : undefined;

  datacenter.energySupplyStationComponent = datacenter.energysupplystation_type
    ? ({
        ...(await GetEnergySupplyStation(datacenter.energysupplystation_type)),
        amount: datacenter.energysupplystation_amount,
      } as EnergySupplyStationDto)
    : undefined;

  datacenter.fdrComponent = datacenter.fdr_type
    ? ({
        ...(await GetFiberDistributionRoom(datacenter.fdr_type)),
        amount: datacenter.fdr_amount,
      } as FiberDistributionRoomDto)
    : undefined;

  datacenter.loadBankComponent = datacenter.loadbank_type
    ? ({
        ...(await GetLoadbank(datacenter.loadbank_type)),
        amount: datacenter.loadbank_amount,
      } as LoadbankDto)
    : undefined;

  datacenter.hallwayAreaComponent = datacenter.hallway_area
    ? ({
        id: 1,
        customAttribute: datacenter.hallway_area.toFixed() + "",
        type: "HallwayArea",
      } as BaseTypicalDto)
    : undefined;

  datacenter.personsElevatorComponent = datacenter.persons_elevator
    ? ({
        id: 1,
        amount: datacenter.persons_elevator,
        type: "PersonsElevator",
      } as BaseTypicalDto)
    : undefined;

  datacenter.freightElevatorComponent = datacenter.freight_elevator
    ? ({
        id: 1,
        amount: datacenter.freight_elevator,
        type: "FreightElevator",
      } as BaseTypicalDto)
    : undefined;

  datacenter.stairwellsComponent = datacenter.stairwells
    ? ({
        id: 1,
        amount: datacenter.stairwells,
        type: "Stairwells",
      } as BaseTypicalDto)
    : undefined;

  datacenter.sanitaryComponent = datacenter.sanitary
    ? ({
        id: 1,
        amount: datacenter.sanitary,
        type: "Sanitary",
      } as BaseTypicalDto)
    : undefined;

  datacenter.lsrShaftsComponent = datacenter.lsr_shafts
    ? ({
        id: 1,
        amount: datacenter.lsr_shafts,
        type: "LSRShafts",
      } as BaseTypicalDto)
    : undefined;

  datacenter.utilityShaftsComponent = datacenter.utility_shafts
    ? ({
        id: 0,
        amount: datacenter.utility_shafts,
        type: "UtilityShafts",
      } as BaseTypicalDto)
    : undefined;

  return datacenter;
}
