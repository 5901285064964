import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useEffect, useState } from "react";
import { StoreApi, UseBoundStore } from "zustand";
import { MoveIcon } from "../../../../icons/MoveIcon";
import { RotateIcon } from "../../../../icons/RotateIcon";
import Maps from "../../../sharedLogic/components/Maps/Maps";
import {
  BaseConfiguratorActions,
  BaseConfiguratorState,
} from "../../../sharedLogic/state/baseConfiguratorState";
import {
  ParkingDto,
  ResidentialInformation,
} from "../../../sharedLogic/types/api";
import DrawedPlot from "../../../sharedLogic/types/DrawedPlot";
import { MapDrawFeatureType, MapType } from "../../../sharedLogic/types/enums";
import { SpaceBuildingDto } from "../../types/api";
import ExportedBuildingDetailsPane from "./ExportedBuildingDetailsPane";
import "./ExportedBuildingView.scss";

interface ExportedBuildingViewProps {
  drawedPlot: DrawedPlot;
  residentialInformation: ResidentialInformation[];
  currentApartmentBuildings: SpaceBuildingDto[];
  selectedExportedBuilingsIds: {
    code: string;
    count: number;
  }[];
  currentConfiguratorStore: UseBoundStore<
    StoreApi<BaseConfiguratorState & BaseConfiguratorActions>
  >;
  parking: ParkingDto | undefined;
}

export function ExportedBuildingView({
  drawedPlot,
  residentialInformation,
  currentConfiguratorStore,
  selectedExportedBuilingsIds,
  currentApartmentBuildings,
  parking,
}: ExportedBuildingViewProps) {
  const { setDrawControlMode } = currentConfiguratorStore();

  const [view, setView] = useState("move");
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    nextView: string
  ) => {
    setView(nextView);
  };

  useEffect(() => {
    setDrawControlMode("");
  }, []);

  return (
    <div className="generate-building-view">
      <div className="rotate-buttons">
        <ToggleButtonGroup
          sx={{ backgroundColor: "white" }}
          orientation="vertical"
          value={view}
          exclusive
          onChange={handleChange}
        >
          <ToggleButton
            value="move"
            aria-label="move"
            onClick={() => setDrawControlMode("simple_select")}
          >
            <MoveIcon className="custom-icon" size={15} />
          </ToggleButton>
          <ToggleButton
            value="rotate"
            aria-label="rotate"
            onClick={() => setDrawControlMode("rotate_mode")}
          >
            <RotateIcon className="custom-icon" size={15} />
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div className="map-detail-view">
        <div className="map">
          <Maps
            mapType={MapType.GenerateFeature}
            mapDrawFeatureType={MapDrawFeatureType.HSABuilding}
            currentConfiguratorStore={currentConfiguratorStore}
          />
        </div>
        <div className="building-detail-view">
          <ExportedBuildingDetailsPane
            drawedPlot={drawedPlot}
            residentialInformation={residentialInformation}
            currentApartmentBuildings={currentApartmentBuildings}
            selectedExportedBuilingsIds={selectedExportedBuilingsIds}
            parking={parking}
          />
        </div>
      </div>
    </div>
  );
}
