import { SpaceType } from "../../HSA/types/enums";
import useUserStore from "../state/userState";
import DrawedPlot from "./DrawedPlot";
import { CarParkingType } from "./enums";

export default interface ProjectDto {
  externalId: string | undefined;
  name: string;
  projectAddress: string | undefined;
  companyName: string;
  createdBy: string;
  configuration: string; //JSON format --> Inputpane with marker/plot etc
  lastModifiedAt: string;
  projectType: string;
  planning: PlanningDto | undefined;
}

export interface ProjectForgeDto extends ProjectDto {
  accessTypes: string[];
  plotAreaInMm2: number | undefined;
  maxBuildingHeightInMm: number;
  maxBuildingGutterHeightInMm: number;
  residentialRange: {
    min: number;
    max: number;
  };
  residentialInformation: ResidentialInformation[];
  selectedModuleCodes: string[];
  parking?: ParkingDto | undefined;
  buildings: BuildingLocation[];
}

export interface ProjectFoundryDto {
  id: number;
  name: string;
  plot: string;
  type_of: string;
  params: string;
  buildings: string;
  imgUrl: string;
  created_by: string;
}

export interface ProjectAddressFeatureDto {
  geometry: {
    coordinates: number[];
  };
  properties: {
    feature_type: string;
  };
}

export function GetEmptyProjectDto(): ProjectFoundryDto {
  return {
    id: 0,
    name: "",
    created_by: useUserStore.getState().emailAddress,
  } as ProjectFoundryDto;
}

export interface DimensionsDto {
  xSizeInMm: number | undefined;
  ySizeInMm: number | undefined | "";
  zSizeInMm: number | undefined | "";
  xSizeInM: number | undefined;
  ySizeInM: number | undefined;
  zSizeInM: number | undefined;
}

export interface PlanningDto {
  id: number;
  planningAsJson: string;
  createdBy: string;
  createdAt: string;
}

export interface ConfigurationDto {
  drawedPlot: DrawedPlot;
}

export interface ResidentialInformation {
  moduleType: SpaceType;
  ratio: number;
  minUsableAreaInMm2: number;
  maxUsableAreaInMm2: number;
  count: number;
}

export interface BuildingLocation {
  code: string;
  name: string;
  gps: number[][] | undefined;
  rd: number[][] | undefined;
}

export interface ParkingDto {
  parkingSpotPerResidentialFactor: number;
  covered: boolean;
  type: CarParkingType;
  nrOfParkingSpots: number | undefined;
  parkingLotInMm2: number | undefined;
  bicycleParkingInput: BicycleParkingInputDto[] | undefined;
  totalBicycleParkingLotInMm2: number | undefined;
  buildingCoveredBicycleParkingLotInMm2: number | undefined;
  nrOfBicycleParkingSpots: number | undefined;
  bicycleParkingFenceLengthInMm: number | undefined;
}

export interface BicycleParkingInputDto{
  moduleSpaceType: SpaceType;
  numLow: number | null;
  numHigh: number | null;
}
