import axios, { AxiosError } from "axios";
import * as L from "leaflet";
import { LngLat, LngLatBounds, Map } from "mapbox-gl";
import { MapboxAccessToken } from "../../../../../config";
import { TrackException } from "../../../logging/LoggingManager";
import DrawedPlot from "../../../types/DrawedPlot";
import { PdokDocDto, PdokResponse } from "../../../types/pdok/PdokResponseDto";
import { PdokRouteCalculationDto } from "../../../types/pdok/PdokRouteCalculationDto";

const mapboxUrl = "https://api.mapbox.com/directions/v5/mapbox/driving/";
const pdokApiUrl = "https://api.pdok.nl/bzk/locatieserver/search/v3_1/";

export async function GetAddressByLongLat(
  long: number,
  lat: number
): Promise<string> {
  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${long},${lat}.json?types=address&access_token=${MapboxAccessToken}&language=nl-NL`;
  const response = await axios.get(url);
  return response.data.features[0]?.place_name ?? "";
}

export async function CalculateDistanceAOrNRoad(
  map: Map,
  drawedPlot: DrawedPlot,
  setDistanceBetweenPlotAndRoad: (values: number) => void
) {
  const center = GetBoundsCenter(drawedPlot.lotCoordinates);
  const data = await CalculateDistanceBetweenPlotAndRoad(
    center.lat,
    center.lng
  );
  if (!data || data.routes.length === 0) return;

  const route = data.routes[0];
  const geojson: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties> =
    {
      type: "Feature",
      properties: {},
      geometry: {
        type: "LineString",
        coordinates: route.geometry.coordinates,
      },
    };

  UpdateMapWithRoute(map, geojson);
  setDistanceBetweenPlotAndRoad(route.distance);

  return null;
}

async function CalculateDistanceBetweenPlotAndRoad(
  lat: number,
  lon: number
): Promise<PdokRouteCalculationDto> {
  try {
    const hectometerPosts = await FetchHectometerPosts(lat, lon);
    if (!hectometerPosts.length) return {} as PdokRouteCalculationDto;

    const routeCalculations = await Promise.all(
      hectometerPosts.map(async (post) => {
        const details = await GetHectometerDetails(post.id);
        if (!details) return null;

        const route = await CalculateRoute(
          { lat, lon },
          { lat: details.lat, lon: details.lon }
        );

        return {
          ...route,
          hectometerPaal: [details.lon, details.lat],
          hectometerPaalRoadNumber: details.roadNumber,
        };
      })
    );

    const validRoutes = routeCalculations.filter(
      Boolean
    ) as PdokRouteCalculationDto[];
    if (!validRoutes.length) return {} as PdokRouteCalculationDto;

    const closestRoute = validRoutes.reduce((closest, current) =>
      current.routes[0].distance < closest.routes[0].distance
        ? current
        : closest
    );

    return FilterRouteToMainRoad(
      closestRoute,
      closestRoute.hectometerPaalRoadNumber!
    );
  } catch (ex) {
    TrackException(ex as AxiosError);
    throw ex;
  }
}

export function GetBoundsCenter(coordinates: number[][]): LngLat {
  const latLngCoordinates: L.LatLngExpression[] = coordinates.map(
    (coordinate) => [coordinate[0], coordinate[1]]
  );

  const bounds = new L.Polygon(latLngCoordinates).getBounds();
  const southWest = new LngLat(
    bounds.getSouthWest().lat,
    bounds.getSouthWest().lng
  );
  const northEast = new LngLat(
    bounds.getNorthEast().lat,
    bounds.getNorthEast().lng
  );

  return new LngLatBounds(southWest, northEast).getCenter();
}

export async function FetchHectometerPosts(
  lat: number,
  lon: number
): Promise<PdokDocDto[]> {
  try {
    const { data: pdokResponse } = await axios.get<PdokResponse>(
      `${pdokApiUrl}reverse?lat=${lat}&lon=${lon}&type=hectometerpaal&rows=20&distance=5000`
    );

    if (!pdokResponse.response.docs.length) return [];

    const docsA = pdokResponse.response.docs
      .filter((doc) => doc.weergavenaam.startsWith("Hectometerpaal A"))
      .sort((a, b) => a.afstand - b.afstand)
      .slice(0, 10);

    const docsN = pdokResponse.response.docs
      .filter((doc) => doc.weergavenaam.startsWith("Hectometerpaal N"))
      .sort((a, b) => a.afstand - b.afstand)
      .slice(0, 10);

    return [...docsA, ...docsN];
  } catch (ex) {
    TrackException(ex as AxiosError);
    return [];
  }
}

export async function GetHectometerDetails(
  id: string
): Promise<{ lat: number; lon: number; roadNumber: string } | null> {
  const { data } = await axios.get<PdokResponse>(
    `${pdokApiUrl}lookup?id=${id}`
  );

  if (!data.response.docs.length) return null;

  const doc = data.response.docs[0];
  const match = doc.centroide_ll.match(
    /POINT\(([-+]?\d*\.\d+) ([-+]?\d*\.\d+)\)/
  );

  if (!match) return null;

  return {
    lon: parseFloat(match[1]),
    lat: parseFloat(match[2]),
    roadNumber: doc.wegnummer,
  };
}

export async function CalculateRoute(
  start: { lat: number; lon: number },
  end: { lat: number; lon: number }
): Promise<PdokRouteCalculationDto> {
  return axios
    .get(`${mapboxUrl}${start.lon},${start.lat};${end.lon},${end.lat}`, {
      params: {
        steps: true,
        geometries: "geojson",
        access_token: MapboxAccessToken,
      },
    })
    .then((response) => response.data);
}

export function FilterRouteToMainRoad(
  route: PdokRouteCalculationDto,
  roadNumber: string
): PdokRouteCalculationDto {
  const steps = route.routes[0].legs[0].steps;
  const mainRoadIndex = steps.findIndex((step) => step.ref === roadNumber);

  if (mainRoadIndex === -1) return route;

  const filteredSteps = steps.slice(0, mainRoadIndex);
  const filteredCoordinates: number[][] = [];
  let totalDistance = 0;

  filteredSteps.forEach((step) => {
    filteredCoordinates.push(...step.geometry.coordinates);
    totalDistance += step.distance;
  });

  return {
    ...route,
    routes: [
      {
        ...route.routes[0],
        geometry: {
          ...route.routes[0].geometry,
          coordinates: filteredCoordinates,
        },
        distance: totalDistance,
      },
    ],
  };
}

export function UpdateMapWithRoute(
  map: Map,
  route: GeoJSON.Feature<GeoJSON.Geometry>
) {
  if (map.getSource("route")) {
    const source = map.getSource("route") as mapboxgl.GeoJSONSource;
    source.setData(route);
  } else {
    map.addLayer({
      id: "route",
      slot: "middle",
      type: "line",
      source: {
        type: "geojson",
        data: route,
      },
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "#3887be",
        "line-opacity": 0.75,
        "line-width": 5,
      },
    });
  }
}
