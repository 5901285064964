import { SpaceBuildingDto, SpaceDto } from "../../../HSA/types/api";
import { DTO } from "../../state/ExploreState/ExploreState";
import { TypeObject } from "../../types/enums";
import { ExploreConfigStore } from "../../types/ExploreConfig";
import ExploreView from "../ExploreView/ExploreView";

interface BaseExploreViewProps<T extends SpaceDto> {
  type: TypeObject;
  notAllowedToUpdate: boolean;
  currentTypicalConfigStore: ExploreConfigStore<T>;
}

export default function BaseExploreView<T extends SpaceDto>({
  type,
  notAllowedToUpdate,
  currentTypicalConfigStore,
}: BaseExploreViewProps<T>) {
  const {
    summaryInfo,
    customTitle,
    detailedInfo,
    sortByOptionsDictionary,
    dimensions,
    chartLabels,
    clickableAxes,
    bestFitOptionsDictionary,
    bestFitOptionsDictionaryReversed,
    handleCustomFilter,
    handleObjectFilters,
  } = currentTypicalConfigStore;

  const currentStore = currentTypicalConfigStore.currentStore();

  function orderObjects(
    objects: T[],
    sortProperty: string,
    isSortOrderAscending: boolean
  ) {
    const currentObjects = objects as DTO[];
    const length =
      currentStore?.loadedObjects?.length === 0
        ? 50
        : currentStore?.loadedObjects.length;
    if (sortProperty === "Best fit rate") {
      currentStore.setLoadedObjects(
        currentObjects
          .sort((a, b) =>
            isSortOrderAscending ? b.value - a.value : a.value - b.value
          )
          .slice(0, length) as T[]
      );
    }
  }

  return (
    <ExploreView
      type={type}
      customTitle={customTitle}
      summaryInfo={summaryInfo || []}
      detailedInfo={detailedInfo}
      currentState={currentStore}
      dimensions={dimensions}
      chartLabels={chartLabels}   
      clickableAxes={clickableAxes}
      notAllowedToUpdate={notAllowedToUpdate}
      sortByOptionsDictionary={sortByOptionsDictionary || {}}
      orderObjects={orderObjects}
      handleCustomFilter={handleCustomFilter || (() => [])}
      handleObjectFilters={handleObjectFilters || ((objects: T[]) => objects)}
      bestFitOptionsDictionary={bestFitOptionsDictionary}
      bestFitOptionsDictionaryReversed={bestFitOptionsDictionaryReversed}
    />
  );
}
