import { SaveAs } from "@mui/icons-material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Save from "@mui/icons-material/Save";
import {
  Box,
  Collapse,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { AxiosError } from "axios";
import { debounce, isEqual } from "lodash";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import CustomPopover from "../../../../components/CustomPopover/CustomPopover";
import { ForgeApiUrl } from "../../../../config";
import { ShowError } from "../../../../http/NotificationService";
import { ProjectTypeEnum } from "../../../../interfaces/enums/ProjectTypeEnum";
import { TrackException } from "../../../../logging/LoggingManager";
import { GetMapLegandaItems } from "../../../../pages/Configurators/Datacenter/components/LegendaItems";
import colors from "../../../../styles/colors.module.scss";
import BaseExploreView from "../../../sharedLogic/components/BaseExploreView/BaseExploreView";
import InputPane from "../../../sharedLogic/components/InputPane/InputPane";
import { CreateDrawedPlot } from "../../../sharedLogic/components/Maps/Components/DrawControlUtils";
import Maps from "../../../sharedLogic/components/Maps/Maps";
import CreateOrUpdateProjectView from "../../../sharedLogic/components/Projects/CreateOrUpdateProjectView";
import TabPanel from "../../../sharedLogic/components/TabPanel/TabPanel";
import { useGetApiHook } from "../../../sharedLogic/services/forge/useGridApiHook";
import {
  GetProject,
  GetProjectDetails,
  UpdateProject,
} from "../../../sharedLogic/services/ProjectService";
import useProjectsStore from "../../../sharedLogic/state/projectsState";
import useUserStore from "../../../sharedLogic/state/userState";
import {
  BuildingLocation,
  ConfigurationDto,
  ParkingDto,
  ProjectForgeDto,
} from "../../../sharedLogic/types/api";
import DrawedPlot from "../../../sharedLogic/types/DrawedPlot";
import { MapType, TypeObject } from "../../../sharedLogic/types/enums";
import { ExploreConfigStore } from "../../../sharedLogic/types/ExploreConfig";
import {
  convertMetersToMilimeters,
  convertSquareMetersToSquareMilimeters,
} from "../../../sharedLogic/utils/format";
import { IsGuid } from "../../../sharedLogic/utils/HelperFunctions";
import { GetSpaceBuilding } from "../../services/BuildingService";
import { createHsaConfiguratorStore } from "../../state/hsaConfiguratorState";
import {
  ExportedLayout,
  SpaceBuildingDto,
  SpaceResidentialDto,
  SupportedValueTypesDto,
} from "../../types/api";
import BuildingsThumbnailGenerator from "../BuildingsThumbnailGenerator";
import { ExportedBuildingView } from "../ExportedBuildingView/ExportedBuildingView";
import { useApartmentConfigStore } from "./Configs/ApartmentConfig";
import { useBuildingConfigStore } from "./Configs/BuildingConfig";
import CalculationModel from "./Configs/CalculationModelConfig";
import { useExportedBuildingsViewConfigStore } from "./Configs/ExportedBuildingsViewConfig";
import { useMapViewConfigStore } from "./Configs/MapViewConfig";
import "./ConfiguratorPage.scss";

export default function ConfiguratorPage() {
  const { t } = useTranslation();
  const { appAbility } = useUserStore();
  const { pathname, state } = useLocation();
  const { heijmansBlue } = colors;
  const hsaConfiguratorStore = useMemo(() => createHsaConfiguratorStore(), []);
  const [isInputPaneOpen, setIsInputPaneOpen] = React.useState<boolean>(true);
  const [accessTypes, setAccessTypes] = React.useState<string[]>([]);
  const [accessTypeOptions, setAccessTypeOptions] = React.useState<string[]>(
    []
  );
  const [spaceTypeOptions, setSpaceTypeOptions] = React.useState<string[]>([]);
  const [parking, setParking] = React.useState<ParkingDto | undefined>();

  const [isSaveAvailable] = React.useState<boolean>(
    appAbility?.can("update", "configurators.stackedhousing") ?? false
  );

  const {
    currentProject,
    setIsProjectSavingAs,
    setIsProjectViewOpen,
    setCurrentProject,
  } = useProjectsStore();

  const debounceDelay = 500;
  const project = currentProject as ProjectForgeDto;
  const forgeProject = useRef<ProjectForgeDto>();

  const {
    buildingLocations,
    drawedPlot,
    currentTabPageIndex,
    exportedLayouts,
    currentApartmentBuildings,
    setBuildingLocations,
    setCurrentTabPageIndex,
    setCurrentPageType,
    setExportedLayouts,
    setCurrentApartmentBuildings,
    setDrawedPlot,
    setSelectedExportedBuildingsIds,
  } = hsaConfiguratorStore();

  const handleChangeAccessType = (options: string[]) => {
    const valuesAccessType = Object.values(accessTypeOptions);
    const currentOptions = options.map((option) => {
      const replacements = {
        HACGallery: "HSAGallery",
        HACPorchway: "HSAPorchway",
        HACCorridor: "HSACorridor",
      };

      Object.entries(replacements).forEach(([old, new_]) => {
        option = option.replace(old, new_);
      });
      return option;
    });

    if (currentOptions.includes("all")) {
      setAccessTypes(
        accessTypes.length === valuesAccessType.length ? [] : valuesAccessType
      );
    } else {
      const selectedAccessTypes = valuesAccessType.filter((accessType) =>
        accessType
          ? currentOptions.includes(accessType.toString())
          : currentOptions.includes("-1")
      );
      setAccessTypes(selectedAccessTypes);
    }
  };

  const mapConfigStore = useMapViewConfigStore(
    accessTypeOptions,
    accessTypes,
    handleChangeAccessType,
    hsaConfiguratorStore,
    isSaveAvailable
  );

  const apartmentConfigStore = useApartmentConfigStore(
    accessTypeOptions,
    spaceTypeOptions,
    accessTypes,
    handleChangeAccessType,
    isSaveAvailable
  );

  const apartmentBuildingConfigStore = useBuildingConfigStore(
    accessTypeOptions,
    accessTypes,
    handleChangeAccessType,
    isSaveAvailable
  );

  const {
    selectedIds: selectedApartmentsIds,
    setSelectedIds: setSelectedApartmentsIds,
    setObjects: setApartments,
  } = apartmentConfigStore.currentStore();

  const {
    objects: apartmentBuildingsbuildings,
    loadedObjects: apartmentLoadedBuildings,
    selectedIds: selectedApartmentBuildingsIds,
    selectedExportedBuilingsIds: selectedExportedApartmentBuilingsIds,
    setObjects: setApartmentBuildings,
    setSelectedIds: setSelectedApartmentBuildingIds,
    setSelectedModulesCodes: setSelectedModuleCodes,
    setSelectedExportedBuildingsIds: setSelectedExportedApartmentBuilingsIds,
  } = apartmentBuildingConfigStore.currentStore();

  const exportedBuildingsViewConfigStore = useExportedBuildingsViewConfigStore(
    currentApartmentBuildings,
    selectedExportedApartmentBuilingsIds,
    setCurrentApartmentBuildings,
    setSelectedExportedApartmentBuilingsIds
  );

  const { data: supportedValuesDto } = useGetApiHook<SupportedValueTypesDto>({
    useCache: true,
    baseUrl: ForgeApiUrl,
    url: "api/v1/modules/supported-values/types/Residential",
  });

  const debouncedUpdateProject = useCallback(
    debounce(async () => {
      try {
        forgeProject.current = (await UpdateProject(
          ProjectTypeEnum.HSA,
          project
        )) as ProjectForgeDto;

        setParking(forgeProject.current.parking);
      } catch (ex) {
        TrackException(ex as AxiosError);
      }
    }, debounceDelay),
    [project]
  );

  const isPlotDrawed =
    drawedPlot !== undefined &&
    drawedPlot.polygon !== undefined &&
    drawedPlot.polygon?.geometry !== undefined;

  useEffect(() => {
    if (!project || !project.configuration) return;

    const configuration = JSON.parse(project.configuration) as ConfigurationDto;
    if (
      drawedPlot.lotCoordinates.length === 0 &&
      configuration.drawedPlot.polygon?.geometry
    ) {
      const polygon = configuration.drawedPlot.polygon!;
      const newDrawedPlot = CreateDrawedPlot(polygon, configuration.drawedPlot);
      setDrawedPlot(newDrawedPlot);
    }

    (async () => {
      try {
        forgeProject.current = await GetProjectDetails<ProjectForgeDto>(
          project
        );
        project.parking = forgeProject.current.parking;
        project.selectedModuleCodes = forgeProject.current.selectedModuleCodes;
        mapConfigStore.setCurrentState(forgeProject.current);

        setSelectedApartmentsIds(
          forgeProject.current.selectedModuleCodes ?? []
        );
        setSelectedModuleCodes(forgeProject.current.selectedModuleCodes ?? []);
        setAccessTypes(forgeProject.current.accessTypes ?? []);
        setBuildingLocations(forgeProject.current.buildings ?? []);
        setSelectedApartmentBuildingIds(
          forgeProject.current.buildings?.map((x) => x.code) ?? []
        );
        setParking(project.parking);
        updateSelectedBuildingWithCount(forgeProject.current.buildings);
      } catch (ex) {
        TrackException(ex as AxiosError);
      }
    })();
  }, [project]);

  useEffect(() => {
    if (selectedApartmentBuildingsIds.length === 0) {
      setBuildingLocations([]);
    } else {
      const newLocations = buildingLocations.filter((location) =>
        selectedApartmentBuildingsIds.includes(location.code)
      );
      selectedApartmentBuildingsIds.forEach((id) => {
        const currentBuilding = buildingLocations.find((x) => x.code === id);
        if (!currentBuilding) {
          const newLocation: BuildingLocation = {
            code: id,
            name: `${currentProject.externalId}-${id}-0`,
            gps: undefined,
            rd: undefined,
          };
          newLocations.push(newLocation);
        }
      });

      setBuildingLocations(newLocations);
      updateSelectedBuildingWithCount(newLocations);
    }
  }, [selectedApartmentBuildingsIds]);

  useEffect(() => {
    if (drawedPlot === undefined || drawedPlot.polygon === null) return;

    setSelectedExportedBuildingsIds(selectedExportedApartmentBuilingsIds);
    if (selectedExportedApartmentBuilingsIds.length === 0) {
      setCurrentApartmentBuildings([]);
      setExportedLayouts([]);
      return;
    }

    const filteredBuildings = currentApartmentBuildings.filter((building) =>
      selectedExportedApartmentBuilingsIds.some(
        (selected) => selected.code === building.code
      )
    );
    const filteredLayouts = exportedLayouts.filter((_, index) =>
      selectedExportedApartmentBuilingsIds.some(
        (selected) => selected.code === currentApartmentBuildings[index]?.code
      )
    );

    if (filteredBuildings.length !== currentApartmentBuildings.length) {
      setCurrentApartmentBuildings(filteredBuildings);
      setExportedLayouts(filteredLayouts);
    }

    const missingBuildingCodes = selectedExportedApartmentBuilingsIds.filter(
      (selected) =>
        !filteredBuildings.find((existing) => existing.code === selected.code)
    );
    if (missingBuildingCodes.length === 0) {
      return;
    }

    (async () => {
      try {
        const newBuildings: SpaceBuildingDto[] = [];
        const newLayouts: ExportedLayout[] = [];

        const plotSurfaceInMm2 = convertSquareMetersToSquareMilimeters(
          drawedPlot.lotSurface
        );
        for (const buildingId of missingBuildingCodes) {
          const building = await GetSpaceBuilding(
            buildingId.code,
            plotSurfaceInMm2
          );
          newBuildings.push(building);
          newLayouts.push({
            layout: building.layout,
            code: building.code,
          });
        }

        const allBuildings = [...filteredBuildings, ...newBuildings];
        const renamedBuildings = renameBuildingsByAccessType(allBuildings);

        setCurrentApartmentBuildings(renamedBuildings);
        setExportedLayouts([
          ...filteredLayouts,
          ...(newLayouts as ExportedLayout[]),
        ]);
      } catch (ex) {
        ShowError(t("Failed to get buildings"));
      }
    })();
  }, [drawedPlot, selectedExportedApartmentBuilingsIds]);

  useEffect(() => {
    if (currentTabPageIndex === 3 && handleSaveProject()) {
      debouncedUpdateProject();
    }
  }, [buildingLocations.length]);

  useEffect(() => {
    if (supportedValuesDto) {
      setAccessTypeOptions(
        (supportedValuesDto as SupportedValueTypesDto).accessTypes
      );
      setSpaceTypeOptions(
        (supportedValuesDto as SupportedValueTypesDto).spaceTypes
      );
    }
  }, [supportedValuesDto]);

  useEffect(() => {
    switch (currentTabPageIndex) {
      case 0:
        setCurrentPageType(TypeObject.MapView);
        return;
      case 1:
        setCurrentPageType(TypeObject.HSAResidential);
        return;
      case 2:
        setCurrentPageType(TypeObject.HSABuilding);
        return;
      case 3:
        setCurrentPageType(TypeObject.ExportView);
        return;
      default:
        setCurrentPageType(TypeObject.MapView);
    }
  }, [currentTabPageIndex, setCurrentPageType]);

  useEffect(() => {
    if (
      pathname &&
      !currentProject?.externalId &&
      state?.params?.new !== true
    ) {
      const pathnames = pathname.split("/");
      const guid = pathnames[pathnames.length - 1];
      if (IsGuid(guid)) {
        (async () => {
          const project = await GetProject(guid);
          setCurrentProject(project);
        })();
      }
    }
  }, [pathname]);

  const renameBuildingsByAccessType = (
    buildings: SpaceBuildingDto[]
  ): SpaceBuildingDto[] => {
    const renamedBuildings = [...buildings];
    const accessTypeCounts: { [key: string]: number } = {};
    return renamedBuildings.map((building) => {
      const accessType = building.accessType;
      if (!accessTypeCounts[accessType]) {
        accessTypeCounts[accessType] = 0;
      }

      accessTypeCounts[accessType]++;
      const formattedNumber =
        accessTypeCounts[accessType] < 10
          ? accessTypeCounts[accessType].toString().padStart(2, "0")
          : accessTypeCounts[accessType].toString();
      const newName = `${t(accessType)}-${formattedNumber}`;
      return {
        ...building,
        name: newName,
      };
    });
  };

  const handleTabPageChange = async (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    try {
      if (!handleSaveProject()) {
        return;
      }

      if (hasProjectChanged(project, forgeProject.current)) {
        const updatedProject = (await UpdateProject(
          ProjectTypeEnum.HSA,
          project
        )) as ProjectForgeDto;

        forgeProject.current = { ...updatedProject };
        setParking(forgeProject.current.parking);

        if (currentTabPageIndex !== 1) {
          setApartments(null);
        }
        if (currentTabPageIndex !== 2) {
          setApartmentBuildings(null);
        }
      }
    } catch (ex) {
      TrackException(ex as AxiosError);
    } finally {
      setCurrentTabPageIndex(newValue);
    }
  };

  const hasProjectChanged = (
    currentProject: ProjectForgeDto,
    forgeProjectRef: ProjectForgeDto | undefined
  ): boolean => {
    if (!forgeProjectRef) return true;

    const fieldsToCompare: (keyof ProjectForgeDto)[] = [
      "selectedModuleCodes",
      "buildings",
      "accessTypes",
      "maxBuildingHeightInMm",
      "maxBuildingGutterHeightInMm",
      "plotAreaInMm2",
      "residentialRange",
      "residentialInformation",
      "parking",
    ];

    return fieldsToCompare.some((field) => {
      const currentValue = currentProject[field];
      const forgeValue = forgeProjectRef[field];
      return !isEqual(currentValue, forgeValue);
    });
  };

  function handleSaveProject() {
    if (!isSaveAvailable) return false;

    try {
      project.plotAreaInMm2 = convertSquareMetersToSquareMilimeters(
        drawedPlot?.lotSurface ?? 0
      );
      const mapViewInputPaneState = mapConfigStore.getCurrentState();
      project.maxBuildingHeightInMm = convertMetersToMilimeters(
        mapViewInputPaneState.maxBuildingHeight
      );
      project.maxBuildingGutterHeightInMm = convertMetersToMilimeters(
        mapViewInputPaneState.maxGutterHeight
      );
      project.accessTypes = accessTypes;
      project.residentialRange = {
        min: mapViewInputPaneState.residentialRange[0],
        max: mapViewInputPaneState.residentialRange[1],
      };
      project.residentialInformation =
        mapViewInputPaneState.residentialInformation;
      project.parking = mapViewInputPaneState.parking;
      project.selectedModuleCodes = selectedApartmentsIds;
      project.buildings = buildingLocations;

      const state = {
        drawedPlot: {
          polygon: drawedPlot.polygon,
          lotSurface: drawedPlot.lotSurface,
          plotPercentage: drawedPlot.plotPercentage,
        } as DrawedPlot,
      } as ConfigurationDto;

      project.configuration = JSON.stringify(state);

      return true;
    } catch (ex) {
      TrackException(ex as AxiosError);
    }

    return false;
  }

  const updateSelectedBuildingWithCount = (
    buildingLocations: BuildingLocation[]
  ) => {
    setSelectedExportedApartmentBuilingsIds(
      Array.from(
        buildingLocations.reduce((acc, location) => {
          const code = location.code;
          acc.set(code, (acc.get(code) || 0) + 1);
          return acc;
        }, new Map<string, number>())
      ).map(([code, count]) => ({ code, count })) ?? []
    );
  };

  const tabConfigurations = [
    {
      index: 0,
      type: TypeObject.MapView,
      currentTypicalConfigStore: mapConfigStore,
      label: "Basic principles",
    },
    {
      index: 1,
      type: TypeObject.HSAResidential,
      currentTypicalConfigStore: apartmentConfigStore,
      label: "InputWindow",
    },
    {
      index: 2,
      type: TypeObject.HSABuilding,
      currentTypicalConfigStore: apartmentBuildingConfigStore,
      label: "InputWindow",
    },
    {
      index: 3,
      type: TypeObject.ExportView,
      currentTypicalConfigStore: exportedBuildingsViewConfigStore,
      label: "StackedHousing.SelectedBuildings",
    },
    {
      index: 4,
      type: TypeObject.HSACountingModel,
      currentTypicalConfigStore: null,
      label: "",
    },
  ];

  return (
    <div className="hsa-generator-page">
      <Box
        component={"div"}
        sx={{
          boxShadow: 3,
          display: "flex",
          paddingRight: "20px",
          alignItems: "center",
        }}
      >
        <Tabs
          sx={{ flexGrow: 1 }}
          value={currentTabPageIndex}
          onChange={async (event, value) =>
            await handleTabPageChange(event, value)
          }
          aria-label="styled tabs example"
        >
          <Tab label={t("Basic principles")} />
          <Tab
            label={
              <Typography>
                {t("Preselection")}
                <br />
                {t("Apartments").toLocaleLowerCase()}
              </Typography>
            }
          />
          <Tab
            label={
              <Typography>
                {t("Select")} {t("Building").toLocaleLowerCase()}
              </Typography>
            }
          />
          <Tab
            disabled={currentApartmentBuildings.length === 0 || !isPlotDrawed}
            label={
              <Typography>
                {t("Generate")} {t("Project").toLocaleLowerCase()}
              </Typography>
            }
          />
          <Tab
            disabled={currentApartmentBuildings.length === 0 || !isPlotDrawed}
            label={
              <Typography>
                {t("CalculationModel")} {t("Project").toLocaleLowerCase()}
              </Typography>
            }
          />
        </Tabs>

        {currentProject.externalId && (
          <CustomPopover
            content={
              <IconButton
                disabled={!isSaveAvailable}
                onClick={() => setIsProjectSavingAs(true)}
              >
                <SaveAs
                  sx={{ fontSize: "2rem", color: heijmansBlue }}
                  className={isSaveAvailable ? "svg" : "save-icon-disabled"}
                />
              </IconButton>
            }
            popoverTitle={t("SaveAsProjectTitle")}
            popoverText={t("NitrogenPage.SaveAsProjectCaption")}
          ></CustomPopover>
        )}

        <CustomPopover
          content={
            <IconButton
              disabled={!isSaveAvailable}
              onClick={() => {
                setIsProjectViewOpen(true);
              }}
            >
              <Save
                sx={{ fontSize: "2rem", color: heijmansBlue }}
                className={isSaveAvailable ? "svg" : "save-icon-disabled"}
              />
            </IconButton>
          }
          popoverTitle={t("SaveProjectTitle")}
          popoverText={t("NitrogenPage.SaveProjectCaption")}
        ></CustomPopover>
      </Box>

      {!isInputPaneOpen && (
        <div className="hsa-input-pane-button">
          <IconButton
            sx={{ backgroundColor: "white", zIndex: 1 }}
            onClick={() => setIsInputPaneOpen(!isInputPaneOpen)}
          >
            <ChevronRightIcon sx={{ fontSize: "2.5rem" }} />{" "}
          </IconButton>
        </div>
      )}

      {tabConfigurations.map((config) => (
        <TabPanel
          key={config.index}
          value={currentTabPageIndex}
          index={config.index}
        >
          {config.type !== TypeObject.HSACountingModel ? (
            <div className="hsa-view">
              <Collapse orientation="horizontal" in={isInputPaneOpen}>
                {
                  <InputPane
                    label={config.label}
                    isInputPaneOpen={isInputPaneOpen}
                    setIsInputPaneOpen={setIsInputPaneOpen}
                    currentTypicalConfigStore={
                      config.currentTypicalConfigStore as ExploreConfigStore<SpaceBuildingDto>
                    }
                  />
                }
              </Collapse>

              {config.type === TypeObject.HSAResidential && (
                <BaseExploreView
                  type={config.type}
                  currentTypicalConfigStore={
                    config.currentTypicalConfigStore as ExploreConfigStore<SpaceResidentialDto>
                  }
                  notAllowedToUpdate={
                    isSaveAvailable !== undefined ? !isSaveAvailable : false
                  }
                />
              )}

              {config.type === TypeObject.HSABuilding && (
                <BaseExploreView
                  type={config.type}
                  currentTypicalConfigStore={
                    config.currentTypicalConfigStore as ExploreConfigStore<SpaceBuildingDto>
                  }
                  notAllowedToUpdate={
                    isSaveAvailable !== undefined ? !isSaveAvailable : false
                  }
                />
              )}

              {config.type === TypeObject.MapView && (
                <Maps
                  mapType={MapType.CreatePlot}
                  legendaItems={GetMapLegandaItems()}
                  showFloatingActionButton={true}
                  currentConfiguratorStore={hsaConfiguratorStore}
                />
              )}

              {config.type === TypeObject.ExportView && (
                <ExportedBuildingView
                  drawedPlot={drawedPlot}
                  residentialInformation={project.residentialInformation}
                  currentConfiguratorStore={hsaConfiguratorStore}
                  currentApartmentBuildings={currentApartmentBuildings}
                  selectedExportedBuilingsIds={
                    selectedExportedApartmentBuilingsIds
                  }
                  parking={parking}
                />
              )}
            </div>
          ) : (
            <div className="counting-model-container">
              <div className="counting-model">
                <CalculationModel
                  isSaveAvailable={isSaveAvailable}
                  currentApartmentBuildings={currentApartmentBuildings}
                  selectedExportedBuilingsIds={
                    selectedExportedApartmentBuilingsIds
                  }
                />
              </div>
            </div>
          )}
        </TabPanel>
      ))}

      <CreateOrUpdateProjectView
        typeProject={ProjectTypeEnum.HSA}
        typeObject={tabConfigurations[currentTabPageIndex].type}
        handleSave={handleSaveProject}
      />

      <BuildingsThumbnailGenerator
        buildings={apartmentBuildingsbuildings ?? []}
        loadedBuildings={apartmentLoadedBuildings}
      />
    </div>
  );
}
