import {
  Box,
  Checkbox,
  Chip,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import {
  AxisConfig,
  createExploreStore,
} from "../../../../sharedLogic/state/ExploreState/ExploreState";
import { ExploreConfigStore } from "../../../../sharedLogic/types/ExploreConfig";
import InfoField, {
  DetailedInfo,
} from "../../../../sharedLogic/types/InfoField";
import { SpaceBuildingDto } from "../../../types/api";
import PCGAxesDto from "../../../../sharedLogic/types/PCGAxesDto";
import UnitMix from "../../UnitMix/UnitMix";
import { SpaceType } from "../../../types/enums";
import { getColor } from "../../../utils/colors";
import { IsStringUndefinedOrNullOrEmpty } from "../../../../sharedLogic/utils/stringUtils";
import { UnitType } from "../../../../sharedLogic/types/UnitType";
import CustomFormControlWithInfoButton from "../../../../../components/CustomFormControl/CustomFormControlWithInfoButton";
import CustomInputLabelWithInfoButton from "../../../../../components/InputLabel/CustomInputLabelWithInfoButton";

export const BuildingDetailedInfo: DetailedInfo[] = [
  {
    category: "General",
    fields: [
      {
        label: "StackedHousing.AccessType",
        property: "accessType",
        unit: "",
      },
      {
        label: "StackedHousing.Apartment",
        property: "nrOfAppartments",
        unit: "st",
        type: "number",
      },
      {
        label: "StackedHousing.Floors",
        property: "nrOfFloors",
        unit: "st",
        type: "number",
      },
      {
        label: "StackedHousing.UnitMix",
        property: "unitMix",
        type: "unitMix",
        unit: "",
      },
    ],
  },
  {
    category: "Dimensions",
    fields: [
      {
        label: "Length",
        property: "dimensions.xSizeInM",
        unit: "m",
        type: "number",
      },
      {
        label: "Width",
        property: "dimensions.ySizeInM",
        unit: "m",
        type: "number",
      },
      {
        label: "Height",
        property: "dimensions.zSizeInM",
        unit: "m",
        type: "number",
      },
      {
        label: "StackedHousing.BVOTotal",
        property: "grossFloorAreaInM2",
        unit: "m²",
        type: "number",
      },
      {
        label: "StackedHousing.BVOHousing",
        property: "grossFloorAreaLivingInM2",
        unit: "m²",
        type: "number",
      },
      {
        label: "StackedHousing.BVOOutdoor",
        property: "grossFloorAreaOutdoorInM2",
        unit: "m²",
        type: "number",
      },
      {
        label: "StackedHousing.GOHousing",
        property: "usableAreaInM2",
        unit: "m²",
        type: "number",
      },
      {
        label: "StackedHousing.BBO",
        property: "grossFootprintAreaInM2",
        unit: "m²",
        type: "number",
      },
      {
        label: "StackedHousing.BGO",
        property: "usableAreaInM2",
        unit: "m²",
        type: "number",
      },
      {
        label: "StackedHousing.BDO",
        property: "grossRoofAreaInM2",
        unit: "m²",
        type: "number",
      },
    ],
  },
  {
    category: "Efficiency",
    fields: [
      {
        label: "StackedHousing.FSI",
        property: "fsi",
        unit: "-",
        type: "number",
      },
      {
        label: "StackedHousing.GO/BVO",
        property: "goBvo",
        unit: "-",
        type: "number",
      },
      {
        label: "StackedHousing.ValuePerGo",
        property: "costInEuroPerGo",
        unit: "€/m²",
        type: "number",
      },
      {
        label: "StackedHousing.CostInEuroPerBVO",
        property: "costInEuroPerBvo",
        unit: "€/m²",
        type: "number",
      },
      {
        label: "StackedHousing.ValuePerApp",
        property: "valuePerAppartment",
        unit: "€/st",
        type: "number",
      },
    ],
  },
  {
    category: "Valuation",
    fields: [
      {
        label: "StackedHousing.IndicationBuildingBudget",
        property: "costInEuro",
        unit: "€",
        type: "number",
      },
      {
        label: "StackedHousing.InvestmentValue",
        property: "investmentValue",
        unit: "€",
        type: "number",
      },
      {
        label: "StackedHousing.RentPriceBuilding",
        property: "rentalPrice",
        unit: "€",
        type: "number",
      },
      {
        label: "StackedHousing.CO₂",
        property: "co2Footprint",
        unit: "kg",
        type: "number",
      },
    ],
  },
  {
    category: "Components",
    fields: [
      {
        label: "Components",
        property: "spaceCodeCounts",
        type: "componentsList",
      },
    ],
  },
];

export const useBuildingConfigStore = (
  accessTypeOptions: string[],
  selectedAccessTypes: string[],
  setSelectedAccessTypes: (options: string[]) => void,
  isSaveAvailable: boolean
): ExploreConfigStore<SpaceBuildingDto> => {
  const currentStore = useMemo(
    () => createExploreStore<SpaceBuildingDto>(),
    []
  );

  const [buildingsLevelUnits, setBuildingsLevelUnits] = useState<UnitType[]>([
    {
      name: SpaceType.Appartment1K,
      color: getColor(SpaceType.Appartment1K),
      minValue: 0,
      maxValue: 250,
      value: [0, 250],
    },
    {
      name: SpaceType.Appartment2K,
      color: getColor(SpaceType.Appartment2K),
      minValue: 0,
      maxValue: 250,
      value: [0, 250],
    },
    {
      name: SpaceType.Appartment3K,
      color: getColor(SpaceType.Appartment3K),
      minValue: 0,
      maxValue: 250,
      value: [0, 250],
    },
    {
      name: SpaceType.Appartment4K,
      color: getColor(SpaceType.Appartment4K),
      minValue: 0,
      maxValue: 250,
      value: [0, 250],
    },
  ] as UnitType[]);

  const detailedInfo = BuildingDetailedInfo;

  const {
    objects,
    brushedObjects,
    setFilteredObjects,
    setFilteredObjectsBrushedOnPCG,
  } = currentStore();

  useEffect(() => {
    if (objects !== null) {
      handleObjectFilters(objects ?? []);
    }
  }, [selectedAccessTypes, buildingsLevelUnits]);

  const sortByOptionsDictionary: { [key: string]: string } = {
    "Best fit rate": "value",
  };

  const defaultSelectedAxes: PCGAxesDto[] = [
    { axis: "goBvo", isColorReversed: false },
  ];

  const summaryInfo: InfoField[] = [
    { label: "StackedHousing.AccessType", property: "accessType", unit: "" },
    { label: "Apartments", property: "nrOfAppartments", unit: "st" },
    {
      label: "StackedHousing.UnitMix",
      property: "unitMix",
      type: "unitMix",
      unit: "",
    },
  ];

  const dimensions = [
    { dimension: "nrOfAppartments", format: "integer" },
    { dimension: "structuralHeightInM", format: "integer" },
    { dimension: "lengthInM", format: "integer" },
    { dimension: "nrOfFloors", format: "integer" },
    { dimension: "grossFloorAreaInM2", format: "float" },
    { dimension: "grossFootprintAreaInM2", format: "float" },
    { dimension: "usableAreaInM2", format: "float" },
    { dimension: "fsi", format: "float" },
    { dimension: "goBvo", format: "float" },
    { dimension: "costInEuro", format: "float" },
    { dimension: "costInEuroPerGo", format: "float" },
    { dimension: "costInEuroPerBvo", format: "float" },
    { dimension: "investmentValue", format: "float" },
    { dimension: "investmentValuePerGo", format: "float" },
    { dimension: "co2", format: "float" },
    { dimension: "costsVSRevenues", format: "float" },
  ] as AxisConfig[];

  const chartLabels = [
    "StackedHousing.BuildingGraph.Appartments",
    "StackedHousing.BuildingGraph.Height",
    "StackedHousing.BuildingGraph.Length",
    "StackedHousing.BuildingGraph.Floors",
    "StackedHousing.BuildingGraph.BVO",
    "StackedHousing.BuildingGraph.BBO",
    "StackedHousing.BuildingGraph.GO",
    "StackedHousing.BuildingGraph.FSI",
    "StackedHousing.BuildingGraph.GO/BVO",
    "StackedHousing.BuildingGraph.IndicationBuildingBudget",
    "StackedHousing.BuildingGraph.IndicationBuildingBudgetPerGo",
    "StackedHousing.BuildingGraph.CostInEuroPerBVO",
    "StackedHousing.BuildingGraph.InvestmentValue",
    "StackedHousing.BuildingGraph.ValuePerGo",
    "StackedHousing.BuildingGraph.CO₂",
    "StackedHousing.BuildingGraph.CostsVSRevenues",
  ];

  const clickableAxes = [
    "nrOfAppartments",
    "structuralHeightInM",
    "lengthInM",
    "nrOfFloors",
    "grossFloorAreaInM2",
    "goBvo",
  ];
  const bestFitOptionsDictionary: PCGAxesDto[] = [
    { axis: "nrOfAppartments", isColorReversed: false },
    { axis: "structuralHeightInM", isColorReversed: false },
    { axis: "lengthInM", isColorReversed: false },
    { axis: "nrOfFloors", isColorReversed: false },
    { axis: "grossFloorAreaInM2", isColorReversed: false },
    { axis: "grossFootprintAreaInM2", isColorReversed: false },
    { axis: "usableAreaInM2", isColorReversed: false },
    { axis: "fsi", isColorReversed: false },
    { axis: "goBvo", isColorReversed: false },
    { axis: "costInEuro", isColorReversed: true },
    { axis: "costInEuroPerGo", isColorReversed: true },
    { axis: "costInEuroPerBvo", isColorReversed: true },
    { axis: "investmentValue", isColorReversed: false },
    { axis: "investmentValuePerGo", isColorReversed: false },
    { axis: "co2", isColorReversed: true },
    { axis: "costsVSRevenues", isColorReversed: true },
  ];

  const bestFitOptionsDictionaryReversed = {
    nrOfAppartments: "StackedHousing.BuildingGraph.Appartments",
    structuralHeightInM: "StackedHousing.BuildingGraph.Height",
    lengthInM: "StackedHousing.BuildingGraph.Length",
    nrOfFloors: "StackedHousing.BuildingGraph.Floors",
    grossFloorAreaInM2: "StackedHousing.BuildingGraph.BVO",
    grossFootprintAreaInM2: "StackedHousing.BuildingGraph.BBO",
    usableAreaInM2: "StackedHousing.BuildingGraph.GO",
    fsi: "StackedHousing.BuildingGraph.FSI",
    goBvo: "StackedHousing.BuildingGraph.GO/BVO",
    costInEuro: "StackedHousing.BuildingGraph.IndicationBuildingBudget",
    costInEuroPerGo:
      "StackedHousing.BuildingGraph.IndicationBuildingBudgetPerGo",
    costInEuroPerBvo: "StackedHousing.BuildingGraph.CostInEuroPerBVO",
    investmentValue: "StackedHousing.BuildingGraph.InvestmentValue",
    investmentValuePerGo: "StackedHousing.BuildingGraph.ValuePerGo",
    co2: "StackedHousing.BuildingGraph.CO₂",
    costsVSRevenues: "StackedHousing.BuildingGraph.CostsVSRevenues",
  };

  const inputPaneContent = () => (
    <>
      <div className="title">{""}</div>
      <CustomFormControlWithInfoButton labelKey="StackedHousing.AccessType">
        <Select
          multiple
          disabled={!isSaveAvailable}
          value={selectedAccessTypes}
          style={{ height: "auto" }}
          labelId="type-power-rack-label"
          id="type-power-rack-select"
          onChange={handleChangeAccessType}
          label={t("StackedHousing.AccessType")}
          renderValue={(selected) => (
            <Box
              component={"div"}
              sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
            >
              {selected.map((value) => (
                <Chip key={value} label={t(value)} />
              ))}
            </Box>
          )}
        >
          <MenuItem value="all">
            <ListItemIcon>
              <Checkbox
                checked={
                  accessTypeOptions !== undefined &&
                  selectedAccessTypes !== undefined &&
                  accessTypeOptions.length > 0 &&
                  selectedAccessTypes.length === accessTypeOptions.length
                }
                indeterminate={
                  accessTypeOptions !== undefined &&
                  selectedAccessTypes !== undefined &&
                  selectedAccessTypes.length > 0 &&
                  selectedAccessTypes.length < accessTypeOptions.length
                }
              />
            </ListItemIcon>
            <ListItemText primary={t("Select all")} />
          </MenuItem>
          {accessTypeOptions?.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={selectedAccessTypes?.indexOf(name) > -1} />
              <ListItemText primary={t(name)} />
            </MenuItem>
          ))}
        </Select>
      </CustomFormControlWithInfoButton>
      <div className="mt20 mb40">
        <CustomInputLabelWithInfoButton
          id="unit-mix-label"
          labelKey="StackedHousing.UnitMixBuilding"
        />
        <UnitMix
          disabled={!isSaveAvailable}
          units={buildingsLevelUnits}
          setUnits={setBuildingsLevelUnits}
          showSlider={false}
        />
      </div>
    </>
  );

  const handleChangeAccessType = (event: SelectChangeEvent<string[]>) => {
    const selectedOptions = event.target.value;
    const valuesAccessType = Object.values(accessTypeOptions);

    if (selectedOptions.includes("all")) {
      setSelectedAccessTypes(
        selectedAccessTypes.length === valuesAccessType.length
          ? []
          : valuesAccessType
      );
    } else {
      const selectedAccessTypes = valuesAccessType.filter((accessType) =>
        accessType
          ? selectedOptions.includes(accessType.toString())
          : selectedOptions.includes("-1")
      );
      setSelectedAccessTypes(selectedAccessTypes);
    }
  };

  const handleObjectFilters = (objects: SpaceBuildingDto[]) => {
    let filteredBuidings = objects;
    if (selectedAccessTypes.length !== 0) {
      filteredBuidings = filteredBuidings.filter((building) => {
        return selectedAccessTypes.includes(building.accessType);
      });
    }
    if (
      buildingsLevelUnits.length !== 0 &&
      !buildingsLevelUnits.every((unit) =>
        IsStringUndefinedOrNullOrEmpty(unit.ratio?.toString() ?? "")
      )
    ) {
      filteredBuidings = filteredBuidings.filter((building) => {
        return buildingsLevelUnits.some((unit) => {
          return building.unitMix.some(
            (unitMix) =>
              unitMix.name === unit.name &&
              unit.ratio &&
              (unitMix.ratio ?? 0) > (unit.ratio ?? 0) - 20 &&
              (unitMix.ratio ?? 0) < (unit.ratio ?? 0) + 20
          );
        });
      });
    }

    setFilteredObjects(filteredBuidings);
    setFilteredObjectsBrushedOnPCG(filteredBuidings);

    return filteredBuidings;
  };

  function handleCustomFilter() {
    const tempWhitespaces = [...brushedObjects];
    return tempWhitespaces;
  }

  return {
    setBuildingsLevelUnits,
    sortByOptionsDictionary,
    currentStore,
    summaryInfo,
    detailedInfo,
    inputPaneContent,
    dimensions,
    chartLabels,
    clickableAxes,
    bestFitOptionsDictionary,
    bestFitOptionsDictionaryReversed,
    handleObjectFilters,
    handleCustomFilter,
    defaultSelectedAxes,
  };
};
