import { SelectedBuildingsDto } from "../../../../../../../interfaces/foundry/SelectedBuildingsDto";
import { ProjectFoundryDto } from "../../../../../types/api";

export function GetSelectedBuildings(
  currentProject: ProjectFoundryDto
): SelectedBuildingsDto {
  const selectedBuildings = currentProject.buildings
    ? (JSON.parse(currentProject.buildings) as SelectedBuildingsDto)
    : ({} as SelectedBuildingsDto);
  return selectedBuildings;
}
