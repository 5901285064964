import { Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import ComponentCard from "../../components/ComponentCard/ComponentCard";
import { GetAvailableOverview } from "../../http/AvailableComponentsService";
import useUserStore from "../../modules/sharedLogic/state/userState";
import useAvailableComponentsStore from "../../state/availableComponentsState";
import useLayoutStore from "../../modules/sharedLogic/state/layoutState";
import "./OverviewPage.scss";
import { Subjects } from "../../modules/sharedLogic/authentication/PermissionsManager";

export default function OverviewPage() {
  const { t } = useTranslation();
  const { setShowBreadcrumb } = useLayoutStore();
  const { availableOverview, setAvailableOverview } =
    useAvailableComponentsStore();
  const { appAbility } = useUserStore();

  React.useEffect(() => {
    setShowBreadcrumb(false);
    if (availableOverview.length === 0) {
      setAvailableOverview(GetAvailableOverview());
    }
  }, []);

  return (
    <div className="overview-page">
      <div className="titel mb25">
        {t("What can Heijmans Synergy do for you?")}
      </div>
      <Grid container spacing={2}>
        {availableOverview
          .filter((overview) =>
            appAbility?.can(
              "read",
              `${overview.title.toLowerCase().replace(/\s/g, "")}` as Subjects
            )
          )
          .map((data) => (
            <ComponentCard key={data.title} componentDto={data} />
          ))}
      </Grid>
    </div>
  );
}
