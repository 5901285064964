import React from "react";
import PercentageInput from "../../PercentageInput/PercentageInput";
import NumberField from "../../PropertySectionView/NumberField";
import CustomInputLabelWithInfoButton from "../../../../../components/InputLabel/CustomInputLabelWithInfoButton";

interface CustomFieldWithPercentageProps {
  labelKey: string;
  percentage: number | null;
  value: number;
  disabled?: boolean;
  onPercentageChange?: (percentage: number | null) => void;
}

const CustomFieldWithPercentage: React.FC<CustomFieldWithPercentageProps> = ({
  labelKey,
  percentage,
  value,
  disabled = false,
  onPercentageChange,
}) => {
  return (
    <div className="mt10">
      <CustomInputLabelWithInfoButton
        id={"buildable-area-label"}
        labelKey={labelKey}
      />
      <div
        style={{
          display: "flex",
          borderBottom: "1px solid #acacac",
          paddingBottom: "2px",
          marginTop: "-10px",
          width: "100%",
        }}
      >
        <PercentageInput
          disabled={disabled}
          value={percentage}
          onChange={(value) => onPercentageChange?.(value)}
        />
        <NumberField
          onChange={() => {}}
          disabled={true}
          value={value}
          unit={"m²"}
        />
      </div>
    </div>
  );
};

export default CustomFieldWithPercentage;
