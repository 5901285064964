export type RelationshipType =
  | "Above"
  | "Below"
  | "Left"
  | "Right"
  | "Front"
  | "Back"
  | "InOtherSpace"
  | "LeftRight"
  | "BelowAbove"
  | "RightBelowAbove"
  | "LeftBelowAbove"
  | "LeftRightBelowAbove"
  | "LeftBelow"
  | "RightBelow"
  | "LeftAbove"
  | "RightAbove"
  | "LeftFront"
  | "RightBack";

export interface RelationMatrixEntry {
  moduleCode: string;
  codeBusiness: string;
  moduleCodeRelations: { [key: string]: RelationshipType[] };
}

export interface RelationshipDto {
  refSpaceCode: string;
  neighbourSpaceCode: string;
  directions: RelationshipType[];
}

export const relationshipTypeData: {
  [key in RelationshipType]: { letter: string; baseColor: string };
} = {
  Below: { letter: "U", baseColor: "RGB(252, 228, 214)" }, //U : O
  Above: { letter: "A", baseColor: "RGB(255, 242, 204)" }, //A : B
  Left: { letter: "L", baseColor: "RGB(221, 235, 247)" }, //L : L
  Right: { letter: "R", baseColor: "RGB(214, 220, 228)" }, //R : R
  Front: { letter: "F", baseColor: "RGB(255, 217, 105)" }, //F : V
  Back: { letter: "B", baseColor: "RGB(255, 217, 105)" }, // B : A
  InOtherSpace: { letter: "I", baseColor: "RGB(255, 192, 0)" }, // I : I
  LeftRight: { letter: "RL", baseColor: "RGB(217, 255, 242)" },
  BelowAbove: { letter: "UA", baseColor: "RGB(226, 239, 218)" },
  RightBelowAbove: { letter: "RUA", baseColor: "RGB(198, 224, 180)" },
  LeftBelowAbove: { letter: "LUA", baseColor: "RGB(198, 224, 180)" },
  LeftRightBelowAbove: { letter: "RLUA", baseColor: "RGB(169, 208, 142)" },
  LeftBelow: { letter: "LU", baseColor: "RGB(197, 158, 226)" },
  RightBelow: { letter: "RU", baseColor: "RGB(197, 158, 226)" },
  LeftAbove: { letter: "LA", baseColor: "RGB(197, 158, 226)" },
  RightAbove: { letter: "RA", baseColor: "RGB(197, 158, 226)" },
  LeftFront: { letter: "FL", baseColor: "RGB(235, 240, 164)" },
  RightBack: { letter: "BR", baseColor: "RGB(235, 240, 164)" },
};

export interface FilterOptions {
  spaceTypes: string[];
  buildingLayers: string[];
  moduleTypes: string[];
}
