import { Map } from "mapbox-gl";
import { useEffect, useRef } from "react";
import { StoreApi, UseBoundStore } from "zustand";
import {
  DatacenterGeneratorActions,
  DatacenterGeneratorState,
} from "../../../../../../../state/DatacenterState/datacenterGeneratorState";
import DrawedPlot, {
  GetEmptyDrawedPlot,
} from "../../../../../types/DrawedPlot";
import { CalculateDistanceAOrNRoad } from "../../PdokDistanceCalculator";
import { PlotDrawControl } from "./PlotDrawControl";

interface PlotDrawControlViewProps {
  map: Map | null;
  currentStore: UseBoundStore<
    StoreApi<DatacenterGeneratorState & DatacenterGeneratorActions>
  >;
}

export default function PlotDrawControlView({
  map,
  currentStore,
}: PlotDrawControlViewProps) {
  const drawControlRef = useRef<PlotDrawControl>();
  const {
    drawedPlot,
    startDrawingPlot,
    deleteDrawingPlot,
    editDrawingPlot,
    calculateDistance,
    drawControlMode,
    currentProject,
    setEditDrawingPlot,
    setDeleteDrawingPlot,
    setDrawedPlot,
    setNavigateDrawingPlot,
    setStartDrawingPlot,
    setDistanceBetweenPlotAndRoad,
    setCalculateDistance,
    setIsSaveAvailable,
    setCurrentProject,
  } = currentStore();

  useEffect(() => {
    if (!map) return;

    drawControlRef.current = new PlotDrawControl({
      currentProject: currentProject,
      drawedPlot: drawedPlot,
      setDrawedPlot: setDrawedPlot,
      setCurrentProject: setCurrentProject,
      setIsSaveAvailable: setIsSaveAvailable,
      setNavigateDrawingPlot: setNavigateDrawingPlot,
    });

    map.addControl(drawControlRef.current);

    map.resize();
  }, [map]);

  useEffect(() => {
    if (
      drawControlMode &&
      drawControlMode !== "" &&
      map &&
      drawControlRef.current
    ) {
      drawControlRef.current.getDrawControl().changeMode(drawControlMode);
    }
  }, [drawControlMode]);

  useEffect(() => {
    if (startDrawingPlot && drawControlRef.current) {
      drawControlRef.current.getDrawControl().changeMode("draw_polygon");
      setStartDrawingPlot(false);
    }
  }, [startDrawingPlot]);

  useEffect(() => {
    if (editDrawingPlot && drawControlRef.current) {
      drawControlRef.current.getDrawControl().changeMode("direct_select", {
        featureId: drawedPlot.id,
      });
      setEditDrawingPlot(false);
      setNavigateDrawingPlot(true);
    }
  }, [editDrawingPlot]);

  useEffect(() => {
    if (map && deleteDrawingPlot && drawControlRef.current) {
      drawControlRef.current.getDrawControl().deleteAll();

      if (map.getLayer("route")) map.removeLayer("route");
      if (map.getSource("route")) map.removeSource("route");

      setDistanceBetweenPlotAndRoad(0);
      setDrawedPlot(GetEmptyDrawedPlot());
      setDeleteDrawingPlot(false);
    }
  }, [deleteDrawingPlot]);

  useEffect(() => {
    if (
      map &&
      calculateDistance &&
      drawedPlot.buildableCoordinates.length > 0
    ) {
      CalculateDistanceAOrNRoad(
        map,
        drawedPlot as DrawedPlot,
        setDistanceBetweenPlotAndRoad
      );
      setCalculateDistance(false);
    }
  }, [calculateDistance]);

  return null;
}
