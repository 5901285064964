import { Delete } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { GridColDef, GridSortModel } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import StyledDataGrid from "../../../../components/StyledDataGrid/StyledDataGrid";
import { ButtonDefinition } from "../../types/ButtonDefinition";
import useUserStore from "../../state/userState";
import { GridApiCommunity } from "@mui/x-data-grid/internals";

export interface FilterDefinition {
  key: string;
  label: string;
  type: "checkbox" | "range" | "text";
  options?: string[];
}

export interface FilterableDataGridProps<T> {
  data: T[];
  loading: boolean;
  columns: GridColDef[];
  idPropertyName: string;
  buttons?: ButtonDefinition[];
  sortModel?: GridSortModel;
  gridRef?: React.MutableRefObject<GridApiCommunity>;
  onSortModelChange?: (model: GridSortModel) => void;
  openDetails?: (code: string) => void;
  deleteItem?: (code: string) => void;
}

export function FilterableDataGrid<T>({
  data,
  loading,
  columns,
  idPropertyName,
  sortModel,
  gridRef,
  openDetails,
  deleteItem,
  onSortModelChange,
}: FilterableDataGridProps<T>) {
  const { t } = useTranslation();
  const { emailAddress } = useUserStore();

  const actionColumn: GridColDef = {
    field: "actions",
    width: 150,
    headerName: "",
    renderCell: ({ row }) => {
      const createdBy = row.owner as string;
      return (
        <div className="full-height center-center pr20">
          {openDetails && (
            <Button
              onClick={() => openDetails(row[idPropertyName])}
              style={{ width: "150px", height: "30px" }}
              className="details-button"
              type="submit"
              variant="text"
            >
              {t("Details")}
            </Button>
          )}
          {deleteItem &&
            createdBy &&
            (!createdBy.includes("@") || createdBy === emailAddress) && (
              <IconButton
                style={{
                  display: "flex",
                  height: "40px",
                }}
                onClick={() => deleteItem(row[idPropertyName])}
              >
                <Delete
                  className="icon"
                  style={{ fontSize: "2rem", fill: "rgba(0, 52, 102, 1)" }}
                />
              </IconButton>
            )}
        </div>
      );
    },
  };

  const allColumns = [...columns, actionColumn];

  return (
    <StyledDataGrid
      apiRef={gridRef}
      rows={data}
      loading={loading}
      columns={allColumns}
      getRowHeight={() => 100}
      onRowDoubleClick={(params) => openDetails && openDetails(params.row.code)}
      showCellHorizontalBorders={true}
      getRowId={(row) => row[idPropertyName]}
      sortModel={sortModel}
      onSortModelChange={onSortModelChange}
    />
  );
}
