import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as turf from "@turf/turf";

interface CustomRotateModeProps {
  map: mapboxgl.Map;
  featureType: string;
  setCurrentFeatureCollection: React.Dispatch<
    React.SetStateAction<GeoJSON.FeatureCollection>
  >;
}

export const createCustomRotateMode = ({
  map,
  featureType,
  setCurrentFeatureCollection,
}: CustomRotateModeProps) => {
  const rotateMode = { ...MapboxDraw.modes.simple_select };
  let isRotating = false;
  let initialClickPoint: [number, number] | null = null;
  let selectedBuildingName: string | null = null;
  let centerOfRotation: [number, number] | null = null;

  rotateMode.onMouseDown = function (_state, e: mapboxgl.MapMouseEvent) {
    if (e.originalEvent.button !== 0) return;

    const clickedFeatures = map.queryRenderedFeatures(e.point, {
      layers: [`${featureType}-3d`],
    });

    const selectedFeature = clickedFeatures[0];
    if (selectedFeature && selectedFeature.properties?.buildingName) {
      selectedBuildingName = selectedFeature.properties.buildingName;
      isRotating = true;
      initialClickPoint = [e.lngLat.lng, e.lngLat.lat];

      const buildingFeatures = clickedFeatures.filter(
        (f: GeoJSON.Feature) =>
          f.properties?.buildingName === selectedBuildingName
      );

      if (buildingFeatures.length > 0) {
        try {
          const validPolygonFeatures = buildingFeatures.filter(
            (feature: GeoJSON.Feature) => feature.geometry.type === "Polygon"
          );
          const buildingPolygon = turf.combine({
            type: "FeatureCollection",
            features: validPolygonFeatures as turf.Feature<turf.Polygon>[],
          });
          const centerPoint = turf.center(buildingPolygon);
          if (
            centerPoint &&
            centerPoint.geometry &&
            Array.isArray(centerPoint.geometry.coordinates)
          ) {
            centerOfRotation = centerPoint.geometry.coordinates as [
              number,
              number
            ];
          } else {
            throw new Error("Invalid center point");
          }
        } catch (error) {
          console.error("Error calculating center of rotation:", error);
          isRotating = false;
          return;
        }
      } else {
        console.error("No features found for the selected building");
        isRotating = false;
        return;
      }

      map.dragPan.disable();
      map.scrollZoom.disable();
      map.doubleClickZoom.disable();

      map.on("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }
  };

  const handleMouseMove = (e: mapboxgl.MapMouseEvent) => {
    if (
      isRotating &&
      initialClickPoint &&
      selectedBuildingName &&
      centerOfRotation
    ) {
      const currentPoint: [number, number] = [e.lngLat.lng, e.lngLat.lat];
      try {
        const initialAngle = turf.bearing(centerOfRotation, initialClickPoint);
        const currentAngle = turf.bearing(centerOfRotation, currentPoint);
        const rotationAngle = currentAngle - initialAngle;

        setCurrentFeatureCollection((prevFeatures) => {
          const newFeatures = prevFeatures.features.map((feature) => {
            if (feature.properties?.buildingName === selectedBuildingName) {
              try {
                const rotatedFeature = turf.transformRotate(
                  feature as turf.AllGeoJSON,
                  rotationAngle,
                  {
                    pivot: centerOfRotation as turf.helpers.Coord,
                  }
                );
                return rotatedFeature as GeoJSON.Feature;
              } catch (error) {
                console.error("Error rotating feature:", error);
                return feature;
              }
            }
            return feature;
          });
          return { ...prevFeatures, features: newFeatures };
        });

        initialClickPoint = currentPoint;
      } catch (error) {
        console.error("Error during rotation:", error);
      }
    }
  };

  const handleMouseUp = () => {
    if (isRotating) {
      isRotating = false;
      initialClickPoint = null;
      selectedBuildingName = null;
      centerOfRotation = null;

      map.dragPan.enable();
      map.scrollZoom.enable();
      map.doubleClickZoom.enable();
      map.off("mousemove", handleMouseMove);

      document.removeEventListener("mouseup", handleMouseUp);
    }
  };

  return rotateMode;
};
